import { COHORT_TYPE, INTERVAL_TYPE } from '@tellonym/enums/lib/Stats'
import dayjs from 'dayjs'
import { pick } from 'ramda'
import * as t from './types'

const statsLimit = 1000

export const changeCompareKey = (payload) => ({
  type: t.CHANGE_COMPARE_KEY,
  payload,
})

export const refreshGraphData = ({ limit = 12, ...payload }) => ({
  type: t.REFRESH_GRAPH_DATA,
  payload,
  meta: {
    offline: {
      shouldNotEnqueueMultiple: true,
      effect: { path: 'admin/stats/shortnames', method: 'POST', limit },
      commit: { type: t.REFRESH_GRAPH_DATA_SUCCESS, meta: { limit } },
      rollback: { type: t.REFRESH_GRAPH_DATA_ERROR },
    },
  },
})

export const refreshChGraphData = (payload) => {
  const params = pick(
    ['cohort', 'countryCode', 'intervalType', 'experimentId'],
    payload
  )

  const startDate =
    payload.startDate ??
    dayjs(payload.endDate)
      .subtract(payload.timespan, 'days')
      .format('YYYY-MM-DD')
  const experimentId = params.experimentId
    ? Number(params.experimentId)
    : undefined

  return {
    shouldAdjustData: false,
    type: t.REFRESH_CH_GRAPH_DATA,
    payload: {
      ...params,
      experimentId,
      shortNames: [payload.shortName],
      dateFrom: startDate,
      dateTo: payload.endDate,
    },
    meta: {
      offline: {
        effect: {
          path: 'admin/stats/clickhouse',
          limit: null,
          method: 'POST',
        },
        commit: { type: t.REFRESH_CH_GRAPH_DATA_SUCCESS },
        rollback: { type: t.REFRESH_CH_GRAPH_DATA_ERROR },
      },
    },
  }
}

export const refreshAll = ({ limit = 100, ...payload }) => ({
  type: t.REFRESH_ALL,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/stats/shortnames', method: 'POST', limit },
      commit: { type: t.REFRESH_ALL_SUCCESS },
      rollback: { type: t.REFRESH_ALL_ERROR },
    },
  },
})

export const refreshAvailableShortNames = (payload) => ({
  type: t.REFRESH_AVAILABLE_SHORT_NAMES,
  meta: {
    offline: {
      effect: {
        path: 'admin/stats/availableshortnames',
        ...payload,
        limit: statsLimit,
      },
      commit: { type: t.REFRESH_AVAILABLE_SHORT_NAMES_SUCCESS },
      rollback: { type: t.REFRESH_AVAILABLE_SHORT_NAMES_ERROR },
    },
  },
})

export const refreshChDiff = (payload) => {
  const params = pick(
    ['cohort', 'countryCode', 'shortNames', 'intervalType', 'experimentId'],
    payload
  )

  const startDate =
    payload.startDate ??
    dayjs(payload.endDate)
      .subtract(payload.timespan, 'days')
      .format('YYYY-MM-DD')
  const experimentId = params.experimentId
    ? Number(params.experimentId)
    : undefined

  return {
    type: t.REFRESH_CH_DIFF,
    payload: {
      ...params,
      experimentId,
      dateFrom: startDate,
      dateTo: payload.endDate,
    },
    meta: {
      offline: {
        effect: {
          path: 'admin/stats/clickhouse',
          limit: null,
          method: 'POST',
        },
        commit: { type: t.REFRESH_CH_DIFF_SUCCESS },
        rollback: { type: t.REFRESH_CH_DIFF_ERROR },
      },
    },
  }
}

export const refreshChShortnames = (payload) => ({
  type: t.REFRESH_CH_SHORTNAMES,
  payload,
  meta: {
    offline: {
      effect: { path: 'admin/stats/clickhouse/shortnames', limit: null },
      commit: { type: t.REFRESH_CH_SHORTNAMES_SUCCESS },
      rollback: { type: t.REFRESH_CH_SHORTNAMES_ERROR },
    },
  },
})

export const refreshDiff = ({
  cohorts: _cohorts,
  timespan: _timespan,
  intervalType: _intervalType,
  ...payload
}) => {
  const cohortTypes = _cohorts
    ? _cohorts.split(',').map((v) => {
        const cohortAsNum = Number(v.trim())

        if (Number.isNaN(cohortAsNum)) {
          return COHORT_TYPE[v.trim()]
        }

        return cohortAsNum
      })
    : undefined
  // we need to fetch more data than the user wants to see to calculate the change for the day/week before
  const intervalType = Number(_intervalType)
  const additionalTimespan = intervalType === INTERVAL_TYPE.DAILY ? 7 : 1
  const timespan = Number(_timespan) + additionalTimespan

  return {
    type: t.REFRESH_DIFF,
    payload: {
      cohortTypes,
      timespan,
      intervalType,
      ...payload,
    },
    meta: {
      offline: {
        effect: { path: 'admin/stats/diff', method: 'POST', limit: statsLimit },
        commit: {
          type: t.REFRESH_DIFF_SUCCESS,
          meta: { endDate: payload.endDate, timespan, intervalType },
        },
        rollback: { type: t.REFRESH_DIFF_ERROR },
      },
    },
  }
}

export const refreshExperiments = (payload) => {
  const intervalType = Number(payload.intervalType)
  const timespan = Number(payload.timespan)
  const experimentIds = [
    payload.controlGroupId,
    ...payload.experimentIds.split(','),
  ]

  return {
    type: t.REFRESH_EXPERIMENTS,
    payload: { ...payload, experimentIds, intervalType, timespan },
    meta: {
      offline: {
        effect: {
          path: 'admin/stats/experiments',
          method: 'POST',
          limit: statsLimit,
        },
        commit: {
          type: t.REFRESH_EXPERIMENTS_SUCCESS,
          meta: { controlGroupId: Number(payload.controlGroupId) },
        },
        rollback: { type: t.REFRESH_EXPERIMENTS_ERROR },
      },
    },
  }
}

export const refreshShortNames = ({ limit = 100, ...payload }) => ({
  type: t.REFRESH_SHORT_NAMES,
  payload: {
    limit,
    ...payload,
  },
  meta: {
    offline: {
      shouldNotEnqueueMultiple: true,
      effect: { path: 'admin/stats/shortnames', method: 'POST', limit },
      commit: { type: t.REFRESH_SHORT_NAMES_SUCCESS },
      rollback: { type: t.REFRESH_SHORT_NAMES_ERROR },
    },
  },
})

export const resetCsvPreview = (payload) => ({
  type: t.RESET_CSV_PREVIEW,
  payload,
})

export const uploadCsv = (payload) => {
  const formData = new FormData()

  if (payload.countryCode) {
    formData.append('countryCode', payload.countryCode)
  }

  formData.append('import', payload.file)
  formData.append('preview', payload.preview)
  formData.append('store', payload.store)

  return {
    type: t.UPLOAD_CSV,
    payload: { formData },
    meta: {
      offline: {
        effect: {
          path: 'admin/stats/import',
          method: 'POST',
          timeout: 61000,
        },
        commit: { type: t.UPLOAD_CSV_SUCCESS, meta: { payload } },
        rollback: { type: t.UPLOAD_CSV_ERROR, meta: { payload } },
      },
    },
  }
}
