export const CHANGE_COMMENT = 'comments/CHANGE_COMMENT'

export const CREATE_COMMENT = 'comments/CREATE_COMMENT'
export const CREATE_COMMENT_ERROR = 'comments/CREATE_COMMENT_ERROR'
export const CREATE_COMMENT_SUCCESS = 'comments/CREATE_COMMENT_SUCCESS'

export const FETCH = 'comments/FETCH'
export const FETCH_ERROR = 'comments/FETCH_ERROR'
export const FETCH_SUCCESS = 'comments/FETCH_SUCCESS'

export const LIKE_COMMENT = 'comments/LIKE_COMMENT'
export const LIKE_COMMENT_ERROR = 'comments/LIKE_COMMENT_ERROR'
export const LIKE_COMMENT_SUCCESS = 'comments/LIKE_COMMENT_SUCCESS'

export const REFRESH = 'comments/REFRESH'
export const REFRESH_ERROR = 'comments/REFRESH_ERROR'
export const REFRESH_SUCCESS = 'comments/REFRESH_SUCCESS'

export const REMOVE_COMMENT = 'comments/REMOVE_COMMENT'
export const REMOVE_COMMENT_ERROR = 'comments/REMOVE_COMMENT_ERROR'
export const REMOVE_COMMENT_SUCCESS = 'comments/REMOVE_COMMENT_SUCCESS'

export const UNLIKE_COMMENT = 'comments/UNLIKE_COMMENT'
export const UNLIKE_COMMENT_ERROR = 'comments/UNLIKE_COMMENT_ERROR'
export const UNLIKE_COMMENT_SUCCESS = 'comments/UNLIKE_COMMENT_SUCCESS'
