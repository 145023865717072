import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Button, FlatList, Text, View } from '../../common'
import {
  fetchNsfwPictures as fetch,
  refreshNsfwPictures as refresh,
  updateNsfwPictures,
} from '../actions'
import { getNsfwPictures } from '../selectors'
import { NsfwPictureItem } from './NsfwPictureItem'

const mapStateToProps = createStructuredSelector({
  nsfwPictures: getNsfwPictures,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetch, refresh, updateNsfwPictures }, dispatch),
})

export const PageNsfwPictures = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ actions, nsfwPictures }) => {
  const refFlatlist = useRef()

  useEffect(() => {
    actions.refresh()
  }, [])

  const { _rerenderItem, isFetching, isRefreshing, data } = nsfwPictures

  return (
    <FlatList
      _rerenderItem={_rerenderItem}
      actions={{
        refresh: actions.refresh,
      }}
      component={NsfwPictureItem}
      extraData={{
        isFetching,
        isRefreshing,
      }}
      forwardRef={refFlatlist}
      isFetching={isFetching}
      isRefreshing={isRefreshing}
      items={data}
      numColumns={8}
      renderHeader={() => (
        <View
          style={{
            alignSelf: 'center',
            alignItems: 'center',
            padding: 20,
            margin: 12,
          }}>
          <Text center style={{ marginBottom: 12 }}>
            Pressing [Update] saves the final decision for each visible image.
            Press an image once to delete the answer (red), press it twice to
            keep the answer but also keep the sensitive warning overlay
            (yellow). Right click an image to open the persons profile. The
            [Refresh] button simply reloads the View.
          </Text>
          <View
            style={{
              alignSelf: 'center',
              flexDirection: 'row',
              width: 240,
              justifyContent: 'space-around',
            }}>
            <Button disabled={isRefreshing} onPress={actions.refresh}>
              Refresh
            </Button>
            <Button
              disabled={isRefreshing || data.ids.length === 0}
              onPress={actions.updateNsfwPictures}>
              Update
            </Button>
          </View>
        </View>
      )}
    />
  )
})
