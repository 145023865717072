import { useQuery } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { normalize } from '@tellonym/core/helpers'
import React from 'react'
import { store } from '../common/store'
import { dateTypeToDates } from './helpers'

const spam = {
  all: ['spam'],
  list: (filters) => ['list', filters],
}

const fetchSpam = async ({ queryKey }) => {
  const { endDate, filterType, startDate, dateType } = queryKey[1]

  const accessToken = getAccessToken(store.getState())

  const dates = dateTypeToDates({ dateType, startDate, endDate })

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/spam/list',
      method: 'GET',
      accessToken,
      payload: {
        dateFrom: dates.startDate,
        dateTo: dates.endDate,
        filterType: filterType === 'none' ? undefined : filterType,
      },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useSpamQuery = (payload) => {
  const result = useQuery(spam.list(payload), fetchSpam)

  const data = React.useMemo(() => normalize(result.data ?? []), [result.data])

  return { ...result, data }
}
