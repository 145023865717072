import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { SPAM_DETECTION_TYPE } from '@tellonym/enums/lib/SpamDetecton'
import { Radio } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  ContentContainer,
  Icon,
  Text,
  TouchableOpacity,
  View,
} from '../../common'
import {
  refreshLastDetectedSpamItem,
  refreshPosts,
  refreshTells,
  refreshUsers,
  undoSpamItem,
} from '../actions'
import {
  getIsRefreshingLastDetectedSpamItem,
  getLastDetectedSpam,
  getSpamItem,
} from '../selectors'
import { PostsList } from './PostsList'
import { TellsList } from './TellsList'
import { UsersList } from './UsersList'

const stages = {
  INFO: 'Info',
  USERS: 'Users',
  TELLS: 'Tells',
  POSTS: 'Posts',
}

const stageOptions = Object.values(stages).map((value) => ({
  label: value,
  value,
}))

class _PageSpamItemInfo extends React.PureComponent {
  state = { stage: stages.INFO }

  componentDidMount() {
    const { actions, match } = this.props
    const spamItemId = Number(match.params.spamItemId)

    if (spamItemId) {
      actions.refreshLastDetectedSpamItem({ spamItemId })
    } else {
      this._goBack()
    }
  }

  _onChangeStage = (e) => {
    this.setState({ stage: e.target.value })
    this._refresh(e.target.value)
  }

  _goBack = () => {
    const { history } = this.props

    history.push('/spam')
  }

  _refresh = (type) => {
    const { actions, match } = this.props
    const spamItemId = Number(match.params.spamItemId)

    switch (type) {
      case stages.TELLS: {
        return actions.refreshTells({ spamItemId })
      }

      case stages.USERS: {
        return actions.refreshUsers({ spamItemId })
      }

      case stages.POSTS: {
        return actions.refreshPosts({ spamItemId })
      }

      default:
        return null
    }
  }

  _undoSpamItem = () => {
    const { actions, spamItem } = this.props

    actions.undo({ spamItemId: spamItem.id })
    this._goBack()
  }

  render() {
    const { isRefreshingItem, match, spamItem } = this.props
    const { stage } = this.state
    const spamItemId = Number(match.params.spamItemId)

    return (
      <ContentContainer isRefreshing={isRefreshingItem}>
        <View style={{ paddingHorizontal: 24, paddingTop: 24 }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={this._goBack}
              style={{
                marginLeft: 24,
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              <Icon icon={faAngleLeft} size="lg" />
              <Text style={{ marginLeft: 6 }}>Back</Text>
            </TouchableOpacity>
            <View style={{ flex: 1 }}>
              {spamItem && (
                <Radio.Group
                  optionType="button"
                  options={stageOptions}
                  onChange={this._onChangeStage}
                  value={stage}
                  size="large"
                  style={{ alignSelf: 'center' }}
                />
              )}
            </View>
            <View style={{}} />
          </View>
          <View style={{ paddingVertical: 24 }}>
            {!spamItem ? (
              <Text center type="h2">
                Item not found
              </Text>
            ) : (
              {
                [stages.INFO]: (
                  <View style={{ alignItems: 'flex-start' }}>
                    <Text
                      style={{
                        fontSize: 24,
                        marginBottom: 16,
                        color: '#68a1ff',
                        padding: '1px 8px',
                        border: '0.5px #68a1ff solid',
                        borderRadius: 5,
                      }}>
                      {SPAM_DETECTION_TYPE[spamItem.type]
                        .replace(/_/g, ' ')
                        .toLowerCase() || 'Unknown'}
                    </Text>
                    <Text style={{ fontSize: 22, marginBottom: 6 }}>
                      Blocked users: {spamItem.blockedUsers}
                    </Text>
                    <Text style={{ fontSize: 22, marginBottom: 6 }}>
                      Blocked Tells: {spamItem.blockedTells}
                    </Text>
                    <Text style={{ fontSize: 22, marginBottom: 6 }}>
                      Blocked Posts: {spamItem.blockedPosts}
                    </Text>
                    <Button
                      onPress={this._undoSpamItem}
                      style={{ marginTop: 16 }}>
                      <Text style={{ fontSize: 22, color: 'white' }}>
                        Undo Action
                      </Text>
                    </Button>
                  </View>
                ),
                [stages.USERS]: <UsersList spamItemId={spamItemId} />,
                [stages.TELLS]: <TellsList spamItemId={spamItemId} />,
                [stages.POSTS]: <PostsList spamItemId={spamItemId} />,
              }[stage]
            )}
          </View>
        </View>
      </ContentContainer>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      refreshLastDetectedSpamItem,
      refreshUsers,
      refreshPosts,
      refreshTells,
      undo: undoSpamItem,
    },
    dispatch
  ),
})

const mapStateToProps = (state, props) => ({
  isRefreshingItem: getIsRefreshingLastDetectedSpamItem(state),
  lastDetectedSpam: getLastDetectedSpam(state),
  spamItem: getSpamItem(state, Number(props.match.params.spamItemId)),
})

const PageSpamItemInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(_PageSpamItemInfo)

export { PageSpamItemInfo }
