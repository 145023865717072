import { USER_LOG_TYPE } from '@tellonym/enums/lib/User'
import { getAccessToken } from '@tellonym/core/app/selectors'
import React from 'react'
import { connect } from 'react-redux'
import { config } from '../../../config'
import { Avatar, Text, theme, View } from '../../common'
import { moment } from '../../common/locales/moment'

const LabelLogType = ({ title }) => (
  <View
    style={{
      alignSelf: 'flex-start',
      borderColor: theme.colors.placeholder,
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      marginBottom: 4,
      paddingHorizontal: 12,
      paddingVertical: 4,
    }}>
    <Text color={theme.colors.placeholder}>{title || 'UNDEFINED'}</Text>
  </View>
)

const LabelLogValue = ({ title, value }) => (
  <View
    style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 4 }}>
    <Text bold style={{ alignSelf: 'center', flex: 1 }}>
      {title}
    </Text>
    <View
      style={{
        backgroundColor: theme.colors.darkerBackground,
        flex: 2,
        borderRadius: 12,
        padding: 12,
      }}>
      <Text>{value}</Text>
    </View>
  </View>
)

const Label = ({ title, value }) =>
  value ? (
    <View style={{ flexDirection: 'row', marginBottom: 4 }}>
      <Text bold style={{ flex: 1 }}>
        {title}
      </Text>
      <Text style={{ flex: 2 }}>{value}</Text>
    </View>
  ) : null

const _AvatarLog = ({
  oldValue,
  newValue,
  size = 88,
  accessToken,
  profile,
}) => (
  <React.Fragment>
    <LabelLogValue
      title="Old Value"
      value={
        oldValue === 'null' ? null : oldValue ? (
          <Avatar
            user={{
              avatarFileName: `${config.api.host}/admin/user/settings/avatarhistory/${oldValue}?access_token=${accessToken}`,
            }}
            size={size}
          />
        ) : (
          oldValue
        )
      }
    />
    <LabelLogValue
      title="New Value"
      value={
        newValue === 'null' ? null : newValue ? (
          <Avatar
            user={
              profile.avatars.some(
                ({ avatarFileName }) => avatarFileName === newValue
              )
                ? { avatarFileName: newValue }
                : {
                    avatarFileName: `${config.api.host}/admin/user/settings/avatarhistory/${newValue}?access_token=${accessToken}`,
                  }
            }
            size={size}
          />
        ) : (
          newValue
        )
      }
    />
  </React.Fragment>
)
const AvatarLog = connect((state) => ({
  accessToken: getAccessToken(state),
}))(_AvatarLog)

export const LogItem = ({ item, profile }) => (
  <View
    style={{
      backgroundColor: theme.colors.background,
      borderRadius: 25,
      paddingVertical: 24,
      paddingHorizontal: 24,
      marginBottom: 12,
    }}>
    <LabelLogType title={USER_LOG_TYPE[item.type]} />
    {[USER_LOG_TYPE.AVATAR, USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR].includes(
      item.type
    ) ? (
      <AvatarLog
        profile={profile}
        oldValue={item.oldValue}
        newValue={item.newValue}
      />
    ) : (
      <React.Fragment>
        <LabelLogValue title="Old Value" value={item.oldValue} />
        <LabelLogValue title="New Value" value={item.newValue} />
      </React.Fragment>
    )}
    <Label title="Agent" value={item.agent} />
    <Label title="Device" value={item.device} />
    <Label title="OS" value={item.os} />
    <Label title="Time" value={moment(item.time).format('D.MM.YYYY, HH:mm')} />
    <Label title="IP" value={item.ip} />
    <Label title="Location" value={item.location} />
    <Label title="Is By Moderator" value={String(item.isByModerator)} />
  </View>
)
