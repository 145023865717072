import { DeleteOutlined } from '@ant-design/icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import {
  REPORT_DECISION,
  REASON as REPORT_REASON,
  REPORT_TYPE,
} from '@tellonym/enums/lib/Report'
import { SENDER_STATUS } from '@tellonym/enums/lib/Tell'
import { USER_LOG_TYPE } from '@tellonym/enums/lib/User'
import {
  Button as AntdButton,
  Badge,
  Modal,
  Radio,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { config } from '../../../config'
import {
  Avatar,
  Box,
  Icon,
  Link,
  PostImage,
  ScrollView,
  Text,
  TextTranslatable,
  View,
  hooks,
  styleSheets,
  theme,
} from '../../common'
import { AvatarGroup } from '../../common/components/AvatarGroup'
import { IconVerified } from '../../common/components/IconVerified'
import { convertObjectToOptions } from '../../common/helpers'
import { useProfileSettingsQuery } from '../../profile/queries'
import { changeSettings, deleteProfilePic, removePostComment } from '../actions'
import {
  moderation as queryKeys,
  useCommunityTellReportsQuery,
  usePostReportsQuery,
  useProfileReportsQuery,
  useQuickBanSexualMutation,
  useQuickBanSexualPermaMutation,
  useUserLogQuery,
  useVoteReportQuery,
} from '../queries'
import { AccountBansByDevice, PastBans } from './BanStats'
import { ModalChatMessages } from './ModalChatMessages'
import { ModalCreateBan } from './ModalCreateBan'
import { ModalCreateWarning } from './ModalCreateWarning'
import { ModalFollowers } from './ModalFollowers'
import { ModalFollowings } from './ModalFollowings'
import { PageProfile } from './PageProfile'
import { ShadowBanButtons } from './ShadowBanButtons'
import { UserTypeBadge } from './UserTypeBadge'

const styles = {
  commentContainer: {
    maxWidth: 697,
    flexDirection: 'row',
    alignItems: 'center',
  },
  comment: {
    borderLeftColor: theme.colors.border,
    borderLeftStyle: 'solid',
    borderLeftWidth: 0.5,
    paddingLeft: 4,
    marginBottom: 4,
    width: '100%',
  },
  postContainer: {
    backgroundColor: colors.white[1],
    borderWidth: 1,
    borderColor: '#F0F0F3',
    borderRadius: 20,
    borderStyle: 'solid',
    padding: 32,
    marginTop: 4,
  },
  quickBanSexual: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 24,
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: '#D85E40',
    cursor: 'pointer',
  },
  bottomMargin: { marginBottom: 8 },
}

const CommentsList = ({ comments, reportedUser }) => {
  const onPressShowChat = (userId) => (e) => {
    e.preventDefault()

    ModalChatMessages.show({
      issuerUserId: userId,
      reportedUserId: reportedUser.id,
    })
  }

  return (
    <View>
      {comments.map((comment, _index, _fullArray) => {
        const { textReason, isChat = false, ip, userId } = comment

        return (
          <View key={textReason} style={styles.commentContainer}>
            <View style={styles.comment}>
              <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                {userId ? (
                  <Link to={`/user/${userId}`}>
                    <Text color={theme.colors.primary}>{userId}</Text>
                  </Link>
                ) : (
                  <Text>{ip}</Text>
                )}
                {isChat && (
                  <AntdButton type="link" onClick={onPressShowChat(userId)}>
                    Show Chat
                  </AntdButton>
                )}
              </Box>
              <TextTranslatable>{textReason}</TextTranslatable>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const PostComment = ({ item, onAction, selectedId, setSelectedId }) => {
  const dispatch = ReactRedux.useDispatch()

  const [isRemoved, setIsRemoved] = React.useState(false)

  const deleteComment = React.useCallback(() => {
    onAction?.()
    setIsRemoved(true)
    dispatch(
      removePostComment({
        id: item.id,
      })
    )
  }, [dispatch, item.id, onAction])

  const onMouseEnter = React.useCallback(() => {
    setSelectedId(item.id)
  }, [item.id, setSelectedId])

  const onMouseLeave = React.useCallback(() => {
    setSelectedId(undefined)
  }, [setSelectedId])

  const isSelected = React.useMemo(
    () => selectedId === item.id,
    [item.id, selectedId]
  )

  return (
    <Box
      key={item.id}
      backgroundColor={
        isSelected
          ? item.isReported
            ? colors.red[1]
            : colors.grey[2]
          : item.isReported
          ? colors.red[4]
          : undefined
      }
      flexDirection="row"
      justifyContent="space-between"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      padding={8}>
      <Box flexDirection="row" flex={1} transparent>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '700',
            marginRight: 8,
            marginTop: 2,
            whiteSpace: 'nowrap',
            ...(isRemoved && {
              color: colors.grey[7],
              textDecorationLine: 'line-through',
            }),
          }}>
          {item.user?.username}
        </Text>
        <TextTranslatable
          style={{
            fontSize: 16,
            fontWeight: '400',
            ...(isRemoved && {
              color: colors.grey[7],
              textDecorationLine: 'line-through',
            }),
          }}>
          {item.content}
        </TextTranslatable>
      </Box>

      <Box
        alignItems="center"
        marginLeft={8}
        height={28}
        width={28}
        padding={4}
        disabled={!isSelected}
        onPress={deleteComment}
        transparent>
        <Icon
          icon={faTrashAlt}
          size="lg"
          style={{
            color: isSelected
              ? item.isReported
                ? colors.white[1]
                : colors.black[1]
              : 'transparent',
          }}
        />
      </Box>
    </Box>
  )
}

const TellPostItem = ({ item, onAction }) => {
  const isCommentReport = item.type === REPORT_TYPE.POST_COMMENT
  const [selectedId, setSelectedId] = React.useState(null)

  return (
    <View style={styles.postContainer}>
      {typeof item.tell.answer === 'string' ||
      (item.tell.media && item.tell.media.length) ? (
        <View>
          <Box flexDirection="row" flexWrap="wrap">
            <TextLabel.BoxItemMeta label="Sender">
              {item.tell.sender?.id ? (
                <Link
                  to={`/user/${item.tell.sender.id}`}
                  style={{ display: 'inline' }}>
                  @{item.tell.sender.username}
                </Link>
              ) : (
                `${
                  item.tell.sender?.ip ??
                  `${item.tell.community?.name} (${item.tell.community?.id})`
                }`
              )}
            </TextLabel.BoxItemMeta>
            <TextLabel.BoxItemMeta label="Sender Status">
              {SENDER_STATUS[item.tell.senderStatus] || item.tell.senderStatus}
            </TextLabel.BoxItemMeta>
          </Box>

          <BoxBlue>
            <TextLabel.BoxItemMeta label="Receiver">
              <Link
                to={`/user/${item.tell.receiver.id}`}
                style={{ display: 'inline' }}>
                @{item.tell.receiver.username}
              </Link>
            </TextLabel.BoxItemMeta>

            <TextTell>{item.tell.tell}</TextTell>

            <TextTranslatable style={{ marginTop: 8 }}>
              {item.tell.answer}
            </TextTranslatable>

            {item.tell.media && item.tell.media.length ? (
              <Link to={item.tell.media[0].url} target="_blank">
                <PostImage
                  imgUrl={item.tell.media[0].thumbUrl || item.tell.media[0].url}
                />
              </Link>
            ) : null}
          </BoxBlue>

          <Box flexDirection="row" flexWrap="wrap">
            <TextLabel.BoxItemMeta
              label="Post ID"
              labelStyle={{ fontWeight: 400, fontStyle: 'italic' }}>
              <Link
                style={{ display: 'inline' }}
                to={`https://tellonym.me/${item.tell.receiver.username}/answer/${item.postId}`}
                target="_blank">
                {item.postId}
              </Link>
            </TextLabel.BoxItemMeta>
            <TextLabel.BoxItemMeta
              label="Report ID"
              labelStyle={{ fontWeight: 400, fontStyle: 'italic' }}
              textStyle={{ color: colors.black[1] }}>
              {item.id}
            </TextLabel.BoxItemMeta>
          </Box>

          {isCommentReport && (
            <Box>
              <Box alignItems="flex-end">
                <TextLabel.BoxItemMeta
                  label="Reported Comments"
                  textStyle={{
                    color:
                      item.postCommentReportsAmount > 0
                        ? colors.red[1]
                        : colors.black[1],
                  }}>
                  {item.postCommentReportsAmount ?? 0}
                </TextLabel.BoxItemMeta>
              </Box>
              <Box height={1} backgroundColor={colors.grey[7]} />
              <ScrollView
                style={{
                  marginTop: 24,
                  maxHeight: 450,
                }}>
                {item.postComments?.map((comment) => (
                  <PostComment
                    key={comment.id}
                    item={comment}
                    onAction={onAction}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                  />
                ))}
              </ScrollView>
            </Box>
          )}
        </View>
      ) : (
        <Text style={{ whiteSpace: 'pre-line' }}>{item.tell.tell}</Text>
      )}
    </View>
  )
}

const TextLabel = ({ label, children, labelStyle, textStyle }) => (
  <Box marginBottom={8} transparent>
    <Text style={labelStyle}>
      {`${label}: `}
      <Text color={colors.grey[7]} style={textStyle}>
        {children}
      </Text>
    </Text>
  </Box>
)

TextLabel.BoxItemMeta = ({ label, children, labelStyle, textStyle }) => (
  <Box marginRight={8} transparent>
    <TextLabel
      label={label}
      labelStyle={{ fontWeight: '700', fontSize: 12, ...labelStyle }}
      textStyle={{
        fontSize: 12,
        fontStyle: 'italic',
        fontWeight: '400',
        ...textStyle,
      }}>
      {children}
    </TextLabel>
  </Box>
)

const BoxBlue = ({ children }) => (
  <Box
    backgroundColor={colors.saphire[1]}
    borderRadius={10}
    paddingVertical={8}
    paddingLeft={32}
    paddingRight={8}
    marginBottom={24}>
    {children}
  </Box>
)

const TextTell = ({ children }) => (
  <Box
    backgroundColor="transparent"
    borderLeftWidth={3}
    borderRightWidth={0}
    borderTopWidth={0}
    borderBottomWidth={0}
    borderColor={colors.saphire[6]}
    borderStyle="solid"
    paddingLeft={8}>
    <TextTranslatable>{children}</TextTranslatable>
  </Box>
)

const CommunityTellItem = ({ item }) => {
  return (
    <Box
      padding={32}
      borderRadius={20}
      borderWidth={1}
      borderColor="#F0F0F3"
      borderStyle="solid"
      maxWidth={532}>
      <Box flexDirection="row" flexWrap="wrap">
        <TextLabel.BoxItemMeta label="Community ID">
          {item.community.id}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Members">
          {shortenNumber(item.community.membersCount)}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Tell Answers">
          {shortenNumber(item.community.tellAnswersCount)}
        </TextLabel.BoxItemMeta>
        <TextLabel.BoxItemMeta label="Sender">
          {item.communityTell.sender.username}
        </TextLabel.BoxItemMeta>
      </Box>
      <BoxBlue>
        <TextTell>{item.communityTell.tell}</TextTell>
      </BoxBlue>
      <Text
        type="note"
        style={{
          fontStyle: 'italic',
        }}>{`Community Tell ID: ${item.communityTell.id}, Report ID: ${item.id}`}</Text>
    </Box>
  )
}

const MetaItem = ({
  value,
  textColor,
  borderColor,
  shouldAlignRight,
  style,
}) => (
  <Box
    paddingVertical={4}
    paddingHorizontal={8}
    borderWidth={1}
    borderColor={borderColor ?? '#F0F0F3'}
    borderStyle="solid"
    borderRadius={6}
    marginLeft={shouldAlignRight ? 'auto' : 11}
    {...style}>
    <Text
      type="small"
      color={textColor ?? colors.grey[7]}
      style={styles.metaText}>
      {value}
    </Text>
  </Box>
)

const ProfileStatsItem = ({ name, value, onPress }) => (
  <Box
    onPress={onPress}
    flex={1}
    flexDirection="row"
    justifyContent="space-between">
    <Text>{name}</Text>
    <Text bold>{shortenNumber(value)}</Text>
  </Box>
)

const PIC_DELETE_TYPES = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
  ALL: 'All Avatars',
}

const getPositionNumberFromString = (string) => {
  switch (string) {
    case PIC_DELETE_TYPES.LEFT:
      return [1]

    case PIC_DELETE_TYPES.CENTER:
      return [0]

    case PIC_DELETE_TYPES.RIGHT:
      return [2]

    case PIC_DELETE_TYPES.ALL:
      return [0, 1, 2]

    default:
      return []
  }
}

const profilePicPositions = Object.values(PIC_DELETE_TYPES)

const MAIN_SECTION_LEFT_WIDTH = 290
const MAIN_SECTION_RIGHT_WIDTH = 160

const modalAvatarCreator = (avatarFileName) => () =>
  Modal.info({
    closable: true,
    maskClosable: true,
    width: '50%',
    content: (
      <Box alignItems="center">
        <Avatar
          rectangle
          borderColor={theme.colors.black}
          user={{ avatarFileName }}
          size={380}
        />
      </Box>
    ),
  })

const ProfileWithActions = ({ setHasTakenAction, report, profile }) => {
  const dispatch = ReactRedux.useDispatch()

  const [shouldTranslate, setShouldTranslate] = React.useState(false)

  const accessToken = ReactRedux.useSelector(getAccessToken)

  const toggleShouldTranslate = () => {
    setShouldTranslate(!shouldTranslate)
  }

  const deleteProfilePicCreator = (position) => () => {
    setHasTakenAction(true)

    const positions = getPositionNumberFromString(position)

    for (const position of positions) {
      dispatch(
        deleteProfilePic({
          userId: report.user.id,
          position,
        })
      )
    }
  }

  const deleteProfileStatus = () => {
    setHasTakenAction(true)

    dispatch(changeSettings({ aboutMe: '', id: report.user.id }))
  }

  const hasNoAvatar = profile.avatars.length === 0

  const { data: userLog } = useUserLogQuery({
    userId: report.user.id,
    enabled: hasNoAvatar,
  })

  const currentAvatarFileNames = profile.avatars.map(
    ({ avatarFileName }) => avatarFileName
  )

  const userLogAvatars = Array.from(
    userLog?.logEntries?.reduce((acc, item) => {
      const isAvatarEntry = [
        USER_LOG_TYPE.AVATAR,
        USER_LOG_TYPE.UPLOAD_ADDITIONAL_AVATAR,
      ].includes(item.type)

      if (isAvatarEntry) {
        acc.add(item.oldValue)
        acc.add(item.newValue)
      }

      return acc
    }, new Set()) ?? []
  ).filter(
    (avatarFileName) =>
      !currentAvatarFileNames.includes(avatarFileName) &&
      avatarFileName !== 'null' &&
      typeof avatarFileName !== 'undefined'
  )

  return (
    <>
      <Box flexDirection="row">
        <AvatarGroup
          containerProps={{ width: MAIN_SECTION_LEFT_WIDTH }}
          creatorOnPressAvatar={modalAvatarCreator}
          user={profile}
        />

        <Box paddingLeft={24} width={MAIN_SECTION_RIGHT_WIDTH}>
          {profilePicPositions.map((position) => (
            <AntdButton
              key={position}
              type="dashed"
              shape="round"
              icon={<DeleteOutlined />}
              onClick={deleteProfilePicCreator(position)}
              style={styleSheets.margin.top[8]}>
              {position}
            </AntdButton>
          ))}
        </Box>
      </Box>
      <Box flexDirection="row" marginTop={12}>
        <Box width={MAIN_SECTION_LEFT_WIDTH} alignItems="flex-start">
          <UserTypeBadge type={profile.type} />
          <Box flexDirection="row" alignItems="center" marginTop={8}>
            <Text bold style={{}}>
              {profile.displayName}
            </Text>
            {(profile.verified || profile.isVerified) && (
              <Box marginLeft={4}>
                <IconVerified size={16} />
              </Box>
            )}
          </Box>
          <Text
            color={theme.colors.placeholder}
            style={{ marginTop: 4 }}>{`@${profile.username}`}</Text>
          <Text color={theme.colors.placeholder}>
            {`joined ${dayjs(profile.registered).fromNow()}`}
          </Text>
        </Box>
        <Box width={MAIN_SECTION_RIGHT_WIDTH} paddingLeft={24} />
      </Box>

      <Box flexDirection="row">
        <Box width={MAIN_SECTION_LEFT_WIDTH}>
          <TextTranslatable.Pure
            shouldTranslate={shouldTranslate}
            style={styleSheets.margin.top[8]}>
            {profile.aboutMe}
          </TextTranslatable.Pure>
        </Box>

        <Box width={MAIN_SECTION_RIGHT_WIDTH} paddingLeft={24}>
          {profile.aboutMe !== '' && (
            <Box marginTop={8}>
              <AntdButton
                type="dashed"
                shape="round"
                icon={<DeleteOutlined />}
                onClick={deleteProfileStatus}>
                About Me
              </AntdButton>

              <AntdButton
                type="dashed"
                shape="round"
                onClick={toggleShouldTranslate}
                style={styleSheets.margin.top[8]}>
                {shouldTranslate ? 'Original' : 'Translate'}
              </AntdButton>
            </Box>
          )}
        </Box>
      </Box>

      {userLogAvatars?.length > 0 && (
        <Box
          marginTop={24}
          width={MAIN_SECTION_LEFT_WIDTH + MAIN_SECTION_RIGHT_WIDTH}>
          <Text style={{ fontWeight: '500' }}>Previous Avatars</Text>

          <Box flexDirection="row" overflowY="scroll">
            {userLogAvatars?.map((avatarFileName) => {
              const avatarPath = `${config.api.host}/admin/user/settings/avatarhistory/${avatarFileName}?access_token=${accessToken}`

              return (
                <Box
                  key={avatarFileName}
                  onPress={modalAvatarCreator(avatarPath)}
                  marginRight={8}>
                  <Avatar
                    rectangle
                    borderColor={theme.colors.black}
                    user={{ avatarFileName }}
                    size={100}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
    </>
  )
}

const MetaFooterBar = ({
  filterType,
  hasTakenAction,
  report,
  setHasTakenAction,
  profile,
}) => {
  const prevInteractCounter = React.useRef(0) // This is the default value of react-query's dataUpdatedAt

  const [chosenVoteType, setChosenVoteType] = React.useState(undefined)
  const [interactCounter, setInteractCounter] = React.useState(0)

  const isProfileReport = report.type === REPORT_TYPE.PROFILE
  const hasProfileReportData = isProfileReport && typeof profile !== 'undefined'
  const isCommunityTellReport = report.type === REPORT_TYPE.COMMUNITY_TELL
  const isCommentReport = report.type === REPORT_TYPE.POST_COMMENT
  const isTellReport = typeof report.tell !== 'undefined'

  const queryClient = useQueryClient()

  const { mutate: triggerQuickBanSexualMutation, isLoading: isLoadingBan } =
    useQuickBanSexualMutation()

  const {
    mutate: triggerQuickBanSexualPermaMutation,
    isLoading: isLoadingBanPerma,
  } = useQuickBanSexualPermaMutation()

  const { isFetching, isSuccess } = useVoteReportQuery({
    decision: chosenVoteType,
    reportId: report.id,
    reportType: report.type,
  })

  const onPressVote = (type) => () => {
    setChosenVoteType(type)
    setInteractCounter(interactCounter + 1)
  }

  const banUser = () => {
    setHasTakenAction(true)

    ModalCreateBan.show({
      userId: report.user.id,
      lang: report.user.lang,
    })
  }

  /**
   * We set chosenVoteType when the user presses a button. This results in all params for useVoteReportQuery being set
   * and therefore 'enabled' of useQuery to turn true. After doing the request once we do not want refetching so we
   * reset the chosenVoteType to null.
   */

  const triggerRefresh = async () => {
    prevInteractCounter.current = interactCounter
    setChosenVoteType(undefined)
    setHasTakenAction(false)
    await queryClient.invalidateQueries({
      queryKey: queryKeys.report(),
    })
  }

  const quickBanSexual = () => {
    if (isProfileReport) {
      setHasTakenAction(true)
      triggerQuickBanSexualMutation(
        { userId: report?.user?.id, reportId: report.id },
        { onSuccess: triggerRefresh }
      )
    }
  }

  const quickBanSexualPerma = () => {
    if (isProfileReport) {
      setHasTakenAction(true)
      triggerQuickBanSexualPermaMutation(
        { userId: report?.user?.id, reportId: report.id },
        { onSuccess: triggerRefresh }
      )
    }
  }

  const createWarning = () => {
    setHasTakenAction(true)

    ModalCreateWarning.show({ userId: profile.id })
  }

  const areShortcutsActive = hooks.useCapsLockShortcuts(
    [REPORT_DECISION.NOT_OKAY, REPORT_DECISION.SKIP, REPORT_DECISION.OKAY].map(
      (action, index) => ({
        keyCode: 49 + index, // 49 is the keyCode for 1
        onPress: () => {
          if (typeof action === 'function') {
            action()
          } else {
            if (hasTakenAction) {
              // The user took an action so he can not skip or declare the profile as okay
              if (action === REPORT_DECISION.NOT_OKAY) {
                onPressVote(REPORT_DECISION.NOT_OKAY)()
              }
            } else {
              onPressVote(action)()
            }
          }
        },
      })
    )
  )

  React.useEffect(() => {
    if (isSuccess && interactCounter > prevInteractCounter.current) {
      triggerRefresh()
    }
  }, [filterType, interactCounter, isSuccess, queryClient])

  const isLoading = isFetching || isLoadingBan || isLoadingBanPerma

  return (
    <Spin spinning={isLoading}>
      <Box
        paddingHorizontal={16}
        paddingVertical={24}
        marginBottom={32}
        borderRadius={20}
        borderWidth={1}
        borderColor="#F0F0F3"
        borderStyle="solid">
        <Box
          backgroundColor="transparent"
          position="absolute"
          top={4}
          left={0}
          right={0}>
          <Text center color={colors.grey[7]}>
            {report.reasons.map((reason) => REPORT_REASON[reason]).join(', ')}
          </Text>
        </Box>
        <Box flexDirection="row">
          <Box flex={1}>
            <Text type="note" color={colors.grey[7]}>
              {report.id}
            </Text>
            {!isProfileReport && (
              <TextLabel label="Report Type">
                {REPORT_TYPE[report.type]}
              </TextLabel>
            )}
            <TextLabel label="Report Count">
              {report.issuerCount ?? 0}
            </TextLabel>
            <TextLabel label="Open since">
              <Tooltip title={dayjs(report.createdAt).toLocaleString()}>
                {dayjs(report.createdAt).fromNow()}
              </Tooltip>
            </TextLabel>
            <PastBans profile={profile} />
            {Boolean(isProfileReport) && (
              <AccountBansByDevice profile={profile} />
            )}
            <TextLabel label="Comments">
              {report?.comments?.length ? (
                <CommentsList
                  comments={report.comments}
                  reportedUser={report.user}
                />
              ) : (
                'none'
              )}
            </TextLabel>
          </Box>
          <Box flex={2} paddingLeft={24} paddingRight={24} alignItems="center">
            {hasProfileReportData ? (
              <ProfileWithActions
                setHasTakenAction={setHasTakenAction}
                report={report}
                profile={profile}
              />
            ) : isTellReport ? (
              <TellPostItem
                item={report}
                onAction={() => setHasTakenAction(true)}
              />
            ) : null}
          </Box>
          <Box>
            <Box flexDirection="row" justifyContent="space-between">
              <Badge count={areShortcutsActive ? 1 : 0} color={colors.grey[7]}>
                <AntdButton
                  danger
                  shape="round"
                  onClick={onPressVote(REPORT_DECISION.NOT_OKAY)}>
                  {isProfileReport || isCommentReport
                    ? 'Took action'
                    : isCommunityTellReport
                    ? 'Delete including answers'
                    : REPORT_DECISION[REPORT_DECISION.NOT_OKAY]}
                </AntdButton>
              </Badge>
              <Badge count={areShortcutsActive ? 2 : 0} color={colors.grey[7]}>
                <AntdButton
                  shape="round"
                  onClick={onPressVote(REPORT_DECISION.SKIP)}
                  disabled={hasTakenAction}
                  style={{ marginLeft: 12, marginRight: 12 }}>
                  {REPORT_DECISION[REPORT_DECISION.SKIP]}
                </AntdButton>
              </Badge>
              <Badge count={areShortcutsActive ? 3 : 0} color={colors.grey[7]}>
                <AntdButton
                  type="primary"
                  shape="round"
                  ghost
                  onClick={onPressVote(REPORT_DECISION.OKAY)}
                  disabled={hasTakenAction}>
                  {REPORT_DECISION[REPORT_DECISION.OKAY]}
                </AntdButton>
              </Badge>
            </Box>

            {hasProfileReportData && (
              <Box width={160} marginTop={24} alignSelf="flex-end">
                <ProfileStatsItem
                  name="Follower"
                  value={profile.follower}
                  onPress={() => ModalFollowers.show({ userId: profile.id })}
                />
                <ProfileStatsItem
                  name="Following"
                  value={profile.followingCount}
                  onPress={() => ModalFollowings.show({ userId: profile.id })}
                />
                <ProfileStatsItem name="Tells" value={profile.tellCount} />
                <ProfileStatsItem name="Answers" value={profile.answerCount} />
                <ProfileStatsItem
                  name="Sent Tells"
                  value={profile.sentTellCount}
                />
              </Box>
            )}

            <Box alignItems="flex-end" marginTop={24}>
              <Box>
                {isCommunityTellReport && (
                  <AntdButton
                    shape="round"
                    onClick={onPressVote(REPORT_DECISION.NOT_OKAY_ONLY_TELL)}
                    style={styles.bottomMargin}>
                    Delete only Tell
                  </AntdButton>
                )}
                <AntdButton
                  shape="round"
                  onClick={createWarning}
                  style={styles.bottomMargin}>
                  Create warning
                </AntdButton>
                <AntdButton
                  shape="round"
                  onClick={banUser}
                  style={styles.bottomMargin}>
                  Ban User
                </AntdButton>
                <Tooltip title="Deletes all pics, bans for 1 day, closes report">
                  <AntdButton
                    shape="round"
                    onClick={quickBanSexual}
                    style={styles.bottomMargin}>
                    Quick Ban Sexual
                  </AntdButton>
                </Tooltip>
                <Tooltip
                  title="Deletes all pics, bans forever, closes report"
                  placement="bottom">
                  <AntdButton
                    shape="round"
                    onClick={quickBanSexualPerma}
                    style={styles.bottomMargin}>
                    Quick Perma Ban Sexual
                  </AntdButton>
                </Tooltip>

                {isProfileReport ? (
                  <ShadowBanButtons
                    onActionTaken={() => setHasTakenAction(true)}
                    profile={profile}
                    report={report}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>

        {isCommentReport && (
          <Box marginTop={24}>
            <Box
              backgroundColor={colors.grey[7]}
              height={1}
              marginBottom={48}
            />
            <TextLabel
              label="Okay Button"
              labelStyle={{ color: colors.grey[6] }}>
              Informs the report creator that he mistakenly reported.
            </TextLabel>
            <TextLabel
              label="Took Action"
              labelStyle={{ color: colors.grey[6] }}>
              Sends information to report creator that we appreciate his report
              and that we did something about it. When deleting a comment, we
              instantly inform the comment creator with a report notification.
            </TextLabel>
          </Box>
        )}
      </Box>
    </Spin>
  )
}

const TABS = {
  TELLS: 'tells',
  COMMUNITY_TELLS: 'communityTells',
  PROFILES: 'profiles',
}

const tabTitles = {
  [TABS.TELLS]: 'Answers',
  [TABS.COMMUNITY_TELLS]: 'Community Tells',
  [TABS.PROFILES]: 'Profiles',
}

const tabOptions = Object.values(TABS).map((value) => ({
  label: tabTitles[value],
  value,
}))

const defaultQueryParams = {
  moderationTab: TABS.TELLS,
}

const REPORT_FILTER_TYPES = {
  ALL_PROFILE_REPORTS: 'All Profile Reports',
  ALL_AUTO_REPORTS: 'All Automatic Reports',
  ONLY_AUTO_NSFW: 'Only Auto NSFW',
  ONLY_CHAT_GPT: 'Only ChatGPT',
  ONLY_SHADOW_BAN: 'Only Shadow Ban',
  ONLY_MANUAL_REPORTS: 'Only Manual Reports',
}

const filterOptions = convertObjectToOptions(REPORT_FILTER_TYPES)

const autoReportNumbers = [
  REPORT_REASON.AUTOMATED_NSFW_DETECTION,
  REPORT_REASON.AUTOMATED_GPT_PROFILE_DETECTION,
]
const manualReportNumbers = Object.values(REPORT_REASON).filter(
  (i) => typeof i === 'number' && autoReportNumbers.includes(i) === false
)

const getReportReasonsFromReportFilter = (filterValue) => {
  switch (filterValue) {
    case REPORT_FILTER_TYPES.ALL_AUTO_REPORTS:
      return [autoReportNumbers, true]

    case REPORT_FILTER_TYPES.ONLY_AUTO_NSFW:
      return [[REPORT_REASON.AUTOMATED_NSFW_DETECTION], false]

    case REPORT_FILTER_TYPES.ONLY_CHAT_GPT:
      return [[REPORT_REASON.AUTOMATED_GPT_PROFILE_DETECTION], false]

    case REPORT_FILTER_TYPES.ONLY_SHADOW_BAN:
      // shadow bans are saved with reason REPORT_REASON.NONE and text_reason "Shadow Ban ...", the latter is controlled with the boolean
      return [[], true]

    case REPORT_FILTER_TYPES.ONLY_MANUAL_REPORTS:
      return [manualReportNumbers, false]

    case REPORT_FILTER_TYPES.ALL_PROFILE_REPORTS:
    default:
      return [[], true]
  }
}

export const PageModeration = () => {
  const [queryParams, setQueryParams] = hooks.useQueryParams(defaultQueryParams)

  const containerStyle = hooks.usePageContainerStyle({
    backgroundColor: '#FAFAFA',
  })

  const [reportReasons, setReportReasons] = React.useState([])
  const [shouldShowShadowBans, setShouldShowShadowBans] = React.useState(true)
  const [hasTakenAction, setHasTakenAction] = React.useState(false)

  const onChangeTab = React.useCallback((e) => {
    setQueryParams({ moderationTab: e.target.value })
  }, [])

  const profileReports = useProfileReportsQuery({
    isActive: queryParams.moderationTab === TABS.PROFILES,
    reasonFilter: reportReasons,
    shouldShowShadowBans,
  })

  const postReports = usePostReportsQuery({
    isActive: queryParams.moderationTab === TABS.TELLS,
  })

  const communityTellReports = useCommunityTellReportsQuery({
    isActive: queryParams.moderationTab === TABS.COMMUNITY_TELLS,
  })

  const { data, isFetching } = React.useMemo(() => {
    switch (queryParams.moderationTab) {
      case TABS.TELLS:
        return postReports

      case TABS.COMMUNITY_TELLS:
        return communityTellReports

      case TABS.PROFILES:
        return profileReports

      default:
        return {}
    }
  }, [
    communityTellReports,
    postReports,
    profileReports,
    queryParams.moderationTab,
  ])

  const { data: user } = useProfileSettingsQuery({
    userId: data?.reports?.[0]?.user?.id,
  })

  const onChangeReportType = (filterValue) => {
    const [reasonFilterNew, shouldFilterShadowBansNew] =
      getReportReasonsFromReportFilter(filterValue)

    setReportReasons(reasonFilterNew)
    setShouldShowShadowBans(shouldFilterShadowBansNew)
  }

  return (
    <View style={containerStyle}>
      <Spin spinning={isFetching}>
        <Box
          flexDirection="row"
          marginTop={24}
          justifyContent="center"
          backgroundColor="transparent">
          <Radio.Group
            optionType="button"
            options={tabOptions}
            onChange={onChangeTab}
            value={queryParams.moderationTab}
            size="large"
          />
          <Box
            position="absolute"
            right={24}
            backgroundColor="transparent"
            flexDirection="row"
            alignItems="center">
            {queryParams.moderationTab === TABS.PROFILES && (
              <Select
                defaultValue={REPORT_FILTER_TYPES.ALL_PROFILE_REPORTS}
                dropdownMatchSelectWidth={false}
                onChange={onChangeReportType}
                options={filterOptions}
                style={{ width: 160, fontSize: 12, marginRight: 12 }}
              />
            )}
            <MetaItem
              shouldAlignRight
              value={`${data?.openReports ?? 0} open reports`}
              borderColor={colors.red[1]}
              textColor={colors.red[1]}
            />
          </Box>
        </Box>
        {data?.reports?.map((item) => (
          <Box
            key={item.id}
            marginBottom={24}
            padding={20}
            backgroundColor="#FAFAFA">
            <MetaFooterBar
              filterType={queryParams.moderationTab}
              hasTakenAction={hasTakenAction}
              setHasTakenAction={setHasTakenAction}
              report={item}
              profile={user}
            />
            {typeof item.communityTell !== 'undefined' ? (
              <CommunityTellItem item={item} />
            ) : typeof user !== 'undefined' ? (
              <PageProfile profile={user} />
            ) : null}
          </Box>
        )) ?? <Box height={200} width={50} backgroundColor="transparent" />}
      </Spin>
    </View>
  )
}
