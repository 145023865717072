import * as t from './types'

export const { name } = t

export const initialState = {}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    default:
      return state
  }
}
