import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { configureCore } from '@tellonym/core/config'
import { events } from '@tellonym/core/events'
import { safeEffects } from '@tellonym/core/sagas'
import { LOGIN_SUCCESS } from '@tellonym/core/user/types'
import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { put, select } from 'redux-saga/effects'
import { refreshPermissions } from './actions'
import { getCustomApiHost } from './selectors'
import { SET_CUSTOM_API_HOST } from './types'

const getPermissions = function* (action) {
  if (action.payload?.code === API_CODE.CAPTCHA_REQUIRED) {
    return
  }

  const isLoggedIn = yield select(getIsLoggedIn)

  if (isLoggedIn) {
    yield put(refreshPermissions())
  }
}

const loadCustomApiSettings = function* () {
  const customApiHost = yield select(getCustomApiHost)
  if (typeof customApiHost === 'string') {
    // eslint-disable-next-line no-console
    console.log('Setting api host to saved customApiHost', customApiHost)
    configureCore({ api: { host: customApiHost } })
  }
}

export const eventHandler = {
  [events.LOAD]: [loadCustomApiSettings, getPermissions],
}

export const actionHandler = {
  [LOGIN_SUCCESS]: [safeEffects.takeLatest, getPermissions],
  [SET_CUSTOM_API_HOST]: [safeEffects.takeLatest, loadCustomApiSettings],
}
