import React from 'react'
import { moment } from '../locales/moment'
import { theme } from '../styles/theme'
import { Avatar } from './Avatar'
import { PopupMenu as Menu } from './PopupMenu'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { Username } from './Username'
import { View } from './View'

const ProfileImage = ({ onPress, profile }) => (
  <TouchableOpacity onPress={onPress} style={{ marginRight: 15 }}>
    <Avatar size={40} user={profile} />
  </TouchableOpacity>
)

const Time = ({ item }) => (
  <Text type="note" color={theme.colors.textLight}>
    {moment(item.createdAt).fromNow()}
  </Text>
)

export const PostItemHeader = ({
  item,
  menuItems,
  onPressMenu,
  onPressProfile,
  profile,
}) => (
  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
    <View style={{ flexDirection: 'row' }}>
      <ProfileImage onPress={onPressProfile} profile={profile} />
      <View>
        <Username
          onPress={onPressProfile}
          style={{ maxWidth: 200, marginBottom: 2 }}
          user={profile}
        />
        <Time item={item} />
      </View>
    </View>
    <Menu onPress={onPressMenu} actions={menuItems} />
  </View>
)
