import { throttle } from '@tellonym/core/helpers'
import React from 'react'
import { history } from '../history'
import { Alert } from './Alert'
import { Box } from './Box'
import { IconVerified } from './IconVerified'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'

export const Username = ({
  bold = true,
  disabled = false,
  isSignature = false,
  hasVerifiedIcon = true,
  italic = false,
  onPress,
  style,
  user = {},
  ...props
}) => {
  const _onPress =
    onPress ??
    throttle((e) => {
      e.stopPropagation()
      const pathname = `/${user.username}`
      history[history.location.pathname === pathname ? 'replace' : 'push'](
        pathname
      )
    })

  return user.isSenderHidden ? (
    <Text
      type="small"
      bold={bold}
      onPress={() =>
        Alert.alert(
          'Hidden Sender',
          `This Tell wasn't sent anonymously, but ${user.receiver} decided to not display the username of the sender.`
        )
      }
      italic={String(italic)}
      style={style}
      {...props}>
      {isSignature ? 'hidden ' : 'hidden'}
    </Text>
  ) : hasVerifiedIcon ? (
    <TouchableOpacity
      disabled={disabled}
      style={[style, { flexDirection: 'row' }]}
      onPress={_onPress}>
      <Text type="small" bold={bold} italic={String(italic)} {...props}>
        {isSignature ? `${user.username} ` : user.username}
      </Text>
      {Boolean(user.isVerified) && (
        <Box paddingHorizontal={4} justifyContent="center">
          <IconVerified size={12} />
        </Box>
      )}
    </TouchableOpacity>
  ) : (
    <Text
      type="small"
      bold={bold}
      onPress={_onPress}
      italic={String(italic)}
      style={style}
      {...props}>
      {isSignature ? `${user.username} ` : user.username}
    </Text>
  )
}
