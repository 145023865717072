export const name = 'filter'

export const FETCH_USERS = `${name}/FETCH_USERS`
export const FETCH_USERS_ERROR = `${name}/FETCH_FETCH_USERS_ERROR`
export const FETCH_USERS_SUCCESS = `${name}/FETCH_USERS_SUCCESS`

export const FETCH_LAST_DETECTED_SPAM = `${name}/FETCH_LAST_DETECTED_SPAM`
export const FETCH_LAST_DETECTED_SPAM_ERROR = `${name}/FETCH_LAST_DETECTED_SPAM_ERROR`
export const FETCH_LAST_DETECTED_SPAM_SUCCESS = `${name}/FETCH_LAST_DETECTED_SPAM_SUCCESS`

export const FETCH_POSTS = `${name}/FETCH_POSTS`
export const FETCH_POSTS_ERROR = `${name}/FETCH_POSTS_ERROR`
export const FETCH_POSTS_SUCCESS = `${name}/FETCH_POSTS_SUCCESS`

export const FETCH_TELLS = `${name}/FETCH_TELLS`
export const FETCH_TELLS_ERROR = `${name}/FETCH_TELLS_ERROR`
export const FETCH_TELLS_SUCCESS = `${name}/FETCH_TELLS_SUCCESS`

export const FILTER_LAST_DETECTED_SPAM = `${name}/FILTER_LAST_DETECTED_SPAM`

export const REFRESH_LAST_BLOCKED_DATA = `${name}/REFRESH_LAST_BLOCKED_DATA`
export const REFRESH_LAST_BLOCKED_DATA_SUCCESS = `${name}/REFRESH_LAST_BLOCKED_DATA_SUCCESS`
export const REFRESH_LAST_BLOCKED_DATA_ERROR = `${name}/REFRESH_LAST_BLOCKED_DATA_ERROR`

export const REFRESH_LAST_DETECTED_SPAM = `${name}/REFRESH_LAST_DETECTED_SPAM`
export const REFRESH_LAST_DETECTED_SPAM_SUCCESS = `${name}/REFRESH_LAST_DETECTED_SPAM_SUCCESS`
export const REFRESH_LAST_DETECTED_SPAM_ERROR = `${name}/REFRESH_LAST_DETECTED_SPAM_ERROR`

export const REFRESH_LAST_DETECTED_SPAM_ITEM = `${name}/REFRESH_LAST_DETECTED_SPAM_ITEM`
export const REFRESH_LAST_DETECTED_SPAM_ITEM_ERROR = `${name}/REFRESH_LAST_DETECTED_SPAM_ITEM_ERROR`
export const REFRESH_LAST_DETECTED_SPAM_ITEM_SUCCESS = `${name}/REFRESH_LAST_DETECTED_SPAM_ITEM_SUCCESS`

export const REFRESH_USERS = `${name}/REFRESH_USERS`
export const REFRESH_USERS_ERROR = `${name}/REFRESH_USERS_ERROR`
export const REFRESH_USERS_SUCCESS = `${name}/REFRESH_USERS_SUCCESS`

export const REFRESH_POSTS = `${name}/REFRESH_POSTS`
export const REFRESH_POSTS_ERROR = `${name}/REFRESH_POSTS_ERROR`
export const REFRESH_POSTS_SUCCESS = `${name}/REFRESH_POSTS_SUCCESS`

export const REFRESH_TELLS = `${name}/REFRESH_TELLS`
export const REFRESH_TELLS_ERROR = `${name}/REFRESH_TELLS_ERROR`
export const REFRESH_TELLS_SUCCESS = `${name}/REFRESH_TELLS_SUCCESS`

export const UNDO_SPAM_ITEM = `${name}/UNDO_SPAM_ITEM`
export const UNDO_SPAM_ITEM_ERROR = `${name}/UNDO_SPAM_ITEM_ERROR`
export const UNDO_SPAM_ITEM_SUCCESS = `${name}/UNDO_SPAM_ITEM_SUCCESS`

export const WHITELIST_TELL = `${name}/WHITELIST_TELL`
export const WHITELIST_TELL_ERROR = `${name}/WHITELIST_TELL_ERROR`
export const WHITELIST_TELL_SUCCESS = `${name}/WHITELIST_TELL_SUCCESS`
