import { nameV2 as name } from './constants'

export const getDataMode = (state) => state[name].dataMode

export const getHeaderMode = (state) => state[name].headerMode

export const getLanguage = (state) => state[name].language

export const getIsRefreshingTopics = (state) => state[name].topics.isRefreshing

export const getIsRefreshingTopicDetails = (state) =>
  state[name].topicDetails.isRefreshing

export const getIsRefreshingGroupDetails = (state) =>
  state[name].groupDetails.isRefreshing

export const getIsRefreshingTodos = (state) => state[name].isRefreshingTodos

export const getIsRefreshingVarianceAnalytics = (state) =>
  state[name].varianceAnalytics.isRefreshing

export const getShouldShowDeletedVariances = (state) =>
  state[name].groupDetails.shouldShowDeletedVariances
