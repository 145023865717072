import { DETECTABLE_LANGUAGE } from '@tellonym/enums/lib/Language'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { ContentContainer, Text, theme, View } from '../../common'
import { refreshBadwordscore as refresh } from '../actions'
import { getBadwordscoreData } from '../selectors'
import { BadwordInput } from './BadwordInput'

const ResultField = ({ style, ...props }) => (
  <Text
    style={[
      {
        flex: 1,
        marginTop: 5,
      },
      style,
    ]}
    {...props}
  />
)

const BadwordScoreResult = ({ data }) => (
  <View
    style={{
      backgroundColor: theme.colors.background,
      flexDirection: 'column',
      borderWidth: 0,
      borderBottomWidth: 0,
      borderColor: theme.colors.borderLightGrey,
      borderStyle: 'solid',
      paddingVertical: 12,
      alignItems: 'left',
      marginLeft: 12,
    }}>
    <ResultField>String: {data.str}</ResultField>
    <ResultField>Score: {data.score}</ResultField>
    <ResultField>Factor: {data.factor.toPrecision(2)}</ResultField>
    <ResultField>Used Lang: {DETECTABLE_LANGUAGE[data.language]}</ResultField>
    <ResultField>Found Words: {data.foundWords.join(',')}</ResultField>
    <ResultField>
      Would be blocked?: {data.wouldBeBlocked.toString()}
    </ResultField>
  </View>
)

const mapStateToProps = createStructuredSelector({
  data: getBadwordscoreData,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refresh }, dispatch),
})

export const PageBadwordscore = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ data }) => {
  return (
    <ContentContainer>
      <View
        style={{
          maxWidth: 1200,
          alignSelf: 'center',
          flex: 1,
          width: '90%',
        }}>
        <BadwordInput style={{ marginBottom: 12 }} />
        {data.str !== '' && (
          <View
            style={{
              backgroundColor: theme.colors.background,
              flexDirection: 'row',
              borderWidth: 0,
              borderBottomWidth: 0,
              borderColor: theme.colors.borderLightGrey,
              borderStyle: 'solid',
              paddingVertical: 12,
              alignItems: 'center',
            }}>
            <BadwordScoreResult data={data} />
          </View>
        )}
      </View>
    </ContentContainer>
  )
})
