import useChange from '@react-hook/change'
import { effect } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { message } from 'antd'
import React from 'react'
import * as Redux from 'react-redux'
import { theme } from '../styles/theme'
import { Text } from './Text'
import { View } from './View'

const unsupportedChildError =
  'TextTranslatablePure received non string child which is not supported for translatable components.'

const translateText = async ({ accessToken, text }) => {
  const action = {
    payload: { language: 'en', items: [{ content: text }] },
    meta: {
      accessToken,
      effect: { path: 'admin/translate', method: 'POST' },
    },
  }
  const res = await effect(action.meta.effect, action)

  const { data = [] } = res.items || {}

  if (data.length > 0) {
    return data[0].content
  }

  throw new Error('Translation failed: Response does not contain translation')
}

export function useTranslation(text, isActive) {
  const accessToken = Redux.useSelector(getAccessToken)
  const [translation, setTranslation] = React.useState(text)

  async function fetchTranslation() {
    try {
      const translated = await translateText({
        accessToken,
        text,
      })
      setTranslation(translated)
    } catch (e) {
      message.error(e.message ?? `${e.status}: ${e.statusText}`)
    }
  }

  React.useEffect(() => {
    if (isActive) {
      fetchTranslation()
    }
  }, [isActive])

  useChange(text, (curr, prev) => {
    if (isActive && typeof curr === 'string' && curr !== prev) {
      fetchTranslation()
    }
  })

  return isActive ? translation : text
}

const TextTranslatablePure = ({
  children,
  onLoadChange,
  onPress,
  shouldTranslate,
  style,
}) => {
  let isLoading = false
  const [textTranslated, setTextTranslated] = React.useState(null)
  const [isTranslationVisible, setIsTranslationVisible] = React.useState(false)
  const accessToken = Redux.useSelector(getAccessToken)

  async function fetchTranslation() {
    isLoading = true
    onLoadChange?.(isLoading)

    try {
      const translated = await translateText({
        accessToken,
        text: children,
      })
      setTextTranslated(translated)
    } catch (e) {
      message.error(e.message ?? `${e.status}: ${e.statusText}`)
    }

    isLoading = false
    onLoadChange?.(isLoading)
    setIsTranslationVisible(true)
  }

  React.useEffect(() => {
    if (!isLoading && !textTranslated && shouldTranslate) {
      fetchTranslation()
    } else if (!isLoading && textTranslated) {
      setIsTranslationVisible(shouldTranslate)
    }
  }, [shouldTranslate])

  useChange(children, (_, nextChildren) => {
    if (typeof nextChildren === 'string') {
      if (shouldTranslate) {
        fetchTranslation()
      } else if (textTranslated) {
        setTextTranslated(null)
      }
    }
  })

  if (typeof children !== 'string') {
    console.error(unsupportedChildError) // eslint-disable-line no-console
    return children || null
  }

  return (
    <Text onPress={onPress} style={style}>
      {isTranslationVisible ? textTranslated : children}
    </Text>
  )
}

export const TextTranslatable = ({
  children,
  onPress = () => {},
  shouldTranslate = false,
  style = {},
}) => {
  const [isTextTranslatedVisible, setIsTextTranslatedVisible] =
    React.useState(shouldTranslate)

  const onSwitchTranslation = React.useCallback(
    (e) => {
      e.stopPropagation()

      setIsTextTranslatedVisible(!isTextTranslatedVisible)
    },
    [isTextTranslatedVisible]
  )

  React.useEffect(() => {
    setIsTextTranslatedVisible(shouldTranslate)
  }, [shouldTranslate])

  if (typeof children !== 'string') {
    console.error(unsupportedChildError) // eslint-disable-line no-console
    return children || null
  }

  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      <TextTranslatablePure
        shouldTranslate={isTextTranslatedVisible}
        onPress={onPress}>
        {children}
      </TextTranslatablePure>
      <View
        style={{
          position: 'relative',
          flex: 1,
          minWidth: 47,
          marginLeft: 2,
        }}>
        <View
          onPress={onSwitchTranslation}
          style={{
            backgroundColor: theme.colors.inputBackground,
            borderRadius: 4,
            cursor: 'pointer',
            left: 0,
            paddingHorizontal: 4,
            paddingVertical: 2,
            position: 'absolute',
            top: 0,
            zIndex: 98,
          }}>
          <Text type="note">{isTextTranslatedVisible ? 'original' : 'en'}</Text>
        </View>
      </View>
    </View>
  )
}

TextTranslatable.Pure = TextTranslatablePure
