import React from 'react'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'

export const TouchableOpacity = Radium(
  ({ children, disabled, forwardRef, onPress = () => {}, style, ...props }) => (
    <div
      ref={forwardRef}
      onClick={
        disabled
          ? () => {}
          : (e) => {
              e.stopPropagation()

              onPress(e)
            }
      }
      style={[
        {
          cursor: 'pointer',
          color: theme.colors.text,
          display: 'flex',
          flexDirection: 'column',
        },
        style,
      ]}
      {...props}>
      {children}
    </div>
  )
)
