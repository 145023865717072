import React from 'react'
import { Button, Modal, Text, theme, View } from '../../common'

const hide = () => Modal.hide({ id: 'ModalConfirmAction' })

const isVisible = () => Modal.isVisible({ id: 'ModalConfirmAction' })

const show = (payload) =>
  Modal.show({
    id: 'ModalConfirmAction',
    render: (props) => <ModalConfirmActionComponent {...payload} {...props} />,
    shouldHideOnBackgroundPress: true,
    hasBackdrop: true,
  })

const ModalConfirmActionComponent = ({ action, description, title }) => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: theme.colors.background,
            padding: 32,
            minWidth: 320,
          }}>
          <Text bold center type="h1" style={{ marginBottom: 12 }}>
            {title}
          </Text>
          <Text type="h4" style={{ marginBottom: 24 }}>
            {description}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginBottom: 12,
            }}>
            <Button onPress={hide}>Cancel</Button>
            <Button onPress={action} style={{ minWidth: 88 }}>
              Confirm
            </Button>
          </View>
        </View>
      </Modal.Body>
    </View>
  )
}

export const ModalConfirmAction = { show, hide, isVisible }
