import { DETECTABLE_LANGUAGE } from '@tellonym/enums/lib/Language'
import { POST_STATUS, POST_TYPE } from '@tellonym/enums/lib/post'
import { SENDER_STATUS } from '@tellonym/enums/lib/Tell'
import { Descriptions, Modal } from 'antd'
import moment from 'moment'
import React from 'react'

const getStringFromValue = (key, value) => {
  const lowerCaseFieldName = key.toLowerCase()

  const isLanguage = lowerCaseFieldName.includes('language')

  if (isLanguage) {
    return `${DETECTABLE_LANGUAGE[value]} (${value})`
  }

  const isPostType =
    ['type', 'posttype'].includes(lowerCaseFieldName) &&
    typeof value === 'number' &&
    POST_TYPE[value]

  if (isPostType) {
    return `${POST_TYPE[value]} (${value})`
  }

  const isPostStatus =
    ['poststatus'].includes(lowerCaseFieldName) &&
    typeof value === 'number' &&
    POST_STATUS[value]

  if (isPostStatus) {
    return `${POST_STATUS[value]} (${value})`
  }

  const isSenderStatus =
    ['senderstatus'].includes(lowerCaseFieldName) &&
    typeof value === 'number' &&
    SENDER_STATUS[value]

  if (isSenderStatus) {
    return `${SENDER_STATUS[value]} (${value})`
  }

  const childrenToNum = parseInt(value, 10)
  const isBoolean =
    !lowerCaseFieldName.includes('count') &&
    (childrenToNum === 0 || childrenToNum === 1)

  if (isBoolean) {
    return value ? 'Yes' : 'No'
  }

  // We test for "At" because IP addresses are interpreted as dates
  if (
    typeof key === 'string' &&
    key.includes('At') &&
    moment(value).isValid()
  ) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
  }

  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value)
  }

  if (typeof value === 'undefined' || value === null) {
    return '-'
  }

  return value
}

const AdditionalInfosComponent = ({ item }) => {
  return (
    <Descriptions>
      {Object.keys(item).map((key) => {
        const value = getStringFromValue(key, item[key])

        return (
          <Descriptions.Item key={key} label={key}>
            {value}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}

const show = (payload) =>
  Modal.info({
    title: 'More info',
    content: <AdditionalInfosComponent {...payload} />,
    width: '80%',
  })

export const AdditionalInfos = {
  show,
}
