import { POST_MEDIA_TYPE } from '@tellonym/enums/lib/post'
import React, { useEffect, useRef, useState } from 'react'
import { FullscreenImage } from './FullscreenImage'
import { View } from './View'

export const AnswerPicture = ({ media }) => {
  const [width, setWidth] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    if (
      width === 0 &&
      ref &&
      ref.current &&
      ref.current.getBoundingClientRect
    ) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
  }, [ref, width])

  const hasPicture =
    !!media &&
    !!media[0] &&
    !!media[0].url &&
    media[0].type === POST_MEDIA_TYPE.IMAGE

  if (!hasPicture) {
    return null
  }

  const picture = media[0]

  return (
    <View
      forwardRef={ref}
      style={{ maxWidth: window.tnym.isDesktop() ? 360 : 500 }}>
      <FullscreenImage
        containerStyle={{ marginTop: 15 }}
        hasWarning={picture.isNSFW}
        style={{
          width: '100%',
          height: width / 1.2,
          borderRadius: 8,
        }}
        source={picture.thumbUrl || picture.url}
        fullscreenSource={picture.url}
        thumbnailSource={
          picture.thumbSmallUrl || picture.thumbUrl || picture.url
        }
      />
    </View>
  )
}
