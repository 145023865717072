import { throttle } from '@tellonym/core/helpers'
import { removePost } from '@tellonym/core/posts/actions'
import { compose } from 'ramda'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connectWithRouter } from '../hocs/connectWithRouter'
import { theme } from '../styles/theme'
import { PostItemHeader } from './PostItemHeader'
import { ReadMore } from './ReadMore'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const Post = ({ children }) => (
  <View style={{ marginTop: 10, marginBottom: 15, marginLeft: 10 }}>
    <ReadMore>{children}</ReadMore>
  </View>
)

const SendTell = ({ onPress }) => (
  <TouchableOpacity
    onPress={onPress}
    style={{
      alignItems: 'center',
      backgroundColor: theme.colors.softGrey,
      borderRadius: 14,
      justifyContent: 'center',
      paddingVertical: 6,
      width: '100%',
    }}>
    <Text color={theme.colors.Placeholder}>Send Tell</Text>
  </TouchableOpacity>
)

class _PostItem extends React.PureComponent {
  _onPressMenuItem = () => {}

  _onPressProfile = throttle(() => {
    const { history, profile } = this.props
    const pathname = `/${profile.username}`
    return history[history.location.pathname === pathname ? 'replace' : 'push'](
      pathname
    )
  })

  render() {
    const { item, hasSeparator, isCurrentUser, profile } = this.props

    const menuItems = [
      !isCurrentUser && 'Report',
      isCurrentUser && 'Remove',
    ].filter(Boolean)

    return (
      <View
        desktopStyle={{
          border: 0,
          marginBottom: 8,
          borderRadius: 8,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
        }}
        style={{
          backgroundColor: theme.colors.background,
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 15,
          paddingRight: 20,
          borderBottomStyle: 'solid',
          borderBottomWidth: theme.dimensions.widths.borders[1],
          borderBottomColor: hasSeparator
            ? theme.colors.border
            : theme.colors.transparent,
        }}>
        <PostItemHeader
          item={item}
          menuItems={menuItems}
          onPressMenu={this._onPressMenuItem}
          onPressProfile={this._onPressProfile}
          profile={profile}
          theme={theme}
        />
        <Post>{item.answer}</Post>

        <SendTell onPress={this._onPressWrite} />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ removePost }, dispatch),
})

const withConnect = connectWithRouter(null, mapDispatchToProps)

const enhancer = compose(withConnect)

export const PostItem = enhancer(_PostItem)
