import React from 'react'
import { Link as L } from 'react-router-dom'
import { theme } from '../styles/theme'

const Link = ({ children, target = null, to, style = {}, onClick }) =>
  to && to.startsWith('http') ? (
    <a
      href={to}
      style={{ display: 'flex', textDecoration: 'none', ...style }}
      target={target}
      onClick={onClick}>
      {children}
    </a>
  ) : (
    <L
      target={target}
      to={to}
      style={{
        display: 'flex',
        textDecoration: 'none',
        color: theme.colors.text,
        ...style,
      }}
      onClick={onClick}>
      {children}
    </L>
  )

export { Link }
