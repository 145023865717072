import { flattenProps } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import {
  BAN_REASON,
  USER_LINK_STATUS,
  USER_LINK_TYPE,
  USER_LOGIN_METHODS_TYPE,
  WARNING_REASON,
} from '@tellonym/enums/lib/User'
import { Button as AntButton, Modal as AntdModal, Table } from 'antd'
import {
  __,
  compose,
  curry,
  findIndex,
  includes,
  insertAll,
  is,
  isEmpty,
  keys,
  prop,
} from 'ramda'
import React from 'react'
import * as Redux from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  FlatList,
  Link,
  Modal,
  Text,
  TouchableOpacity,
  View,
  moment,
  theme,
} from '../../common'
import {
  formatTimestamp,
  getDeviceDump,
  getProfileDump,
} from '../../common/helpers'
import { deletePublicLink, refreshDevices, refreshUserLog } from '../actions'
import { getLastDevices, getLastIpAddress } from '../selectors'
import { AccountBansTable } from './BanStats'

const getString = (key, value) => {
  const isBoolean =
    /^(is|has|are|should|allow)[A-Z]\w+/.test(key) ||
    includes(key, ['verified', 'tellsOnlyFromRegistered'])

  if (isBoolean) {
    return value ? 'yes' : 'no'
  }

  const isNumber = /^[0-9]+$/.test(value)

  if (isNumber) {
    return parseInt(value, 10)
  }

  const isTime =
    !is(Object, value) && moment(value, moment.ISO_8601, true).isValid()

  if (isTime) {
    return formatTimestamp(value)
  }

  if (typeof value === 'string') {
    return String(value)
  }

  return null
}

const Key = ({ children }) => (
  <Text
    bold
    style={{ flex: 1, textAlign: 'right', marginRight: 12, marginBottom: 6 }}>
    {children}
  </Text>
)

const Value = ({ children }) => (
  <Text style={{ flex: 1, marginBottom: 6, minWidth: 0 }}>
    {children || '-'}
  </Text>
)

const Row = ({ label, value }) => (
  <View style={{ flexDirection: 'row', flex: 1 }}>
    <Key>{label}</Key>
    <Value>{value}</Value>
  </View>
)

const Section = ({ hasData, label, onPress }) => (
  <Row
    label={label}
    value={
      hasData ? (
        <Text onPress={onPress} color={theme.colors.primary}>
          show
        </Text>
      ) : null
    }
  />
)

const CloseButton = ({ onPress }) => (
  <TouchableOpacity
    onPress={onPress}
    style={{
      width: '100%',
      borderBottomRightRadius: 25,
      borderBottomLeftRadius: 25,
      paddingVertical: 12,
      borderTopWidth: 1,
      borderTopColor: theme.colors.borderLightGrey,
      borderTopStyle: 'solid',
    }}>
    <Text center>Close</Text>
  </TouchableOpacity>
)

const ModalTable = ({ config, modalId, data }) => {
  const columns = config.map(({ key, text, getDerivedValue, render }) => ({
    dataIndex: key,
    title: text,
    render: render ?? ((value) => getDerivedValue?.(value) ?? value),
  }))

  return (
    <Modal.Body
      style={{
        flex: 1,
        margin: 36,
        backgroundColor: theme.colors.background,
        paddingTop: 25,
        borderRadius: 25,
        justifyContent: 'space-between',
      }}>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={false}
      />
      <CloseButton onPress={() => Modal.hide({ id: modalId })} />
    </Modal.Body>
  )
}

const showModal = curry((Component, payload) =>
  Modal.show({
    animationType: Modal.Animations.FADE,
    render: (props) => <Component {...props} {...payload} />,
    hasBackdrop: true,
  })
)

const showModalTable = showModal(ModalTable)

const DeleteLink = ({ profileId, type }) => {
  const dispatch = Redux.useDispatch()

  const onPressDeleteLink = () => {
    dispatch(deletePublicLink({ userId: profileId, linkType: type }))
  }

  return (
    <AntButton type="link" onClick={onPressDeleteLink}>
      Delete link
    </AntButton>
  )
}

const tableConfigLinks = [
  {
    key: 'type',
    text: 'type',
    getDerivedValue: prop(__, USER_LINK_TYPE),
  },
  {
    key: 'status',
    text: 'status',
    getDerivedValue: prop(__, USER_LINK_STATUS),
  },
  { key: 'link', text: 'link' },
  {
    key: 'remove',
    render: (text, record) => (
      <DeleteLink type={record.type} profileId={record.profileId} />
    ),
  },
]

const tableConfigWarnings = [
  {
    key: 'reason',
    text: 'reason',
    getDerivedValue: prop(__, WARNING_REASON),
  },
  { key: 'time', text: 'time', getDerivedValue: formatTimestamp },
  { key: 'textReason', text: 'text' },
  { key: 'issuerUserId', text: 'issuer' },
  { key: 'textReasonInternal', text: 'internal' },
]

const tableConfigBans = [
  {
    key: 'reason',
    text: 'reason',
    getDerivedValue: prop(__, BAN_REASON),
  },
  { key: 'time', text: 'time', getDerivedValue: formatTimestamp },
  { key: 'timeEnd', text: 'timeEnd', getDerivedValue: formatTimestamp },
  { key: 'textReason', text: 'textReason' },
  { key: 'issuerUserId', text: 'issuer' },
  { key: 'internalNote', text: 'internal note' },
]

const tableConfigAssociatedDeviceBans = [
  {
    key: 'reason',
    text: 'reason',
    getDerivedValue: prop(__, BAN_REASON),
  },
  { key: 'time', text: 'time', getDerivedValue: formatTimestamp },
  { key: 'timeEnd', text: 'timeEnd', getDerivedValue: formatTimestamp },
  { key: 'textReason', text: 'textReason' },
  { key: 'issuerUserId', text: 'issuer' },
  { key: 'deviceUuid', text: 'device id' },
  { key: 'internalNote', text: 'internal note' },
]

const tableConfigLoginMethods = [
  {
    key: 'type',
    text: 'type',
    getDerivedValue: prop(__, USER_LOGIN_METHODS_TYPE),
  },
  { key: 'time', text: 'time', getDerivedValue: formatTimestamp },
  {
    key: 'identifier',
    text: 'userIdentifier',
  },
]

const changeOrderAndFilter = (keys) => {
  const desiredKeys = [
    'email',
    'phoneNumberCombined',
    'location',
    'lang',
    'purchaserId',
  ]

  const blacklist = [
    'associatedDeviceBans',
    'allowEmails',
    'allowSearchByPhone',
    'allowShowActivity',
    'answerCount',
    'avatar',
    'avatars',
    'bans',
    'follower',
    'followingCount',
    'hasAllowedSearchByLocation',
    'isPreciseBirthDate',
    'isVerified',
    'lastActive',
    'loginMethods',
    'phoneNumber',
    'phonePrefix',
    'sentTellCount',
    'tellCount',
    'theme',
    'type',
    'warnings',
  ]

  const restProfile = keys.filter(
    (key) => !desiredKeys.includes(key) && !blacklist.includes(key)
  )

  const index = findIndex((key) => key === 'avatar', restProfile) || 0
  const result = insertAll(index + 1, desiredKeys, restProfile)

  return result
}

const getProfileInfoKeys = compose(changeOrderAndFilter, keys)

class _TabInfo extends React.PureComponent {
  state = {
    profileInfoKeys: [],
    profileId: undefined,
    isModalVisible: false,
  }

  componentDidMount() {
    const { actions, profile } = this.props

    actions.refreshDevices({ userId: profile.id })
    actions.refreshUserLog({ id: profile.id })
  }

  static getDerivedStateFromProps(props, state) {
    const { profile } = props
    const { profileId: prevProfileId } = state

    if (profile.id !== prevProfileId) {
      return {
        profileInfoKeys: getProfileInfoKeys(profile),
        profileId: profile.id,
      }
    }

    return null
  }

  _toggleModal = (data) => () => {
    this.setState((state) => ({
      isModalVisible: !state.isModalVisible,
      modalData: data,
    }))
  }

  _renderHeader = () => {
    const { actions, profile: p, lastDevices, lastIpAddress } = this.props
    const {
      associatedDeviceBans,
      bans,
      linkData,
      warnings,
      loginMethods,
      ...profile
    } = p

    const linkDataExtended = linkData.map((item) => ({
      ...item,
      profileId: p.id,
    }))

    const onPressCopyData = () => {
      const profileDump = getProfileDump({ ...profile, lastIpAddress })
      const devicesDump = lastDevices.reduce(
        (acc, device, index) =>
          `${acc}\nDevice #${index + 1}${getDeviceDump(
            flattenProps(device)
          )}\n`,
        ''
      )
      const dump = `${profileDump}\n${devicesDump}`

      actions.copyToClipboard(dump)
    }

    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.background,
          borderRadius: 25,
          paddingVertical: 12,
          paddingHorizontal: 6,
          minWidth: 700,
        }}>
        <Button
          onPress={onPressCopyData}
          style={{ alignSelf: 'center', marginBottom: 12 }}>
          Copy Data
        </Button>

        {this.state.profileInfoKeys.map((key) => {
          const valueString = getString(key, profile[key])
          const purchaseValue =
            key === 'purchaserId' && valueString ? (
              <Link
                to={`https://app.revenuecat.com/customers/771e9a0d/${valueString}`}
                target={'_blank'}>
                {valueString}
              </Link>
            ) : undefined

          return (
            <Row key={key} label={key} value={purchaseValue ?? valueString} />
          )
        })}

        <Section
          label="public links"
          hasData={!isEmpty(linkDataExtended)}
          onPress={() =>
            showModalTable({
              config: tableConfigLinks,
              data: linkDataExtended,
            })
          }
        />

        <Section
          label="warnings"
          hasData={!isEmpty(warnings)}
          onPress={() =>
            showModalTable({ config: tableConfigWarnings, data: warnings })
          }
        />

        <Section
          label="bans"
          hasData={!isEmpty(bans)}
          onPress={this._toggleModal(bans)}
        />

        <Section
          label="associatedDeviceBans"
          hasData={!isEmpty(associatedDeviceBans)}
          onPress={this._toggleModal(associatedDeviceBans)}
        />

        <Section
          label="loginMethods"
          hasData={!isEmpty(loginMethods)}
          onPress={() =>
            showModalTable({
              config: tableConfigLoginMethods,
              data: loginMethods,
            })
          }
        />
      </View>
    )
  }

  render() {
    return (
      <>
        <AntdModal
          open={this.state.isModalVisible}
          onOk={this._toggleModal()}
          onCancel={this._toggleModal()}
          width="90%">
          <AccountBansTable bans={this.state.modalData} />
        </AntdModal>
        <FlatList
          component={null}
          hasMore={false}
          isFetching={false}
          isRefreshing={false}
          items={{ ids: [], data: {} }}
          renderHeader={this._renderHeader}
          style={{ height: window.innerHeight - 171, minWidth: 700 }}
        />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { copyToClipboard, refreshDevices, refreshUserLog },
    dispatch
  ),
})

const mapStateToProps = (state, props) => ({
  lastDevices: getLastDevices(state, { profileId: props.profile.id }),
  lastIpAddress: getLastIpAddress(state, { profileId: props.profile.id }),
})

export const TabInfo = Redux.connect(
  mapStateToProps,
  mapDispatchToProps
)(_TabInfo)
