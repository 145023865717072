import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Avatar, FlatList, Link, Text, View } from '../../common'
import { UserTypeBadge } from '../../moderator/components/UserTypeBadge'
import { fetchUsers as fetch, refreshUsers as refresh } from '../actions'
import {
  getHasMoreUsers,
  getIsFetchingUsers,
  getIsRefreshingUsers,
  getUsers,
} from '../selectors'

const ProfileItem = ({ item }) =>
  item && item.id ? (
    <View style={{ flex: 1, marginBottom: 24 }}>
      <Link
        to={`/user/${item.id}`}
        target="_blank"
        style={{ alignItems: 'center', flexDirection: 'column' }}>
        <Avatar
          user={{
            ...item,
            avatarFileName: item.avatarFileName,
          }}
          alt="User Avatar"
          size={75}
        />
        <UserTypeBadge type={item.type} style={{ marginTop: 10 }} />
        <Text center style={{ fontSize: 32 }}>
          {item.displayName}
        </Text>
        <Text center style={{ color: 'grey' }}>{`@${item.username}`}</Text>
      </Link>
    </View>
  ) : null

class _UsersList extends React.PureComponent {
  render() {
    const { actions, hasMore, isFetching, isRefreshing, spamItemId, users } =
      this.props

    if (!users || !users.ids || !users.ids.length) {
      return null
    }

    return (
      <FlatList
        actions={{
          fetch: (payload) => actions.fetch({ ...payload, spamItemId }),
          refresh: (payload) => actions.refresh({ ...payload, spamItemId }),
        }}
        contentContainerStyle={{ justifyContent: 'space-between' }}
        component={ProfileItem}
        extraProps={{ spamItemId }}
        hasMore={hasMore}
        isFetching={isFetching}
        isRefreshing={isRefreshing}
        items={users}
        style={{ maxHeight: 720 }}
        numColumns={3}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetch, refresh }, dispatch),
})

const mapStateToProps = (state, props) => ({
  users: getUsers(state, props.spamItemId),
  hasMore: getHasMoreUsers(state),
  isFetching: getIsFetchingUsers(state),
  isRefreshing: getIsRefreshingUsers(state),
})

const UsersList = connect(mapStateToProps, mapDispatchToProps)(_UsersList)

export { UsersList }
