import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { ScrollView } from 'react-native-web'
import { theme } from '../styles/theme'
import { Icon } from './Icon'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const Item = ({ children, isActive, isFirst, isLast, onPress }) => (
  <TouchableOpacity
    onPress={onPress}
    style={{
      borderTop: isFirst ? undefined : `1px solid ${theme.colors.border}`,
      backgroundColor: isActive ? theme.colors.darkerBackground : undefined,
      boxShadow: isActive ? theme.styles.shadowInset : undefined,
      borderTopLeftRadius: isFirst ? 25 : undefined,
      borderTopRightRadius: isFirst ? 25 : undefined,
      borderBottomLeftRadius: isLast ? 25 : undefined,
      borderBottomRightRadius: isLast ? 25 : undefined,
    }}>
    <Text
      style={{
        paddingVertical: 12,
        paddingHorizontal: 32,
        onHover: {
          color: theme.colors.primary,
        },
      }}>
      {children}
    </Text>
  </TouchableOpacity>
)

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    const { currentIndex, shouldSetInitialValue = true } = props

    this.state = {
      selectedIndex:
        currentIndex ||
        (!shouldSetInitialValue ? null : props.initialActiveIndex || 0),
      isOpen: false,
    }
  }

  _toggleDropdown = () => this.setState((state) => ({ isOpen: !state.isOpen }))

  _onPressItem = (index) => {
    const { onChange = () => {}, options } = this.props
    const { selectedIndex } = this.state

    onChange({ item: options[index], index })

    if (index !== selectedIndex) {
      this.setState({ isOpen: false, selectedIndex: index })
    } else {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const {
      currentIndex,
      containerStyle = {},
      options,
      label,
      labelStyle,
      name,
      shouldSetInitialValue = true,
      style = {},
    } = this.props
    const { isOpen, selectedIndex } = this.state
    const isControlledComponent = Number.isInteger(currentIndex)
    const indexToUse = isControlledComponent ? currentIndex : selectedIndex
    const shouldUseScrollView = options.length > 12
    const Wrapper = shouldUseScrollView ? ScrollView : View

    return (
      <View style={containerStyle}>
        {label && (
          <Text
            type="small"
            bold
            style={{ marginLeft: 16, marginBottom: 4, ...labelStyle }}>
            {label}
          </Text>
        )}
        <TouchableOpacity
          onPress={this._toggleDropdown}
          style={[
            {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.colors.border,
              borderRadius: 25,
              boxShadow: theme.styles.shadow,
              backgroundColor: theme.colors.background,
            },
            style,
          ]}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              paddingVertical: 12,
              paddingHorizontal: 16,
            }}>
            <Text
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                onHover: {
                  color: theme.colors.primary,
                },
              }}>
              {shouldSetInitialValue
                ? name
                  ? `${name}: ${options[indexToUse]}`
                  : options[indexToUse]
                : 'Select...'}
            </Text>
            <Icon
              icon={faChevronDown}
              style={{
                marginLeft: 8,
                onHover: {
                  color: theme.colors.primary,
                },
              }}
            />
          </View>
        </TouchableOpacity>
        {isOpen && (
          <View
            style={{
              borderRadius: 25,
              border: `1px solid ${theme.colors.border}`,
              position: 'absolute',
              backgroundColor: theme.colors.background,
              zIndex: 99,
              boxShadow: theme.styles.shadow,
              maxHeight: shouldUseScrollView ? 520 : undefined,
            }}>
            <Wrapper>
              {options.map((item, index) => (
                <Item
                  key={item}
                  isActive={index === indexToUse}
                  isFirst={index === 0}
                  isLast={index === options.length - 1}
                  onPress={() => this._onPressItem(index)}>
                  {item}
                </Item>
              ))}
            </Wrapper>
          </View>
        )}
      </View>
    )
  }
}

export { Dropdown }
