import React from 'react'
import * as Redux from 'react-redux'
import { getIsSidemenuShown } from '../../app/selectors'
import { SideMenu } from '../../navigation/components/SideMenu'

export const usePageContainerStyle = (styles) => {
  const isSidemenuShown = Redux.useSelector(getIsSidemenuShown)
  const [containerStyles, setContainerStyles] = React.useState({
    width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
    height: window.tnym.getHeight(),
    ...styles,
  })

  React.useEffect(() => {
    setContainerStyles({
      width: window.tnym.getWidth() - (isSidemenuShown ? SideMenu.width : 0),
      height: window.tnym.getHeight(),
      ...styles,
    })
  }, [isSidemenuShown])

  return containerStyles
}
