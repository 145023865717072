import React from 'react'
import { ContentContainer } from '../../common'
import { useProfileSettingsQuery } from '../../profile/queries'
import { PageProfile } from './PageProfile'

export const PageResult = ({ match }) => {
  const { data: profile, isFetching } = useProfileSettingsQuery({
    userId: parseInt(match.params.id, 10),
  })

  if (typeof profile === 'undefined') {
    return <ContentContainer isRefreshing={isFetching && !profile} />
  }

  return <PageProfile profile={profile} />
}
