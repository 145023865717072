import React from 'react'
import { theme } from '../styles/theme'
import { Modal } from './Modal'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

const ActionSheetComponent = ({ actions, modalId }) => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Modal.Body
      style={{ backgroundColor: theme.colors.background, borderRadius: 25 }}>
      {actions.map((item, index) => (
        <TouchableOpacity
          key={item}
          onPress={() =>
            Modal.hide({
              id: modalId,
              extraData: { selected: index },
            })
          }
          style={{
            minWidth: 300,
            paddingHorizontal: 16,
            paddingVertical: 16,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.colors.borderLightGrey,
          }}>
          <Text center>{item}</Text>
        </TouchableOpacity>
      ))}
      <TouchableOpacity
        onPress={() => Modal.hide({ id: modalId, extraData: { selected: -1 } })}
        style={{
          minWidth: 300,
          paddingHorizontal: 12,
          paddingVertical: 12,
        }}>
        <Text center>Cancel</Text>
      </TouchableOpacity>
    </Modal.Body>
  </View>
)

const show = (payload = {}) =>
  payload.actions &&
  Modal.show({
    render: (props) => <ActionSheetComponent {...payload} {...props} />,
    shouldHideOnBackgroundPress: true,
    hasBackdrop: true,
  })

export const ActionSheet = {
  DISMISSED: -1,
  show,
}
