import * as t from './types'

export const changeState = (payload) => ({
  type: t.CHANGE_STATE,
  payload,
})

/**
 * @param {{ content?: string, item?: object }} payload
 * @returns
 */
export const createPost = (payload) => ({
  type: t.CREATE_POST,
  payload,
  meta: {
    offline: {
      effect: { path: 'posts/create', method: 'POST' },
      commit: { type: t.CREATE_POST_SUCCESS },
      rollback: { type: t.CREATE_POST_ERROR },
    },
  },
})

export const fetchTaggedAnswers = (payload) => ({
  type: t.FETCH_TAGGED_ANSWERS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `posts/${payload.userId}/tag/${payload.postId}`,
        ...payload,
      },
      rollback: { type: t.FETCH_TAGGED_ANSWERS_ERROR },
      commit: {
        type: t.FETCH_TAGGED_ANSWERS_SUCCESS,
        meta: { postId: payload.postId, userId: payload.userId },
      },
    },
  },
})

export const fetchTaggedTells = (payload) => ({
  type: t.FETCH_TAGGED_TELLS,
  payload,
  meta: {
    offline: {
      effect: { path: `tells/tag/${payload.postId}`, ...payload },
      rollback: { type: t.FETCH_TAGGED_TELLS_ERROR },
      commit: {
        type: t.FETCH_TAGGED_TELLS_SUCCESS,
        meta: { postId: payload.postId },
      },
    },
  },
})

export const pinPost = (payload) => ({
  type: t.PIN_POST,
  payload,
  meta: {
    offline: {
      effect: { path: `posts/id/${payload.postId}/pin`, method: 'POST' },
      commit: { type: t.PIN_POST_SUCCESS },
      rollback: { type: t.PIN_POST_ERROR, meta: payload },
    },
  },
})

export const refreshPost = (payload) => ({
  shouldAdjustData: false,
  type: t.REFRESH_POST,
  payload,
  meta: {
    offline: {
      effect: { path: `answers/id/${payload.postId}`, ...payload },
      commit: { type: t.REFRESH_POST_SUCCESS },
      rollback: { type: t.REFRESH_POST_ERROR },
    },
  },
})

export const refreshTaggedAnswers = (payload) => ({
  type: t.REFRESH_TAGGED_ANSWERS,
  payload,
  meta: {
    offline: {
      effect: { path: `posts/${payload.userId}/tag/${payload.postId}` },
      rollback: { type: t.REFRESH_TAGGED_ANSWERS_ERROR },
      commit: {
        type: t.REFRESH_TAGGED_ANSWERS_SUCCESS,
        meta: { postId: payload.postId, userId: payload.userId },
      },
    },
  },
})

export const refreshTaggedTells = (payload) => ({
  type: t.REFRESH_TAGGED_TELLS,
  payload,
  meta: {
    offline: {
      effect: { path: `tells/tag/${payload.postId}` },
      rollback: { type: t.REFRESH_TAGGED_TELLS_ERROR },
      commit: {
        type: t.REFRESH_TAGGED_TELLS_SUCCESS,
        meta: { postId: payload.postId, userId: payload.userId },
      },
    },
  },
})

export const removePost = (payload) => ({
  type: t.REMOVE_POST,
  payload,
  meta: {
    offline: {
      effect: { path: `posts/${payload.postId}`, method: 'DELETE' },
      rollback: { type: t.REMOVE_POST_ERROR },
      commit: { type: t.REMOVE_POST_SUCCESS },
    },
  },
})

export const unpinPost = (payload) => ({
  type: t.UNPIN_POST,
  payload,
  meta: {
    offline: {
      effect: { path: `posts/id/${payload.postId}/unpin`, method: 'POST' },
      commit: { type: t.UNPIN_POST_SUCCESS },
      rollback: { type: t.UNPIN_POST_ERROR, meta: payload },
    },
  },
})

export const refreshHighlights = (payload) => ({
  type: t.REFRESH_HIGHLIGHTS,
  payload,
  meta: {
    offline: {
      effect: { path: 'posts/highlights', ...payload },
      commit: { type: t.REFRESH_HIGHLIGHTS_SUCCESS, meta: payload },
      rollback: { type: t.REFRESH_HIGHLIGHTS_ERROR, meta: payload },
    },
  },
})
