/* eslint-disable */
window.tnym = window.tnym || {}
window.tnym.getWidth = () => document.documentElement.clientWidth
window.tnym.getHeight = () => document.documentElement.clientHeight - 50
window.tnym.getFullHeight = () => document.documentElement.clientHeight
window.tnym.isAndorid =
  window.navigator.userAgent.toLowerCase().indexOf('android') > -1
window.tnym.isDesktop = () => document.documentElement.clientWidth >= 768
window.tnym.logProduction = () => {}
window.tnym.renderApp = () => {}
window.tnym.whoami = (() => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return {
      browser: 'ie',
      version: tem[1],
    }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null)
      return {
        browser: tem.indexOf('Edge') > -1 ? 'edge' : 'opera',
        version: tem[2],
      }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return {
    browser: M[0].toLowerCase(),
    version: M[1],
  }
})()
