import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { normalize } from '@tellonym/core/helpers'
import { message } from 'antd'
import dayjs from 'dayjs'
import { pick } from 'ramda'
import React from 'react'
import { store } from '../common/store'
import { dateTypes } from './constants'
import { dateTypeToDates } from './services'

const getIsValidValue = (string) =>
  typeof string !== 'undefined' && string !== ''

const statistics = {
  all: ['statistics'],
  compare: () => [...statistics.all, 'compare'],
  diff: () => [...statistics.all, 'diff'],
  diffCountry: () => [...statistics.all, 'diffCountry'],
  graphData: (props) => [...statistics.all, 'graphData', props],
  shortnames: () => [...statistics.all, 'shortnames'],
  shortnameQuery: (props) => [...statistics.all, 'shortnameQuery', props],
  chEventData: () => [...statistics.all, 'chEventData'],
}

const showErrorMessage = async (error) => {
  const data = await error.json()

  message.error(data.err.code)
}

const fetchCompare = async ({
  control,
  experiment,
  expPercentage,
  shortNames,
  shouldSummarize,
  statsType,
}) => {
  const accessToken = getAccessToken(store.getState())

  const controlDates = dateTypeToDates({
    dateType: control.dateType,
    startDate: control.dates?.[0],
    endDate: control.dates?.[1],
  })

  const experimentDates =
    experiment.dateType === dateTypes.SYNCED
      ? controlDates
      : dateTypeToDates({
          dateType: experiment.dateType,
          startDate: experiment.dates?.[0],
          endDate: experiment.dates?.[1],
        })

  const payload = {
    control: {
      dateFrom: controlDates.startDate,
      dateTo: controlDates.endDate,
      cohort: getIsValidValue(control.cohort) ? control.cohort : undefined,
      experimentId: getIsValidValue(control.experimentId)
        ? Number(control.experimentId)
        : undefined,
    },
    experiment: {
      dateFrom: experimentDates.startDate,
      dateTo: experimentDates.endDate,
      cohort: getIsValidValue(experiment.cohort)
        ? experiment.cohort
        : undefined,
      experimentId: getIsValidValue(experiment.experimentId)
        ? Number(experiment.experimentId)
        : undefined,
    },
    expPercentage: Number(expPercentage),
    shortNames,
    countryCode: control.country,
    doValueSum: shouldSummarize,
    type: statsType,
  }

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/compare',
      method: 'POST',
      accessToken,
      payload,
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useCompareMutation = () => {
  const result = useMutation({
    mutationKey: statistics.compare(),
    mutationFn: fetchCompare,
  })

  return result
}

const fetchDiff = async (params) => {
  const { cohort, countryCode, shortNames, intervalType, ...payload } = params

  const accessToken = getAccessToken(store.getState())

  const startDate =
    payload.startDate ??
    dayjs(payload.endDate)
      .subtract(payload.timespan, 'days')
      .format('YYYY-MM-DD')

  const experimentId = payload.experimentId
    ? Number(payload.experimentId)
    : undefined

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse',
      method: 'POST',
      accessToken,
      payload: {
        cohort,
        countryCode,
        shortNames,
        intervalType,
        experimentId,
        dateFrom: startDate,
        dateTo: payload.endDate,
      },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useDiffMutation = () => {
  // We cache the data to display the previous data when the query is refetching
  const [data, setData] = React.useState({ ids: [], data: {} })

  const result = useMutation({
    mutationKey: statistics.diff(),
    mutationFn: fetchDiff,
  })

  React.useEffect(() => {
    if (result.isSuccess) {
      setData(result.data)
    }
  }, [result.data])

  return { ...result, data }
}

const fetchDiffMultiCountry = async (payload) => {
  const data = await Promise.all(
    payload.countryCodes.map((countryCode) =>
      fetchDiff({ ...payload, countryCode })
    )
  )

  return data.map((dataset, index) => ({
    ...dataset,
    countryCode: payload.countryCodes[index],
  }))
}

export const useDiffMultiCountryMutation = () => {
  const result = useMutation({
    mutationKey: statistics.diffCountry(),
    mutationFn: fetchDiffMultiCountry,
  })

  return result
}

export const getTimeoutAmount = (data) => {
  const idleJobStates = ['waiting', 'inprogress']

  if (typeof data === 'undefined') {
    return false
  }

  const hasInProgress = data.ids.some((shortnameId) =>
    data.data?.[shortnameId]?.ids?.some((id) => {
      const metric = data.data[shortnameId]?.data?.[id]

      return idleJobStates.includes(metric?.jobState)
    })
  )

  return hasInProgress ? 2000 : false
}

export const useGraphDataQuery = (payload) => {
  const restructuredPayload = {
    ...payload,
    shortNames: [payload.shortName],
  }

  const queryClient = useQueryClient()

  // Get the data from the open diff table and use it as initial data for the graph
  const diffQueryData = queryClient.getQueryData({
    queryKey: statistics.diff(),
    exact: false,
    type: 'active',
  })

  const result = useQuery(
    statistics.graphData(restructuredPayload),
    ({ queryKey }) => fetchDiff(queryKey[2]),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: getTimeoutAmount,
      initialData: diffQueryData,
    }
  )

  const data = pick(
    ['ids', 'data'],
    result.data?.data?.[payload.shortName] ?? { ids: [], data: {} }
  )

  return { ...result, data }
}

const fetchGenerateCohort = async (payload) => {
  const accessToken = getAccessToken(store.getState())

  const startDate =
    payload.startDate ??
    dayjs(payload.endDate)
      .subtract(payload.timespan, 'days')
      .format('YYYY-MM-DD')

  const expIds = payload.experimentId
    ? [Number(payload.experimentId)]
    : undefined

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/regenerate',
      method: 'POST',
      accessToken,
      payload: {
        cohorts: [payload.cohort],
        countryCodes: [payload.countryCode],
        expIds,
        dateFrom: startDate,
        dateTo: payload.endDate,
      },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useGenerateCohortMutation = () => {
  const result = useMutation({
    mutationKey: 'generateCohort',
    mutationFn: fetchGenerateCohort,
  })

  return result
}

const fetchShortnames = async () => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/shortnames',
      method: 'GET',
      accessToken,
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  try {
    const data = await resolveOrReject(response, null, false)

    return data
  } catch (e) {
    return e
  }
}

export const useShortnamesQuery = () => {
  const result = useQuery(statistics.shortnames(), fetchShortnames, {
    initialData: { ids: [], data: {} },
    refetchOnWindowFocus: false,
  })

  const groups = normalize(result.data?.groups)

  return { ...result, data: groups }
}

export const useShortnamesMainGroupsQuery = () => {
  const result = useQuery(statistics.shortnames(), fetchShortnames, {
    initialData: { ids: [], data: {} },
    refetchOnWindowFocus: false,
  })

  const mainGroups = normalize(result.data?.mainGroups)

  return { ...result, data: mainGroups }
}

const fetchShortnameQuery = async (params) => {
  const { cohort, countryCode, experimentId, shortName, date } = params

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/getquery',
      method: 'POST',
      accessToken,
      payload: {
        cohort,
        countryCode,
        experimentId,
        date,
        shortName,
      },
    },
    { accessToken }
  )

  // Response can either be text or json, so we need to clone the response and test the result
  const response = await fetch(url, fetchConfig)

  const duplicate = response.clone()

  try {
    await duplicate.json()

    // This means an error was returned
    return resolveOrReject(response, null, false)
  } catch (e) {
    // This means the response was successful and we received the text
    return response.text()
  }
}

export const useGetShortnameQueryMutation = ({ onSuccess }) => {
  const result = useMutation({
    mutationKey: statistics.shortnameQuery(),
    mutationFn: fetchShortnameQuery,
    onSuccess,
    onError: showErrorMessage,
  })

  return result
}

const fetchChEventData = async (payload) => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/analyticseventdata',
      method: 'POST',
      payload,
      accessToken,
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useChEventDataMutation = () => {
  const result = useMutation({
    mutationKey: statistics.chEventData(),
    mutationFn: fetchChEventData,
  })

  return result
}
