import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { LOGIN_SUCCESS, REGISTER_SUCCESS } from '@tellonym/core/user/types'
import { identity, mergeDeepRight, pathOr } from 'ramda'
import * as t from './types'

export const { name } = t

export const accountState = {
  accessToken: undefined,
  avatarFileName: undefined,
  permissions: [],
  userId: undefined,
  username: undefined,
}

export const initialState = {
  error: null,
  isLoading: false,
  isSidemenuShown: true,
  accounts: { activeUserId: undefined },
  customApiHost: null,
}

export const persistence = {
  in: identity,
  out: identity,
  clear: (state) =>
    mergeDeepRight(state, {
      accounts: {
        [state.accounts.activeUserId]: { accessToken: undefined },
        activeUserId: undefined,
      },
    }),
}

const setAccountState = (state, newState) => ({
  ...state,
  accounts: {
    ...state.accounts,
    [state.accounts.activeUserId]: {
      ...state.accounts[state.accounts.activeUserId],
      ...newState,
    },
  },
})

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS: {
      if (action.payload.code === API_CODE.CAPTCHA_REQUIRED) {
        return state
      }

      return {
        ...state,
        accounts: {
          ...state.accounts,
          activeUserId: action.payload.userId,
          [action.payload.userId]: {
            ...accountState,
            ...state.accounts[action.payload.userId],
            accessToken: action.payload.accessToken,
            userId: action.payload.userId,
          },
        },
      }
    }

    case t.REFRESH_PERMISSIONS_SUCCESS:
      return setAccountState(state, {
        permissions: pathOr([], ['payload', 'permissions', 'data'], action),
      })

    case t.TOGGLE_SIDEMENU:
      return {
        ...state,
        isSidemenuShown: !state.isSidemenuShown,
      }

    case t.SET_CUSTOM_API_HOST:
      return {
        ...state,
        customApiHost: action.payload.customApiHost,
      }

    default:
      return state
  }
}
