import { REPORT_DECISION } from '@tellonym/enums/lib/Report'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Image, TouchableOpacity, View } from '../../common'
import { setNsfwPictureStatus } from '../actions'

const colorMap = {
  undefined: 'rgba(255, 255, 255, 0.0)',
  [REPORT_DECISION.OKAY]: 'rgba(255, 255, 255, 0.0)',
  [REPORT_DECISION.NOT_OKAY]: 'rgba(207, 0, 15, 0.8)',
  [REPORT_DECISION.SKIP]: 'rgba(240, 255, 0, 0.8)',
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ setNsfwPictureStatus }, dispatch),
})

export const NsfwPictureItem = connect(
  null,
  mapDispatchToProps
)(({ actions, item }) => {
  return (
    <View style={{}}>
      <TouchableOpacity
        onPress={() => actions.setNsfwPictureStatus({ pictureId: item.id })}
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
          window.open(item.postUrl, '_blank')
        }}
        style={{ position: 'relative' }}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: colorMap[item._decision],
          }}
        />
        <Image
          style={{
            height: 100,
            width: 120,
          }}
          source={{
            uri: item.thumbUrl,
          }}
        />
      </TouchableOpacity>
    </View>
  )
})
