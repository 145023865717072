import { createReducer } from '@tellonym/core/common/reducers'
import { normalize } from '@tellonym/core/helpers'
import {
  assoc,
  assocPath,
  compose,
  concat,
  curry,
  filter,
  lensProp,
  mergeDeepRight,
  path,
  pathOr,
  prop,
  set,
  uniq,
} from 'ramda'
import {
  FETCH_BAN_CANDIDATES,
  FETCH_BAN_CANDIDATES_ERROR,
  FETCH_BAN_CANDIDATES_SUCCESS,
  HIDE_BAN_CANDIDATE,
  REFRESH_BAN_CANDIDATES,
  REFRESH_BAN_CANDIDATES_ERROR,
  REFRESH_BAN_CANDIDATES_SUCCESS,
  UNHIDE_BAN_CANDIDATE,
} from './types'

export const name = 'banCandidates'

export const initialState = {
  hasMore: false,
  isFetching: false,
  isRefreshing: false,
  data: { ids: [], data: {} },
}

const getHasMore = path(['payload', 'users', 'hasMore'])
const setHasMore = assoc('hasMore')

const setIsFetching = assoc('isFetching')
const setIsFetchingTrue = setIsFetching(true)
const setIsFetchingFalse = setIsFetching(false)

const setIsRefreshing = assoc('isRefreshing')
const setIsRefreshingTrue = setIsRefreshing(true)
const setIsRefreshingFalse = setIsRefreshing(false)

const setError = (state, payload) => ({
  ...state,
  isRefreshing: false,
  isFetching: false,
  error: payload,
})

const getNormalized = (action) =>
  normalize(pathOr({}, ['payload', 'users', 'data'], action))

const setBanCandidates = curry((data, state) => {
  const mergedData = mergeDeepRight(
    prop('data', data),
    path(['data', 'data'], state)
  )
  const mergedIds = uniq(concat(data.ids, path(['data', 'ids'], state)))

  const getNewState = compose(
    assocPath(['data', 'data'], mergedData),
    assocPath(['data', 'ids'], mergedIds)
  )

  return getNewState(state)
})

const fetch = (state, action) => {
  const normalized = getNormalized(action)
  const hasMore = getHasMore(action)

  const getNewState = compose(
    setBanCandidates(normalized),
    setHasMore(hasMore),
    setIsFetchingFalse
  )

  return getNewState(state)
}

const refresh = (state, action) => {
  const normalized = getNormalized(action)
  const hasMore = getHasMore(action)
  const lensData = lensProp('data')

  const getNewState = compose(
    set(lensData, normalized),
    setHasMore(hasMore),
    setIsRefreshingFalse
  )

  return getNewState(state)
}

const filterUserId = (state, action) => {
  const { userId } = action.payload

  const getFilteredIds = compose(
    filter((id) => id !== userId),
    path(['data', 'ids'])
  )

  return assocPath(['data', 'ids'], getFilteredIds(state), state)
}

export const reducer = createReducer(
  {
    [FETCH_BAN_CANDIDATES]: setIsFetchingTrue,
    [FETCH_BAN_CANDIDATES_ERROR]: setError,
    [FETCH_BAN_CANDIDATES_SUCCESS]: fetch,
    [HIDE_BAN_CANDIDATE]: filterUserId,
    [UNHIDE_BAN_CANDIDATE]: filterUserId,
    [REFRESH_BAN_CANDIDATES]: setIsRefreshingTrue,
    [REFRESH_BAN_CANDIDATES_ERROR]: setError,
    [REFRESH_BAN_CANDIDATES_SUCCESS]: refresh,
  },
  initialState
)
