import { Alert, Button, Input } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Text, View, theme } from '../../common'
import { editGroup } from '../actionsV2'

const styles = {
  alert: { marginBottom: 24 },
  bgContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  buttonSubmit: { alignSelf: 'center', marginTop: 48 },
  mainContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 25,
    minHeight: 380,
    padding: 32,
    width: 600,
  },
}

const MoveGroupToOtherTopic = ({ modalId, group, topicId, language }) => {
  const dispatch = useDispatch()

  const [newTopicId, setNewTopicId] = React.useState()

  const onTopicIdChange = ({ target: { value } }) => {
    setNewTopicId(value)
  }

  const isValidId = React.useMemo(
    () => typeof newTopicId !== 'undefined',
    [newTopicId]
  )

  const onSubmit = () => {
    if (isValidId) {
      Modal.hide({ id: modalId })

      dispatch(
        editGroup({
          id: group.id,
          language,
          topicId: Number(newTopicId),
          _noRefresh: true,
        })
      )
    }
  }

  return (
    <View style={styles.bgContainer}>
      <Modal.Body style={styles.mainContainer}>
        <Text type="h2" bold style={{ marginBottom: 12 }}>
          Move group to other topic
        </Text>
        <Alert
          message="Please input a valid topic id that this group should be moved to. You can find the topic id by opening the target topic in a new tab and use the id that is placed in the URL behind topic/"
          type="info"
          showIcon
          style={styles.alert}
        />

        <Input.TextArea
          allowClear
          autoSize={{ minRows: 4, maxRows: 1 }}
          placeholder="Id of the topic that this group should move to..."
          onChange={onTopicIdChange}
          value={newTopicId}
        />

        <Button
          type="primary"
          shape="round"
          disabled={!isValidId}
          onClick={onSubmit}
          style={styles.buttonSubmit}>
          Submit
        </Button>
      </Modal.Body>
    </View>
  )
}

const show = (payload) =>
  Modal.show({
    render: (props) => <MoveGroupToOtherTopic {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalMoveGroupToOtherTopic = { show }
