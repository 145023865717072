import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList } from '../../common'
import { TellItem } from '../../moderator/components/TellItem'
import { fetchTells as fetch, refreshTells as refresh } from '../actions'
import {
  getHasMoreTells,
  getIsFetchingTells,
  getIsRefreshingTells,
  getRerenderTellItem,
  getTells,
} from '../selectors'

class _TellsList extends React.PureComponent {
  render() {
    const {
      _rerenderItem,
      actions,
      hasMore,
      isFetching,
      isRefreshing,
      spamItemId,
      tells,
    } = this.props
    if (!tells || !tells.ids || !tells.ids.length) {
      return null
    }

    return (
      <FlatList
        _rerenderItem={_rerenderItem}
        actions={{
          fetch: (payload) => actions.fetch({ ...payload, spamItemId }),
          refresh: (payload) => actions.refresh({ ...payload, spamItemId }),
        }}
        component={TellItem}
        extraProps={{ spamItemId, type: 'tell' }}
        hasMore={hasMore}
        isFetching={isFetching}
        isRefreshing={isRefreshing}
        items={tells}
        style={{ maxHeight: 720 }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetch, refresh }, dispatch),
})

const mapStateToProps = (state, props) => ({
  _rerenderItem: getRerenderTellItem(state),
  hasMore: getHasMoreTells(state),
  isFetching: getIsFetchingTells(state),
  isRefreshing: getIsRefreshingTells(state),
  tells: getTells(state, props.spamItemId),
})

const TellsList = connect(mapStateToProps, mapDispatchToProps)(_TellsList)

export { TellsList }
