import { UserOutlined } from '@ant-design/icons'
import { getActiveUserId } from '@tellonym/core/app/selectors'
import {
  Button,
  DatePicker,
  Input,
  Radio,
  Select,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as Redux from 'react-redux'
import { Box, View, colors, styleSheets } from '../../common'
import * as hooks from '../../common/hooks'
import {
  useAnalyticsEventNamesQuery,
  useAnalyticsEventsQuery,
} from '../queries'

const envOptions = [
  { label: 'Production', value: 'production' },
  { label: 'Testing', value: 'testing' },
]

const styles = {
  container: {
    padding: 16,
    backgroundColor: colors.background,
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 48,
  },
  textAutoRefresh: {
    fontWeight: 'bold',
    fontSize: 12,
    marginLeft: 8,
    lineHeight: 1,
  },
  timeFilterContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  userIdInput: { width: 180, marginBottom: 16 },
}

const timeOptions = {
  TODAY: 'Today',
  LAST_15_MIN: 'last 15 min',
}

const timeOptionValues = Object.values(timeOptions)

/**
 *  Convert normalised semver (003023003) to semver (3.23.2) string
 *
 * @param {String} platformVersion normalised semver platform version
 * @returns {String} semver string
 */
const convFromZerofillClientVersion = (clientVersion) => {
  if (typeof clientVersion === 'undefined') {
    return ''
  }

  const parsedClientVersion = `${clientVersion}`

  const convclientVersion =
    parsedClientVersion.length < 9
      ? [...parsedClientVersion.split('').reverse(), ...new Array(9).fill('0')]
          .slice(0, 9)
          .reverse()
          .join('')
      : parsedClientVersion

  return `${Number(convclientVersion.slice(0, 3))}.${Number(
    convclientVersion.slice(3, 6)
  )}.${Number(convclientVersion.slice(6, 9))}`
}

const tooltipRenderer = (text) => (
  <Tooltip placement="topLeft" title={text}>
    {text}
  </Tooltip>
)

const columnOptions = {
  id: {
    ellipsis: {
      showTitle: false,
    },
    width: 100,
    render: tooltipRenderer,
  },
  event_name: {
    align: 'left',
    width: '100%',
    RC_TABLE_INTERNAL_COL_DEFINE: { style: { minWidth: 280 } }, // workaround to make sure the column has a minWidth
  },
  event_time: {
    width: 180,
    render: (time) => (
      <Tooltip
        placement="bottom"
        title={dayjs(time).format('YYYY-MM-DD HH:mm:ss')}>
        <Typography.Text>{dayjs(time).fromNow()}</Typography.Text>
      </Tooltip>
    ),
  },
  ad_experiment_id: {
    width: 150,
  },
  cohorts: {
    ellipsis: {
      showTitle: false,
    },
    width: 280,
    render: (cohorts) => {
      const cohortsString = cohorts.join(', ')
      return (
        <Tooltip placement="topLeft" title={cohortsString}>
          {cohortsString}
        </Tooltip>
      )
    },
  },
  experiment_id: {
    width: 120,
  },
  platform_version: {
    width: 140,
    render: (platformVersion) => (
      <Typography.Text>
        {convFromZerofillClientVersion(platformVersion)}
      </Typography.Text>
    ),
  },
  user_id: {
    width: 120,
  },
}

const displayedEventProps = [
  ...Object.keys(columnOptions),
  'country',
  'platform',
  'app_view',
]

const expandedRowRender = (record) => {
  return (
    <View>
      {Object.keys(record).map((key) => {
        if (key !== 'cohorts' && displayedEventProps.includes(key)) {
          return null
        }

        return (
          <View key={key} style={styleSheets.flex.direction.row}>
            <Typography.Text>{`${key}: `}</Typography.Text>
            <Typography.Text style={styleSheets.margin.left[8]}>
              {key === 'cohorts' ? record[key].join(', ') : record[key]}
            </Typography.Text>
          </View>
        )
      })}
    </View>
  )
}

const rowExpandable = (record) => {
  const hasAdditionalKeys = Object.keys(record).some(
    (key) => !displayedEventProps.includes(key)
  )

  return hasAdditionalKeys
}

const expandableConfig = {
  expandedRowRender,
  rowExpandable,
  expandRowByClick: true,
}

const dateInThePast = (date) => dayjs().isSameOrBefore(date)

export const PageAnalyticsE2E = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const ownUserId = Redux.useSelector(getActiveUserId)

  const [env, setEnv] = React.useState(
    __DEV__ ? envOptions[1].value : envOptions[0].value
  )
  const [userId, setUserId] = React.useState(`${ownUserId}`)
  const [time, setTime] = React.useState(dayjs().subtract(5, 'minutes'))
  const [eventOptionsMap, setEventOptionsMap] = React.useState([])
  const [shouldAutoRefresh, setShouldAutoRefresh] = React.useState(false)
  const [shouldSendAnonUserId, setShouldSendAnonUserId] = React.useState(false)

  const {
    isLoading,
    data: analyticsEvents,
    refetch,
  } = useAnalyticsEventsQuery({
    env,
    ...(shouldSendAnonUserId ? { anonUserId: userId } : { userId }),
    time: time?.toISOString(),
    events: eventOptionsMap,
  })
  const { data: availableEventNames } = useAnalyticsEventNamesQuery()

  const columns = React.useMemo(() => {
    const columnsObject = analyticsEvents.ids.reduce((acc, id) => {
      Object.keys(analyticsEvents.data[id]).forEach((key) => {
        if (displayedEventProps.includes(key)) {
          acc[key] = {
            title: key,
            dataIndex: key,
            key,
            align: 'center',
            width: 100,
            ...(columnOptions[key] ?? {}),
          }
        }
      })

      return acc
    }, {})

    const { id, name, time, cohorts, ...rest } = columnsObject

    // This defines the order of columns
    return [id, name, time, ...Object.values(rest ?? {}), cohorts].filter(
      Boolean
    )
  }, [analyticsEvents.data, analyticsEvents.ids])

  const dataSource = React.useMemo(
    () => analyticsEvents.ids.map((id) => analyticsEvents.data[id]),
    [analyticsEvents.data, analyticsEvents.ids]
  )

  const onChangeEnv = (e) => {
    setEnv(e.target.value)
  }

  const onChangeEvents = (v) => {
    setEventOptionsMap(v)
  }

  const onChangeUserId = (e) => {
    setUserId(e.target.value)
  }

  const onChangeTime = (date) => {
    setTime(date)
  }

  const onChangeTimeSelector = (value) => () => {
    switch (value) {
      case timeOptions.TODAY: {
        setTime(dayjs().hour(0).minute(0).second(0))
        break
      }

      case timeOptions.LAST_15_MIN: {
        setTime(dayjs().subtract(15, 'minutes'))
        break
      }

      default: {
        break
      }
    }
  }

  const onToggleAutoRefresh = () => {
    setShouldAutoRefresh(!shouldAutoRefresh)
  }

  const onToggleAnonUserId = () => {
    setShouldSendAnonUserId((state) => !state)
  }

  React.useEffect(() => {
    if (shouldAutoRefresh) {
      const interval = setInterval(() => {
        refetch()
      }, 2000)

      return () => clearInterval(interval)
    }
  }, [refetch, shouldAutoRefresh])

  return (
    <View style={containerStyle}>
      <View style={styles.container}>
        <Typography.Title>Analytics E2E</Typography.Title>
        <View style={styles.headerContainer}>
          <View style={styleSheets.margin.right[48]}>
            <Typography.Title level={4}>Configure</Typography.Title>
            <Radio.Group
              options={envOptions}
              onChange={onChangeEnv}
              value={env}
              optionType="button"
              buttonStyle="solid"
              style={styleSheets.margin.bottom[16]}
            />
            <Tooltip title="Put 0 for anonymous events" placement="bottom">
              <Input
                disabled={isLoading}
                placeholder="userId"
                prefix={<UserOutlined />}
                onChange={onChangeUserId}
                value={userId}
                style={styles.userIdInput}
              />
            </Tooltip>
            <View style={styleSheets.flex.direction.row}>
              <Tooltip title="Use the userId above as anonUserId for web events">
                <Box flexDirection="row" alignItems="center" marginRight={24}>
                  <Switch
                    checked={shouldSendAnonUserId}
                    onChange={onToggleAnonUserId}
                  />
                  <Typography.Text style={styles.textAutoRefresh}>
                    Anon user id
                  </Typography.Text>
                </Box>
              </Tooltip>
              <Box flexDirection="row" alignItems="center" marginRight={24}>
                <Switch
                  checked={shouldAutoRefresh}
                  onChange={onToggleAutoRefresh}
                />
                <Typography.Text style={styles.textAutoRefresh}>
                  Auto refresh
                </Typography.Text>
              </Box>
            </View>
          </View>
          <View style={{ width: '50%' }}>
            <Typography.Title level={4}>Filter</Typography.Title>
            <View style={styles.timeFilterContainer}>
              {timeOptionValues.map((v) => (
                <Button
                  key={v}
                  disabled={isLoading}
                  onClick={onChangeTimeSelector(v)}
                  style={styleSheets.margin.right[8]}>
                  {v}
                </Button>
              ))}
              <DatePicker
                allowClear={false}
                disabled={isLoading}
                disabledDate={dateInThePast}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeTime}
                value={time}
                showTime
                style={styleSheets.margin.left[8]}
              />
            </View>
            <Select
              disabled={isLoading}
              mode="tags"
              maxTagCount="responsive"
              placeholder="Filter for events"
              options={availableEventNames}
              onChange={onChangeEvents}
            />
          </View>
        </View>
      </View>
      <Table
        columns={columns}
        expandable={expandableConfig}
        dataSource={dataSource}
        rowKey="id"
        sticky={{ offsetHeader: 50 }}
        pagination={false}
        scroll={{ x: 1 }}
        loading={isLoading}
      />
    </View>
  )
}
