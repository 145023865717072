import { normalize } from '@tellonym/core/helpers'
import { assocPath, mergeDeepRight, pick } from 'ramda'
import { enhanceStatsWithChanges } from './services'
import * as t from './types'

export const { name } = t

export const initialState = {
  error: null,
  isLoading: false,
  all: { ids: [], data: {} },
  availableShortNames: {
    error: null,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  chShortnames: {
    error: null,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  chDiff: {
    error: null,
    isRefreshing: false,
    isRefreshingShortnames: false,
    shortNames: { ids: [], data: {} },
    data: { ids: [], data: {} },
  },
  diff: {
    error: null,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  experiments: {
    error: null,
    isRefreshing: false,
    data: { ids: [], data: {} },
  },
  compareKey: undefined,
  csvPreview: undefined,
  isUploadingCsv: false,
}

const mapKeyToData = (data = [], key = 'shortName') =>
  data[0] ? data.map((item) => ({ id: item[key], ...item })) : []

export const reducer = (state = initialState, { type, payload, meta = {} }) => {
  switch (type) {
    case t.CHANGE_COMPARE_KEY:
      return { ...state, compareKey: payload.compareKey }

    case t.REFRESH_ALL:
      return {
        ...state,
        isLoading: true,
      }

    case t.REFRESH_ALL_SUCCESS: {
      const normalized = normalize(mapKeyToData(payload.data))

      return {
        ...state,
        isLoading: false,
        all: normalized,
      }
    }

    case t.REFRESH_ALL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    case t.REFRESH_AVAILABLE_SHORT_NAMES:
      return assocPath(['availableShortNames', 'isRefreshing'], true, state)

    case t.REFRESH_AVAILABLE_SHORT_NAMES_SUCCESS: {
      const normalized = normalize(payload.groups.data)

      const filterDuplicateShortNames = ({ ids, data }) =>
        ids.reduce((acc, groupId) => {
          const { shortNames, ...groupData } = data[groupId]

          const uniqueShortNamesObject = shortNames.reduce(
            (acc, curr) => ({ ...acc, [curr.id]: curr }),
            {}
          )

          const uniqueShortNamesArray = Object.values(uniqueShortNamesObject)

          return {
            ...acc,
            [groupId]: {
              ...groupData,
              shortNames: uniqueShortNamesArray,
            },
          }
        }, {})

      const uniqueData = filterDuplicateShortNames(normalized)

      return mergeDeepRight(state, {
        availableShortNames: {
          isRefreshing: false,
          data: { ids: normalized.ids, data: uniqueData },
        },
      })
    }

    case t.REFRESH_AVAILABLE_SHORT_NAMES_ERROR:
      return mergeDeepRight(state, {
        availableShortNames: { error: payload, isRefreshing: false },
      })

    case t.REFRESH_CH_DIFF:
      return assocPath(['chDiff', 'isRefreshing'], true, state)

    case t.REFRESH_CH_DIFF_ERROR: {
      return mergeDeepRight(state, {
        chDiff: {
          error: payload,
          isRefreshing: false,
        },
      })
    }

    case t.REFRESH_CH_DIFF_SUCCESS:
      return mergeDeepRight(state, {
        chDiff: {
          error: null,
          isRefreshing: false,
          data: {
            data: payload.data,
            ids: payload.ids.data,
          },
        },
      })

    case t.REFRESH_CH_SHORTNAMES:
      return assocPath(['chShortnames', 'isRefreshing'], true, state)

    case t.REFRESH_CH_SHORTNAMES_ERROR:
      return mergeDeepRight(state, {
        chShortnames: {
          error: payload,
          isRefreshing: false,
        },
      })

    case t.REFRESH_CH_SHORTNAMES_SUCCESS: {
      const normalized = normalize(payload.groups.data)

      return mergeDeepRight(state, {
        chShortnames: {
          isRefreshing: false,
          data: normalized,
        },
      })
    }

    case t.REFRESH_DIFF:
      return assocPath(['diff', 'isRefreshing'], true, state)

    case t.REFRESH_DIFF_SUCCESS: {
      const intervalType = Number(meta.intervalType)

      const data = enhanceStatsWithChanges({
        data: payload.stats.data,
        endDate: meta.endDate,
        intervalType,
        timespan: meta.timespan,
      })

      const normalized = normalize(data)

      return mergeDeepRight(state, {
        diff: { isRefreshing: false, data: normalized },
      })
    }

    case t.REFRESH_DIFF_ERROR:
      return mergeDeepRight(state, {
        diff: { error: payload, isRefreshing: false },
      })

    case t.REFRESH_EXPERIMENTS:
      return assocPath(['experiments', 'isRefreshing'], true, state)

    case t.REFRESH_EXPERIMENTS_SUCCESS: {
      const data = payload.stats.data.map((item) => {
        const result = { ...item }
        const { shortName } = item

        const controlGroupIndex = item.data.findIndex(
          (i) => i.experimentId === meta.controlGroupId
        )

        const orderedData = [
          item.data[controlGroupIndex],
          ...item.data.filter((_, index) => index !== controlGroupIndex),
        ]

        const enrichedData = orderedData
          .map((currentItem, index) => {
            const resultItem = { ...currentItem }

            if (
              typeof currentItem?.value === 'undefined' ||
              currentItem?.value === null
            ) {
              return false
            }

            if (index === 0) {
              const itemFromState = state.experiments.data?.data?.[
                shortName
              ]?.data?.find(
                (_item) => _item.experimentId === currentItem.experimentId
              )

              resultItem.graphData = itemFromState?.graphData

              return resultItem
            }

            const compareItem = orderedData[0]

            if (typeof compareItem?.value === 'undefined') {
              return resultItem
            }

            resultItem.compareValues = pick(
              [
                'amountActions',
                'amountPerUnique',
                'amountPerUser',
                'amountUniques',
                'amountUsers',
                'percentageOfUsers',
                'value',
              ],
              compareItem
            )

            const itemFromState = state.experiments.data?.data?.[
              shortName
            ]?.data?.find(
              (_item) => _item.experimentId === currentItem.experimentId
            )

            resultItem.graphData = itemFromState?.graphData

            return resultItem
          })
          .filter(Boolean)

        result.data = enrichedData

        return item
      })

      const normalized = normalize(data)

      return mergeDeepRight(state, {
        experiments: { isRefreshing: false, data: normalized },
      })
    }

    case t.REFRESH_EXPERIMENTS_ERROR:
      return mergeDeepRight(state, {
        experiments: { error: payload, isRefreshing: false },
      })

    case t.REFRESH_GRAPH_DATA_SUCCESS: {
      const id = payload.data[0].shortName

      return {
        ...state,
        isLoading: false,
        all: {
          ids: [...new Set([id, ...state.all.ids])],
          data: {
            ...state.all.data,
            [id]: {
              ...state.all.data[id],
              [`data${meta.limit}`]: payload.data[0].data,
            },
          },
        },
      }
    }

    case t.REFRESH_CH_GRAPH_DATA_ERROR:
    case t.REFRESH_GRAPH_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    case t.REFRESH_CH_GRAPH_DATA_SUCCESS: {
      // this adds the result as graphData prop in existing diffCh shortNames data structure
      const transformedResult = payload.ids.reduce((acc, shortName) => {
        acc[shortName] = {
          graphData: pick(['data', 'ids'], payload.data[shortName]),
        }

        return acc
      }, {})

      return mergeDeepRight(state, {
        isLoading: false,
        chDiff: { data: { data: transformedResult } },
      })
    }

    case t.REFRESH_SHORT_NAMES:
      return {
        ...state,
        isLoading: true,
      }

    case t.REFRESH_SHORT_NAMES_SUCCESS: {
      const normalized = normalize(mapKeyToData(payload.data))

      return {
        ...state,
        isLoading: false,
        all: {
          ids: [...new Set([...state.all.ids, ...normalized.ids])],
          data: { ...state.all.data, ...normalized.data },
        },
      }
    }

    case t.REFRESH_SHORT_NAMES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    case t.REFRESH_CH_GRAPH_DATA:
    case t.REFRESH_GRAPH_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case t.RESET_CSV_PREVIEW:
      return {
        ...state,
        csvPreview: undefined,
      }

    case t.UPLOAD_CSV:
      return {
        ...state,
        isUploadingCsv: true,
      }

    case t.UPLOAD_CSV_ERROR:
      return {
        ...state,
        csvPreview:
          meta.payload?.preview === true ? undefined : state.csvPreview,
        isUploadingCsv: false,
      }

    case t.UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        csvPreview: payload?.previewData?.data.map((row) => ({
          ...row,
          countryCode: payload?.previewData?.countryCode,
        })),
        isUploadingCsv: false,
      }

    default:
      return state
  }
}
