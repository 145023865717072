import { grey } from '@ant-design/colors'
import { shortenNumber } from '@tellonym/core/helpers'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
  ARTIFICIAL_TELL_STATUS,
} from '@tellonym/enums/lib/Tell'
import { Typography } from 'antd'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  PopupMenu,
  TableEndlessScroll,
  View,
  _,
  styleSheets,
} from '../../common'
import {
  valueToPercentage,
  valueToPercentageWithSign,
} from '../../statistics/services'
import { editGroup } from '../actionsV2'
import { getHeaderMode } from '../selectorsV2'
import { ArtificialTellDepthTag } from './ArtificialTellDepthTag'
import { ArtificialTellGroupTypeTag } from './ArtificialTellGroupTypeTag'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import { SharedColumns, useColumnSearchProps } from './SharedColumns'

const typeOrder = {
  [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: 0,
  [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: 1,
  [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: 2,
}

const depthOrder = {
  [ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]: 0,
  [ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]: 1,
}

const Stat = ({ value, change, isPercentage }) => {
  const formatValue = isPercentage
    ? (v) => valueToPercentage(v, v < 10 ? 1 : 0).replace('.0', '')
    : (v) => shortenNumber(v, false, 999)
  const formatChange = isPercentage
    ? (v) => valueToPercentageWithSign(v, v < 10 ? 1 : 0).replace('.0', '')
    : (v) => `${v > 0 ? '+' : ''}${shortenNumber(v, false, 999)}`

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Typography.Text>{formatValue(value)}</Typography.Text>
      <Typography.Text
        style={{
          marginLeft: 4,
          fontSize: 12,
          color: grey[1],
        }}>
        {formatChange(change)}
      </Typography.Text>
    </View>
  )
}

export const ArtificialTellTopicGroupsTable = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const searchProps = useColumnSearchProps('name')

  const onPopupEvent = React.useCallback(
    ({ index, group }) => {
      const setInactiveForLang = () => {
        dispatch(
          editGroup({
            id: group.id,
            status: ARTIFICIAL_TELL_STATUS.INACTIVE,
            languageForChangingStatus: langDetectObjectsByType1[props.language],
          })
        )
      }

      const setInactiveForAllLangs = () => {
        dispatch(
          editGroup({
            id: group.id,
            status: ARTIFICIAL_TELL_STATUS.INACTIVE,
            languageForChangingStatus: 'ALL',
          })
        )
      }

      const deleteGroup = () => {
        if (group.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
          dispatch(
            editGroup({
              id: group.id,
              status: ARTIFICIAL_TELL_STATUS.DELETED,
              languageForChangingStatus: 'ALL',
            })
          )
        }
      }

      switch (index) {
        case 0:
          return ModalMoveGroupToOtherTopic.show({
            topicId: props.topicId,
            group,
          })
        case 1:
          return setInactiveForLang()
        case 2:
          return setInactiveForAllLangs()
        case 3:
          return deleteGroup()
        default:
          return undefined
      }
    },
    [dispatch, props.language, props.topicId]
  )

  const getMenuActions = ({ group }) => {
    return [
      'Move group to other topic',
      'Set inactive for selected language',
      'Set inactive for all languages',
      group.status === ARTIFICIAL_TELL_STATUS.INACTIVE &&
        'Delete for all languages',
    ].filter(Boolean)
  }

  const columns = useMemo(
    () =>
      props.columns ?? [
        {
          ...SharedColumns.id,
          sorter: { multiple: 1, compare: SharedColumns.id.sorter },
        },
        SharedColumns.status,
        {
          title: 'Depth',
          dataIndex: 'id',
          key: 'depth',
          width: 100,
          render: (id, group) => (
            <Box transparent style={{ alignItems: 'center' }}>
              <ArtificialTellDepthTag depth={group.depth} />
            </Box>
          ),
          filters: [
            {
              text: 'Intro',
              value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
            },
            {
              text: 'Deep',
              value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP,
            },
          ],
          filterMultiple: true,
          filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
          onFilter: (value, groupOrTopic) => {
            switch (value) {
              case ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO:
                return (
                  groupOrTopic.depth === ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
                )

              case ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP:
                return (
                  groupOrTopic.depth === ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
                )

              default:
                return true
            }
          },
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            const textA = depthOrder[a.depth]
            const textB = depthOrder[b.depth]

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        {
          title: 'Type',
          dataIndex: 'id',
          key: 'type',
          width: 100,
          render: (id, group) => (
            <Box transparent style={{ alignItems: 'center' }}>
              <ArtificialTellGroupTypeTag groupType={group.type} />
            </Box>
          ),
          filters: [
            {
              text: 'Simple',
              value: ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
            },
            {
              text: 'Organic',
              value: ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
            },
            {
              text: 'Open',
              value: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
            },
          ],
          filterMultiple: true,
          filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
          onFilter: (value, groupOrTopic) => {
            switch (value) {
              case ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE:
                return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE

              case ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC:
                return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC

              case ARTIFICIAL_TELL_GROUP_TYPES.OPEN:
                return groupOrTopic.type === ARTIFICIAL_TELL_GROUP_TYPES.OPEN

              default:
                return true
            }
          },
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            const textA = typeOrder[a.type]
            const textB = typeOrder[b.type]

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        {
          title: 'Question',
          dataIndex: 'id',
          key: 'name',
          width: 200,
          render: (id, group) => <View>{group.name}</View>,
          ...searchProps,
          sorter: (a, b) => {
            const textA = a.name?.toLowerCase()
            const textB = b.name?.toLowerCase()

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        {
          title: 'Total Sent',
          dataIndex: 'id',
          key: 'totalSent',
          width: 80,
          defaultSortOrder: 'descend',
          sorter: (a, b) =>
            (a.analytics.totalSent.amount ?? 0) -
            (b.analytics.totalSent.amount ?? 0),
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                value={group.analytics.totalSent.amount}
                change={group.analytics.totalSent.change}
              />
            )
          },
        },
        {
          title: 'Answer Rate',
          dataIndex: 'id',
          key: 'answerRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.answerRate.amount - b.analytics.answerRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.answerRate.amount}
                change={group.analytics.answerRate.change}
              />
            )
          },
        },
        {
          title: 'Median Length',
          dataIndex: 'id',
          key: 'p50Length',
          width: 80,
          sorter: (a, b) =>
            a.analytics.p50Length.amount - b.analytics.p50Length.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                value={group.analytics.p50Length.amount}
                change={group.analytics.p50Length.change}
              />
            )
          },
        },
        {
          title: 'Share Rate',
          dataIndex: 'id',
          key: 'shareRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.shareRate.amount - b.analytics.shareRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.shareRate.amount}
                change={group.analytics.shareRate.change}
              />
            )
          },
        },
        {
          title: 'Reaskab. in Days',
          dataIndex: 'id',
          key: 'reaskInDays',
          width: 80,
          sorter: (a, b) => a.reaskInDays - b.reaskInDays,
          render: (id, group) => (
            <View style={styleSheets.center}>{group.reaskInDays}</View>
          ),
        },
        {
          title: 'Vars',
          dataIndex: 'id',
          key: 'activeVariancesCountTotal',
          width: 60,
          sorter: (a, b) =>
            a.activeVariancesCount.total - b.activeVariancesCount.total,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <View style={styleSheets.center}>
                {group.activeVariancesCount.total}
              </View>
            )
          },
        },
        {
          title: 'Sender Hint',
          dataIndex: 'id',
          key: 'senderHint',
          width: 100,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <View>
                {
                  {
                    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT]: 'Not set',
                    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE]: 'None',
                    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC]:
                      'Pretend Organic',
                    [ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL]: 'Reveal',
                  }[group.senderHint]
                }
              </View>
            )
          },
          filterIcon: () => null,
          filterDropdown: () => null,
          onFilter: (value, group) => {
            if (!value) {
              return true
            }

            return new RegExp(value, 'i').test(group.senderHint)
          },
          sorter: (a, b) => {
            const textA = a.senderHint
            const textB = b.senderHint

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        SharedColumns.lastUpdatedAt,
        {
          title: 'Actions',
          dataIndex: 'id',
          key: 'actions',
          width: 80,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            const _onPopupEvent = (index) => onPopupEvent({ index, group })

            return (
              <Box alignItems="center" justifyContent="center" transparent>
                <PopupMenu
                  actions={getMenuActions({ group })}
                  onPress={_onPopupEvent}
                />
              </Box>
            )
          },
        },
      ],
    [props.columns, onPopupEvent]
  )

  const onRow = React.useCallback(
    (group) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${group.id}${
          headerMode ? `?headerMode=${headerMode}` : ''
        }`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, headerMode]
  )

  return <TableEndlessScroll {...props} columns={columns} onRow={onRow} />
}
