/**
 * Mainly as boilerplate to be used for development if the mock should not be committed.
 */

export const serializers = {}
export const models = {}

export const factories = {}

export const seeds = function seeds(server) {}

export const routes = function routes() {}
