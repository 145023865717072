import { USER_TYPE } from '@tellonym/enums/lib/User'
import React from 'react'
import { Text, theme } from '../../common'

const getUserTypeColor = (type) => {
  switch (type) {
    case USER_TYPE.ADMIN:
      return theme.colors.niceYellow

    case USER_TYPE.MODERATOR:
      return theme.colors.niceBlue

    case USER_TYPE.BANNED:
    case USER_TYPE.BAN_SHADOW:
    case USER_TYPE.BAN_SHADOW_AUTOMATIC:
    case USER_TYPE.DELETED:
      return theme.colors.niceRed

    default:
      return theme.colors.placeholder
  }
}

export const UserTypeBadge = ({ type, style }) => (
  <Text
    center
    type="note"
    color={theme.colors.white}
    style={[
      {
        paddingVertical: 4,
        paddingHorizontal: 12,
        borderRadius: 25,
        backgroundColor: getUserTypeColor(type),
        minWidth: 52,
      },
      style,
    ]}>
    {USER_TYPE[type]}
  </Text>
)
