import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import P from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Icon, Text, TouchableOpacity, View } from '../../common'
import { deleteBlacklistEntry } from '../actions'

const _BlacklistItem = ({ actions, tab, item }) => {
  return (
    <View style={{ alignItems: 'center', flexDirection: 'row', marginTop: 10 }}>
      <View style={{ flex: 1, alignItems: 'center' }}>
        <TouchableOpacity
          onPress={() => actions.delete({ type: tab, value: item })}>
          <Icon icon={faTrashAlt} />
        </TouchableOpacity>
      </View>
      <View style={{ flex: 7 }}>
        <Text>{item}</Text>
      </View>
    </View>
  )
}

_BlacklistItem.propTypes = {
  actions: P.shape({
    delete: P.func.isRequired,
  }).isRequired,
  item: P.string.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ delete: deleteBlacklistEntry }, dispatch),
})

export const BlacklistItem = connect(null, mapDispatchToProps)(_BlacklistItem)
