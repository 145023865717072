export const name = 'statistics'

export const CHANGE_COMPARE_KEY = `${name}/CHANGE_COMPARE_KEY`

export const REFRESH_ALL = `${name}/REFRESH_ALL`
export const REFRESH_ALL_SUCCESS = `${name}/REFRESH_ALL_SUCCESS`
export const REFRESH_ALL_ERROR = `${name}/REFRESH_ALL_ERROR`

export const REFRESH_AVAILABLE_SHORT_NAMES = `${name}/REFRESH_AVAILABLE_SHORT_NAMES`
export const REFRESH_AVAILABLE_SHORT_NAMES_ERROR = `${name}/REFRESH_AVAILABLE_SHORT_NAMES_ERROR`
export const REFRESH_AVAILABLE_SHORT_NAMES_SUCCESS = `${name}/REFRESH_AVAILABLE_SHORT_NAMES_SUCCESS`

export const REFRESH_CH_DIFF = `${name}/REFRESH_CH_DIFF`
export const REFRESH_CH_DIFF_SUCCESS = `${name}/REFRESH_CH_DIFF_SUCCESS`
export const REFRESH_CH_DIFF_ERROR = `${name}/REFRESH_CH_DIFF_ERROR`

export const REFRESH_CH_SHORTNAMES = `${name}/REFRESH_CH_SHORTNAMES`
export const REFRESH_CH_SHORTNAMES_SUCCESS = `${name}/REFRESH_CH_SHORTNAMES_SUCCESS` // prettier-ignore
export const REFRESH_CH_SHORTNAMES_ERROR = `${name}/REFRESH_CH_SHORTNAMES_ERROR`

export const REFRESH_DIFF = `${name}/REFRESH_DIFF`
export const REFRESH_DIFF_ERROR = `${name}/REFRESH_DIFF_ERROR`
export const REFRESH_DIFF_SUCCESS = `${name}/REFRESH_DIFF_SUCCESS`

export const REFRESH_EXPERIMENTS = `${name}/REFRESH_EXPERIMENTS`
export const REFRESH_EXPERIMENTS_ERROR = `${name}/REFRESH_EXPERIMENTS_ERROR`
export const REFRESH_EXPERIMENTS_SUCCESS = `${name}/REFRESH_EXPERIMENTS_SUCCESS`

export const REFRESH_GRAPH_DATA = `${name}/REFRESH_GRAPH_DATA`
export const REFRESH_GRAPH_DATA_SUCCESS = `${name}/REFRESH_GRAPH_DATA_SUCCESS`
export const REFRESH_GRAPH_DATA_ERROR = `${name}/REFRESH_GRAPH_DATA_ERROR`

export const REFRESH_CH_GRAPH_DATA = `${name}/REFRESH_CH_GRAPH_DATA`
export const REFRESH_CH_GRAPH_DATA_ERROR = `${name}/REFRESH_CH_GRAPH_DATA_ERROR`
export const REFRESH_CH_GRAPH_DATA_SUCCESS = `${name}/REFRESH_CH_GRAPH_DATA_SUCCESS`

export const REFRESH_SHORT_NAMES = `${name}/REFRESH_SHORT_NAMES`
export const REFRESH_SHORT_NAMES_ERROR = `${name}/REFRESH_SHORT_NAMES_ERROR`
export const REFRESH_SHORT_NAMES_SUCCESS = `${name}/REFRESH_SHORT_NAMES_SUCCESS`

export const RESET_CSV_PREVIEW = `${name}/RESET_CSV_PREVIEW`
export const UPLOAD_CSV = `${name}/UPLOAD_CSV`
export const UPLOAD_CSV_ERROR = `${name}/UPLOAD_CSV_ERROR`
export const UPLOAD_CSV_SUCCESS = `${name}/UPLOAD_CSV_SUCCESS`
