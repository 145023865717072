import { effect } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { flattenProps, getDump } from '@tellonym/core/helpers'
import { safeEffects } from '@tellonym/core/sagas'
import { copyToClipboard } from '@tellonym/core/share/actions'
import { message } from 'antd'
import { put, select } from 'redux-saga/effects'
import { Alert } from '../common'
import {
  getAnswerDump,
  getSentTellDump,
  getTellDump,
  propSynonymMap,
} from '../common/helpers'
import { queryClient } from '../common/queries'
import {
  refreshAccountVerifications,
  refreshBannedDevices,
  refreshBlacklists,
  refreshDevices,
  refreshNsfwPictures,
  refreshProfile,
} from './actions'
import * as t from './types'

const changeSettingsSuccess = function* ({ meta }) {
  const { pathname } = window.location
  const isModeration = pathname.startsWith('/moderation')

  yield put(refreshProfile({ userId: meta.userId, isModeration }))
  queryClient.invalidateQueries(['profile', meta.userId])
}

const getSenderInfo = function* getSenderInfo({ accessToken, senderId }) {
  const action = {
    meta: {
      accessToken,
      effect: { path: `admin/user/settings/${senderId}` },
    },
  }

  const res = yield effect(action.meta.effect, action)

  const dump = getDump(
    [
      'username',
      propSynonymMap.lastActive,
      propSynonymMap.registered,
      propSynonymMap.lastIpAddress,
    ],
    res
  )

  return dump
}

const getDeviceInfo = function* getDeviceInfo({ accessToken, senderId }) {
  const action = {
    meta: {
      accessToken,
      effect: { path: `admin/user/devices/${senderId}` },
    },
  }

  const { devices } = yield effect(action.meta.effect, action)

  const dump = devices.data
    .map((device, index) => {
      const info = getDump(
        [
          'device',
          propSynonymMap.firstSeen,
          propSynonymMap.lastIp,
          propSynonymMap.lastSeen,
        ],
        flattenProps(device)
      )

      return `\nDevice #${index + 1}${info}`
    })
    .join('\n')

  return dump
}

const typeDumpMap = {
  answers: getAnswerDump,
  sentTells: getSentTellDump,
  tells: getTellDump,
}

const copyItems = function* copyItems({ payload }) {
  try {
    const { items, type, userId } = payload

    let itemsToUse
    const clipboardData = []
    const state = yield select()
    const accessToken = getAccessToken(state)

    if (!items) {
      const allItems = state.moderator[type].data[userId]

      const selectedTells = allItems.ids.reduce((acc, id) => {
        const item = allItems.data[id]

        if (item._isSelected) {
          acc.push(item)
        }

        return acc
      }, [])

      itemsToUse = selectedTells
    } else {
      itemsToUse = items
    }

    // eslint-disable-next-line no-unused-vars
    for (const item of itemsToUse) {
      let itemEntryInfo = typeDumpMap[type](item)

      if (item.senderId) {
        const profileDump = yield getSenderInfo({
          accessToken,
          senderId: item.senderId,
        })

        itemEntryInfo = itemEntryInfo.concat('\n', profileDump)

        const deviceDump = yield getDeviceInfo({
          accessToken,
          senderId: item.senderId,
        })

        itemEntryInfo = itemEntryInfo.concat('\n', deviceDump)
      }

      clipboardData.push(itemEntryInfo)
    }

    yield put(copyToClipboard(clipboardData.join(`\n\n${'-'.repeat(35)}\n`)))
  } catch (e) {
    message.error(`Something went wrong: ${e.message}`)
  }
}

const blockAllSentTellsSuccess = function* () {
  yield Alert.success('Success')
}

const logoutDeviceSuccess = function* ({ meta }) {
  yield Alert.success('Success')
  yield put(refreshDevices({ userId: meta.userId }))
}

const showSuccess = function* () {
  yield Alert.success('Success')
}

const showSuccessAndRefreshProfile = function* ({ meta }) {
  yield queryClient.invalidateQueries({ queryKey: ['profile', meta.userId] })
  yield Alert.success('Success')
}

const showSuccessAndRefreshBlacklist = function* () {
  yield Alert.success('Success')
  yield put(refreshBlacklists({ limit: 200 }))
}

const refreshBannedDevicesWorker = function* () {
  yield put(refreshBannedDevices())
}

const updateNsfwPicturesSuccess = function* () {
  yield put(refreshNsfwPictures())
}

const updateAccountVerificationsSuccess = function* () {
  yield put(refreshAccountVerifications())
}

export const actionHandler = {
  [t.CHANGE_SETTINGS_SUCCESS]: [safeEffects.takeLatest, changeSettingsSuccess],
  [t.CREATE_BAN_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshProfile], // prettier-ignore
  [t.CREATE_WARNING_SUCCESS]: [safeEffects.takeLatest, showSuccess],
  [t.COPY_ITEMS]: [safeEffects.takeLatest, copyItems],
  [t.BLOCK_ALL_SENT_TELLS_SUCCESS]: [safeEffects.takeLatest, blockAllSentTellsSuccess], // prettier-ignore
  [t.DELETE_DEVICE_BAN_SUCCESS]: [safeEffects.takeLatest, refreshBannedDevicesWorker], // prettier-ignore
  [t.DELETE_PROFILE_PIC_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshProfile], // prettier-ignore
  [t.DELETE_USER_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshProfile], // prettier-ignore
  [t.LOGOUT_ALL_DEVICES_SUCCESS]: [safeEffects.takeLatest, logoutDeviceSuccess],
  [t.LOGOUT_DEVICE_SUCCESS]: [safeEffects.takeLatest, logoutDeviceSuccess],
  [t.RECOUNT_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshProfile],
  [t.SEND_MODERATION_INVITATION_SUCCESS]: [safeEffects.takeLatest, showSuccess],
  [t.SEND_RECOVERY_MAIL_SUCCESS]: [safeEffects.takeLatest, showSuccess],
  [t.SEND_TELL_SUCCESS]: [safeEffects.takeLatest, showSuccess],
  [t.UPDATE_NSFW_PICTURES_SUCCESS]: [safeEffects.takeLatest, updateNsfwPicturesSuccess], // prettier-ignore
  [t.REMOVE_BLACKLIST_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshBlacklist], // prettier-ignore
  [t.ADD_BLACKLIST_ENTRY_SUCCESS]: [safeEffects.takeLatest, showSuccessAndRefreshBlacklist], // prettier-ignore
  [t.UPDATE_ACCOUNT_VERIFICATIONS_SUCCESS]: [safeEffects.takeLatest, updateAccountVerificationsSuccess], // prettier-ignore
}
