import React from 'react'
import { theme } from '../styles/theme'
import { LoadingIndicator } from './LoadingIndicator'
import { View } from './View'

export const Container = ({ children, isRefreshing, style, ...props }) => (
  <View
    style={[
      {
        flex: 1,
        backgroundColor: theme.colors.white,
        position: 'relative',
      },
      style,
    ]}
    {...props}>
    <LoadingIndicator isLoading={isRefreshing} />
    {children}
  </View>
)
