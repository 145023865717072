import React from 'react'
import { getMergedStyles } from '../helpers'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'
import { Text } from './Text'

const defaultStyle = {
  borderRadius: 25,
  borderWidth: 0,
  borderStyle: 'solid',
  cursor: 'pointer',
  paddingHorizontal: 24,
  paddingVertical: 8,
  color: 'white',
  justifyContent: 'center',
  alignItems: 'center',
}

const whiteColorStyles = {
  backgroundColor: theme.colors.white,
  ':active': {
    backgroundColor: theme.colors.transparentWhite,
  },
  ':hover': {
    backgroundColor: theme.colors.transparentWhite,
  },
}

const defaultColorStyles = {
  background: 'linear-gradient(to right bottom, #FF5BB5, #ED0063)',
  ':active': {
    backgroundColor: theme.colors.translucentPink,
  },
  ':hover': {
    backgroundColor: theme.colors.translucentPink,
  },
}

export const grayStyle = {
  background: theme.colors.placeholder,
  borderColor: theme.colors.placeholder,
}

const getBackground = ({ backgroundColor, style, white }) => {
  if (style.background || style.backgroundColor) {
    return {}
  }

  if (backgroundColor) {
    return { backgroundColor }
  }

  if (white) {
    return whiteColorStyles
  }

  return defaultColorStyles
}

export const Button = Radium(
  ({
    backgroundColor,
    borderColor,
    children,
    color,
    disabled = false,
    desktopStyle,
    onPress,
    style = {},
    textStyle = {},
    white = false,
    ...props
  }) => {
    const mergedStyles = getMergedStyles(style)

    return (
      <button
        type="button"
        disabled={disabled}
        onClick={onPress}
        {...props}
        style={[
          defaultStyle,
          getBackground({ backgroundColor, style: mergedStyles, white }),
          borderColor && { borderColor, borderWidth: 1 },
          color && { color },
          disabled && { cursor: 'not-allowed', ...grayStyle },
          mergedStyles,
          desktopStyle && {
            '@media (min-width: 481px)': {
              ...desktopStyle,
            },
          },
        ]}>
        {typeof children === 'string' ? (
          <Text
            color={defaultStyle.color}
            style={[{ fontSize: 11 }, textStyle]}>
            {children}
          </Text>
        ) : (
          children
        )}
      </button>
    )
  }
)
