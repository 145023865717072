import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { throttle } from '@tellonym/core/helpers'
import P from 'prop-types'
import React from 'react'
import { Header } from '../../navigation/components/Header'
import { Icon } from './Icon'
import { LoadingIndicator } from './LoadingIndicator'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'

/**
 * Plugin replacement for flatlist
 *
 * Not supported
 * - fetching
 * - refreshing
 * - extraData
 */

const itemsPerBadgeCount = 24
const renderThreshold = 450

class WebListComponent extends React.PureComponent {
  _fetchedForScrollPositionEnd = undefined
  _ref = React.createRef()

  state = { badgeCount: 1 }

  componentDidMount() {
    if (this._ref.current) {
      this._ref.current.addEventListener('scroll', this._onScroll)
    }
  }

  componentWillUnmount() {
    if (this._ref.current) {
      this._ref.current.removeEventListener('keydown', this._onScroll)
    }
  }

  _setNextBadge = () => {
    if (this._ref.current) {
      const { scrollHeight, offsetHeight } = this._ref.current
      const scrollPositionEnd = scrollHeight - offsetHeight

      this._fetchedForScrollPositionEnd = scrollPositionEnd
      this.setState((state) => ({ badgeCount: state.badgeCount + 1 }))
    }
  }

  _onScroll = throttle((e) => {
    const {
      offsetHeight,
      scrollHeight,
      scrollTop: currentScrollPosition,
    } = e.target

    const scrollPositionEnd = scrollHeight - offsetHeight

    if (
      currentScrollPosition >= scrollPositionEnd - renderThreshold &&
      scrollPositionEnd !== this._fetchedForScrollPositionEnd
    ) {
      this._setNextBadge()
    }
  }, 30)

  scrollToOffset = ({ offset }) => {
    this._ref.current.scrollTop = offset
  }

  render() {
    const { badgeCount } = this.state
    const {
      component: Component,
      contentContainerStyle,
      extraData,
      extraProps,
      isRefreshing,
      items,
      renderHeader,
    } = this.props

    const hasData = items.ids.length >= 1
    const idsToRender = items.ids.slice(0, itemsPerBadgeCount * badgeCount)
    const hasMoreItems = hasData && items.ids.length > idsToRender.length

    return (
      <View
        forwardRef={this._ref}
        style={{
          maxWidth: '100%',
          height: window.tnym.getFullHeight() - (Header.height + 12), // 12 equals padding from ContentContainer
          overflow: 'auto',
          position: 'relative',
        }}>
        <LoadingIndicator isLoading={isRefreshing || extraData.isRefreshing} />
        <table style={{ position: 'relative', ...contentContainerStyle }}>
          <thead>
            <tr>
              <th
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 'normal',
                  textAlign: 'left',
                }}>
                {typeof renderHeader === 'function' && renderHeader()}
              </th>
            </tr>
          </thead>
          <tbody>
            {hasData
              ? idsToRender.map((itemId, index) => (
                  <tr key={itemId}>
                    <td>
                      <Component
                        hasSeparator={index !== items.ids.length - 1}
                        positionInList={index}
                        item={items.data[itemId]}
                        {...extraProps}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>

        {hasMoreItems && (
          <TouchableOpacity
            onPress={this._setNextBadge}
            style={{ alignSelf: 'center' }}>
            <Icon icon={faChevronDown} style={{ fontSize: 24 }} />
          </TouchableOpacity>
        )}
      </View>
    )
  }
}

export const WebList = ({ forwardRef, ...props }) => (
  <WebListComponent {...props} ref={forwardRef} />
)
/* eslint-disable react/forbid-prop-types */
WebList.propTypes = {
  component: P.oneOfType([P.object, P.func]).isRequired,
  contentContainerStyle: P.object,
  extraProps: P.object,
  extraData: P.shape({ isRefreshing: P.bool }),
  forwardRef: P.object,
  items: P.shape({ ids: P.array, data: P.object }).isRequired,
  isRefreshing: P.bool, // Legacy support, use extraData instead
  renderHeader: P.func,
}

WebList.defaultProps = {
  contentContainerStyle: undefined,
  extraProps: undefined,
  extraData: { isRefreshing: false },
  forwardRef: undefined,
  renderHeader: undefined,
  isRefreshing: undefined,
}
