import { hexToRgb } from '@tellonym/core/helpers'

export const removeLeadingSlash = (url) =>
  url.indexOf('/') === 0 ? url.substr(1) : url

export const hexWithAlpha = (hex, alpha) => {
  const { r, g, b } = hexToRgb(hex)

  return `rgba(${r},${g},${b},${alpha})`
}
