import React from 'react'
import { createStore } from 'redux'
import { Card } from './Card'
import { ModalOption } from './ModalOption'
import { Overlay } from './Overlay'

const initialState = { isVisible: false }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW':
      return { ...state, isVisible: true, ...action.payload }
    case 'HIDE':
      return initialState
    default:
      return state
  }
}

const store = createStore(reducer)

const hide = () => store.dispatch({ type: 'HIDE' })

const show = (payload) => store.dispatch({ type: 'SHOW', payload })

class PopupModal extends React.Component {
  static hide = hide
  static show = show

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => this.forceUpdate())
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  render() {
    const { actions, isVisible, onPress } = store.getState()

    return isVisible ? (
      <Overlay onPress={hide}>
        <Card style={{ paddingHorizontal: 0, paddingVertical: 4 }}>
          {actions.map((item, index) => (
            <ModalOption
              key={typeof item === 'object' ? item.text : item}
              onPress={() => {
                onPress(index)
                hide()
              }}
              hasBorderBottom>
              {typeof item === 'object' ? item.text : item}
            </ModalOption>
          ))}
          <ModalOption onPress={hide}>Cancel</ModalOption>
        </Card>
      </Overlay>
    ) : null
  }
}

export { PopupModal }
