import * as t from './types'

export const chooseBackgroundPicture = (payload) => ({
  type: t.CHOOSE_BACKGROUND_PICTURE,
  payload,
})

export const copyToClipboard = (payload) => ({
  type: t.COPY_TO_CLIPBOARD,
  payload,
})

/**
 * Used to create a sticker for a post or profile.
 * The ViewShotSticker component automatically shares the sticker after its creation.
 *
 * @param {String} payload.shareMethod the share method to use
 * @param {String} payload.shareType SHARE_TYPE
 * @param {Object} payload.item the post or profile to share
 * @param {Object} payload.url the url for deep linking
 * @param {Object} payload.backgroundSrc optional, the uri source for a background image
 * @param {String} payload.content optional, the content in case of profile sharing
 * @param {String} payload.contentId optional, the contentId in case of profile sharing (for analytics)
 *
 */
export const createSticker = (payload) => ({
  type: t.CREATE_STICKER,
  payload,
})

export const refreshProfileSharingCards = (payload) => ({
  type: t.REFRESH_PROFILE_SHARING_CARDS,
  payload,
  meta: {
    offline: {
      effect: { path: 'profiles/sharing-cards', ...payload },
      rollback: { type: t.REFRESH_PROFILE_SHARING_CARDS_ERROR },
      commit: { type: t.REFRESH_PROFILE_SHARING_CARDS_SUCCESS },
    },
  },
})

export const removeBackgroundPicture = (payload) => ({
  type: t.REMOVE_BACKGROUND_PICTURE,
  payload,
})

export const toggleMultiShareItem = (payload) => ({
  type: t.TOGGLE_MULTI_SHARE_ITEM,
  payload,
})

export const resetSticker = (payload) => ({
  type: t.RESET_STICKER,
  payload,
})

export const setBgOwnPic = (payload) => ({
  type: t.SET_BG_OWN_PIC,
  payload,
})

export const setBgType = (payload) => ({
  type: t.SET_BG_TYPE,
  payload,
})

export const setBgVariant = (payload) => ({
  type: t.SET_BG_VARIANT,
  payload,
})

export const setActiveMultiShareMethod = (payload) => ({
  type: t.SET_ACTIVE_MULTI_SHARE_METHOD,
  payload,
})

export const setHasProfileSharingTabBadge = (payload) => ({
  type: t.SET_HAS_PROFILE_SHARING_TAB_BADGE,
  payload,
})

export const setSeenProfileMessageIndex = (payload) => ({
  type: t.SET_SEEN_PROFILE_MESSAGE_INDEX,
  payload,
})

export const setSeenProfileMessageIndices = (payload) => ({
  type: t.SET_SEEN_PROFILE_MESSAGE_INDICES,
  payload,
})

export const setShareStickerWithBgShareMethod = (payload) => ({
  type: t.SET_SHARE_STICKER_WITH_BG_SHARE_METHOD,
  payload,
})

export const setStickerCreationState = (payload) => ({
  type: t.SET_STICKER_CREATION_STATE,
  payload,
})

/**
 * Used to share a post.
 *
 * @param {String} payload.shareMethod the method or network used for sharing
 * @param {String} payload.sourceElement added to analytics event as shareMethod
 * @param {Object} payload.item the post to share
 * @param {string} [payload.actionType] optional action type of parent action
 */
export const sharePost = (payload) => ({
  type: t.SHARE_POST,
  payload,
})

/**
 * Used to share multiple posts.
 *
 * @param {String} payload.shareMethod the method or network used for sharing
 * @param {String} payload.sourceElement added to analytics event as shareMethod
 * @param {Object} payload.items the posts to share
 */
export const sharePosts = (payload) => ({
  type: t.SHARE_POSTS,
  payload,
})

/**
 * Used to share a profile.
 *
 * @param {String} payload.shareMethod the method or network used for sharing
 * @param {String} payload.sourceElement added to analytics event as shareMethod
 * @param {Object} payload.item the profile to share
 */
export const shareProfile = (payload) => ({
  type: t.SHARE_PROFILE,
  payload,
})

/**
 * Used to share a sticker of a post or profile. Used by the ViewShotSticker component.
 *
 * @param {String} payload.shareMethod the share method to use
 * @param {String} payload.shareType SHARE_TYPE
 * @param {Object} payload.url the url for deep linking
 * @param {Object} payload.overlay.uri the path of the overlay asset
 * @param {Object} payload.sticker.uri the path of the sticker asset
 */
export const shareSticker = (payload) => ({
  type: t.SHARE_STICKER,
  payload,
})

/**
 * Used to share a tell.
 *
 * @param {String} payload.shareMethod the method or network used for sharing
 * @param {String} payload.sourceElement added to analytics event as shareMethod
 * @param {Object} payload.item the post to share
 * @param {string} [payload.actionType] optional action type of parent action
 */
export const shareTell = (payload) => ({
  type: t.SHARE_TELL,
  payload,
})

export const triggerPushNotificationAfterSharing = () => ({
  type: t.TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING,
  meta: {
    offline: {
      effect: { path: 'notifications/sendpush' },
      rollback: { type: t.TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_ERROR },
      commit: { type: t.TRIGGER_PUSH_NOTIFICATION_AFTER_SHARING_SUCCESS },
    },
  },
})

export const setLinkInBioAlertShareDataBuffer = (payload) => ({
  type: t.SET_LINK_IN_BIO_ALERT_SHARE_DATA_BUFFER,
  payload,
})

export const setHasPressedBgTypes = (payload) => ({
  type: t.SET_HAS_PRESSED_BG_TYPES,
  payload,
})

export const saveHighlightsSticker = (payload) => ({
  type: t.SAVE_HIGHLIGHTS_STICKER,
  payload,
})

export const createHighlightsSticker = (payload) => ({
  type: t.CREATE_HIGHLIGHTS_STICKER,
  payload,
})
