import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { history, Icon, theme, TouchableOpacity } from '../../common'

export const ButtonBack = ({ onPress, to, style }) => (
  <TouchableOpacity
    onPress={onPress || (() => history.push(`${to}`))}
    style={{
      boxShadow: theme.styles.shadow,
      borderRadius: 25,
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      ...style,
    }}>
    <Icon icon={faChevronLeft} color={theme.colors.placeholder} />
  </TouchableOpacity>
)
