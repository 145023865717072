import * as t from './types'

export const { name } = t

export const initialState = {
  error: null,
  isLoading: false,
  dashboard: { ids: [], data: {} },
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case t.REFRESH_DASHBOARD:
      return {
        ...state,
        isLoading: true,
      }

    case t.REFRESH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboard: {
          ids: Object.keys(payload),
          data: payload,
        },
      }

    case t.REFRESH_DASHBOARD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      }

    default:
      return state
  }
}
