import { effect } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  Button,
  Modal,
  ScrollView,
  styleSheets,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { refreshChatMessages } from '../actions'
import { getChatMessages } from '../selectors'

const styles = {
  background: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  mainContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 25,
    maxHeight: 640,
    minHeight: 380,
    padding: 32,
    width: 500,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  messageItem: {
    borderRadius: 12,
    paddingVertical: 4,
    paddingHorizontal: 16,
    margin: 6,
  },
  messageFromReportedUser: {
    alignSelf: 'flex-start',
    backgroundColor: theme.colors.backgroundLight,
  },
  messageFromIssuer: {
    alignSelf: 'flex-end',
    backgroundColor: theme.colors.border,
  },
}

const MessageItem = ({ content, userId, reportedUserId }) => {
  const isFromReportedUser = userId === reportedUserId

  return (
    <View
      style={[
        styles.messageItem,
        isFromReportedUser
          ? styles.messageFromReportedUser
          : styles.messageFromIssuer,
      ]}>
      <Text type="small">{content}</Text>
    </View>
  )
}

class _ChatMessages extends React.Component {
  state = {
    isTextTranslatedVisible: false,
    chatMessages: this.props.chatMessages.slice().reverse(),
  }

  static getDerivedStateFromProps(props, state) {
    if (state.isTextTranslatedVisible === false) {
      return {
        chatMessages: props.chatMessages.slice().reverse(),
      }
    }

    return null
  }

  componentDidMount() {
    const { actions, issuerUserId, reportedUserId } = this.props

    if (
      !!issuerUserId &&
      !!reportedUserId &&
      typeof actions.refreshChatMessages === 'function'
    ) {
      actions.refreshChatMessages({ issuerUserId, reportedUserId })
    }
  }

  _closeModal = () => Modal.hide({ id: this.props.modalId })

  _translate = async () => {
    if (this.state.isTextTranslatedVisible) {
      this.setState({
        isTextTranslatedVisible: false,
        chatMessages: this.props.chatMessages.slice().reverse(),
      })
    } else {
      const action = {
        payload: {
          language: 'en',
          items: this.props.chatMessages.map(({ content }) => ({ content })),
        },
        meta: {
          accessToken: this.props.accessToken,
          effect: { path: 'admin/translate', method: 'POST' },
        },
      }

      const res = await effect(action.meta.effect, action)
      const { items = {} } = res ?? {}
      const { data = [] } = items

      if (data.length > 0) {
        this.setState({
          isTextTranslatedVisible: true,
          chatMessages: this.props.chatMessages
            .map((message, index) => ({
              ...message,
              content: data[index].content,
            }))
            .reverse(),
        })
      }
    }
  }

  render() {
    const { issuerUserId, reportedUserId } = this.props
    const { chatMessages, isTextTranslatedVisible } = this.state

    return (
      <View style={styles.background}>
        <Modal.Body>
          <View style={styles.mainContainer}>
            <View style={styles.headerContainer}>
              <Text bold>{`Reported User: ${reportedUserId}`}</Text>
              <Text bold>{`Issuer: ${issuerUserId}`}</Text>
            </View>
            <ScrollView style={styleSheets.margin.bottom[16]}>
              {chatMessages.map((message) => (
                <MessageItem
                  key={message.id}
                  {...message}
                  reportedUserId={reportedUserId}
                />
              ))}
            </ScrollView>
            <Button
              onPress={this._closeModal}
              style={{ alignSelf: 'center', marginBottom: 16 }}>
              <Text color={theme.colors.white}>Close</Text>
            </Button>
            <TouchableOpacity
              onPress={this._translate}
              style={styleSheets.alignSelf.center}>
              <Text type="small">
                {isTextTranslatedVisible ? 'Original' : 'Translate'}
              </Text>
            </TouchableOpacity>
          </View>
        </Modal.Body>
      </View>
    )
  }
}
const mapStateToProps = createStructuredSelector({
  accessToken: getAccessToken,
  chatMessages: getChatMessages,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refreshChatMessages }, dispatch),
})

const ChatMessages = connect(mapStateToProps, mapDispatchToProps)(_ChatMessages)

const show = (payload) =>
  Modal.show({
    render: (props) => <ChatMessages {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalChatMessages = { show }
