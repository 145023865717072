import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { normalize } from '@tellonym/core/helpers'
import { Alert } from '../common'
import { buildRequest, queryClient } from '../common/queries'
import { store } from '../common/store'

const defined = (v) => typeof v !== 'undefined'

export const moderation = {
  all: ['moderation'],
  report: () => [...moderation.all, 'report'],
  communityTellReports: (props) => [...moderation.report(), 'communityTell', props], // prettier-ignore
  postReports: (props) => [...moderation.report(), 'post', props],
  profileReports: (props) => [...moderation.report(), 'profile', props],
  quickBanSexual: () => [...moderation.all, 'quickBanSexual'],
  quickBanSexualPerma: () => [...moderation.all, 'quickBanSexualPerma'],
  shadowBan: (props) => [...moderation.all, 'shadowBan', props],
  undoShadowBan: (props) => [...moderation.all, 'undoShadowBan', props],
  userLog: (userId) => [...moderation.all, 'userLog', userId],
  userStats: (userId) => [...moderation.all, 'userStats', userId],
  voteReport: (props) => [...moderation.all, 'vote', props],
}

export const banCandidates = {
  all: ['banCandidates'],
  filter: (showOnlyHidden) => [...banCandidates.all, showOnlyHidden],
  hide: (props) => [...banCandidates.all, 'hide', props],
  unhide: (props) => [...banCandidates.all, 'unhide', props],
}

const fetchProfileReports = buildRequest({
  path: 'admin/reports/profile',
  buildPayload: ({ queryKey }) => queryKey[3],
})

const fetchPostReports = buildRequest({
  path: 'admin/reports/post',
  buildPayload: ({ queryKey }) => queryKey[3],
})

const fetchCommunityTellReports = buildRequest({
  path: 'admin/reports/communitytell',
  buildPayload: ({ queryKey }) => queryKey[3],
})

export const useProfileReportsQuery = ({ isActive, ...props }) => {
  const result = useQuery({
    queryKey: moderation.profileReports(props),
    queryFn: fetchProfileReports,
    enabled: typeof isActive !== 'undefined',
    refetchOnWindowFocus: false,
  })

  return result
}

export const usePostReportsQuery = ({ isActive, ...props }) => {
  const result = useQuery({
    queryKey: moderation.postReports(props),
    queryFn: fetchPostReports,
    enabled: typeof isActive !== 'undefined',
    refetchOnWindowFocus: false,
  })

  return result
}

export const useCommunityTellReportsQuery = ({ isActive, ...props }) => {
  const result = useQuery({
    queryKey: moderation.communityTellReports(props),
    queryFn: fetchCommunityTellReports,
    enabled: typeof isActive !== 'undefined',
    refetchOnWindowFocus: false,
  })

  return result
}

const fetchVoteReport = async ({ queryKey }) => {
  const { decision, reportId, reportType } = queryKey[2]
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `reports/${reportId}`,
      method: 'POST',
      accessToken,
      payload: { decision, type: reportType },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useVoteReportQuery = ({ decision, reportId, reportType }) => {
  const result = useQuery({
    queryKey: moderation.voteReport({ decision, reportId, reportType }),
    queryFn: fetchVoteReport,
    enabled: [decision, reportId, reportType].every(defined),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity,
  })

  return result
}

const fetchUserStats = async ({ queryKey }) => {
  const userId = queryKey[2]
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/user/stats/${userId}`,
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useUserStats = ({ userId }) => {
  const result = useQuery({
    queryKey: moderation.userStats(userId),
    queryFn: fetchUserStats,
    enabled: defined(userId),
    refetchOnWindowFocus: false,
  })

  return result
}

const fetchBanCandidates = async ({ queryKey }) => {
  const showOnlyHidden = queryKey[1] ?? false
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/users/bancandidates',
      showOnlyHidden,
    },
    { accessToken, limit: 200 }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useBanCandidatesQuery = (payload) => {
  const result = useQuery({
    queryKey: banCandidates.filter(payload),
    queryFn: fetchBanCandidates,
    refetchOnWindowFocus: false,
  })

  const data =
    result.data?.users?.length > 0
      ? normalize(result.data.users)
      : { ids: [], data: {} }

  return { ...result, data }
}

const fetchUserLog = async ({ queryKey }) => {
  const userId = queryKey[2]
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    { path: `admin/user/log/${userId}` },
    { accessToken, limit: 50 }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useUserLogQuery = (payload) => {
  const result = useQuery({
    queryKey: moderation.userLog(payload?.userId),
    queryFn: fetchUserLog,
    enabled: defined(payload?.userId),
    refetchOnWindowFocus: false,
  })

  return result
}

const hideBanCanidates = async ({ userId, untilDate }) => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/users/bancandidates/${userId}`,
      method: 'DELETE',
      accessToken,
      payload: { untilDate },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useHideBanCandidatesMutation = () => {
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: banCandidates.hide(),
    mutationFn: hideBanCanidates,
    onMutate: async ({ userId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: banCandidates.filter(false) })

      const previousItems = queryClient.getQueryData({
        queryKey: banCandidates.filter(false),
      })

      queryClient.setQueryData(banCandidates.filter(false), (data) => ({
        users: data.users.filter((user) => user.id !== userId),
      }))

      return { previousItems } // Return a context object with the snapshotted value to be used in onError
    },
    onError: (error, newItem, context) => {
      queryClient.setQueryData(
        banCandidates.filter(false),
        context.previousItems
      )
    },
  })

  return result
}

const unhideBanCanidates = async ({ userId }) => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/users/bancandidates/${userId}`,
      method: 'PUT',
      accessToken,
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useUnhideBanCandidatesMutation = () => {
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: banCandidates.unhide(),
    mutationFn: unhideBanCanidates,
    onMutate: async ({ userId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: banCandidates.filter(true) })

      const previousItems = queryClient.getQueryData({
        queryKey: banCandidates.filter(true),
      })

      queryClient.setQueryData(banCandidates.filter(true), (data) => ({
        users: data.users.filter((user) => user.id !== userId),
      }))

      return { previousItems } // Return a context object with the snapshotted value to be used in onError
    },
    onError: (error, newItem, context) => {
      queryClient.setQueryData(
        banCandidates.filter(true),
        context.previousItems
      )
    },
  })

  return result
}

const quickBanSexual = async ({ userId, reportId }) => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/user/actions/ban/create/sexual',
      method: 'POST',
      accessToken,
      payload: { userId, reportId },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useQuickBanSexualMutation = () => {
  const result = useMutation({
    mutationKey: moderation.quickBanSexual(),
    mutationFn: quickBanSexual,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(['profile', vars.userId])
      Alert.success('User 1 day banned successfully')
    },
  })

  return result
}

const quickBanSexualPerma = async ({ userId, reportId }) => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/user/actions/ban/create/sexual/perma',
      method: 'POST',
      accessToken,
      payload: { userId, reportId },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useQuickBanSexualPermaMutation = () => {
  const result = useMutation({
    mutationKey: moderation.quickBanSexualPerma(),
    mutationFn: quickBanSexualPerma,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(['profile', vars.userId])
      Alert.success('User perma banned successfully')
    },
  })

  return result
}

const shadowBanRequest = buildRequest({
  path: 'admin/user/actions/ban/create/shadow',
  method: 'POST',
})

export const useShadowBanMutation = () => {
  const result = useMutation({
    mutationKey: moderation.shadowBan(),
    mutationFn: shadowBanRequest,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(['profile', vars.userId])
      Alert.success('User shadow banned successfully')
    },
  })

  return result
}

const undoShadowBanRequest = buildRequest({
  path: 'admin/user/actions/ban/destroy/shadow',
  method: 'POST',
})

export const useUndoShadowBanMutation = () => {
  const result = useMutation({
    mutationKey: moderation.undoShadowBan(),
    mutationFn: undoShadowBanRequest,
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(['profile', vars.userId])
      Alert.success('User shadow ban undone')
    },
  })

  return result
}
