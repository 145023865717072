import { safeEffects } from '@tellonym/core/sagas'
import { Alert } from '../common'
import * as t from './types'

const whitelistTellSuccess = function* () {
  yield Alert.success('Success')
}

export const actionHandler = {
  [t.WHITELIST_TELL_SUCCESS]: [safeEffects.takeLatest, whitelistTellSuccess],
}
