import React from 'react'
import { fade } from '../animations'
import { View } from './View'

const Animations = {
  FADE: 'FADE',
  SLIDE: 'SLIDE',
}
// TODO implement animations
class Animate extends React.Component {
  static Animations = Animations

  state = { animation: undefined }

  componentDidMount() {
    this._setStateForAnimation()
  }

  componentDidUpdate(prevProps) {
    const { isRequestingClose, onClose } = this.props
    if (!prevProps.isRequestingClose && isRequestingClose) {
      return this._setStateForAnimation({
        isFadeIn: false,
        callback: typeof onClose === 'function' ? onClose : undefined,
      })
    }

    return undefined
  }

  _setStateForAnimation = (
    payload = { callback: () => {}, isFadeIn: true }
  ) => {
    const { type } = this.props
    const { callback, isFadeIn } = payload

    if (type === Animations.FADE) {
      const animation = isFadeIn ? fade.in() : fade.out()

      this.setState({ animation }, () => setTimeout(callback, 300))
    } else {
      callback()
    }
  }

  render() {
    const { children, style } = this.props
    const { animation } = this.state

    return <View style={[style, animation]}>{children}</View>
  }
}

export { Animate }
