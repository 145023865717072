import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { Button, Spin, Table, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { config } from '../../../config'
import { Box, Image, hooks } from '../../common'
import {
  useTikTokAccountsQuery,
  useTikTokCookiesMutation,
  useTikTokVideosQuery,
} from '../queries'

const Cover = ({ coverImageUrl, width, height, isVisible: isVisibleOuter }) => {
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    setIsVisible(isVisibleOuter)
  }, [isVisibleOuter])

  if (!isVisible) {
    return (
      <Typography.Link onClick={() => setIsVisible(true)}>Show</Typography.Link>
    )
  }

  return (
    <Image
      source={coverImageUrl}
      style={{
        width: (width || 1080) / 10, // we need the || operator here since width is 0 for image slides
        height: (height || 1920) / 10, // we need the || operator here since height is 0 for image slides
      }}
    />
  )
}

export const PageTiktokStats = () => {
  const containerStyle = hooks.usePageContainerStyle()
  const [shouldShowAllCovers, setShouldShowAllCovers] = React.useState(false)

  const { data: accounts, isLoading: isLoadingAccounts } =
    useTikTokAccountsQuery()
  const { data, isLoading } = useTikTokVideosQuery()
  const { mutate, isLoadingCookies } = useTikTokCookiesMutation()

  const addTiktokAccount = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          window.open(`${config.api.host}/accounts/connect/tiktok`, '_self')
        },
      }
    )
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Cover',
        dataIndex: 'coverImageUrl',
        key: 'coverImageUrl',
        render: (coverImageUrl, record) => (
          <Cover
            isVisible={shouldShowAllCovers}
            coverImageUrl={coverImageUrl}
            height={record.height}
            width={record.width}
          />
        ),
        filterDropdown: () => null,
        filterIcon: () =>
          shouldShowAllCovers ? <EyeInvisibleOutlined /> : <EyeOutlined />,
        onFilterDropdownOpenChange: (isVisible) => {
          setShouldShowAllCovers(isVisible)
        },
        onFilter: () => true,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (title, record) => (
          <Box transparent>
            <Typography.Text>{title}</Typography.Text>
            {title !== record.description && (
              <Typography.Text type="secondary">
                {record.description}
              </Typography.Text>
            )}
          </Box>
        ),
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Views',
        dataIndex: 'views',
        key: 'views',
        sorter: (a, b) => a.views - b.views,
      },
      {
        title: 'Likes',
        dataIndex: 'likes',
        key: 'likes',
        sorter: (a, b) => a.likes - b.likes,
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        sorter: (a, b) => a.comments - b.comments,
      },
      {
        title: 'Shares',
        dataIndex: 'shares',
        key: 'shares',
        sorter: (a, b) => a.shares - b.shares,
      },
      {
        title: 'Created',
        dataIndex: 'createAt',
        key: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('DD.MM.YYYY'),
        sorter: (a, b) => a.createdAt - b.createdAt,
        defaultSortOrder: 'descend',
      },
      {
        title: 'Account',
        dataIndex: ['account', 'displayName'],
        key: ['account', 'displayName'],
        width: 180,
        render: (displayName, record) => (
          <Box flexDirection="row" alignItems="center" transparent>
            <Image
              source={record.account.avatarUrl}
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
            <Typography.Text style={{ fontSize: 12 }}>
              {displayName}
            </Typography.Text>
          </Box>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'shareUrl',
        key: 'shareUrl',
        render: (shareUrl) => (
          <Typography.Link onClick={() => window.open(shareUrl, '_blank')}>
            open
          </Typography.Link>
        ),
      },
    ],
    [shouldShowAllCovers]
  )

  return (
    <Box style={containerStyle}>
      <Box padding={32} backgroundColor={colors.background}>
        <Typography.Title>Tiktok Stats</Typography.Title>

        <Box alignItems="flex-start">
          <Button loading={isLoadingCookies} onClick={addTiktokAccount}>
            Add Tiktok Account
          </Button>
        </Box>

        <Box marginTop={12} alignItems="flex-start">
          <Spin spinning={isLoadingAccounts}>
            {accounts?.map((account) => (
              <Box
                borderWidth={1}
                borderColor={colors.grey[4]}
                borderStyle="solid"
                borderRadius={8}
                padding={8}
                flexDirection="row"
                alignItems="center">
                <Image
                  source={account.avatarUrl}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                <Typography.Text>{account.displayName}</Typography.Text>
              </Box>
            ))}
          </Spin>
        </Box>

        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          size="medium"
          style={{ marginTop: 24 }}
        />
      </Box>
    </Box>
  )
}
