import { safeEffects } from '@tellonym/core/sagas'
import { Alert } from '../common'
import * as t from './types'

const success = function* () {
  yield Alert.success('Success')
}

export const actionHandler = {
  [t.REROLL_EXPERIMENTS_SUCCESS]: [safeEffects.takeLatest, success],
}
