import { useMutation, useQuery } from '@tanstack/react-query'
import { HOST_TYPE, buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { _ } from '../common'
import * as hooks from '../common/hooks'
import { buildRequest } from '../common/queries'
import { store } from '../common/store'

const analyticsEvents = {
  all: ['analyticsEvents'],
  list: (filters) => ['list', filters],
}

const analyticsEventNames = {
  all: ['analyticsEventNames'],
  props: (tableName) => ['analyticsEventNames', 'props', tableName],
}

const tiktokVideoBackgrounds = {
  all: ['tiktokVideoBackgrounds'],
}

const tiktokStats = {
  all: ['tiktokStats'],
  accounts: ['tiktokStats', 'accounts'],
  cookies: ['tiktokStats', 'cookies'],
  videos: ['tiktokStats', 'videos'],
}

const fetchAnalyticsEvents = async ({ queryKey }) => {
  const { userId, time, env, events } = queryKey[1]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/stats/clickhouse/analyticsevents',
      method: 'POST',
      accessToken,
      payload: { userId, time, env, events },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventsQuery = ({ env, userId, time, events }) => {
  const userIdDebounced = hooks.useDebounceValue(userId, 500)

  const result = useQuery(
    analyticsEvents.list({
      env,
      userId: userIdDebounced === '' ? undefined : Number(userIdDebounced),
      time,
      events,
    }),
    fetchAnalyticsEvents,
    {
      enabled: time?.length > 0 && userIdDebounced?.length > 0,
      initialData: { ids: [], data: {} },
    }
  )

  return result
}

const fetchAnalyticsEventNames = async () => {
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    { path: 'admin/stats/clickhouse/analyticseventnames' },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventNamesQuery = () => {
  const result = useQuery(analyticsEventNames.all, fetchAnalyticsEventNames, {
    refetchOnWindowFocus: false,
  })

  const data =
    result?.data?.eventNames?.map?.((v) => ({ label: v, value: v })) ?? []

  return { ...result, data }
}

const fetchAnalyticsEventProps = async ({ queryKey }) => {
  const tableName = queryKey[2]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      method: 'POST',
      path: 'admin/stats/clickhouse/analyticseventprops',
      payload: { tableName },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAnalyticsEventPropsQuery = ({ tableName, onSuccess }) => {
  const result = useQuery({
    queryKey: analyticsEventNames.props(tableName),
    queryFn: fetchAnalyticsEventProps,
    onSuccess,
    enabled: typeof tableName !== 'undefined',
    refetchOnWindowFocus: false,
  })

  return result
}

const fetchTikTokVideoBackgrounds = async () => {
  const [url, fetchConfig] = buildFetch(
    {
      path: 'backgrounds',
    },
    {
      hostType: HOST_TYPE.VIDEOGEN,
    }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useTiktokVideoBackgroundQuery = () => {
  const result = useQuery(
    tiktokVideoBackgrounds.all,
    fetchTikTokVideoBackgrounds,
    { refetchOnWindowFocus: false, cacheTime: 10 * 60 * 1000 }
  )

  return result
}

const fetchTikTokAccounts = buildRequest({
  path: 'admin/tiktokstats/accounts',
})

export const useTikTokAccountsQuery = () => {
  const result = useQuery({
    queryKey: tiktokStats.accounts,
    queryFn: fetchTikTokAccounts,
    refetchOnWindowFocus: false,
    cacheTime: 10 * _.min,
  })

  return result
}

const fetchTikTokVideos = buildRequest({
  path: 'admin/tiktokstats/videos',
})

export const useTikTokVideosQuery = () => {
  const result = useQuery({
    queryKey: tiktokStats.videos,
    queryFn: fetchTikTokVideos,
    refetchOnWindowFocus: false,
    cacheTime: 10 * _.min,
  })

  return result
}

const fetchTikTokCookies = buildRequest({
  path: 'accounts/connect/setcookies',
  buildPayload: () => ({}),
  fetchOptions: { credentials: 'include' },
})

export const useTikTokCookiesMutation = () => {
  const result = useMutation({
    mutationKey: tiktokStats.cookies,
    mutationFn: fetchTikTokCookies,
    refetchOnWindowFocus: false,
    cacheTime: 10 * _.min,
  })

  return result
}
