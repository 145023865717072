import { DETECTABLE_LANGUAGE } from '@tellonym/enums/lib/Language'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown, Input, theme, View } from '../../common'
import { refreshBadwordscore } from '../actions'

const languages = Object.keys(DETECTABLE_LANGUAGE).filter((lang) =>
  Number.isNaN(Number.parseInt(lang, 10))
)
const badwordLevels = [1, 2, 3, 4, 5]
const sexualLevels = [1, 2, 3, 4, 5]

const dropDownStyle = {
  boxShadow: 'none',
  borderRadius: '0px 25px 25px 0px',
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderRightWidth: 0,
  minWidth: 130,
}

class BadwordInputComponent extends React.Component {
  state = {
    searchTerm: '',
    languageIndex: 0,
    badwordLevelIndex: 2,
    sexualLevelIndex: 2,
  }

  componentDidMount() {
    this.searchInput.focus()
  }

  _onChangeSearchTerm = (e) => this.setState({ searchTerm: e.target.value })

  _handleLanguageChange = ({ index }) => this.setState({ languageIndex: index })
  _handleBadwordLevelChange = ({ index }) =>
    this.setState({ badwordLevelIndex: index })
  _handleSexualLevelChange = ({ index }) =>
    this.setState({ sexualLevelIndex: index })

  _handleSubmit = (e) => {
    const { searchTerm, languageIndex, badwordLevelIndex, sexualLevelIndex } =
      this.state
    const language =
      DETECTABLE_LANGUAGE[languages[languageIndex]] ===
      DETECTABLE_LANGUAGE.UNKNOWN
        ? undefined
        : DETECTABLE_LANGUAGE[languages[languageIndex]]
    this.props.actions.refreshBadwordscore({
      str: searchTerm,
      language,
      badwordLevel: badwordLevels[badwordLevelIndex],
      sexualLevel: sexualLevels[sexualLevelIndex],
    })
    e.preventDefault()
  }
  render() {
    const { searchTerm } = this.state
    const { style: styleProps } = this.props

    return (
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.colors.background,
            borderRadius: 25,
            boxShadow: theme.styles.shadow,
            marginTop: 48,
            width: '100%',
          },
          styleProps,
        ]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 12,
            flex: 1,
          }}>
          <form
            onSubmit={this._handleSubmit}
            style={{ flex: 1, marginLeft: 24, marginRight: 12 }}>
            <Input
              forwardRef={(input) => (this.searchInput = input)}
              placeholder="Input Tell here"
              onChange={this._onChangeSearchTerm}
              value={searchTerm}
              style={{
                borderBottom: 'none',
                marginBottom: 0,
                width: '100%',
              }}
            />
          </form>
        </View>
        <Dropdown
          options={badwordLevels}
          label="badwordLevel"
          onChange={this._handleBadwordLevelChange}
          initialActiveIndex={this.state.badwordLevelIndex}
          style={dropDownStyle}
        />
        <Dropdown
          options={sexualLevels}
          label="sexualLevel"
          onChange={this._handleSexualLevelChange}
          initialActiveIndex={this.state.sexualLevelIndex}
          style={dropDownStyle}
        />
        <Dropdown
          options={languages}
          onChange={this._handleLanguageChange}
          style={dropDownStyle}
        />
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refreshBadwordscore }, dispatch),
})

const BadwordInput = connect(null, mapDispatchToProps)(BadwordInputComponent)

export { BadwordInput }
