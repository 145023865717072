import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal, Spinner, Text, theme, View } from '../../common'
import { restoreIap } from '../actions'
import { IapRestore as selector } from '../selectors'

const mapStateToProps = (state) => ({
  ...selector(state),
})

const IapRestore = connect(mapStateToProps, (dispatch) => ({
  actions: bindActionCreators({ restoreIap }, dispatch),
}))(({ actions, modalId, userId, isRefreshing, hasRestoredItems }) => {
  const [didSent, setDidSent] = useState(false)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: theme.colors.background,
            padding: 32,
            width: 500,
            minHeight: 150,
          }}>
          <Text type="h2" bold style={{ marginBottom: 12 }}>
            Restore Inapp Purchases
          </Text>
          {isRefreshing ? (
            <View style={{ alignItems: 'center', marginTop: 48 }}>
              <Spinner />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                marginTop: 48,
                padding: 16,
              }}>
              {!didSent ? (
                <Button
                  disabled={didSent || isRefreshing}
                  onPress={() => {
                    setDidSent(true)
                    actions.restoreIap({
                      userId,
                    })
                  }}
                  style={[{ flex: 1, alignSelf: 'center', marginTop: 24 }]}>
                  Restore
                </Button>
              ) : (
                <View style={{ flex: 1 }}>
                  <Text center>
                    {hasRestoredItems
                      ? 'Successfully restored purchases'
                      : 'Did not restore any purchases'}
                  </Text>
                  <Button
                    onPress={() => Modal.hide({ id: modalId })}
                    style={[{ flex: 1, alignSelf: 'center', marginTop: 24 }]}>
                    Close
                  </Button>
                </View>
              )}
            </View>
          )}
        </View>
      </Modal.Body>
    </View>
  )
})

const show = (payload) =>
  Modal.show({
    render: (props) => <IapRestore {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

export const ModalIapRestore = { show }
