import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList, Text, theme, View } from '../../common'
import { search as refresh } from '../actions'
import { Search as selector } from '../selectors'
import { SearchBar } from './SearchBar'
import { SearchItem } from './SearchItem'

const _SearchItem = ({ item }) => <SearchItem profile={item} />

class SearchComponent extends React.Component {
  _renderHeader = () => <SearchBar />

  _renderEmptyComponent = () =>
    this.props.hasSearched && this.props.profiles.ids.length === 0 ? (
      <View style={{ marginTop: 24 }}>
        <Text type="h3" center color={theme.colors.placeholder}>
          Nothing found.
        </Text>
      </View>
    ) : (
      <View style={{ marginTop: 24 }} />
    )

  render() {
    return (
      <FlatList
        actions={this.props.actions}
        component={_SearchItem}
        extraData={{
          hasMore: this.props.hasMore,
          isRefreshing: this.props.isRefreshing,
        }}
        hasMore={this.props.hasMore}
        isRefreshing={this.props.isRefreshing}
        items={this.props.profiles}
        renderHeader={this._renderHeader}
        renderEmptyComponent={this._renderEmptyComponent}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ refresh }, dispatch),
})

const mapStateToProps = (state) => ({
  ...selector(state),
})

export const PageSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchComponent)
