import { useMutation, useQuery } from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { _ } from '../common'
import { store } from '../common/store'

const profile = {
  all: ['profile'],
  id: (userId) => [...profile.all, 'id', userId],
  ids: () => [...profile.all, 'ids'],
  settings: (userId) => [...profile.all, userId],
}

const fetchSettings = async ({ queryKey }) => {
  const userId = queryKey[1]
  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/user/settings/${userId}`,
      method: 'GET',
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useProfileSettingsQuery = ({ userId }) => {
  const result = useQuery({
    queryKey: profile.settings(userId),
    queryFn: fetchSettings,
    enabled: typeof userId !== 'undefined',
    refetchOnWindowFocus: false,
    staleTime: 1 * _.h,
  })

  return result
}

const fetchProfiles = async (params) => {
  const { userIds } = params

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: 'admin/profiles/ids',
      method: 'POST',
      accessToken,
      payload: { userIds },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useProfilesMutation = () => {
  const result = useMutation({
    mutationKey: profile.ids(),
    mutationFn: fetchProfiles,
  })

  return result
}
