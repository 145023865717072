import { DeleteOutlined } from '@ant-design/icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useDebounceCallback } from '@react-hook/debounce'
import { headerModes } from '@tellonym/core/artificialTells/constants'
import { colors } from '@tellonym/core/common/colorSystem'
import { shortenNumber } from '@tellonym/core/helpers'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
} from '@tellonym/enums/lib/Tell'
import {
  Button,
  DatePicker,
  Input,
  Segmented,
  Select,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  Icon,
  ScrollView,
  hooks,
  moment,
  styleSheets,
  theme,
} from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import {
  createGroup,
  editTopic,
  refreshTopic,
  setHeaderMode,
} from '../actionsV2'
import {
  useDeleteInactiveGroupsMutation,
  useTranslateNotLocalizedMutation,
  useValidateInactiveGroupsMutation,
} from '../queries'
import {
  getHeaderMode,
  getIsRefreshingTopicDetails,
  getLanguage,
} from '../selectorsV2'
import { ArtificialTellTopicGroupsTable } from './ArtificialTellTopicGroupsTable'
import { BreadcrumbHeader } from './BreadcrumbHeader'
import { ButtonModalCreateTodo } from './ButtonModalCreateTodo'
import { GptAnalysisBox } from './GptAnalysisBox'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { TodoItem } from './PageArtificialTellsTodos'
import { PerformanceCheckIns } from './PerformanceCheckIns'

const medianTexts = [
  'Total Sent:',
  'Answer Rate:',
  'Share Rate:',
  'Median Length:',
]

const TAB_HEIGHT = 192

const styles = {
  medianLabelStyle: {
    fontWeight: 'bold',
    marginRight: 8,
    marginLeft: 8,
  },
  marginRight16: {
    marginRight: 16,
  },
  gptAnalysisTextArea: {
    flex: 1,
    resize: 'none',
    borderColor: colors.grey[4],
  },
  smallButton: { fontSize: 11, marginBottom: 12 },
  tinyButton: { fontSize: 9, marginBottom: 12 },
}

const TextInput = ({ isBold, keyName, placeholder, topic, ...props }) => {
  const dispatch = ReactRedux.useDispatch()

  const [text, setText] = React.useState(topic[keyName] || '')

  const saveDescription = useDebounceCallback(
    React.useCallback(
      (text) => {
        dispatch(
          editTopic({
            id: topic.id,
            [keyName]: text,
          })
        )
      },
      [dispatch, keyName, topic]
    ),
    1500,
    false
  )

  const onChange = (e) => {
    setText(e.target.value)
    saveDescription(e.target.value)
  }

  return (
    <Box transparent {...props}>
      <Input.TextArea
        value={text}
        onChange={onChange}
        placeholder={placeholder}
        autoSize={{ minRows: 1, maxRows: 3 }}
        style={{ width: '100%', fontWeight: isBold ? 'bold' : undefined }}
      />
    </Box>
  )
}

const TimeFrame = ({ topic }) => {
  const dispatch = ReactRedux.useDispatch()

  const isRefreshing = ReactRedux.useSelector(getIsRefreshingTopicDetails)

  const startDate = topic.validPeriod.startDate
    ? moment(topic.validPeriod.startDate)
    : undefined

  const endDate = topic.validPeriod.endDate
    ? moment(topic.validPeriod.endDate)
    : undefined

  const [isLimited, setIsLimited] = React.useState(
    topic.validPeriod.endDate || topic.validPeriod.startDate
  )

  const onChangeStartDate = (date, dateString) => {
    if (dateString !== startDate?.format('YYYY-MM-DD')) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { startDate: dateString },
        })
      )
    }
  }

  const onChangeEndDate = (date, dateString) => {
    if (dateString !== endDate?.format('YYYY-MM-DD')) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { endDate: dateString },
        })
      )
    }
  }

  const onChangeIsRepeatedYearly = (value) => {
    dispatch(
      editTopic({
        id: topic.id,
        validPeriod: { repeatedYearly: value },
      })
    )
  }

  const toggleIsLimited = () => {
    if (isLimited) {
      dispatch(
        editTopic({
          id: topic.id,
          validPeriod: { startDate: '', endDate: '', repeatedYearly: false },
        })
      )

      setIsLimited(false)
    } else {
      setIsLimited(true)
    }
  }

  return (
    <Box
      flexDirection="row"
      alignItems="flex-end"
      backgroundColor={theme.colors.antdBackgroundElevated}>
      <Tooltip
        placement="topLeft"
        title={
          isLimited
            ? 'Remove the time frame that this topic is limited to'
            : 'Set a time frame this group is limited to'
        }>
        <Button
          onClick={toggleIsLimited}
          style={styleSheets.margin.right[12]}
          danger={isLimited}>
          {isLimited ? <DeleteOutlined /> : 'Set limited time frame'}
        </Button>
      </Tooltip>

      {isLimited && (
        <>
          <DatePicker
            value={startDate}
            disabled={!isLimited}
            onChange={onChangeStartDate}
            placeholder="Start date"
            style={{ width: 140, marginRight: 2 }}
          />
          <DatePicker
            value={
              topic.validPeriod.endDate
                ? moment(topic.validPeriod.endDate)
                : undefined
            }
            disabled={!isLimited}
            onChange={onChangeEndDate}
            placeholder="End date"
            style={{ width: 140, marginRight: 12 }}
          />
          <Switch
            loading={isRefreshing}
            checked={topic.validPeriod.repeatedYearly}
            disabled={!isLimited}
            onChange={onChangeIsRepeatedYearly}
            checkedChildren="Repeated yearly"
            unCheckedChildren="Repeated yearly"
            style={{ width: 120 }}
          />
        </>
      )}
    </Box>
  )
}

const ConnectedInterest = ({ topic }) => {
  const dispatch = ReactRedux.useDispatch()

  const onChange = (connectedInterestId) => {
    dispatch(
      editTopic({
        id: topic.id,
        connectedInterestId:
          connectedInterestId === 'none' ? null : connectedInterestId,
      })
    )
  }

  return (
    <Box backgroundColor={theme.colors.antdBackgroundElevated} marginLeft={16}>
      <Box
        alignItems="center"
        backgroundColor={theme.colors.antdBackgroundElevated}
        flexDirection="row"
        marginRight={8}>
        <Typography.Text style={{ fontSize: 10 }}>
          Connected Interest
        </Typography.Text>
        <Tooltip title="If the user has this interest connected, the topic is immediately considered as qualified and the user can start receiving deep questions.">
          <Icon icon={faInfoCircle} style={{ marginLeft: 4, fontSize: 12 }} />
        </Tooltip>
      </Box>
      <Select
        onChange={onChange}
        options={[
          { id: 'none', text: 'NONE' },
          ...topic.allInterestsAvailablePerLang,
        ]?.map((interest) => ({
          label: interest.text,
          value: interest.id,
        }))}
        value={topic.connectedInterestId ?? 'none'}
      />
    </Box>
  )
}

const TopicEntriesAmount = ({ language, topic }) => {
  const groupsMedianIntro = topic.activeGroupsCount[
    ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
  ] ?? { total: 0, open: 0, simple: 0 }

  const groupsMedianDeep = topic.activeGroupsCount[
    ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
  ] ?? { total: 0, open: 0, simple: 0 }

  return (
    <Box
      backgroundColor={theme.colors.antdBackgroundElevated}
      flexDirection="row"
      minWidth={556} /* This is corresponding with the width of
      ChangeGroupPropertiesSection to set the whole containers width */
    >
      <Typography.Text style={styles.medianLabelStyle}>Groups</Typography.Text>
      <Typography.Text style={styles.marginRight16}>
        {`${groupsMedianIntro.total + groupsMedianDeep.total ?? 0} Total`}
      </Typography.Text>
      <Typography.Text style={styles.marginRight16}>
        {`${groupsMedianIntro.total} Intro (${groupsMedianIntro.open}O ${groupsMedianIntro.simple}S)`}
      </Typography.Text>

      <Typography.Text style={styles.marginRight16}>
        {`${groupsMedianDeep.total} Deep (${groupsMedianDeep.open}O ${groupsMedianDeep.simple}S)`}
      </Typography.Text>

      <Typography.Text style={styles.medianLabelStyle}>Members</Typography.Text>
      <Typography.Text style={styles.marginRight16}>
        {`${shortenNumber(topic.memberCount, false, 999)} Total`}
      </Typography.Text>
      <Typography.Text style={styles.marginRight16}>
        {`${shortenNumber(
          topic.memberOfLanguageCount,
          false,
          999
        )} in ${language.toUpperCase()}`}
      </Typography.Text>
    </Box>
  )
}

const ActionsSection = ({ language, topic, toggleCreateGroupSection }) => {
  const {
    mutate: translateNotLocalizedGroups,
    isLoading: isLoadingNotLocalizedGroups,
  } = useTranslateNotLocalizedMutation({ topicId: topic.id, language })

  const {
    mutate: deleteInactiveGroups,
    isLoading: isLoadingDeleteInactiveGroups,
  } = useDeleteInactiveGroupsMutation({ topicId: topic.id, language })

  const {
    mutate: validateInactiveGroups,
    isLoading: isLoadingValidateInactiveGroups,
  } = useValidateInactiveGroupsMutation({ topicId: topic.id, language })

  return (
    <Box width={150} transparent>
      <Button
        loading={isLoadingDeleteInactiveGroups}
        type="dashed"
        danger
        size="small"
        onClick={deleteInactiveGroups}
        style={styles.smallButton}>
        Delete Inactive Groups
      </Button>

      <Button
        loading={isLoadingValidateInactiveGroups}
        size="small"
        onClick={validateInactiveGroups}
        style={styles.smallButton}>
        Validate Inactive Groups
      </Button>

      <Button
        loading={isLoadingNotLocalizedGroups}
        size="small"
        onClick={translateNotLocalizedGroups}
        style={styles.tinyButton}>
        Translate Not Localized Groups
      </Button>

      <Button size="small" onClick={toggleCreateGroupSection}>
        Create Group
      </Button>
    </Box>
  )
}

const HeaderEditTab = ({ topic, todo, language, toggleCreateGroupSection }) => {
  if (!topic.id) {
    return <Skeleton />
  }

  return (
    <Box flexDirection="row" transparent justifyContent="space-between">
      <Box flexGrow={1} marginRight={12} transparent>
        <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
          Topic Name
        </Typography.Text>

        <TextInput
          isBold
          keyName="name"
          placeholder="Topic Name"
          topic={topic}
          paddingBottom={8}
        />

        <TopicEntriesAmount language={language} topic={topic} />

        <TextInput
          keyName="description"
          placeholder="Description"
          topic={topic}
          paddingVertical={8}
        />

        <Box transparent flexDirection="row" justifyContent="space-between">
          <TimeFrame topic={topic} />
          <ConnectedInterest topic={topic} />
        </Box>
      </Box>

      <Box
        transparent
        marginRight={12}
        justifyContent="flex-end"
        alignItems="center"
        flexGrow={0.5}>
        <ActionsSection
          topic={topic}
          language={language}
          toggleCreateGroupSection={toggleCreateGroupSection}
        />
      </Box>

      <Box
        transparent
        alignItems="flex-end"
        justifyContent="space-between"
        flexGrow={1}>
        <TodoItem item={todo} />

        <ButtonModalCreateTodo language={language} size="small" topic={topic} />
      </Box>
    </Box>
  )
}

const TopicStats = ({ topic }) => {
  const dispatch = ReactRedux.useDispatch()

  const isRefreshingTopicDetails = ReactRedux.useSelector(
    getIsRefreshingTopicDetails
  )

  const onChangeDataMode = () => {
    dispatch(refreshTopic({ topicId: topic.id }))
  }

  return (
    <Box
      transparent
      flexShrink={0}
      justifyContent="space-between"
      alignItems="center"
      minWidth={277}
      maxWidth={340}
      flexGrow={1}>
      <MedianDataForDataMode
        data={[
          {
            title: 'Median Intro',
            texts: medianTexts,
            values: [
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].totalSent,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].answerRate,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].shareRate,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].p50Length,
            ],
          },
          {
            title: 'Median Deep',
            texts: medianTexts,
            values: [
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].totalSent,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].answerRate,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].shareRate,
              topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].p50Length,
            ],
          },
        ]}
        isLoading={isRefreshingTopicDetails}
        onChange={onChangeDataMode}
        flexShrink={0}
        backgroundColor={theme.colors.antdBackgroundElevated}
      />
    </Box>
  )
}

const HeaderStatsTab = ({ topic, todo, language }) => {
  if (!topic.id) {
    return <Skeleton />
  }

  return (
    <Box flexDirection="row" transparent justifyContent="space-between">
      <Box
        transparent
        marginRight={24}
        flexGrow={1}
        maxWidth={500}
        alignItems="center">
        <TopicStats topic={topic} />
      </Box>

      <GptAnalysisBox topic={topic} language={language} />

      <PerformanceCheckIns
        data={topic.languages}
        topicId={topic.id}
        language={language}
        isLoading={!topic?.id}
      />

      <Box transparent alignItems="flex-end" justifyContent="space-between">
        <TodoItem item={todo} />

        <Box alignItems="flex-end" transparent>
          <ButtonModalCreateTodo topic={topic} language={language} />
        </Box>
      </Box>
    </Box>
  )
}

const config = {
  submitAction: createGroup,
  defaultState: {
    type: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
    depth: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
    name: '',
  },
}

const CreateGroupSection = ({ topic, language }) => {
  const dispatch = ReactRedux.useDispatch()
  const inputRef = React.useRef(null)
  const [state, setState] = React.useState(config.defaultState)

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      topicId: topic.id,
    }))
  }, [topic])

  const onSubmit = () => {
    if (state.name === '') return

    dispatch(config.submitAction(state))

    setState((state) => ({
      ...state,
      ...config.defaultState,
    }))
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit,
    hasActionKey: true,
  })

  const langUpper =
    DETECTABLE_LANGUAGE[langDetectObjectsByType1[language]] ?? ''

  const lang = `${langUpper[0]}${langUpper.substring(1).toLowerCase()}`

  return (
    <Box transparent flexDirection="row" marginTop={12}>
      <Box transparent flexDirection="row" flex={3}>
        <Input.TextArea
          ref={inputRef}
          autoSize={{ minRows: 2, maxRows: 5 }}
          onChange={(e) => {
            setState((state) => ({ ...state, name: e.target.value }))
          }}
          value={state.name}
          placeholder={`Enter a first variance for ${lang}...`}
          style={styleSheets.margin.right[12]}
        />

        <Box transparent marginRight={12} justifyContent="space-between">
          <Box transparent width={200}>
            <Segmented
              block
              value={state.type}
              options={[
                { label: 'Open', value: ARTIFICIAL_TELL_GROUP_TYPES.OPEN },
                { label: 'Simple', value: ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE },
                {
                  label: 'Organic',
                  value: ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
                },
              ]}
              onChange={(type) => {
                setState((state) => ({ ...state, type }))
              }}
              size="small"
              style={styleSheets.margin.bottom[8]}
            />

            <Segmented
              block
              value={state.depth}
              options={[
                { label: 'Intro', value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO },
                { label: 'Deep', value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP },
              ]}
              onChange={(depth) => {
                setState((state) => ({ ...state, depth }))
              }}
              size="small"
            />
          </Box>
        </Box>

        <Box transparent justifyContent="flex-end">
          <Button type="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </Box>

      <Box transparent flex={1} />
    </Box>
  )
}

const Header = ({ topic = {}, todo = {}, language }) => {
  const dispatch = ReactRedux.useDispatch()
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const [isCreateGroupSectionVisible, setIsCreateGroupSectionVisible] =
    React.useState(false)

  const toggleCreateGroupSection = () => {
    setIsCreateGroupSectionVisible(
      (isCreateGroupSectionVisible) => !isCreateGroupSectionVisible
    )
  }

  const SelectedTab = React.useMemo(() => {
    switch (headerMode) {
      case headerModes.EDIT:
        return HeaderEditTab
      case headerModes.STATS:
        return HeaderStatsTab
      default: {
        dispatch(setHeaderMode(headerModes.STATS))

        return () => null
      }
    }
  }, [dispatch, headerMode])

  if (!topic.id) {
    return <Skeleton />
  }

  return (
    <Box
      paddingVertical={12}
      paddingHorizontal={24}
      backgroundColor={theme.colors.antdBackgroundElevated}>
      <BreadcrumbHeader language={language} topic={topic} />

      <Box
        transparent
        minHeight={TAB_HEIGHT}
        maxWidth={1600}
        margin="auto"
        width="100%">
        <SelectedTab
          topic={topic}
          todo={todo}
          language={language}
          toggleCreateGroupSection={toggleCreateGroupSection}
        />
      </Box>

      {isCreateGroupSectionVisible && (
        <CreateGroupSection topic={topic} language={language} />
      )}
    </Box>
  )
}

export const PageArtificialTellsTopicDetails = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const table = hooks.useTableState()

  const topicId = parseInt(match.params.topicId, 10)
  const language = ReactRedux.useSelector(getLanguage)

  const artificialTellsV2 = ReactRedux.useSelector(
    (state) => state.artificialTellsV2
  )

  const allData = artificialTellsV2.topicDetails[topicId]

  React.useEffect(() => {
    updateLocationSearch({ headerMode })
  }, [headerMode])

  React.useEffect(() => {
    dispatch(refreshTopic({ topicId }))
  }, [language])

  return (
    <Box>
      <Header
        language={language}
        topic={allData?.topic}
        todo={allData?.todo}
        groups={allData?.groups}
      />
      <ScrollView>
        <ArtificialTellTopicGroupsTable
          {...table}
          topicId={topicId}
          dataSource={allData?.groups}
          language={language}
          locale={{
            emptyText: allData?.groups ? null : <Skeleton active={true} />,
          }}
        />
      </ScrollView>
    </Box>
  )
}
