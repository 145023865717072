import { message } from 'antd'
import qs from 'qs'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { Modal } from '../components/Modal'
import { history } from '../history'

export { usePageContainerStyle } from './usePageContainerStyle'
export { useTableState } from './useTableState'

/**
 * DO NOT ADD HOOKS BELOW
 *
 * Instead create a new file in this directory and export the hook here.
 */

export const useForceUpdate = () => {
  return useReducer(() => ({}), {})[1]
}

let isCapsOn = false

export const useCapsLockShortcuts = (keyConfig) => {
  const [isCapsLockOn, setIsCapsLockOn] = useState(isCapsOn)

  const handleCaps = (e) => {
    const isOn = e.getModifierState('CapsLock')

    if (isOn !== isCapsOn) {
      message.info(`Keyboard shortcuts are now ${isOn ? 'on' : 'off'}`)

      isCapsOn = isOn
      setIsCapsLockOn(isOn)
    }
  }

  const _onKeyDown = (e) => {
    handleCaps(e)

    const matchedKey = keyConfig.find(({ keyCode }) => keyCode === e.keyCode)

    if (
      typeof matchedKey !== 'undefined' &&
      isCapsOn &&
      Modal.isVisible() === false
    ) {
      matchedKey.onPress?.()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', _onKeyDown)
    document.addEventListener('keyup', handleCaps)

    return () => {
      document.removeEventListener('keydown', _onKeyDown)
      document.removeEventListener('keyup', handleCaps)
    }
  })

  return isCapsLockOn
}

export const useKeyboardShortcutToSubmit = ({
  inputRef,
  onSubmit,
  hasActionKey,
}) => {
  useEffect(() => {
    /**
     * Antd's Input component has a different API than the native input element.
     */
    const input =
      inputRef.current?.input ??
      inputRef.current?.resizableTextArea?.textArea ??
      inputRef.current

    const onKeyDown = (e) =>
      e.keyCode === 13 &&
      (hasActionKey ? e.metaKey || e.ctrlKey : true) &&
      typeof onSubmit === 'function'
        ? onSubmit(e)
        : null

    input?.addEventListener?.('keydown', onKeyDown)

    return () => {
      input?.removeEventListener?.('keydown', onKeyDown)
    }
  }, [inputRef, onSubmit, hasActionKey])

  return null
}

export const useEscapeKey = ({ inputRef, onPress }) => {
  useEffect(() => {
    /**
     * Antd's Input component has a different API than the native input element.
     */
    const input =
      inputRef.current?.input ??
      inputRef.current?.resizableTextArea?.textArea ??
      inputRef.current

    const onKeyDown = (e) =>
      e.keyCode === 27 && typeof onPress === 'function' ? onPress(e) : null

    input?.addEventListener?.('keydown', onKeyDown)

    return () => {
      input?.removeEventListener?.('keydown', onKeyDown)
    }
  }, [inputRef, onPress])

  return null
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useBlur = ({ ref, onBlur }) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('blur', onBlur)
    }
    return () => ref.current && ref.current.removeEventListener('blur', onBlur)
  })
}

/**
 * Hook to get and set query params. To remove a param from the query, set it to empty string.
 *
 * @param {Object} defaults Default query params
 * @returns [params, setQueryParams]
 *
 * @example
 *
 * const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)
 *
 * setQueryParams({foo: "bar"})
 */
export const useQueryParams = (defaults = {}, arrayLimit = 100) => {
  const location = useLocation()
  const initialParams = {
    ...defaults,
    ...qs.parse(location.search.split('?')[1], { arrayLimit }), // https://github.com/ljharb/qs/issues/324
  }
  const [params, setParams] = useState(initialParams)

  const setQueryParams = (object) => {
    const params = qs.parse(
      location.search ? location.search.split('?')[1] : {},
      { arrayLimit }
    )
    const urlParamsString = location.search ? location.search.split('?')[1] : ''

    const mergedParams = {
      ...params,
      ...object,
    }

    const newParams = Object.keys(mergedParams).reduce(
      (acc, curr) =>
        mergedParams[curr] === ''
          ? acc
          : { ...acc, [curr]: mergedParams[curr] },
      {}
    )

    const newParamsString = qs.stringify(newParams)

    const hasQueryChanged = newParamsString !== urlParamsString

    if (hasQueryChanged) {
      history.replace({
        pathname: location.pathname,
        search: newParamsString,
      })
    }
  }

  useEffect(() => {
    setQueryParams(initialParams)
  }, [])

  useEffect(() => {
    const urlParamsString = location.search.split('?')[1]

    if (urlParamsString) {
      setParams(qs.parse(urlParamsString, { arrayLimit }))
    }
  }, [location.search])

  return [params, setQueryParams]
}

export const useDebounceValue = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useTimeout = (callback, delay) => {
  const savedCallback = React.useRef(callback)
  const timeoutRef = React.useRef(null)

  const clear = React.useCallback(() => {
    clearTimeout(timeoutRef.current)
  }, [timeoutRef])

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    return clear
  }, [])

  const set = (callback, newDelay) => {
    clear()

    if (typeof callback === 'function') {
      savedCallback.current = callback
    }

    timeoutRef.current = setTimeout(
      () => {
        if (typeof savedCallback.current === 'function') {
          savedCallback.current()
        }
      },
      typeof newDelay === 'number' ? newDelay : delay
    )
  }

  return { clear, set }
}
