import { countriesToTrack } from '@tellonym/enums/lib/Stats'
import { compose } from 'ramda'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Alert, Button, Input, Spinner, Text, theme, View } from '../../common'
import { rerollExperiments } from '../actions'
import { getIsLoadingRerollExperiments } from '../selectors'

const Section = ({ children, text }) => (
  <View style={{ marginBottom: 24, flex: 1, marginLeft: 16 }}>
    <Text bold style={{ marginBottom: 16 }}>
      {text}
    </Text>
    {children}
  </View>
)

const _PageExperimentsTools = ({ actions, isLoadingReroll }) => {
  const [countryToReroll, setCountryToReroll] = useState('')
  const [isValueSupported, setIsValueSupported] = useState(true)

  useEffect(() => {
    if (!isValueSupported) {
      setIsValueSupported(true)
    }
  }, [countryToReroll])

  const onPressReroll = async () => {
    const isValidCountry = !!countriesToTrack.includes(
      countryToReroll.toUpperCase()
    )

    setIsValueSupported(isValidCountry)

    if (isValidCountry) {
      const response = await Alert.alert(
        `Really rerolling in ${countryToReroll}?`,
        'This will reset the distribution for all users in this country.  Be certain to not influence running experiments.'
      )

      if (response === Alert.CONFIRMED) {
        actions.rerollExperiments({
          countryCode: countryToReroll.toLowerCase(),
        })
      }
    }
  }

  return (
    <View
      style={{
        flex: 1,
        padding: 16,
        backgroundColor: theme.colors.background,
      }}>
      <View>
        <Text bold type="h1" style={{ marginBottom: 24 }}>
          Experiments
        </Text>
        <Section text="Reroll Experiments For Country">
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 8,
            }}>
            <Text style={{ marginRight: 12, width: 120 }}>Country</Text>
            <Input
              onChangeText={setCountryToReroll}
              value={countryToReroll}
              placeholder="required"
              style={{ marginBottom: 0, width: 100 }}
            />
            {!isValueSupported && (
              <Text color={theme.colors.appleRed}>unsupported value!</Text>
            )}
          </View>
          <Button
            onPress={onPressReroll}
            style={{
              alignSelf: 'flex-start',
              marginTop: 12,
              paddingTop: 6,
              paddingBottom: 6,
              width: 100,
            }}>
            {isLoadingReroll ? (
              <Spinner
                size={9}
                border={2}
                color={theme.colors.white}
                style={{ margin: '0 auto' }}
              />
            ) : (
              'Submit'
            )}
          </Button>
        </Section>
      </View>
    </View>
  )
}

const mapStateToProps = createStructuredSelector({
  isLoadingReroll: getIsLoadingRerollExperiments,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ rerollExperiments }, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(withConnect)

export const PageExperimentsTools = enhancer(_PageExperimentsTools)
