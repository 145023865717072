import { ACCOUNT_VERIFICATION_STATUS } from '@tellonym/enums/lib/AccountVerification'
import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { REACTION_TYPE } from '@tellonym/enums/lib/Reactions'
import {
  append,
  assoc,
  assocPath,
  compose,
  identity,
  mergeDeepRight,
  omit,
  pathOr,
  pick,
} from 'ramda'
import * as answerTypes from '../answer/types'
import { CREATE_COMMENT_SUCCESS, REMOVE_COMMENT } from '../comments/types'
import {
  WRITE_COMMUNITY_TELL,
  WRITE_COMMUNITY_TELL_ERROR,
  WRITE_COMMUNITY_TELL_SUBMISSION_SUCCESS,
  WRITE_COMMUNITY_TELL_SUCCESS,
} from '../communities/types'
import { events } from '../events'
import { FOLLOW_SUCCESS, UNFOLLOW_SUCCESS } from '../followers/types'
import {
  getModifiedLikesValues,
  hideItemInList,
  limitNormalizedData,
  normalize,
} from '../helpers'
import { PIN_POST, REMOVE_POST, UNPIN_POST } from '../posts/types'
import { REPORT } from '../reporting/types'
import { REFRESH_ACTIVE_RT_POST_SUCCESS } from '../search/types'
import * as _ from '../underscore'
import {
  LOGIN_LEGACY_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_WITH_APPLE_SUCCESS,
  LOGIN_WITH_GOOGLE_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_WITH_APPLE_SUCCESS,
  REGISTER_WITH_GOOGLE_SUCCESS,
} from '../user/types'
import * as t from './types'

export { completeProfileSliderHiddenType, name } from './constants'

export const initialState = {
  _rerenderItem: {
    ids: [],
    count: 0,
  },
  accountVerificationSelfieFileName: undefined,
  activeLoginMethod: undefined,
  activeRtPost: undefined,
  answers: { ids: [], data: {} },
  captcha: undefined,
  config: {},
  completeProfileSliderHiddenStatus: {
    isHidden: false,
    hiddenBy: undefined, // 'user' | 'session'
    updatedAt: undefined,
  },
  verificationBannerStatus: {
    isVisible: false,
    lastSeenAt: undefined,
  },
  hasFetchFailed: false,
  hasMore: false,
  hasRefreshed: false,
  hasDisabledNotificationsDuringSession: false,
  hasUploadingAccountVerificationSelfieError: false,
  isCheckingSafetyCode: false,
  isCompleteProfileSliderComplete: false,
  isCorrectPassword: true,
  isFetching: false,
  isMaintenanceMode: false,
  isRefreshing: false,
  isSaving: false,
  isSavingEmail: false,
  isSavingPassword: false,
  isSettingSafetyCode: false,
  isSending: false,
  isSavingPhone: false,
  isUploadingAccountVerificationSelfie: false,
  isVerifyingAccount: false,
  pinnedPosts: { ids: [], data: {} },
  profile: {
    createdAt: undefined,
    hasAllowedInterestBasedArTells: true,
    id: undefined,
    isPushNotificationsEnabled: true,
    isPushNotificationsTellEnabled: true,
    isPushNotificationsLikedEnabled: true,
    isPushNotificationsAnswerEnabled: true,
    isPushNotificationsFollowingAnswerEnabled: true,
    isPushNotificationsPublicSubscriptionEnabled: true,
    isPushNotificationsAnonymousSubscriptionEnabled: true,
    purchaserId: undefined,
    pushNotificationToken: undefined,
  },
  refreshedAt: undefined,
  shouldShowMainLanguageSelectionModalDueToAppLanguageChange: false,
  shouldShowCaptcha: false,
  statusEmojis: {
    isRefreshing: false,
    emojis: { ids: [], data: {} },
  },
  tells: {},
}

const persistKeys = [
  'answers',
  'activeLoginMethod',
  'config',
  'info',
  'pinnedPosts',
  'profile',
  'refreshedAt',
  'shouldShowMainLanguageSelectionModalDueToAppLanguageChange',
  'verificationBannerStatus',
]

export const persistence = {
  in: compose(limitNormalizedData('answers'), pick(persistKeys)),
  out: identity,
}

const reducerSafeGuard = (reducer) => (oldState, action) => {
  const state = reducer(oldState, action)

  /**
   * Due to the transformData function in the api middleware,
   * the interests are turned into an object.
   * We can't use the shouldAdjustData flag inside the actions,
   * as some older values rely on the automatic transformation.
   */
  if (
    state.profile.interests &&
    Array.isArray(state.profile.interests) === false
  ) {
    state.profile.interests = state.profile.interests?.data ?? []
  }

  return state
}

export const reducer = reducerSafeGuard((state = initialState, action) => {
  switch (action.type) {
    case t.CHANGE_EMAIL:
    case t.CONNECT_EMAIL:
      return {
        ...state,
        isSavingEmail: true,
      }

    case t.CHANGE_EMAIL_ERROR:
    case t.CONNECT_EMAIL_ERROR:
      return {
        ...state,
        isSavingEmail: false,
      }

    case t.CONNECT_EMAIL_SUCCESS:
      return {
        ...state,
        isSavingEmail: false,
        profile: {
          ...state.profile,
          email: action.meta.email,
          isSecureAccountRecommended: false,
        },
      }

    case t.CONNECT_APPLE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          isAppleConnected: true,
          isSecureAccountRecommended: false,
        },
      }

    case t.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        isSavingEmail: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.CONNECT_GOOGLE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          isGoogleConnected: true,
          isSecureAccountRecommended: false,
        },
      }

    case t.CHANGE_PASSWORD:
      return {
        ...state,
        isCorrectPassword: true,
        isSavingPassword: true,
      }

    case t.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isCorrectPassword: false,
        isSavingPassword: false,
      }

    case t.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSavingPassword: false,
      }

    case t.CHANGE_PUSH_SETTINGS:
      return mergeDeepRight(state, {
        profile: omit(
          ['isPushNotificationsEnabledSystem', 'pushNotificationToken'],
          action.payload
        ),
      })

    case t.CHANGE_PUSH_SETTINGS_SUCCESS:
      return mergeDeepRight(state, {
        profile: {
          /**
           * The server doesn't return any data for this action.
           * We don't set the token optimistically to ensure that
           * the next push token comparison would fail if the request
           * doesn't succeed.
           */
          pushNotificationToken:
            typeof action.meta.pushNotificationToken !== 'undefined'
              ? action.meta.pushNotificationToken
              : state.profile.pushNotificationToken,
        },
      })

    case t.CHANGE_SAFETY_LEVEL:
      return {
        ...state,
        isSaving: true,
      }

    case t.CHANGE_SAFETY_LEVEL_ERROR:
      return {
        ...state,
        isSaving: false,
      }

    case t.CHANGE_SAFETY_LEVEL_SUCCESS:
      return {
        ...state,
        isSaving: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.CHANGE_STATUS_EMOJI:
      return {
        ...state,
        isSaving: true,
        profile: { ...state.profile, statusEmoji: action.payload.statusEmoji },
      }

    case t.CHANGE_SETTINGS:
      return {
        ...state,
        isSaving: true,
      }

    case t.CHANGE_SETTINGS_ERROR:
    case t.CHANGE_STATUS_EMOJI_ERROR:
      return {
        ...state,
        isSaving: false,
      }

    case t.CHANGE_APP_TRANSLATION_SUCCESS:
    case t.CHANGE_SETTINGS_SUCCESS:
    case t.CHANGE_STATUS_EMOJI_SUCCESS:
      return {
        ...state,
        isSaving: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
        shouldShowMainLanguageSelectionModalDueToAppLanguageChange:
          action.type === t.CHANGE_APP_TRANSLATION_SUCCESS ||
          state.shouldShowMainLanguageSelectionModalDueToAppLanguageChange,
      }

    case t.CHANGE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case t.CHANGE_TELL:
      return {
        ...state,
        tells: {
          ...state.tells,
          [action.payload.userId]: action.payload.tell,
        },
      }

    case t.CHECK_SAFETY_CODE:
      return {
        ...state,
        isCheckingSafetyCode: true,
      }

    case t.CHECK_SAFETY_CODE_ERROR:
    case t.CHECK_SAFETY_CODE_SUCCESS:
      return {
        ...state,
        isCheckingSafetyCode: false,
      }

    case t.DISCONNECT_INSTAGRAM_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          instagramId: null,
        },
      }

    case t.EDIT_INTERESTS:
      return mergeDeepRight(state, {
        profile: {
          interests: Array.from(
            new Set(
              state.profile.interests
                .filter((interest) =>
                  action.payload.interestsToRemove.every(
                    (interestToRemove) => interest.id !== interestToRemove.id
                  )
                )
                .concat(action.payload.interestsToAdd)
            )
          ),
        },
      })

    case t.EDIT_INTERESTS_ERROR:
      return mergeDeepRight(state, {
        profile: {
          interests: Array.from(
            new Set(
              state.profile.interests
                .filter((interest) =>
                  action.meta.interestsToAdd.every(
                    (interestToRemove) => interest.id !== interestToRemove.id
                  )
                )
                .concat(action.meta.interestsToRemove)
            )
          ),
        },
      })

    case t.EDIT_INTERESTS_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          interests: action.payload.interests,
        },
      }

    case t.FETCH_ANSWERS:
      return {
        ...state,
        hasFetchFailed: false,
        isFetching: true,
      }

    case t.FETCH_ANSWERS_ERROR:
      return {
        ...state,
        hasFetchFailed: true,
        isFetching: false,
      }

    case t.FETCH_ANSWERS_SUCCESS: {
      const { data, hasMore } = action.payload.answers
      const normalized = normalize(data, {
        ...action.meta,
        idsLength: state.answers.ids.length,
      })
      const ids = [...new Set([...state.answers.ids, ...normalized.ids])]
      return {
        ...state,
        hasMore: hasMore && ids.length > state.answers.ids.length,
        isFetching: false,
        answers: {
          ids,
          data: {
            ...state.answers.data,
            ...normalized.data,
          },
        },
      }
    }

    case t.INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS: {
      const { _emojiId: emojiId, value = 1 } = action.payload

      const isStandardEmoji = pathOr(
        false,
        ['statusEmojis', 'emojis', 'data', emojiId],
        state
      )

      if (isStandardEmoji) {
        const incrementedCurrent =
          pathOr(
            0,
            ['statusEmojis', 'emojis', 'data', emojiId, 'current'],
            state
          ) + value

        return mergeDeepRight(state, {
          statusEmojis: {
            isRefreshing: true,
            emojis: { data: { [emojiId]: { current: incrementedCurrent } } },
          },
        })
      }

      return mergeDeepRight(state, {
        statusEmojis: {
          isRefreshing: true,
        },
      })
    }

    case answerTypes.LIKE_ANSWER:
    case answerTypes.UNLIKE_ANSWER: {
      const { answerId } = action.payload

      const isListElement = state.answers?.data?.[answerId]

      const isPinned = state.pinnedPosts?.ids?.[0] === answerId

      const isActiveRtPost = state.activeRtPost?.id === answerId

      if (!isListElement && !isPinned && !isActiveRtPost) {
        return state
      }

      const item = isListElement
        ? state.answers.data[answerId]
        : isPinned
        ? state.pinnedPosts.data[answerId]
        : state.activeRtPost

      const reactionType = action.payload.type ?? REACTION_TYPE.HEART

      const {
        anonGivenReactions,
        previewReactions,
        reactionCounts,
        watchingUserGivenReactions,
      } = getModifiedLikesValues({
        isLikeAction: action.type === answerTypes.LIKE_ANSWER,
        item,
        reactionType,
      })

      return mergeDeepRight(state, {
        _rerenderItem: {
          ids: [answerId],
          count: state._rerenderItem.count + 1,
        },
        activeRtPost: isActiveRtPost
          ? {
              likes: {
                anonGivenReactions,
                previewReactions,
                reactionCounts,
                watchingUserGivenReactions,
              },
            }
          : state.activeRtPost,
        answers: isListElement
          ? {
              data: {
                [answerId]: {
                  likes: {
                    anonGivenReactions,
                    reactionCounts,
                    previewReactions,
                    watchingUserGivenReactions,
                  },
                },
              },
            }
          : state.answers,
        pinnedPosts: isPinned
          ? {
              data: {
                [answerId]: {
                  likes: {
                    anonGivenReactions,
                    reactionCounts,
                    previewReactions,
                    watchingUserGivenReactions,
                  },
                },
              },
            }
          : state.pinnedPosts,
      })
    }

    case CREATE_COMMENT_SUCCESS: {
      const { postId } = action.meta.payload

      const isListElement = typeof state.answers?.data?.[postId] !== 'undefined'

      const isPinned = state.pinnedPosts?.ids?.[0] === postId

      if (!isListElement && !isPinned) {
        return state
      }

      return mergeDeepRight(state, {
        _rerenderItem: {
          ids: [postId],
          count: state._rerenderItem.count + 1,
        },

        answers: isListElement
          ? {
              data: {
                [postId]: {
                  comments: {
                    amount:
                      (state.answers.data[postId].comments?.amount ?? 0) + 1,
                    previews: append(
                      action.payload,
                      state.answers.data[postId].comments?.previews
                    ),
                  },
                },
              },
            }
          : state.answers,
        pinnedPosts: isPinned
          ? {
              data: {
                [postId]: {
                  comments: {
                    amount:
                      (state.pinnedPosts.data[postId].comments?.amount ?? 0) +
                      1,
                    previews: append(
                      action.payload,
                      state.pinnedPosts.data[postId].comments?.previews
                    ),
                  },
                },
              },
            }
          : state.pinnedPosts,
      })
    }

    case REMOVE_COMMENT: {
      const { postId, commentId } = action.meta

      const isListElement = typeof state.answers?.data?.[postId] !== 'undefined'

      const isPinned = state.pinnedPosts?.ids?.[0] === postId

      if (!isListElement && !isPinned) {
        return state
      }

      const previews = hideItemInList({
        data: isListElement
          ? state.answers.data[postId].comments?.previews
          : state.pinnedPosts.data[postId].comments?.previews,
        id: commentId,
      })

      const amount = Math.max(
        ((isListElement
          ? state.answers.data[postId].comments?.amount
          : state.pinnedPosts.data[postId].comments?.amount) ?? 0) - 1,
        0
      )

      return mergeDeepRight(state, {
        _rerenderItem: {
          ids: [postId],
          count: state._rerenderItem.count + 1,
        },
        answers: isListElement
          ? {
              data: {
                [postId]: {
                  comments: {
                    amount,
                    previews,
                  },
                },
              },
            }
          : state.answers,
        pinnedPosts: isPinned
          ? {
              data: {
                [postId]: {
                  comments: {
                    amount,
                    previews,
                  },
                },
              },
            }
          : state.pinnedPosts,
      })
    }

    case PIN_POST:
    case UNPIN_POST: {
      const { postId } = action.payload

      const isListElement =
        state.answers && state.answers.data && state.answers.data[postId]

      const isPinned = action.type === UNPIN_POST

      if (!isListElement && !isPinned) {
        return state
      }

      return {
        ...state,
        pinnedPosts: {
          ids: isPinned ? [] : [postId],
          data: isPinned ? {} : { [postId]: state.answers.data[postId] },
        },
      }
    }

    case answerTypes.REMOVE_SENDER_STATUS:
    case answerTypes.REMOVE_SENDER_STATUS_SUCCESS: {
      const answerId = action.payload.answerId || action.meta.answerId

      const isListElement =
        state.answers && state.answers.data && state.answers.data[answerId]

      const isPinned =
        state.pinnedPosts &&
        state.pinnedPosts.ids &&
        state.pinnedPosts.ids.length &&
        state.pinnedPosts.ids[0] === answerId

      if (!isListElement && !isPinned) {
        return state
      }

      return {
        ...state,
        _rerenderItem: {
          ids: [answerId],
          count: state._rerenderItem.count + 1,
        },
        answers: isListElement
          ? {
              ...state.answers,
              data: {
                ...state.answers.data,
                [answerId]: {
                  ...state.answers.data[answerId],
                  isCurrentUserTellSender: false,
                },
              },
            }
          : state.answers,
        pinnedPosts: isPinned
          ? {
              ...state.pinnedPosts,
              data: {
                ...state.pinnedPosts.data,
                [answerId]: {
                  ...state.pinnedPosts.data[answerId],
                  isCurrentUserTellSender: false,
                },
              },
            }
          : state.pinnedPosts,
      }
    }

    case REFRESH_ACTIVE_RT_POST_SUCCESS: {
      const { post } = action.payload

      const userId = action.meta.userId ?? post?.userId

      const isCurrentUser = userId === state.profile.id

      if (isCurrentUser) {
        return {
          ...state,
          activeRtPost: post,
        }
      }

      return state
    }

    case t.REFRESH:
    case t.REFRESH_ACHIEVABLE_EMOJIS:
    case t.REFRESH_ANSWERS:
      return {
        ...state,
        isRefreshing: true,
      }

    case t.REFRESH_ERROR:
    case t.REFRESH_ACHIEVABLE_EMOJIS_ERROR:
    case t.REFRESH_ANSWERS_ERROR:
      return {
        ...state,
        isRefreshing: false,
      }

    case t.REFRESH_SUCCESS: {
      const {
        answers = {},
        config,
        info,
        pinnedPosts = {},
        /**
         * Deprecated.
         * TODO: remove when backend removed ai voices code.
         */
        voiceMessage, // eslint-disable-line no-unused-vars
        ...profile
      } = action.payload

      return {
        ...state,
        hasMore: answers.hasMore || false,
        hasRefreshed: true,
        isRefreshing: false,
        answers: normalize(answers.data, action.meta),
        config,
        info,
        pinnedPosts: normalize(pinnedPosts.data),
        profile: {
          ...state.profile,
          ...profile,
        },
        refreshedAt: action.meta.createdAt,
      }
    }

    case t.REFRESH_ACHIEVABLE_EMOJIS_SUCCESS: {
      const { data: achievableEmojis } = action.payload.achievableEmojis

      return {
        ...state,
        isRefreshing: false,
        profile: { ...state.profile, achievableEmojis },
      }
    }

    case t.REFRESH_ANSWERS_SUCCESS: {
      const { answers = {} } = action.payload
      return {
        ...state,
        hasMore: answers.hasMore || false,
        hasRefreshed: true,
        isRefreshing: false,
        answers: normalize(answers.data, action.meta),
      }
    }

    case t.REFRESH_SETTINGS_SUCCESS:
      return {
        ...state,
        isRefreshing: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.REFRESH_STATUS_EMOJIS:
      return assocPath(['statusEmojis', 'isRefreshing'], true, state)

    case t.REFRESH_STATUS_EMOJIS_ERROR:
    case t.INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_ERROR:
    case t.INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_SUCCESS:
      return assocPath(['statusEmojis', 'isRefreshing'], false, state)

    case t.REFRESH_STATUS_EMOJIS_SUCCESS: {
      const { data: emojis } = action.payload.emojis || {}

      const normalized = normalize(emojis)

      return {
        ...state,
        statusEmojis: {
          ...state.statusEmojis,
          isRefreshing: false,
          emojis: normalized,
        },
      }
    }

    case REMOVE_POST:
    case answerTypes.REMOVE_ANSWER: {
      const { answerId, postId } = action.payload

      const id = action.type === REMOVE_POST ? postId : answerId

      const isListElement =
        state.answers && state.answers.data && state.answers.data[id]

      const isPinned =
        state.pinnedPosts &&
        state.pinnedPosts.ids &&
        state.pinnedPosts.ids.length &&
        state.pinnedPosts.ids[0] === id

      if (!isListElement && !isPinned) {
        return state
      }

      return {
        ...state,
        _rerenderItem: {
          ids: [id],
          count: state._rerenderItem.count + 1,
        },
        activeRtPost:
          action.type === REMOVE_POST && state.activeRtPost?.id === postId
            ? undefined
            : state.activeRtPost,
        answers: isListElement
          ? {
              ...state.answers,
              data: {
                ...state.answers.data,
                [id]: {
                  ...state.answers.data[id],
                  _isHidden: true,
                },
              },
            }
          : state.answers,
        pinnedPosts: isPinned
          ? {
              ids: [],
              data: {},
            }
          : state.pinnedPosts,
      }
    }

    case t.CHANGE_AVATAR_POSITION:
    case t.CHANGE_AVATAR_POSITION_ERROR: {
      const { currentPos, targetPos } =
        action.type === t.CHANGE_AVATAR_POSITION
          ? action.payload
          : action.meta.payload

      const data = state.profile.avatars.data.map((avatar) => {
        if (avatar.position === currentPos) {
          return { ...avatar, position: targetPos }
        }

        if (avatar.position === targetPos) {
          return { ...avatar, position: currentPos }
        }

        return avatar
      })

      return {
        ...state,
        profile: {
          ...state.profile,
          avatars: { ...state.profile.avatars, data },
        },
      }
    }

    case t.REMOVE_AVATAR: {
      const { avatarFileName } = action.payload

      if (avatarFileName !== undefined && state.profile.avatars?.data?.length) {
        const avatarsData = state.profile.avatars.data.filter(
          (avatar) =>
            avatar?.avatarFileName && avatar.avatarFileName !== avatarFileName
        )

        return {
          ...state,
          profile: {
            ...state.profile,
            ...(avatarFileName === state.profile.avatarFileName && {
              avatarFileName: null,
            }),
            avatars: {
              ...state.profile.avatars,
              data: avatarsData,
            },
          },
        }
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          avatarFileName: null,
        },
      }
    }

    case t.REMOVE_SAFETY_CODE:
    case t.SEND_SAFETY_CODE_MAIL:
      return {
        ...state,
        isCheckingSafetyCode: true,
      }

    case t.REMOVE_SAFETY_CODE_ERROR:
    case t.SEND_SAFETY_CODE_MAIL_ERROR:
      return {
        ...state,
        isCheckingSafetyCode: false,
      }

    case t.REMOVE_SAFETY_CODE_SUCCESS:
      return {
        ...state,
        isCheckingSafetyCode: false,
        profile: {
          ...state.profile,
          isSafetyCodeSet: false,
        },
      }

    case t.SEND_SAFETY_CODE_MAIL_SUCCESS:
      return {
        ...state,
        isCheckingSafetyCode: false,
      }

    case t.SET_SAFETY_CODE:
      return {
        ...state,
        isSettingSafetyCode: true,
      }

    case t.SET_PHONE:
      return {
        ...state,
        isSavingPhone: true,
      }

    case t.SET_PHONE_ERROR:
      return {
        ...state,
        isSavingPhone: false,
      }

    case t.SET_PHONE_SUCCESS:
      return {
        ...state,
        isSavingPhone: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.SET_SAFETY_CODE_ERROR:
      return {
        ...state,
        isSettingSafetyCode: false,
      }

    case t.SET_SAFETY_CODE_SUCCESS:
      return {
        ...state,
        isSettingSafetyCode: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.SET_STATUS_EMOJI_IS_OWNED: {
      const { emojiId, isOwned } = action.payload

      const updatedEmojis = assocPath(
        ['data', emojiId, 'isOwned'],
        isOwned,
        state.statusEmojis.emojis
      )

      return {
        ...state,
        statusEmojis: {
          ...state.statusEmojis,
          emojis: updatedEmojis,
        },
      }
    }

    case t.TOGGLE_PUSH_NOTIFICATION:
      return {
        ...state,
        hasDisabledNotificationsDuringSession:
          typeof action.payload.isPushNotificationsEnabled !== 'undefined'
            ? action.payload.isPushNotificationsEnabled === false
            : state.hasDisabledNotificationsDuringSession,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.UNLOCK_STATUS_EMOJI: {
      const { id: emojiId } = action.payload

      const updatedEmojis = assocPath(
        ['data', emojiId, 'isOwned'],
        true,
        state.statusEmojis.emojis
      )

      return {
        ...state,
        statusEmojis: {
          ...state.statusEmojis,
          emojis: updatedEmojis,
        },
      }
    }

    case WRITE_COMMUNITY_TELL:
    case t.WRITE_TELL:
      return {
        ...state,
        hasSendingFailed: false,
        isSending: true,
      }

    case WRITE_COMMUNITY_TELL_ERROR:
    case t.WRITE_TELL_ERROR:
      return {
        ...state,
        hasSendingFailed: true,
        captcha: undefined,
        isSending: false,
      }

    case WRITE_COMMUNITY_TELL_SUCCESS:
    case WRITE_COMMUNITY_TELL_SUBMISSION_SUCCESS:
      return {
        ...state,
        isSending: false,
        tells: {
          ...state.tells,
          [action.meta.communityId]: undefined,
        },
      }

    case t.WRITE_TELL_SUCCESS: {
      const update = {
        isSending: false,
      }

      if (action.payload.code === API_CODE.CAPTCHA_REQUIRED) {
        update.captcha = undefined
        update.shouldShowCaptcha = true
        return mergeDeepRight(state, update)
      }

      if (state.shouldShowCaptcha) {
        update.captcha = undefined
        update.shouldShowCaptcha = false
      }

      update.tells = {
        [action.meta.userId]: undefined,
      }

      const isCurrentUser = action.meta.userId === state.profile.id

      if (isCurrentUser) {
        update.profile = {
          tellCount: state.profile.tellCount + 1,
        }
      }

      return mergeDeepRight(state, update)
    }

    case FOLLOW_SUCCESS:
    case UNFOLLOW_SUCCESS: {
      const { isFollowingAnonymous } = action.meta

      if (isFollowingAnonymous) {
        return state
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          followingCount:
            action.type === FOLLOW_SUCCESS
              ? state.profile.followingCount + 1
              : state.profile.followingCount > 0
              ? state.profile.followingCount - 1
              : state.profile.followingCount,
        },
      }
    }

    case REPORT: {
      const { postId, tellId } = action.payload

      const id = postId || tellId

      if (state.answers && state.answers.data && state.answers.data[id]) {
        return {
          ...state,
          _rerenderItem: {
            ids: [id],
            count: state._rerenderItem.count + 1,
          },
          answers: {
            ...state.answers,
            data: {
              ...state.answers.data,
              [id]: {
                ...state.answers.data[id],
                _isHidden: true,
              },
            },
          },
        }
      }
      return state
    }

    case LOGIN_LEGACY_SUCCESS:
      return {
        ...state,
        activeLoginMethod: action.meta.service ?? action.meta.method,
      }

    case LOGIN_SUCCESS:
    case LOGIN_WITH_APPLE_SUCCESS:
    case LOGIN_WITH_GOOGLE_SUCCESS:
    case REGISTER_SUCCESS:
    case REGISTER_WITH_APPLE_SUCCESS:
    case REGISTER_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        activeLoginMethod: action.meta.service ?? action.meta.method,
        profile: {
          ...state.profile,
          createdAt: action.payload.createdAt,
          id: action.payload.userId,
          lang: action.payload.lang,
          location: action.payload.location,
          purchaserId: action.payload.purchaserId ?? state.profile.purchaserId,
          username: action.payload.username,
        },
      }

    case t.UPLOAD_AVATAR:
      return assoc('isSaving', true, state)

    case t.SAVE_AVATAR_ERROR:
    case t.UPLOAD_AVATAR_ERROR:
      return assoc('isSaving', false, state)

    case t.SAVE_AVATAR_SUCCESS:
      return {
        ...state,
        isSaving: false,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }

    case t.SET_COMPLETE_PROFILE_SLIDER_COMPLETE:
      return {
        ...state,
        isCompleteProfileSliderComplete: action.payload,
      }

    case t.SET_COMPLETE_PROFILE_SLIDER_HIDE:
      return {
        ...state,
        completeProfileSliderHiddenStatus: {
          isHidden: action.payload.isHidden ?? false,
          hiddenBy: action.payload.hiddenBy,
          updatedAt: action.meta.createdAt,
        },
      }

    case t.UPLOAD_ACCOUNT_VERIFICATION_SELFIE:
      return {
        ...state,
        accountVerificationSelfieFileName: undefined,
        hasUploadingAccountVerificationSelfieError: false,
        isUploadingAccountVerificationSelfie: true,
      }

    case t.UPLOAD_ACCOUNT_VERIFICATION_SELFIE_ERROR:
      return {
        ...state,
        hasUploadingAccountVerificationSelfieError: true,
        isUploadingAccountVerificationSelfie: false,
      }

    case t.UPLOAD_ACCOUNT_VERIFICATION_SELFIE_SUCCESS:
      return {
        ...state,
        accountVerificationSelfieFileName: action.payload.data.imageId,
        isUploadingAccountVerificationSelfie: false,
      }

    case t.VERIFY_ACCOUNT:
      return {
        ...state,
        accountVerificationSelfieFileName: undefined,
        isVerifyingAccount: true,
      }

    case t.VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        hasUploadingAccountVerificationSelfieError: true,
        isVerifyingAccount: false,
      }

    case t.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isVerifyingAccount: false,
        profile: {
          ...state.profile,
          isVerified: true,
          verificationStatus: ACCOUNT_VERIFICATION_STATUS.SOFT_APPROVED,
          verifiedSince: action.meta.createdAt,
        },
      }

    case t.SET_VERIFICATION_BANNER_STATUS:
      return mergeDeepRight(state, {
        verificationBannerStatus: {
          isVisible:
            action.payload.isVisible ??
            state.verificationBannerStatus.isVisible,
          lastSeenAt: action.payload.isSeen
            ? action.meta.createdAt
            : state.verificationBannerStatus.lastSeenAt,
        },
      })

    case events.MODAL_OPEN:
      if (action.payload.id !== _.ModalMainLanguageSelection) {
        return state
      }

      return {
        ...state,
        shouldShowMainLanguageSelectionModalDueToAppLanguageChange: false,
      }

    case t.SET_IS_MAINTENANCE_MODE:
      return {
        ...state,
        isMaintenanceMode: action.payload,
      }

    default:
      return state
  }
})
