import { refreshSettings } from '@tellonym/core/profile/actions'
import { safeEffects } from '@tellonym/core/sagas'
import * as t from '@tellonym/core/user/types'
import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { put } from 'redux-saga/effects'

const loginSuccess = function* (action) {
  if (action.payload?.code === API_CODE.CAPTCHA_REQUIRED) {
    return
  }

  yield put(refreshSettings())
}

export const actionHandler = {
  [t.LOGIN_SUCCESS]: [safeEffects.takeLatest, loginSuccess],
}
