import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import React from 'react'
import { connectWithRouter } from '../../common'

class IfAuthenticatedComponent extends React.Component {
  render() {
    const { children, renderElse, isLoggedIn } = this.props
    return isLoggedIn
      ? children
      : typeof renderElse === 'function'
      ? renderElse()
      : null
  }
}

export const IfAuthenticated = connectWithRouter((state) => ({
  isLoggedIn: getIsLoggedIn(state),
}))(IfAuthenticatedComponent)
