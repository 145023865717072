import { grey } from '@ant-design/colors'
import { SearchOutlined } from '@ant-design/icons'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Input, Typography } from 'antd'
import React from 'react'
import { Box, View, hooks, moment, styleSheets } from '../../common'
import { ArtificialTellStatusTag } from './ArtificialTellStatusTag'

const onCellIdObject = {
  style: { paddingLeft: 4, paddingRight: 4 },
}

const statusOrder = {
  [ARTIFICIAL_TELL_STATUS.ACTIVE]: 0,
  [ARTIFICIAL_TELL_STATUS.VALIDATION]: 1,
  [ARTIFICIAL_TELL_STATUS.VALIDATION_QA]: 2,
  [ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING]: 3,
  [ARTIFICIAL_TELL_STATUS.INACTIVE]: 4,
  [ARTIFICIAL_TELL_STATUS.DELETED]: 5,
  [ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED]: 6,
}

export const SharedColumns = {
  id: {
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: 60,
    onCell: () => onCellIdObject,
    sorter: (a, b) => a.id - b.id,
    render: (id, groupOrTopic, index) => (
      <View style={styleSheets.center}>
        <Typography.Text>{index + 1}</Typography.Text>
        <Typography.Text style={{ fontSize: 10, color: grey[1] }}>
          {id}
        </Typography.Text>
      </View>
    ),
  },
  status: {
    title: 'Status',
    dataIndex: 'id',
    key: 'status',
    width: 90,
    render: (id, groupOrTopic) => (
      <ArtificialTellStatusTag status={groupOrTopic.status} />
    ),
    filters: [
      {
        text: 'Active',
        value: ARTIFICIAL_TELL_STATUS.ACTIVE,
      },
      {
        text: 'Validation',
        value: ARTIFICIAL_TELL_STATUS.VALIDATION,
      },
      {
        text: 'Validation QA',
        value: ARTIFICIAL_TELL_STATUS.VALIDATION_QA,
      },
      {
        text: 'Validation QA Checking',
        value: ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING,
      },
      {
        text: 'Inactive',
        value: ARTIFICIAL_TELL_STATUS.INACTIVE,
      },
      {
        text: 'Not Localized',
        value: ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED,
      },
      {
        text: 'Deleted',
        value: ARTIFICIAL_TELL_STATUS.DELETED,
      },
    ],
    filterMultiple: true,
    filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
    defaultFilteredValue: [
      ARTIFICIAL_TELL_STATUS.ACTIVE,
      ARTIFICIAL_TELL_STATUS.INACTIVE,
      ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED,
      ARTIFICIAL_TELL_STATUS.VALIDATION,
      ARTIFICIAL_TELL_STATUS.VALIDATION_QA,
      ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING,
    ],
    onFilter: (value, groupOrTopic) => {
      switch (value) {
        case ARTIFICIAL_TELL_STATUS.ACTIVE:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.ACTIVE

        case ARTIFICIAL_TELL_STATUS.INACTIVE:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.INACTIVE

        case ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED

        case ARTIFICIAL_TELL_STATUS.DELETED:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.DELETED

        case ARTIFICIAL_TELL_STATUS.VALIDATION:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.VALIDATION

        case ARTIFICIAL_TELL_STATUS.VALIDATION_QA:
          return groupOrTopic.status === ARTIFICIAL_TELL_STATUS.VALIDATION_QA

        case ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING:
          return (
            groupOrTopic.status ===
            ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING
          )

        default:
          return true
      }
    },
    defaultSortOrder: 'ascend',
    sorter: (a, b) => {
      const textA = statusOrder[a.status]
      const textB = statusOrder[b.status]

      if (textA < textB) {
        return -1
      }

      if (textA > textB) {
        return 1
      }

      return 0
    },
  },
  lastUpdatedAt: {
    title: 'Last Updated',
    dataIndex: 'id',
    key: 'lastUpdatedAt',
    width: 110,
    render: (id, groupOrTopic) => {
      if (groupOrTopic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
        return null
      }

      const date = groupOrTopic.lastUpdatedAt
        ? moment(groupOrTopic.lastUpdatedAt).format('DD.MM.YY')
        : ''

      return <View style={styleSheets.center}>{date}</View>
    },
    sorter: (a, b) => {
      const timeA = a.lastUpdatedAt
      const timeB = b.lastUpdatedAt

      if (timeA && timeB) {
        return new Date(timeA).getTime() - new Date(timeB).getTime()
      }

      if (!timeA && !timeB) {
        return 0
      }

      if (timeA) {
        return 1
      }

      return -1
    },
  },
}

export const useColumnSearchProps = (dataIndex) => {
  const searchInput = React.useRef(null)

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const onConfirm = () => {
        confirm()
        searchInput.current?.blur()
      }

      hooks.useEscapeKey({
        inputRef: searchInput,
        onPress: close,
      })

      return (
        <Box padding={12} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            allowClear
            placeholder={`Search ${dataIndex}`}
            value={`${selectedKeys[0] || ''}`}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={onConfirm}
            style={{ marginBottom: 8, width: 280 }}
          />

          <Box flexDirection="row" justifyContent="space-between">
            <Button
              type="primary"
              onClick={onConfirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}>
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters?.()
                setTimeout(() => {
                  onConfirm()
                }, 100)
              }}
              size="small"
              style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}>
              close
            </Button>
          </Box>
        </Box>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
  }
}
