import { colors } from '@tellonym/core/common/colorSystem'
import { assoc } from 'ramda'
import React from 'react'
import { Avatar } from './Avatar'
import { Box } from './Box'

/**
 *
 * @param {Object} containerProps any props that should be set on the most outer Box component
 * @param {Function} creatorOnPressAvatar Creator function that is called when an avatar is pressed, should be a func that returns a func, receives avatarFileName as a flat param
 * @param {Array} profile.avatars The avatars that should be rendered
 * @param {Number} size The size of the main avatar, other avatars will be scaled down by 10% automatically
 */
export const AvatarGroup = ({
  containerProps,
  creatorOnPressAvatar,
  size = 110,
  user,
}) => (
  <Box flexDirection="row" alignItems="center" {...containerProps}>
    {typeof user?.avatars !== 'undefined'
      ? [
          user.avatars.find(({ position }) => position === 1),
          user.avatars.find(({ position }) => position === 0),
          user.avatars.find(({ position }) => position === 2),
        ].map((avatar, index) => {
          const isMain = index === 1

          return (
            <Box
              key={avatar?.avatarFileName ?? index}
              style={{
                marginRight: index === 0 ? -12 : 0,
                marginLeft: index === 2 ? -12 : 0,
                zIndex: isMain ? 1 : 0,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onPress={creatorOnPressAvatar?.(avatar?.avatarFileName)}>
              <Avatar
                rectangle
                borderWidth={isMain ? 1 : 0}
                borderColor={colors.black[1]}
                user={assoc('avatarFileName', avatar?.avatarFileName, user)}
                size={size * (isMain ? 1 : 0.9)}
              />
            </Box>
          )
        })
      : null}
  </Box>
)
