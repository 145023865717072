import { useMutation } from '@tanstack/react-query'
import { buildRequest } from '../common/queries'

export const dashboard = {
  all: ['dashboard'],
  contentMeetingSheet: () => [...dashboard.all, 'contentMeetingSheet'],
  productMeetingSheet: () => [...dashboard.all, 'productMeetingSheet'],
}

const fetchContentMeetingSheet = buildRequest({
  path: 'admin/stats/clickhouse/meeting/content',
  method: 'GET',
})

export const useContentMeetingSheetMutation = () => {
  const result = useMutation({
    mutationKey: dashboard.contentMeetingSheet(),
    mutationFn: fetchContentMeetingSheet,
    onSuccess: ({ sheetUrl }) => {
      window.open(sheetUrl, '_blank')
    },
  })

  return result
}

const fetchProductMeetingSheet = buildRequest({
  path: 'admin/stats/clickhouse/meeting',
  method: 'GET',
})

export const useProductMeetingSheetMutation = () => {
  const result = useMutation({
    mutationKey: dashboard.productMeetingSheet(),
    mutationFn: fetchProductMeetingSheet,
    onSuccess: ({ sheetUrl }) => {
      window.open(sheetUrl, '_blank')
    },
  })

  return result
}
