import { colors } from '@tellonym/core/common/colorSystem'
import { Button, Input } from 'antd'
import React from 'react'
import { Box, styleSheets } from '../../common'
import {
  useGptGroupAnalysisResult,
  useGptGroupAnalysisStartMutation,
} from '../queries'

const styles = {
  gptAnalysisTextArea: {
    flex: 1,
    resize: 'none',
    borderColor: colors.grey[4],
  },
}

/**
 * Either topic or group is defined
 */
export const GptAnalysisBox = ({ topic, group, language }) => {
  const interval = React.useRef(null)
  const [isPollingForResult, setIsPollingForResult] = React.useState(false)

  const { isLoading: isLoadingStart, mutate } =
    useGptGroupAnalysisStartMutation({
      groupId: group?.id,
      topicId: topic?.id,
      language,
      onSuccess: () => {
        setIsPollingForResult(true)
      },
    })

  const {
    data,
    isLoading: isLoadingResult,
    refetch,
  } = useGptGroupAnalysisResult({
    groupId: group?.id,
    topicId: topic?.id,
    language,
  })

  const gptAnalysisString = React.useMemo(() => {
    if (typeof data?.summary !== 'string' && data?.summary !== '') {
      return ''
    }

    return `${data.summary}\n\nIncluded answers: ${data.includedAnswers}`
  }, [data])

  React.useEffect(() => {
    if (isPollingForResult && !interval.current) {
      interval.current = setInterval(() => {
        if (data?.summary !== 'string') {
          refetch()
        } else {
          // safeguard but this does not seem to be executed, he uses the cleanup function bc data?.summary changes
          setIsPollingForResult(false)

          clearInterval(interval.current)
        }
      }, 5000)

      return () => {
        setIsPollingForResult(false)
        clearInterval(interval.current)
      }
    } else if (
      isPollingForResult &&
      interval.current &&
      data?.summary === 'string'
    ) {
      setIsPollingForResult(false)
      clearInterval(interval.current)
    }
  }, [data?.summary, isPollingForResult, refetch])

  return (
    <Box
      minWidth={240}
      maxWidth={500}
      flexGrow={1}
      transparent
      justifyContent="space-between"
      marginRight={12}>
      <Input.TextArea
        style={styles.gptAnalysisTextArea}
        value={gptAnalysisString}
        placeholder="Click 'GPT analysis' to let ChatGPT analyze this groups performance based on answers that users created..."
      />

      <Box transparent alignItems="flex-end">
        <Button
          size="small"
          onClick={mutate}
          loading={isLoadingStart || isLoadingResult || isPollingForResult}
          style={styleSheets.margin.top[8]}>
          GPT analysis
        </Button>
      </Box>
    </Box>
  )
}
