import * as t from '@tellonym/core/profile/types'
import { safeEffects } from '@tellonym/core/sagas'
import { history } from '../common'

const logoutSuccess = function* () {
  yield history.push('/')
}

export const actionHandler = {
  [t.LOGOUT_SUCCESS]: [safeEffects.takeLatest, logoutSuccess],
}
