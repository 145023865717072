export const REPORT = 'reporting/REPORT'
export const REPORT_ERROR = 'reporting/REPORT_ERROR'
export const REPORT_SUCCESS = 'reporting/REPORT_SUCCESS'

export const REPORT_NETZDG = 'reporting/REPORT_NETZDG'
export const REPORT_NETZDG_ERROR = 'reporting/REPORT_NETZDG_ERROR'
export const REPORT_NETZDG_SUCCESS = 'reporting/REPORT_NETZDG_SUCCESS'

export const REPORT_SPAM = 'reporting/REPORT_SPAM'
export const REPORT_SPAM_ERROR = 'reporting/REPORT_SPAM_ERROR'
export const REPORT_SPAM_SUCCESS = 'reporting/REPORT_SPAM_SUCCESS'
