import Radium from 'radium'

const fadeInKeyframes = Radium.keyframes(
  {
    '0%': {
      opacity: 0,
      visibility: 'hidden',
    },
    '100%': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  'fadeIn'
)

const fadeOutKeyframes = Radium.keyframes(
  {
    '0%': {
      opacity: 1,
      visibility: 'visible',
    },
    '100%': {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  'fadeOut'
)

const fadeIn = (payload = { duration: 300 }) => ({
  animation: `x ${payload.duration}ms ease`,
  animationFillMode: 'forwards',
  animationName: fadeInKeyframes,
})

const fadeOut = (payload = { duration: 300 }) => ({
  animation: `x ${payload.duration}ms ease`,
  animationName: fadeOutKeyframes,
})

export const fade = {
  in: fadeIn,
  out: fadeOut,
}
