import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import React from 'react'
import { colors } from '../../common'
import { TagBox } from '../../common/components/TagBox'

export const ArtificialTellStatusTag = ({ status }) => {
  return (
    <TagBox
      backgroundColor={
        {
          [ARTIFICIAL_TELL_STATUS.ACTIVE]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.ACTIVE],
          [ARTIFICIAL_TELL_STATUS.INACTIVE]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.INACTIVE],
          [ARTIFICIAL_TELL_STATUS.DELETED]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.DELETED],
          [ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED],
          [ARTIFICIAL_TELL_STATUS.VALIDATION]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.VALIDATION],
          [ARTIFICIAL_TELL_STATUS.VALIDATION_QA]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.VALIDATION_QA],
          [ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING]:
            colors.artTellStatus[ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING],
        }[status]
      }
      text={
        {
          [ARTIFICIAL_TELL_STATUS.ACTIVE]: 'Active',
          [ARTIFICIAL_TELL_STATUS.INACTIVE]: 'Inactive',
          [ARTIFICIAL_TELL_STATUS.DELETED]: 'Deleted',
          [ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED]: 'Not Localized',
          [ARTIFICIAL_TELL_STATUS.VALIDATION]: 'Validation',
          [ARTIFICIAL_TELL_STATUS.VALIDATION_QA]: 'Validation QA',
          [ARTIFICIAL_TELL_STATUS.VALIDATION_QA_CHECKING]: 'Valid. QA Check',
        }[status]
      }
    />
  )
}
