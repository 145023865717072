export const fontSizes = {
  default: 16,
  nano: 8,
  micro: 10,
  note: 12,
  small: 14,
  h1: 27,
  h2: 24,
  h3: 21,
  h4: 19,
  undefined: 16,
}

export const desktopFontSizes = {
  default: 18,
  nano: 8,
  micro: 10,
  note: 12,
  small: 14,
  h1: 48,
  h2: 36,
  h3: 21,
  h4: 19,
  undefined: 18,
}
