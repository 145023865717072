import React from 'react'
import { theme } from '../styles/theme'
import { LoadingIndicator } from './LoadingIndicator'
import { View } from './View'

export const ContentContainer = ({
  children,
  isRefreshing,
  style,
  ...props
}) => (
  <View
    {...props}
    style={[
      {
        flex: 1,
        paddingHorizontal: 12,
        backgroundColor: theme.colors.white,
        position: 'relative',
        height: '100%',
      },
      style,
    ]}>
    {typeof isRefreshing === 'boolean' && (
      <LoadingIndicator isLoading={isRefreshing} />
    )}
    {children}
  </View>
)
