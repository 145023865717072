import P from 'prop-types'
import { compose, isNil } from 'ramda'
import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getIsSidemenuShown } from '../../app/selectors'
import { FlatList, history, Text, theme, View } from '../../common'
import { Header } from '../../navigation/components/Header'
import { addBlacklistEntry, refreshBlacklists } from '../actions'
import { Blacklists as selector } from '../selectors'
import { AddNewBlacklistEntry } from './AddNewBlacklistEntry'
import { BlacklistItem } from './BlacklistItem'

const Tabs = {
  ABOUT_ME: 'aboutMe',
  TELL: 'tell',
  SOCIAL_LINK: 'socialLink',
}

const TabChooser = ({ onPressTab, tab }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      paddingVertical: 12,
      maxWidth: 600,
      alignSelf: 'center',
      width: '100%',
    }}>
    <Text
      center
      color={tab !== Tabs.ABOUT_ME ? theme.colors.placeholder : undefined}
      onPress={() => onPressTab(Tabs.ABOUT_ME)}
      style={{ flex: 1, onHover: { color: theme.colors.primary } }}>
      About Me
    </Text>
    <Text
      center
      color={tab !== Tabs.TELL ? theme.colors.placeholder : undefined}
      onPress={() => onPressTab(Tabs.TELL)}
      style={{ flex: 1, onHover: { color: theme.colors.primary } }}>
      Tell
    </Text>
    <Text
      center
      color={tab !== Tabs.SOCIAL_LINK ? theme.colors.placeholder : undefined}
      onPress={() => onPressTab(Tabs.SOCIAL_LINK)}
      style={{ flex: 1, onHover: { color: theme.colors.primary } }}>
      Social Link
    </Text>
  </View>
)

const TableHead = () => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.colors.borderLightGrey,
      paddingVertical: 8,
    }}>
    <Text center color={theme.colors.placeholder} style={{ flex: 1 }}>
      Delete
    </Text>
    <Text color={theme.colors.placeholder} style={{ flex: 7, marginLeft: 16 }}>
      Value
    </Text>
  </View>
)

const BlacklistHeader = ({ onPressTab, onSubmit, tab }) => (
  <React.Fragment>
    <TabChooser tab={tab} onPressTab={onPressTab} />
    <TableHead tab={tab} />
    <AddNewBlacklistEntry onSubmit={onSubmit} type={tab} />
  </React.Fragment>
)

const _PageBlacklist = ({ actions, match, ...props }) => {
  const { tab } = match.params
  const [params, setParams] = useState()

  useEffect(() => {
    if (isNil(tab) || typeof tab === 'undefined') {
      history.push('/blacklist/tell')
    }
  }, [tab])

  useEffect(() => {
    setParams({ tab })
    actions.refresh({ limit: 200 })
  }, [tab])

  return (
    <FlatList
      actions={{
        refresh: (payload) =>
          actions.refresh({ ...payload, ...params, limit: 200 }),
      }}
      component={BlacklistItem}
      extraData={{
        isRefreshing: props.isRefreshing,
      }}
      extraProps={{
        tab,
      }}
      hasMore={false}
      isRefreshing={props.isRefreshing}
      items={props.data[tab || 'tell']}
      renderHeader={(props) => (
        <BlacklistHeader
          onPressTab={(tab) => history.push(`/blacklist/${tab}`)}
          onSubmit={(props) => actions.addBlacklistEntry(props)}
          tab={tab}
          {...props}
        />
      )}
      style={{
        height: window.tnym.getFullHeight() - (Header.height + 12), // 12 equals padding from ContentContainer
      }}
    />
  )
}

_PageBlacklist.propTypes = {
  actions: P.shape({ refresh: P.func.isRequired }).isRequired,
  data: P.shape({
    aboutMe: P.shape({
      ids: P.arrayOf(P.oneOfType([P.number, P.string])),
      data: P.any,
    }).isRequired,
    socialLink: P.shape({
      ids: P.arrayOf(P.oneOfType([P.number, P.string])),
      data: P.any,
    }).isRequired,
    tell: P.shape({
      ids: P.arrayOf(P.oneOfType([P.number, P.string])),
      data: P.any,
    }).isRequired,
  }).isRequired,
  isRefreshing: P.bool.isRequired,
  isSidemenuShown: P.bool.isRequired,
  match: P.shape({ params: P.shape({ tab: P.string }) }).isRequired,
}

const mapStateToProps = (state) => ({
  isSidemenuShown: getIsSidemenuShown(state),
  ...selector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { addBlacklistEntry, refresh: refreshBlacklists },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(memo, withConnect)

export const PageBlacklist = enhancer(_PageBlacklist)
