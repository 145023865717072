import { colors } from './colors'

export const styles = {
  buttonDisabled: {
    backgroundColor: colors.background,
    borderColor: colors.background,
    color: colors.placeholder,
  },
  shadowInset: 'inset 0px 0px 10px rgba(0,0,0,0.1)',
  shadowInsetVertical:
    'inset 0px 11px 10px -10px rgba(0,0,0,0.1), inset 0px -11px 10px -10px rgba(0,0,0,0.1)',
  shadow: '0px 0px 12px 0px rgba(0,0,0,0.15)',
}
