import { ARTIFICIAL_TELL_QUESTION_DEPTH } from '@tellonym/enums/lib/Tell'
import React from 'react'
import { colors } from '../../common'
import { TagBox } from '../../common/components/TagBox'

export const ArtificialTellDepthTag = ({ depth }) => {
  return (
    <TagBox
      backgroundColor={
        {
          [ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]:
            colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP],
          [ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]:
            colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO],
        }[depth]
      }
      text={
        {
          [ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]: 'Deep',
          [ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]: 'Intro',
        }[depth]
      }
    />
  )
}
