import { parseJson } from '@tellonym/core/api'
import { LOGOUT, LOGOUT_SUCCESS } from '@tellonym/core/profile/types'
import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { Alert } from '../common'

export const errorHandler = async ({
  code = 'NO_CODE',
  dispatch,
  isLoggedIn,
  message = '',
  payload = {},
}) => {
  switch (code) {
    case API_CODE.TOKEN_INVALID: {
      if (isLoggedIn) {
        dispatch({ type: LOGOUT })
        return dispatch({ type: LOGOUT_SUCCESS })
      }
      return undefined
    }

    case API_CODE.NOT_FOUND: {
      if (window.location.pathname.startsWith('/artificialtells')) {
        return undefined
      }
      return Alert.error(message)
    }

    default: {
      const response = await parseJson(payload)

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(code, response)
      }

      return Alert.error(`${code} ${message}`)
    }
  }
}
