import { SPAM_DETECTION_TYPE } from '@tellonym/enums/lib/SpamDetecton'
import { Select } from 'antd'
import React from 'react'
import { Box, FlatList, Text } from '../../common'
import { TimeFramePicker } from '../../statistics/components/TimeFramePicker'
import { snakeToCamelCase } from '../../statistics/services'
import { dateTypes } from '../constants'
import { useSpamQuery } from '../queries'
import { SpamItem } from './SpamItem'

const createOptionsFromEnum = (enumObject) =>
  Object.keys(enumObject).reduce((acc, key) => {
    if (typeof enumObject[key] !== 'string') {
      return acc
    }

    acc.push({
      label: enumObject[key].replace(/_/g, ' ').toLowerCase(),
      value: key,
    })

    return acc
  }, [])

const filterOptions = [
  { label: 'None', value: 'none' },
  ...createOptionsFromEnum(SPAM_DETECTION_TYPE),
]

const dateTypeOptions = Object.keys(dateTypes).map((dateType) => ({
  value: dateType,
  label: snakeToCamelCase(dateType),
}))

const SelectWithLabel = (props) => (
  <Box>
    <Text semibold type="small">
      {props.label}
    </Text>
    <Select style={{ marginRight: 12, width: 200 }} {...props} />
  </Box>
)

const ConfigurationBar = ({
  onChangeDateType,
  onChangeDates,
  onChangeFilterType,
  dateType,
  startDate,
  endDate,
}) => (
  <Box
    flexDirection="row"
    marginBottom={24}
    marginLeft={12}
    alignItems="flex-end">
    <Box marginRight={12}>
      <TimeFramePicker
        defaults={{ dateType, startDate, endDate }}
        dateTypeOptions={dateTypeOptions}
        isLoading={false}
        isIntervalTypeVisible={false}
        onChangeDateType={onChangeDateType}
        onChangeDates={onChangeDates}
        style={{ marginBottom: 0 }}
      />
    </Box>
    <SelectWithLabel
      label="Filter by"
      defaultValue={filterOptions[0]}
      options={filterOptions}
      onChange={onChangeFilterType}
      style={{ width: 350 }}
    />
  </Box>
)

export const PageLastDetectedSpam = () => {
  const [dateType, setDateType] = React.useState(dateTypes.LAST_30_MINUTES)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [filterType, setFilterType] = React.useState(filterOptions[0].value)
  const { data, isFetching } = useSpamQuery({
    filterType,
    dateType,
    startDate,
    endDate,
  })

  const onChangeFilterType = (value) => {
    setFilterType(value)
  }

  const onChangeDateType = (value) => {
    setDateType(value)
  }

  const onChangeDates = (dates) => {
    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  return (
    <FlatList
      component={SpamItem}
      hasMore={false}
      isRefreshing={isFetching}
      items={data}
      renderHeader={() => (
        <ConfigurationBar
          onChangeFilterType={onChangeFilterType}
          dateType={dateType}
          startDate={startDate}
          endDate={endDate}
          onChangeDateType={onChangeDateType}
          onChangeDates={onChangeDates}
        />
      )}
      contentContainerStyle={{ padding: 20 }}
    />
  )
}
