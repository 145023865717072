import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Select } from 'antd'
import { compose } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Icon, Input, theme, View } from '../../common'
import { search } from '../actions'

const searchCategories = {
  EMAIL: 'email',
  IP: 'ip',
  PHONE_NUMBER: 'phonenumber',
  SOCIAL_LINK: 'sociallink',
  USERNAME: 'username',
  USER_ID: 'userid',
  REVENUECAT_ID: 'revenuecatid',
}

const searchCategoriesOrder = [
  searchCategories.USERNAME,
  searchCategories.USER_ID,
  searchCategories.SOCIAL_LINK,
  searchCategories.EMAIL,
  searchCategories.PHONE_NUMBER,
  searchCategories.REVENUECAT_ID,
  searchCategories.IP,
]

class SearchBarComponent extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { type } = props.match.params || {}
    const typeIndex = searchCategoriesOrder.findIndex((i) => i === type)
    const hasTypeChanged =
      typeIndex >= 0 && state.searchCategoryIndex !== typeIndex

    if (hasTypeChanged) {
      return {
        ...state,
        searchCategoryIndex: typeIndex,
      }
    }

    return null
  }

  state = { searchTerm: '', searchCategoryIndex: 0 }

  componentDidMount() {
    this.searchInput.focus()
  }

  _onChangeSearchTerm = (searchTerm) => {
    this.setState({ searchTerm })
  }

  _handleCategoryChange = (index) => {
    const { history } = this.props

    this.setState({ searchCategoryIndex: index })
    history.push(`/search/${searchCategoriesOrder[index]}`)
  }

  _handleSubmit = (e) => {
    const { searchTerm, searchCategoryIndex } = this.state
    this.props.actions.search({
      value: searchTerm,
      type: searchCategoriesOrder[searchCategoryIndex],
    })
    e.preventDefault()
  }

  render() {
    const { searchTerm } = this.state

    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: 12,
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.colors.background,
            borderRadius: 25,
            boxShadow: theme.styles.shadow,
            width: '70%',
          }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 12,
              flex: 1,
            }}>
            <Icon icon={faSearch} style={{ marginLeft: 24, marginRight: 12 }} />
            <form onSubmit={this._handleSubmit} style={{ flex: 1 }}>
              <Input
                forwardRef={(input) => (this.searchInput = input)}
                placeholder="Search"
                onChangeText={this._onChangeSearchTerm}
                value={searchTerm}
                style={{
                  borderBottom: 'none',
                  marginBottom: 0,
                  width: '100%',
                }}
              />
            </form>
          </View>
          <Select
            defaultValue={searchCategoriesOrder}
            options={searchCategoriesOrder.map((label, value) => ({
              label,
              value,
            }))}
            onChange={this._handleCategoryChange}
            style={{
              minWidth: 130,
              marginRight: 24,
            }}
          />
        </View>
      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ search }, dispatch),
})

const withConnect = connect(null, mapDispatchToProps)

const enhancer = compose(withRouter, withConnect)

const SearchBar = enhancer(SearchBarComponent)

export { SearchBar }
