import { Button } from 'antd'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { hooks, Input, View } from '../../common'
import { theme } from '../../common/styles/theme'
import {
  blockAllSentTells,
  clearSelection,
  copyItems,
  selectAll,
} from '../actions'
import { ModalConfirmAction } from './ModalConfirmAction'

const SearchBar = ({ onChange, onPress, searchPhrase }) => {
  const inputRef = useRef(null)

  hooks.useKeyboardShortcutToSubmit({ inputRef, onSubmit: onPress })

  return (
    <View style={{ flexDirection: 'row', marginBottom: 12 }}>
      <Input
        forwardRef={inputRef}
        onChangeText={onChange}
        placeholder="Search word"
        style={{
          borderRadius: 25,
          flex: 1,
          marginBottom: 0,
          marginRight: 16,
          paddingHorizontal: 24,
          paddingVertical: 5,
        }}
        value={searchPhrase}
      />
      <Button onClick={onPress} shape="round" style={{ alignSelf: 'flex-end' }}>
        Search!
      </Button>
    </View>
  )
}

const _HeaderSelectionActions = ({
  actions,
  hasItems,
  isSentTells,
  onChangeSearchInput,
  onPressSearch,
  profile,
  searchPhrase,
  shouldShowOnlyPostsWithMedia,
  shouldTranslate,
  toggleOnlyPostsWithMedia,
  toggleTranslate,
  type,
}) => {
  const onPressBlockAllSentTells = (e) => {
    e.stopPropagation()
    ModalConfirmAction.show({
      action: () => actions.blockAllSentTells({ userId: profile.id }),
      description: 'Do you really want to delete all sent tells?',
      title: 'Sure?',
    })
  }

  if (!hasItems) {
    return null
  }

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignSelf: 'center',
          paddingVertical: 12,
        }}>
        <Button
          shape="round"
          onClick={() =>
            actions.selectAll({
              type,
              userId: profile.id,
            })
          }
          style={{ marginRight: 6 }}>
          Select All
        </Button>
        <Button
          shape="round"
          onClick={() =>
            actions.clearSelection({
              type,
              userId: profile.id,
            })
          }
          style={{ marginRight: 6 }}>
          Clear Selection
        </Button>
        <Button
          shape="round"
          onClick={() =>
            actions.copyItems({
              type,
              userId: profile.id,
            })
          }
          style={{ marginRight: 6 }}>
          Copy Selection
        </Button>
        {isSentTells && (
          <Button
            shape="round"
            borderColor={theme.colors.primary}
            onClick={onPressBlockAllSentTells}
            style={{ paddingVertical: 6, marginRight: 6 }}
            textStyle={{ color: theme.colors.primary, fontSize: 12 }}
            white>
            Block all sent tells
          </Button>
        )}
        <Button
          onClick={toggleTranslate}
          shape="round"
          style={{ marginRight: 6 }}>
          {shouldTranslate ? 'Show Originals' : 'Translate all'}
        </Button>

        {type === 'answers' && (
          <Button
            onClick={toggleOnlyPostsWithMedia}
            shape="round"
            style={{ marginRight: 6 }}>
            {shouldShowOnlyPostsWithMedia ? 'Show all' : 'Only with media'}
          </Button>
        )}
      </View>
      <SearchBar
        onChange={onChangeSearchInput}
        onPress={onPressSearch}
        searchPhrase={searchPhrase}
      />
    </View>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { clearSelection, copyItems, blockAllSentTells, selectAll },
    dispatch
  ),
})

export const HeaderSelectionActions = connect(
  null,
  mapDispatchToProps
)(_HeaderSelectionActions)
