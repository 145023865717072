import * as t from './types'

export const hide = (payload) => ({
  type: t.HIDE,
  payload,
})

export const likeAnswer = (payload, options = {}) => ({
  type: t.LIKE_ANSWER,
  payload,
  meta: {
    offline: {
      effect: { path: 'likes/create', method: 'POST' },
      commit: { type: t.LIKE_ANSWER_SUCCESS },
      rollback: { type: t.LIKE_ANSWER_ERROR },
    },
    options,
  },
})

export const refreshAnswer = (payload) => ({
  shouldAdjustData: false,
  type: t.REFRESH_ANSWER,
  payload,
  meta: {
    offline: {
      effect: { path: `answers/id/${payload.answerId}`, ...payload },
      commit: { type: t.REFRESH_ANSWER_SUCCESS },
      rollback: { type: t.REFRESH_ANSWER_ERROR },
    },
  },
})

export const removeAnswer = (payload) => ({
  type: t.REMOVE_ANSWER,
  payload,
  meta: {
    offline: {
      effect: { path: `posts/${payload.answerId}`, method: 'DELETE' },
      rollback: { type: t.REMOVE_ANSWER_ERROR },
      commit: { type: t.REMOVE_ANSWER_SUCCESS },
    },
  },
})

export const removeSenderStatus = (payload) => ({
  type: t.REMOVE_SENDER_STATUS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `answers/id/${payload.answerId}/senderStatus`,
        method: 'DELETE',
      },
      rollback: { type: t.REMOVE_SENDER_STATUS_ERROR },
      commit: { type: t.REMOVE_SENDER_STATUS_SUCCESS, meta: payload },
    },
  },
})

export const unlikeAnswer = (payload, options = {}) => ({
  type: t.UNLIKE_ANSWER,
  payload,
  meta: {
    offline: {
      effect: { path: 'likes/destroy', method: 'POST' },
      commit: { type: t.UNLIKE_ANSWER_SUCCESS },
      rollback: { type: t.UNLIKE_ANSWER_ERROR },
    },
    options,
  },
})
