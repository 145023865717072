import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { CountryPhoneCodes } from '@tellonym/core/constants/CountryPhoneCodes'
import { ACCOUNT_VERIFICATION_STATUS } from '@tellonym/enums/lib/AccountVerification'
import { USER_LANGUAGE, USER_TYPE } from '@tellonym/enums/lib/User'
import { Button, Checkbox, Input, Select, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import RNFlatList from 'react-native-web/dist/exports/FlatList'
import RNText from 'react-native-web/dist/exports/Text'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { config } from '../../../config'
import {
  ActionSheet,
  Alert,
  Box,
  FlatList,
  Modal,
  Text,
  View,
  helpers,
  moment,
  theme,
} from '../../common'
import { RNImage } from '../../common/components/Image'
import {
  changeSettings,
  deleteProfilePic,
  deleteUser,
  recount,
  sendModerationInvitation,
  sendRecoveryMail,
  sendTell,
} from '../actions'
import {
  useQuickBanSexualMutation,
  useQuickBanSexualPermaMutation,
} from '../queries'
import { ProfilePicture } from './AccountVerificationItem'
import { ModalCreateBan } from './ModalCreateBan'
import { ModalCreateWarning } from './ModalCreateWarning'
import { ModalIapRestore } from './ModalIapRestore'
import { ModalSendPushNotification } from './ModalSendPushNotification'

const langs = Object.values(USER_LANGUAGE)
const types = helpers.getStringsFromEnums(USER_TYPE)
const safetyLevelOptions = [1, 2, 3, 4, 5]

const getChangedData = (state, profile) =>
  Object.keys(state).reduce(
    (acc, curr) =>
      state[curr] !== profile[curr] ? { ...acc, [curr]: state[curr] } : acc,
    undefined
  )

const Action = ({ onPress, ...props }) => (
  <Text
    onPress={onPress}
    color={theme.colors.primary}
    style={{ marginBottom: 16 }}
    {...props}
  />
)

const CountryListItem = ({ item, onPress }) => (
  <Box
    onPress={onPress}
    flexDirection="row"
    paddingHorizontal={16}
    paddingVertical={8}
    borderBottomWidth={0.5}
    borderBottomColor={theme.colors.border}
    transparent>
    <Box flex={1} flexDirection="row" alignItems="center" transparent>
      <Text type="h1" style={{ marginRight: 4 }}>
        {item.flag}
      </Text>
      <Box>
        <RNText ellipsizeMode="tail" numberOfLines={1} type="h4">
          {item.name}
        </RNText>
      </Box>
    </Box>
    <Box justifyContent="center">
      <Text type="h4" color={theme.colors.Placeholder}>
        {item.dial_code}
      </Text>
    </Box>
  </Box>
)

const DropdownPhonePrefix = ({ closeModal, setCountryPhoneItem }) => (
  <ModalContainer>
    <Box height={360} marginBottom={12} width={400}>
      <RNFlatList
        data={CountryPhoneCodes}
        isRefreshing={false}
        keyExtractor={(item) => item.code}
        renderItem={({ item }) => (
          <CountryListItem
            item={item}
            onPress={() => {
              setCountryPhoneItem(item)
              closeModal()
            }}
          />
        )}
      />
    </Box>
    <Button onClick={closeModal} shape="round">
      Cancel
    </Button>
  </ModalContainer>
)

const DropdownPhonePrefixInput = ({ onPress, value }) => (
  <Button
    onClick={onPress}
    icon={<DownOutlined style={{ fontSize: 11, color: '#bfbfbf' }} />}>
    {value}
  </Button>
)

const ModalContainer = ({ children }) => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Modal.Body>
      <Box
        borderRadius={25}
        backgroundColor={theme.colors.background}
        minWidth={320}
        padding={32}>
        {children}
      </Box>
    </Modal.Body>
  </View>
)

const SingleInput = ({ modalId, placeholder, text, title }) => {
  const [input, setInput] = useState('')

  return (
    <ModalContainer>
      <Text type="h2" bold style={{ marginBottom: 24 }}>
        {title}
      </Text>
      <Text style={{ marginBottom: 8 }}>{text}</Text>
      <Input
        name="displayName"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={placeholder}
      />
      <Box marginTop={16}>
        <Button
          shape="round"
          onClick={() => Modal.hide({ id: modalId, extraData: { input } })}>
          Submit
        </Button>
      </Box>
    </ModalContainer>
  )
}

const showInput = (payload) =>
  Modal.show({
    render: (props) => <SingleInput {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

const showPhonePrefixInput = (payload) =>
  Modal.show({
    id: 'ModalPhonePrefixInput',
    render: (props) => (
      <DropdownPhonePrefix
        closeModal={() => Modal.hide({ id: 'ModalPhonePrefixInput' })}
        {...payload}
        {...props}
      />
    ),
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
  })

const Label = ({ label, style }) => (
  <Typography.Text
    style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 8, ...style }}>
    {label}
  </Typography.Text>
)

const BoxSelect = ({ label, onChange, options, value, ...props }) => (
  <Box marginRight={8} {...props}>
    <Label label={label} />
    <Select
      defaultValue={value}
      value={value}
      onChange={onChange}
      options={options}
    />
  </Box>
)

const BoxInput = ({ label, name, onChange, value, ...props }) => (
  <Box marginBottom={16} {...props}>
    <Label label={label} />
    <Input name={name} value={value} onChange={onChange} />
  </Box>
)

const EditProfileSection = connect(
  (state) => ({ isLoading: state.moderator.isLoading }),
  (dispatch) => ({ actions: bindActionCreators({ changeSettings }, dispatch) })
)(({ actions, isLoading, profile }) => {
  const [state, setState] = useState(profile)
  const [isEdited, setIsEdited] = useState(false)

  const {
    aboutMe,
    displayName,
    email,
    instagram,
    isTellsOnlyFromRegistered,
    lang,
    location,
    phoneNumber,
    phonePrefix,
    safetyLevelInsult,
    safetyLevelSexHarass,
    safetyLevelSpam,
    type,
    username,
    verifiedSince,
    verificationPhotos,
    verificationStatus,
  } = state

  const isVerified = React.useMemo(
    () =>
      state.isVerified ||
      [
        ACCOUNT_VERIFICATION_STATUS.HARD_APPROVED,
        ACCOUNT_VERIFICATION_STATUS.MANUAL_APPROVED,
        ACCOUNT_VERIFICATION_STATUS.SOFT_APPROVED,
      ].includes(verificationStatus),
    [state.isVerified, verificationStatus]
  )

  const defaultCountryItem = CountryPhoneCodes.filter(
    (item) =>
      item.dial_code === phonePrefix ||
      item.code === location ||
      item.code === config.CountryPhoneNumber.defaultCountryCode
  )[0]

  useEffect(() => {
    const isEdited = Object.keys(state).some(
      (item) => state[item] !== profile[item]
    )

    setIsEdited(isEdited)
  }, [state])

  useEffect(() => {
    setState(profile)

    setIsEdited(false)
  }, [profile])

  const onPressSave = () => {
    const changedData = getChangedData(state, profile)

    if (changedData) {
      actions.changeSettings({
        ...changedData,
        id: profile.id,
      })
    }
  }

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    })
  }

  return (
    <>
      <BoxInput
        label="Display Name"
        onChange={onChange}
        name="displayName"
        value={displayName}
      />
      <BoxInput
        label="Username"
        onChange={onChange}
        name="username"
        value={username}
      />
      <BoxInput label="Email" onChange={onChange} name="email" value={email} />
      <BoxInput
        label="Status"
        onChange={onChange}
        name="aboutMe"
        value={aboutMe}
      />
      <BoxInput
        label="Instagram"
        onChange={onChange}
        name="instagram"
        value={instagram || ''}
      />

      <Label label="Phone Number" />
      <Box flexDirection="row" marginBottom={24}>
        <DropdownPhonePrefixInput
          onPress={() =>
            showPhonePrefixInput({
              setCountryPhoneItem: (phoneItem) =>
                setState({ ...state, phonePrefix: phoneItem.dial_code }),
            })
          }
          value={phonePrefix || defaultCountryItem.dial_code}
        />
        <Input
          name="phoneNumber"
          value={phoneNumber || ''}
          onChange={onChange}
        />
      </Box>
      <Box flexDirection="row" justifyContent="space-around" marginBottom={32}>
        <BoxSelect
          label="language"
          onChange={(value) => setState({ ...state, lang: value })}
          options={langs.map((value) => ({ label: value, value }))}
          value={lang}
        />

        <BoxSelect
          label="user type"
          onChange={(value) => setState({ ...state, type: USER_TYPE[value] })}
          options={types.map((value) => ({ label: value, value }))}
          value={USER_TYPE[type]}
          flex={1}
        />

        <BoxSelect
          label="SL Harassment"
          onChange={(value) =>
            setState({ ...state, safetyLevelSexHarass: value })
          }
          options={safetyLevelOptions.map((value) => ({
            label: value,
            value,
          }))}
          value={safetyLevelSexHarass}
        />

        <BoxSelect
          label="SL Insult"
          onChange={(value) => setState({ ...state, safetyLevelInsult: value })}
          options={safetyLevelOptions.map((value) => ({
            label: value,
            value,
          }))}
          value={safetyLevelInsult}
        />

        <BoxSelect
          label="SL Spam"
          onChange={(value) => setState({ ...state, safetyLevelSpam: value })}
          options={safetyLevelOptions.map((value) => ({
            label: value,
            value,
          }))}
          value={safetyLevelSpam}
        />
      </Box>

      <Checkbox
        name="isTellsOnlyFromRegistered"
        checked={isTellsOnlyFromRegistered}
        onChange={onChange}>
        Tells only from registered users?
      </Checkbox>

      <Box marginTop={16}>
        <Checkbox
          name="isVerified"
          checked={isVerified}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              isVerified: e.target.checked,
              verificationStatus: e.target.checked
                ? ACCOUNT_VERIFICATION_STATUS.MANUAL_APPROVED
                : ACCOUNT_VERIFICATION_STATUS.REJECTED,
            }))
          }}>
          Verified?
        </Checkbox>
      </Box>

      <Text
        style={{
          fontWeight: '700',
          marginTop: 8,
        }}>
        Verification Photos
      </Text>
      {isVerified && typeof verifiedSince === 'string' && (
        <Box flexDirection="row" alignItems="center">
          <RNImage
            source={'/assets/img/verified.png'}
            style={{
              width: 14,
              height: 14,
              marginRight: 4,
              tintColor: colors.blue[2],
            }}
          />
          <Text type="small" color={colors.grey[6]}>
            {moment(verifiedSince).format('MMMM YYYY')}
          </Text>
        </Box>
      )}
      <Box flexDirection="row" marginTop={16}>
        {verificationPhotos.map((avatarFileName) => (
          <ProfilePicture
            key={avatarFileName}
            avatarFileName={avatarFileName}
          />
        ))}
      </Box>
      <Box marginTop={verificationPhotos.length > 0 ? 14 : 0}>
        <Checkbox
          checked={verificationStatus === ACCOUNT_VERIFICATION_STATUS.BLOCKED}
          name="verificationStatus"
          label=""
          onChange={(e) => {
            setState((state) => ({
              ...state,
              isVerified: e.target.checked ? false : state.isVerified,
              verificationStatus: e.target.checked
                ? ACCOUNT_VERIFICATION_STATUS.BLOCKED
                : ACCOUNT_VERIFICATION_STATUS.UNBLOCKED,
            }))
          }}>
          Block Verification
        </Checkbox>
      </Box>

      <Button
        onClick={onPressSave}
        shape="round"
        disabled={!isEdited}
        style={{ width: '50%', marginTop: 8 }}>
        {isLoading ? (
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 12, color: 'white' }} spin />
            }
          />
        ) : (
          'Save'
        )}
      </Button>
    </>
  )
})

const ActionSection = connect(null, (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteProfilePic,
      deleteUser,
      recount,
      sendModerationInvitation,
      sendRecoveryMail,
      sendTell,
    },
    dispatch
  ),
}))(({ actions, profile = {} }) => {
  const { lang, id } = profile
  const recountTypes = ['all', 'followers', 'followings', 'tells']

  const {
    mutate: triggerQuickBanSexualMutation,
    isLoading: isLoadingQuickBan,
  } = useQuickBanSexualMutation()

  const quickBanSexual = async () => {
    const res = await Alert.alert(
      'Sure?',
      'Do you really want to delete all pics and ban the user for a day?'
    )

    if (res === Alert.CONFIRMED) {
      triggerQuickBanSexualMutation({ userId: id })
    }
  }

  const {
    mutate: triggerQuickBanSexualPermaMutation,
    isLoading: isLoadingQuickBanPerma,
  } = useQuickBanSexualPermaMutation()

  const quickBanSexualPerma = async () => {
    const res = await Alert.alert(
      'Sure?',
      'Do you really want to delete all pics and ban the user permanently?'
    )

    if (res === Alert.CONFIRMED) {
      triggerQuickBanSexualPermaMutation({ userId: id })
    }
  }

  return (
    <Box marginTop={32}>
      <Action
        onPress={() =>
          Alert.alert(
            'Sure?',
            'Do you really want to invite this user to our moderation?'
          ).then(
            (res) =>
              res === Alert.CONFIRMED &&
              actions.sendModerationInvitation({ userId: id, lang })
          )
        }>
        Send moderation invitation
      </Action>
      <Action
        onPress={() =>
          showInput({
            title: 'Send Tellonym Tell',
            text: 'This message will be labeled as "from Tellonym".',
            placeholder: 'Your message',
          }).then(
            ({ input, type }) =>
              type !== Modal.Actions.CLOSED_BY_USER &&
              actions.sendTell({ tell: input, receiver: id })
          )
        }>
        Send Tell from Tellonym user account
      </Action>
      <Text color={theme.colors.primary} style={{ marginBottom: 16 }}>
        {[1, 0, 2, -1].map((position) => (
          <Text
            key={String(position)}
            onPress={() =>
              Alert.alert(
                'Sure?',
                'Do you really want to perform this action?'
              ).then((res) => {
                if (res === Alert.CONFIRMED) {
                  const positions = position === -1 ? [0, 1, 2] : [position]

                  for (const position of positions) {
                    actions.deleteProfilePic({
                      userId: id,
                      position,
                    })
                  }
                }
              })
            }
            color={theme.colors.primary}
            style={{ marginRight: 8 }}>
            {(() => {
              switch (position) {
                case 1:
                  return 'Delete Left,'
                case 0:
                  return 'Center,'
                case 2:
                  return 'Right,'
                case -1:
                  return 'or All Avatars'
                default:
                  return ''
              }
            })()}
          </Text>
        ))}
      </Text>
      <Action
        onPress={() =>
          showInput({
            title: 'Delete User',
            text: 'This reason will be visible to the user.',
            placeholder: 'reason (optional)',
          }).then(
            ({ input, type }) =>
              type !== Modal.Actions.CLOSED_BY_USER &&
              actions.deleteUser({
                userId: id,
                reason: input === '' ? undefined : input,
              })
          )
        }>
        Delete user
      </Action>
      <Action onPress={() => ModalCreateBan.show({ userId: id, lang })}>
        Create ban
      </Action>
      <Spin spinning={isLoadingQuickBan}>
        <Action onPress={quickBanSexual}>Quick ban sexual</Action>
      </Spin>
      <Spin spinning={isLoadingQuickBanPerma}>
        <Action onPress={quickBanSexualPerma}>Quick perma ban sexual</Action>
      </Spin>
      <Action onPress={() => ModalCreateWarning.show({ userId: id })}>
        Create warning
      </Action>
      <Action
        onPress={() =>
          ActionSheet.show({
            actions: recountTypes,
          }).then(
            ({ selected }) =>
              selected >= 0 &&
              actions.recount({ userId: id, type: recountTypes[selected] })
          )
        }>
        Recount
      </Action>
      <Action
        onPress={() =>
          Alert.alert(
            'Sure?',
            'Do you really want to perform this action?'
          ).then(
            (res) =>
              res === Alert.CONFIRMED &&
              actions.sendRecoveryMail({ userId: id })
          )
        }>
        Send recovery mail
      </Action>
      <Action onPress={() => ModalSendPushNotification.show({ userId: id })}>
        Send Push Notification
      </Action>
      <Action onPress={() => ModalIapRestore.show({ userId: id })}>
        Restore InappPurchases
      </Action>
    </Box>
  )
})

export const TabSettings = ({ profile }) => (
  <FlatList
    component={null}
    hasMore={false}
    isFetching={false}
    isRefreshing={false}
    items={{ ids: [], data: {} }}
    renderHeader={() => (
      <Box maxWidth={600}>
        <EditProfileSection profile={profile} />
        <ActionSection profile={profile} />
      </Box>
    )}
    style={{ height: window.innerHeight - 171, minWidth: 700 }}
  />
)
