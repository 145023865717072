import { path, prop } from 'ramda'
import { createSelector } from 'reselect'
import { name } from './types'

const getState = prop('filter')

export const getLastDetectedSpam = createSelector(
  getState,
  path(['lastDetectedSpam', 'data'])
)

export const getHasMoreLastDetectedSpam = createSelector(
  getState,
  path(['lastDetectedSpam', 'hasMore'])
)

export const getIsFetchingLastDetectedSpam = createSelector(
  getState,
  path(['lastDetectedSpam', 'isFetching'])
)

export const getIsRefreshingLastDetectedSpam = createSelector(
  getState,
  path(['lastDetectedSpam', 'isRefreshing'])
)

export const getIsRefreshingLastDetectedSpamItem = createSelector(
  getState,
  path(['lastDetectedSpam', 'isRefreshingItem'])
)

export const getSpamItem = (state, id) =>
  createSelector(
    getState,
    path(['lastDetectedSpam', 'data', 'data', id])
  )(state)

export const getPosts = (state, id) =>
  createSelector(getState, path(['posts', 'data', id]))(state)

export const getHasMorePosts = createSelector(
  getState,
  path(['posts', 'hasMore'])
)

export const getIsFetchingPosts = createSelector(
  getState,
  path(['posts', 'isFetching'])
)

export const getIsRefreshingPosts = createSelector(
  getState,
  path(['posts', 'isRefreshing'])
)

export const getRerenderPostItem = createSelector(
  getState,
  path(['posts', '_rerenderItem'])
)

export const getTells = (state, id) =>
  createSelector(getState, path(['tells', 'data', id]))(state)

export const getHasMoreTells = createSelector(
  getState,
  path(['tells', 'hasMore'])
)

export const getIsFetchingTells = createSelector(
  getState,
  path(['tells', 'isFetching'])
)

export const getIsRefreshingTells = createSelector(
  getState,
  path(['tells', 'isRefreshing'])
)

export const getRerenderTellItem = createSelector(
  getState,
  path(['tells', '_rerenderItem'])
)

export const getUsers = (state, id) =>
  createSelector(getState, path(['users', 'data', id]))(state)

export const getHasMoreUsers = createSelector(
  getState,
  path(['users', 'hasMore'])
)

export const getIsFetchingUsers = createSelector(
  getState,
  path(['users', 'isFetching'])
)

export const getIsRefreshingUsers = createSelector(
  getState,
  path(['users', 'isRefreshing'])
)

export const LastBlocked = (state) => ({
  lastBlocked: state[name].lastBlocked,
  isRefreshing: state[name].lastBlocked.isRefreshing,
})
