import { formatNumber } from '@tellonym/core/helpers/index'
import { INTERVAL_TYPE } from '@tellonym/enums/lib/Stats'
import dayjs from 'dayjs'
import { compose, pick } from 'ramda'
import { moment } from '../common/locales/moment'
import { theme } from '../common/styles/theme'
import { dateTypes } from './constants'

// eslint-disable-next-line
export const valueToString = (num) => shortenNumber(num)
export const valueToStringWithSign = (num) =>
  // eslint-disable-next-line
  num > 0 ? `+${shortenNumber(num)}` : shortenNumber(num)

export const valueToPercentage = (num, pos = 2) => {
  if (num === null) {
    return ''
  }

  return `${(num * 100).toFixed(pos)}%`
}

export const valueToPercentageWithSign = (num, pos = 2) =>
  num === 0
    ? '0%'
    : num > 0
    ? `+${(num * 100).toFixed(pos)}%`
    : `${(num * 100).toFixed(pos)}%`

// regex removes redundant digits if both are 00
export const shortenNumber = (number) => {
  const num = parseInt(number, 10)

  return num
    ? num > 99999999
      ? `${Math.floor(num / 1000000)}M`
      : num > 999999
      ? `${(num / 1000000).toFixed(2)}M`.replace(/(\.00)/, '')
      : num > 99999
      ? `${Math.floor(num / 1000)}K`
      : num > 9999
      ? `${(num / 1000).toFixed(2)}K`.replace(/(\.00)/, '')
      : formatNumber(num)
    : number
}

export const getNumbersFromData = ({ data = [] }) => {
  if (data.length) {
    const dateOneWeekBefore = moment(data[0].date)
      .subtract(7, 'days')
      .format('YYYY-MM-DD')

    const weekBeforeValue = data.reduce(
      (acc, curr) =>
        moment(curr.date).isSame(dateOneWeekBefore) ? curr.value : acc,
      NaN
    )

    const oldest = Math.floor(weekBeforeValue * 10000) / 10000
    const latest = Math.floor(data[0].value * 10000) / 10000

    return {
      oldest,
      latest,
      diffNumber:
        oldest === 0 || latest === 0
          ? oldest - latest
          : Math.floor(oldest * 100 - latest * 100) / 100,
    }
  }
  return {
    displayValue: 'no data',
    displayDiffValue: '',
    displayDiffPercentage: '',
    diffNumber: 0,
  }
}

export const shouldShowColor = ({ isPercentage, data = [] }) => {
  const { oldest, diffNumber } = getNumbersFromData({ data })

  if (isPercentage) {
    return diffNumber > 0.03 || diffNumber < -0.03
  }
  return diffNumber < -(oldest * 0.03) || diffNumber > oldest * 0.03
}

export const getColor = ({
  data = [],
  isPercentage,
  diffNumber,
  isHigherBetter,
}) => {
  const shouldColorizeItem = shouldShowColor({ data, isPercentage })

  return shouldColorizeItem
    ? diffNumber > 0
      ? isHigherBetter
        ? theme.colors.niceRed
        : theme.colors.niceGreen
      : diffNumber < 0
      ? isHigherBetter
        ? theme.colors.niceGreen
        : theme.colors.niceRed
      : theme.colors.placeholder
    : theme.colors.placeholder
}

export const dataToDisplayValues = ({ data = [], isPercentage = false }) => {
  if (data.length) {
    const { oldest, latest, diffNumber } = getNumbersFromData({ data })

    if (isPercentage) {
      return {
        displayValue: valueToPercentage(latest),
        displayDiffValue: valueToPercentageWithSign(-diffNumber),
        displayDiffPercentage: '',
        diffNumber,
      }
    }

    return {
      displayValue: valueToString(latest),
      displayDiffValue: valueToStringWithSign(-diffNumber),
      displayDiffPercentage: valueToPercentageWithSign(
        diffNumber === 0 && oldest === 0
          ? 0
          : oldest === 0
          ? -diffNumber
          : latest === 0
          ? -oldest
          : -(diffNumber / oldest)
      ),
      diffNumber,
    }
  }
  return {
    displayValue: 'no data',
    displayDiffValue: '',
    displayDiffPercentage: '',
    diffNumber: 0,
  }
}

/**
 * The backend does not always have a value for dates when something went wrong with
 * stats generation. Therefore, we build an array with all dates that should be there
 * and try to fill it with data.
 */
const getDatesForTimespan = ({ endDate, intervalType, timespan }) => {
  const additionalTimespan = intervalType === INTERVAL_TYPE.DAILY ? 7 : 1
  const metric = intervalType === INTERVAL_TYPE.DAILY ? 'days' : 'weeks'

  const expectedData = [...new Array(timespan - additionalTimespan)]
    .map((_, index) => {
      const date = moment(endDate).subtract(index + 1, metric)

      return {
        date: date.format('YYYY-MM-DD'),
        calendarWeek: {
          year: Number(date.isoWeekYear()) - 2000,
          week: Number(date.isoWeek()),
        },
      }
    })
    .reverse()

  return expectedData
}

export const enhanceStatsWithChanges = ({
  data,
  endDate,
  intervalType: _intervalType,
  timespan,
}) => {
  const intervalType = Number(_intervalType)

  const expectedData = getDatesForTimespan({ intervalType, timespan, endDate })

  const result = data.map((item) => {
    if (item.data.length === 0) {
      return item
    }

    const dataObject = item.data.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.date]: curr,
        [`${curr.calendarWeek.year}-${curr.calendarWeek.week}`]: curr,
      }),
      {}
    )

    const enrichedData = expectedData.map((i) => {
      const key =
        intervalType === INTERVAL_TYPE.DAILY
          ? i.date
          : `${i.calendarWeek.year}-${i.calendarWeek.week}`

      const currentItem = dataObject[key]

      if (typeof currentItem?.value === 'undefined') {
        const metric =
          intervalType === INTERVAL_TYPE.DAILY ? 'date' : 'calendarWeek'

        return {
          [metric]: i[metric],
          id: key,
          value: null,
        }
      }

      const compareKey =
        intervalType === INTERVAL_TYPE.DAILY
          ? moment(i.date).subtract(7, 'days').format('YYYY-MM-DD')
          : i.calendarWeek.week === 1 // we need to explicitly handle the case of the first week because a year can have 52 or 53 weeks
          ? `${i.calendarWeek.year - 1}-${moment()
              .year(i.calendarWeek.year - 1)
              .month(11)
              .date(31)
              .isoWeek()}`
          : `${i.calendarWeek.year}-${i.calendarWeek.week - 1}`

      const compareItem = dataObject[compareKey]

      if (typeof compareItem?.value === 'undefined') {
        return currentItem
      }

      currentItem.compareValues = pick(
        [
          'amountActions',
          'amountPerUnique',
          'amountPerUser',
          'amountUniques',
          'amountUsers',
          'percentageOfUsers',
          'value',
        ],
        compareItem
      )

      return currentItem
    })

    return {
      ...item,
      data: enrichedData,
    }
  })

  return result
}

export const camelToSentenceCase = (string) =>
  string
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .replace(/^([a-z])/, (c) => c.toUpperCase())

export const snakeToCamelCase = (string) =>
  string
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (c) => c.toUpperCase())

export const dateTypeToTimespan = ({ dateType, startDate, endDate }) => {
  switch (dateType) {
    case dateTypes.CUSTOM_DATE:
      return { endDate, timespan: moment(endDate).diff(startDate, 'days') }

    case dateTypes.LAST_14_DAYS:
    case dateTypes.LAST_21_DAYS:
    case dateTypes.LAST_30_DAYS:
    case dateTypes.LAST_7_DAYS:
    case dateTypes.LAST_90_DAYS: {
      const [_, timespan] = dateType.match(/_(\d*)_/)
      const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')

      return { endDate: yesterday, timespan: Number(timespan) }
    }

    case dateTypes.YESTERDAY: {
      const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD')

      return {
        timespan: 1,
        endDate: yesterday,
      }
    }

    case dateTypes.LAST_WEEK: {
      return {
        timespan: 7 - 1, // We need to remove one because endDate is inclusive
        endDate: moment()
          .subtract(7, 'days')
          .endOf('isoWeek')
          .format('YYYY-MM-DD'),
      }
    }

    case dateTypes.LAST_MONTH: {
      const date = moment().subtract(1, 'months')
      const timespan = date.daysInMonth() - 1 // We need to remove one because endDate is inclusive
      const lastMonth = date.endOf('month').format('YYYY-MM-DD')
      return { timespan, endDate: lastMonth }
    }

    default:
      return undefined
  }
}

export const dateTypeToDates = ({ dateType, startDate, endDate }) => {
  switch (dateType) {
    case dateTypes.CUSTOM_DATE:
      return {
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      }

    case dateTypes.LAST_14_DAYS:
    case dateTypes.LAST_21_DAYS:
    case dateTypes.LAST_30_DAYS:
    case dateTypes.LAST_7_DAYS:
    case dateTypes.LAST_90_DAYS: {
      const [_, timespan] = dateType.match(/_(\d*)_/)
      return {
        endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        startDate: dayjs()
          .subtract(Number(timespan), 'days')
          .format('YYYY-MM-DD'),
      }
    }

    case dateTypes.YESTERDAY:
      return {
        endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      }

    case dateTypes.LAST_WEEK: {
      return {
        timespan: 7 - 1, // We need to remove one because endDate is inclusive
        startDate: dayjs()
          .subtract(7, 'days')
          .startOf('isoWeek')
          .format('YYYY-MM-DD'),
        endDate: dayjs()
          .subtract(7, 'days')
          .endOf('isoWeek')
          .format('YYYY-MM-DD'),
      }
    }

    case dateTypes.LAST_MONTH: {
      return {
        startDate: dayjs()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: dayjs()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD'),
      }
    }

    default:
      return undefined
  }
}

const percentageMultiplyer = (isPercentage) => (num) =>
  isPercentage ? num * 100 : num

const withFixed = (isPercentage, amountDecimals) => (num) =>
  num.toFixed(amountDecimals ?? (isPercentage ? 1 : 2))

const withShorten = (isPercentage) => (num) =>
  isPercentage || num < 99 ? num : shortenNumber(num)

export const getDisplayValue = (
  value,
  isPercentage = false,
  amountDecimals
) => {
  if (typeof value === 'undefined' || value === null) {
    return '-'
  }

  const _value = compose(
    withShorten(isPercentage),
    withFixed(isPercentage, amountDecimals),
    percentageMultiplyer(isPercentage)
  )(value)

  return isPercentage ? `${_value}%` : _value
}

/**
 * Downloads a csv file in the browser based on the input string
 * @param {String} csvData The csv data to download as a string
 */
export const downloadCsv = (csvData) => {
  const csvContent = `data:text/csv;charset=utf-8,${csvData}`
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${new Date().getTime()}.csv`)
  document.body.appendChild(link) // Required for FF
  link.click()
}

/**
 * Gets the names of the available values to compare with
 */
const getChangeKeys = (data) => {
  const id = data.ids[0]
  const shortname = data.data[id]
  const changeObject = shortname.data[shortname.ids[0]].amountActions?.change

  if (typeof changeObject !== 'undefined') {
    const { id, ...rest } = changeObject

    return Object.keys(rest)
  }

  return []
}

/**
 * Function to transform the diff data to an array of arrays, useful for csv export
 * @param {*} data normalized diff data
 * @param {{additionalHeader: Array, additionalItems: Array}} options additional options
 * @returns Array of arrays
 */
export const transformDiffDataToArray = (data, options) => {
  const changeKeys = getChangeKeys(data)
  const header = [
    ...(options?.additionalHeader ?? []),
    'shortname',
    'date',
    'metric',
    'value',
    ...changeKeys,
  ]
  const csvData = [header]

  data.ids.forEach((id) => {
    const shortname = data.data[id]

    shortname.ids.forEach((date) => {
      const { id, ...metrics } = shortname.data[date]

      Object.keys(metrics).forEach((metric) => {
        const changeValues = changeKeys.map(
          (changeKey) => metrics[metric].change?.[changeKey]?.value ?? ''
        )

        // Entries need to be the same order as header
        csvData.push([
          ...(options?.additionalItems ?? []),
          shortname.shortname,
          date,
          metric,
          metrics[metric].value,
          ...changeValues,
        ])
      })
    })
  })

  return csvData
}
