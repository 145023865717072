import { colors } from '@tellonym/core/common/colorSystem'
import { Button, Input, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, View, hooks } from '../../common'
import { refreshTopics } from '../actionsV2'
import { useAddBulkMutation } from '../queries'
import { getLanguage } from '../selectorsV2'
import { SelectorsLanguage } from './SelectorsLanguage'

export const PageArtificialTellsSpreadsheetParser = () => {
  const dispatch = ReactRedux.useDispatch()
  const containerStyle = hooks.usePageContainerStyle()

  const [topicString, setTopicString] = React.useState('')
  const [textareaValue, setTextareaValue] = React.useState('')

  const language = ReactRedux.useSelector(getLanguage)

  const data = ReactRedux.useSelector((state) => state.artificialTellsV2.topics)

  const { mutate, isLoading } = useAddBulkMutation()

  const topicName = data?.topics?.find(
    (topic) => topic.id === parseInt(topicString, 10)
  )?.name

  const groupEntries = textareaValue
    .split('\n')
    .map((x) => x.trim())
    .filter((x) => x.length > 0)
    .map((x) => x.replace(/^[0-9]+\.?/, ''))
    .map((x) => x.replace(/^"/, ''))
    .map((x) => x.replace(/"$/, ''))
    .map((x) => x.trim())

  const onChangeTopicString = (e) => {
    setTopicString(e.target.value)
  }

  const onChangeTextArea = (e) => {
    setTextareaValue(e.target.value)
  }

  const onPressSend = () => {
    const topicId = parseInt(topicString, 10)

    mutate({ language, topicId, content: groupEntries })
  }

  React.useEffect(() => {
    dispatch(refreshTopics())
  }, [language])

  return (
    <View style={containerStyle}>
      <Box padding={16}>
        <Typography.Title>Artificial Tell Spreadsheet Parser</Typography.Title>

        <Box marginTop={24} alignItems="flex-start">
          <SelectorsLanguage />

          <Box marginTop={12}>
            <Typography.Text style={{ fontSize: 12, fontWeight: 'bold' }}>
              Topic id
            </Typography.Text>

            <Box flexDirection="row" alignItems="center">
              <Input
                value={topicString}
                onChange={onChangeTopicString}
                style={{ width: 180 }}
              />

              <Typography.Text
                style={{ color: colors.grey[7], marginLeft: 12 }}>
                {topicName}
              </Typography.Text>
            </Box>
          </Box>

          <Box flexDirection="row" flex={1} marginTop={12} width="100%">
            <Box flex={1} alignItems="flex-start">
              <Input.TextArea
                placeholder="Paste the groups you would like to add to the topic here"
                value={textareaValue}
                onChange={onChangeTextArea}
                autoSize={{ minRows: 15, maxRows: 15 }}
                style={{ width: '100%' }}
              />
              <Button
                disabled={!topicString || !textareaValue}
                loading={isLoading}
                type="primary"
                onClick={onPressSend}
                style={{ marginTop: 12 }}>
                Add to topic
              </Button>
            </Box>

            <Box marginLeft={16} flex={1}>
              {groupEntries.map((entry, index) => (
                <Typography.Text
                  key={entry}
                  style={{ color: colors.grey[7] }}>{`${
                  index + 1
                }. ${entry}`}</Typography.Text>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </View>
  )
}
