import { Table } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { copyToClipboard } from '@tellonym/core/share/actions'
import * as Redux from 'react-redux'
import React from 'react'
import { Box, Text, styleSheets } from '../../common'
import { useUserStats } from '../queries'

const rowsWithIndentation = [
  'attributionPageViewsSum',
  'attributionDownloadClicksSum',
]

const BoldText = (props) => {
  const string = typeof props === 'object' ? props?.children ?? '' : props

  return (
    <Text semibold type={props?.type ?? 'small'}>
      {string}
    </Text>
  )
}

const FirstColumn = (text, record) => {
  if (rowsWithIndentation.includes(record.key)) {
    return (
      <Text type="note" style={styleSheets.margin.left[16]}>
        {text}
      </Text>
    )
  }

  return <BoldText>{text}</BoldText>
}

/**
 * Data structure:
 * [{key: "pageViews", data: { "2-2023": 4, "2-2023": 5, ... }}, ...]
 */
const getDataForCalendarWeek = (data, cwKey) =>
  data.reduce((acc, curr) => {
    acc.push(curr.data[cwKey])

    return acc
  }, [])

const CopyableColumnHeader = ({ text, data, cwKey }) => {
  const dispatch = Redux.useDispatch()

  const copyRowData = () => {
    dispatch(copyToClipboard(getDataForCalendarWeek(data, cwKey).join(', ')))
  }

  return (
    <Box
      onPress={copyRowData}
      backgroundColor="transparent"
      flexDirection="row"
      alignItems="center">
      <BoldText>{text}</BoldText>
      <CopyOutlined style={styleSheets.margin.left[8]} />
    </Box>
  )
}

const getTableColumns = (data) => {
  if (!data || data.length === 0) {
    return []
  }

  const result = Object.keys(data[0].data).map((key) => {
    const title = `CW ${key.split('-')?.[0] ?? '?'}`

    return {
      title: <CopyableColumnHeader text={title} data={data} cwKey={key} />,
      dataIndex: ['data', key],
      width: 120,
    }
  })

  return [
    {
      title: <BoldText>Web Analytics</BoldText>,
      dataIndex: 'title',
      width: 230,
      fixed: true,
      render: FirstColumn,
    },
    {
      title: <BoldText>Summary</BoldText>,
      dataIndex: 'sum',
      width: 120,
      render: BoldText,
    },
    ...result,
  ]
}

/**
 * Antd Table needs a fixed parent to have a horizontable scrollable table.
 * So we first render the table with flex 1, then we set the measured width
 * as a fixed one. After that we render the table.
 */
export const TabAnalytics = ({ profile }) => {
  const ref = React.useRef(null)
  const [containerWidth, setContainerWidth] = React.useState(undefined)
  const { data, isFetching } = useUserStats({ userId: profile.id })

  const columns = React.useMemo(() => getTableColumns(data), [data])
  const isRenderReady = containerWidth > 0

  React.useEffect(() => {
    if (ref?.current?.offsetWidth) {
      setContainerWidth(ref.current.offsetWidth)
    }
  }, [ref])

  return (
    <Box
      ref={ref}
      flex={1}
      justifyContent="flex-start"
      paddingTop={36}
      width={containerWidth}>
      {isRenderReady && (
        <Table
          columns={columns}
          dataSource={data}
          loading={isFetching}
          scroll={{ x: 1 }}
          pagination={false}
        />
      )}
    </Box>
  )
}
