import { grey } from '@ant-design/colors'
import { TODO_ENTITY_TYPE, TODO_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Input, InputNumber, Modal, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, hooks, styleSheets } from '../../common'
import { createTodo } from '../actionsV2'

const config = {
  submitAction: createTodo,
  defaultState: {
    status: TODO_STATUS.OPEN,
    content: '',
    dueInXDays: 7,
  },
}

export const ButtonModalCreateTodo = ({
  group,
  topic,
  children = 'Create Todo',
  language,
  size = 'small',
  ...buttonProps
}) => {
  const dispatch = ReactRedux.useDispatch()
  const inputRef = React.useRef(null)
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [state, setState] = React.useState(config.defaultState)

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      language,
      referenceId: topic?.id ?? group?.id,
      type:
        typeof topic?.id !== 'undefined'
          ? TODO_ENTITY_TYPE.TOPIC
          : TODO_ENTITY_TYPE.GROUP,
    }))
  }, [group, topic, language])

  const toggleModal = () => {
    setIsModalVisible((isModalVisible) => !isModalVisible)
  }

  const onSubmit = () => {
    if (state.content.length === 0) {
      return
    }

    toggleModal()

    dispatch(config.submitAction(state))

    setState((state) => ({
      ...state,
      ...config.defaultState,
    }))
  }

  const onChangeDueInXDays = (value) => {
    setState((state) => ({
      ...state,
      dueInXDays: value,
    }))
  }

  const creatorSetDueInXDays = (days) => () => {
    setState((state) => ({
      ...state,
      dueInXDays: days,
    }))
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit,
    hasActionKey: true,
  })

  return (
    <>
      <Button onClick={toggleModal} size={size} {...buttonProps}>
        {children}
      </Button>
      <Modal
        open={isModalVisible}
        onOk={onSubmit}
        onCancel={toggleModal}
        width="75%">
        <Box>
          <Typography.Title level={2}>{children}</Typography.Title>

          <Typography.Text style={{ color: grey[1] }}>
            {typeof topic?.id !== 'undefined'
              ? `Will be assigned to topic ${topic.id} "${topic.name}".`
              : `Will be assigned to group ${group.id} "${group.name}".`}
          </Typography.Text>

          <Input.TextArea
            ref={inputRef}
            autoSize={{ minRows: 2, maxRows: 5 }}
            onChange={(e) => {
              setState((state) => ({ ...state, content: e.target.value }))
            }}
            value={state.content}
          />

          <Box alignItems="flex-end" style={styleSheets.margin.top[12]}>
            <Box alignItems="center" flexDirection="row">
              <Typography.Text style={styleSheets.margin.right[8]}>
                Due in days:
              </Typography.Text>
              <InputNumber
                onChange={onChangeDueInXDays}
                value={state.dueInXDays}
              />
            </Box>
            <Box flexDirection="row" style={styleSheets.margin.top[12]}>
              {[
                ['today', 0],
                ['1 day', 1],
                ['7 days', 7],
                ['30 days', 30],
              ].map(([text, value]) => (
                <Typography.Link
                  key={text}
                  onClick={creatorSetDueInXDays(value)}
                  style={styleSheets.margin.right[12]}>
                  {text}
                </Typography.Link>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
