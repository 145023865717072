import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { POST_TYPE } from '@tellonym/enums/lib/post'
import { SENDER_STATUS } from '@tellonym/enums/lib/Tell'
import * as actions from '@tellonym/core/answer/actions'
import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { throttle } from '@tellonym/core/helpers'
import { pathOr } from 'ramda'
import React from 'react'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { history } from '../history'
import { connectWithRouter } from '../hocs/connectWithRouter'
import { theme } from '../styles/theme'
import { Alert } from './Alert'
import { AnswerPicture } from './AnswerPicture'
import { Avatar } from './Avatar'
import { Icon } from './Icon'
import { ItemParentTag } from './ItemParentTag'
import { PostItem } from './PostItem'
import { PostItemHeader } from './PostItemHeader'
import { ReadMore } from './ReadMore'
import { Text } from './Text'
import { TouchableOpacity } from './TouchableOpacity'
import { Username } from './Username'
import { View } from './View'

export const Wrapper = ({ children, hasSeparator, noBottomSpacing, style }) => (
  <View>
    <View
      desktopStyle={{
        border: 0,
        marginBottom: noBottomSpacing ? 0 : 8,
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
      }}
      style={[
        {
          backgroundColor: theme.colors.background,
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 15,
          paddingRight: 20,
          borderBottomWidth: hasSeparator
            ? theme.dimensions.widths.borders[1]
            : 0,
          borderBottomColor: theme.colors.border,
          borderBottomStyle: 'solid',
        },
        style,
      ]}>
      {children}
    </View>
  </View>
)

export const Tell = ({ item, profile }) => {
  const isSenderHidden = item.senderStatus === SENDER_STATUS.HIDDEN
  const hasSender =
    item.senderStatus === SENDER_STATUS.HIDDEN ||
    item.senderStatus === SENDER_STATUS.PUBLIC
  const hasTag = !!pathOr(false, ['parent', 'content'], item)

  return (
    <View>
      {hasTag && <ItemParentTag item={item} style={{ marginLeft: 4 }} />}
      <View
        style={{
          marginTop: 10,
          marginLeft: hasSender ? 34 : 58,
          marginBottom: 10,
        }}>
        {hasSender ? (
          <View
            style={{
              flexDirection: 'row',
            }}>
            <TouchableOpacity
              onPress={throttle(() =>
                isSenderHidden
                  ? Alert.alert(
                      'Hidden Sender',
                      `This Tell wasn't sent anonymously, but ${profile.username} decided to not display the username of the sender.`
                    )
                  : history[
                      history.location.pathname === `/${item.sender.username}`
                        ? 'replace'
                        : 'push'
                    ](`/${item.sender.username}`)
              )}
              style={{ marginRight: 10 }}>
              <Avatar user={isSenderHidden ? {} : item.sender} size={24} />
            </TouchableOpacity>
            <ReadMore style={{ flex: 1 }} user={item.sender}>
              {item.tell}
            </ReadMore>
          </View>
        ) : (
          <View
            style={{
              borderLeft: `3px solid ${theme.colors.icon}`,
              paddingLeft: 10,
              paddingBottom: 4,
              paddingTop: 4,
            }}>
            <ReadMore>{item.tell}</ReadMore>
          </View>
        )}
      </View>
    </View>
  )
}

const Answer = ({ item }) => {
  const { answer, media } = item

  return (
    <View style={{ marginLeft: 10, marginBottom: 15 }}>
      <ReadMore>{answer}</ReadMore>
      <AnswerPicture media={media} />
    </View>
  )
}

const Footer = withRouter(({ item, history, onPressLike, profile }) => {
  const { isCurrentUserTellSender, likes = {} } = item
  const previewUsers = (likes.previewUsers || []).slice(
    0,
    likes.isLikedBySender ? 1 : 2
  )
  const additionalLikes =
    likes.count - previewUsers.length - (likes.isLikedBySender ? 1 : 0)
  const isLiked =
    likes.isLiked || (item.isCurrentUserTellSender && likes.isLikedBySender)
  const isOnlyLiker = likes.isLiked && likes.count === 1

  return (
    <View style={{ flexDirection: 'row' }}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 20,
        }}>
        <TouchableOpacity
          onPress={onPressLike}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 25,
          }}>
          <Icon
            icon={isLiked ? faHeart : faHeartOutline}
            color={isLiked ? theme.colors.primary : theme.colors.icon}
          />
        </TouchableOpacity>
        {likes.count > 0 && !isOnlyLiker && (
          <View
            style={{
              flex: 1,
              paddingLeft: 8,
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}>
            <Text type="small" color={theme.colors.textLight}>
              {'Liked by '}
              {likes.isLikedBySender && (
                <Username
                  bold={false}
                  hasVerifiedIcon={false}
                  onPress={() =>
                    isCurrentUserTellSender
                      ? Alert.alert(
                          'Sender Status',
                          'As the author of this Tell, your interactions with this post remain anonymous. Your name will be replaced by "sender". You can like this answer publicly by cancelling your sender status. However, this cannot be undone.'
                        )
                      : Alert.alert(
                          'The anonymous author of this Tell likes that post.',
                          'Anonymous Sender'
                        )
                  }
                  user={{ username: 'sender' }}
                />
              )}
              {previewUsers.map((user, index) => (
                <Text
                  key={user.username}
                  type="small"
                  color={theme.colors.textLight}>
                  {index > 0 || likes.isLikedBySender ? ', ' : ''}
                  <Username bold={false} hasVerifiedIcon={false} user={user} />
                </Text>
              ))}
              {additionalLikes > 0 && (
                <Text type="small" color={theme.colors.textLight}>
                  {' and '}
                  <Username
                    bold={false}
                    hasVerifiedIcon={false}
                    onPress={throttle(() =>
                      history.push(
                        `/${profile.username}/answer/${item.id}/likes`,
                        {
                          isLikedBySender: likes.isLikedBySender,
                        }
                      )
                    )}
                    user={{
                      username:
                        additionalLikes > 1
                          ? `${additionalLikes} others`
                          : `${additionalLikes} other`,
                    }}
                  />
                </Text>
              )}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
})

class AnswerItemComponent extends React.Component {
  _onPopupEvent = () => {}

  _onPressLike = () => {
    const { actions, item } = this.props
    const { user } = item

    const { likes = {} } = item
    const isLiked =
      likes.isLiked || (item.isCurrentUserTellSender && likes.isLikedBySender)

    if (isLiked) {
      actions.unlikeAnswer({ answerId: item.id, userId: user.id })
    } else {
      actions.likeAnswer({ answerId: item.id, userId: user.id })
    }
  }

  _onPressProfile = throttle(() => {
    const { history, item } = this.props
    const { user } = item

    const pathname = `/user/${user.id}`
    return history[history.location.pathname === pathname ? 'replace' : 'push'](
      pathname
    )
  })

  render() {
    const {
      hasSeparator,
      isCurrentUser,
      item,
      noBottomSpacing = false,
    } = this.props

    if (!item || item._isHidden) {
      return null
    }

    const profile = item.user

    if (
      item.type === POST_TYPE.REQUEST_TELL ||
      item.postType === POST_TYPE.REQUEST_TELL ||
      (!item.tell && item.answer)
    ) {
      return (
        <PostItem
          hasSeparator={hasSeparator}
          isCurrentUser={isCurrentUser}
          item={item}
          profile={profile}
        />
      )
    }

    const isSenderHidden = item.senderStatus === SENDER_STATUS.HIDDEN

    const parsedItem = isSenderHidden
      ? {
          ...item,
          sender: {
            ...item.sender,
            isSenderHidden,
            receiver: isCurrentUser
              ? profile.username
              : item.user
              ? item.user.username
              : 'recipient',
            username: 'hidden',
          },
        }
      : item

    const menuItems = [
      parsedItem.isCurrentUserTellSender && 'Remove Sender Status',
      !isCurrentUser && 'Report',
      isCurrentUser && 'Delete Answer',
    ].filter(Boolean)

    return (
      <Wrapper
        hasSeparator={hasSeparator}
        isCurrentUserTellSender={parsedItem.isCurrentUserTellSender}
        noBottomSpacing={noBottomSpacing}>
        <PostItemHeader
          item={parsedItem}
          menuItems={menuItems}
          onPressMenu={this._onPopupEvent}
          onPressProfile={this._onPressProfile}
          profile={profile}
        />
        <Tell item={parsedItem} profile={profile} />
        <Answer item={parsedItem} />
        <Footer
          item={parsedItem}
          onPressLike={this._onPressLike}
          profile={profile}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: getIsLoggedIn,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})

export const AnswerItem = connectWithRouter(
  mapStateToProps,
  mapDispatchToProps
)(AnswerItemComponent)
