import * as t from './types'

export const fetchUsers = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.FETCH_USERS,
  payload: { limit },
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/users`,
        method: 'GET',
        ...payload,
      },
      commit: {
        type: t.FETCH_USERS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.FETCH_USERS_ERROR },
    },
  },
})

export const fetchLastDetectedSpam = ({ limit = 12, ...payload }) => ({
  type: t.FETCH_LAST_DETECTED_SPAM,
  payload: { limit },
  meta: {
    offline: {
      effect: {
        path: 'admin/spam/list',
        method: 'GET',
        ...payload,
      },
      commit: { type: t.FETCH_LAST_DETECTED_SPAM_SUCCESS },
      rollback: { type: t.FETCH_LAST_DETECTED_SPAM_ERROR },
    },
  },
})

export const fetchPosts = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.FETCH_POSTS,
  payload: { limit },
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/posts`,
        method: 'GET',
        ...payload,
      },
      commit: {
        type: t.FETCH_POSTS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.FETCH_POSTS_ERROR },
    },
  },
})

export const fetchTells = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.FETCH_TELLS,
  payload: { limit },
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/tells`,
        method: 'GET',
        ...payload,
      },
      commit: {
        type: t.FETCH_TELLS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.FETCH_TELLS_ERROR },
    },
  },
})

export const filterLastDetectedSpam = (payload) => ({
  type: t.FILTER_LAST_DETECTED_SPAM,
  payload,
})

export const refreshLastBlocked = ({ limit = 12, ...payload }) => ({
  type: t.REFRESH_LAST_BLOCKED_DATA,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/tells/detectiontype?limit=${limit}`,
        method: 'POST',
        limit,
      },
      commit: { type: t.REFRESH_LAST_BLOCKED_DATA_SUCCESS },
      rollback: { type: t.REFRESH_LAST_BLOCKED_DATA_ERROR },
    },
  },
})

export const refreshLastDetectedSpam = ({ limit = 12 }) => ({
  type: t.REFRESH_LAST_DETECTED_SPAM,
  payload: {
    limit,
  },
  meta: {
    offline: {
      effect: {
        path: 'admin/spam/list',
        method: 'GET',
        limit,
      },
      commit: { type: t.REFRESH_LAST_DETECTED_SPAM_SUCCESS },
      rollback: { type: t.REFRESH_LAST_DETECTED_SPAM_ERROR },
    },
  },
})

export const refreshLastDetectedSpamItem = ({ spamItemId, ...payload }) => ({
  type: t.REFRESH_LAST_DETECTED_SPAM_ITEM,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}`,
        method: 'GET',
      },
      commit: { type: t.REFRESH_LAST_DETECTED_SPAM_ITEM_SUCCESS },
      rollback: { type: t.REFRESH_LAST_DETECTED_SPAM_ITEM_ERROR },
    },
  },
})

export const refreshUsers = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.REFRESH_USERS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/users`,
        method: 'GET',
        limit,
      },
      commit: {
        type: t.REFRESH_USERS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.REFRESH_USERS_ERROR },
    },
  },
})

export const refreshPosts = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.REFRESH_POSTS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/posts`,
        method: 'GET',
        limit,
      },
      commit: {
        type: t.REFRESH_POSTS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.REFRESH_POSTS_ERROR },
    },
  },
})

export const refreshTells = ({ limit = 12, spamItemId, ...payload }) => ({
  type: t.REFRESH_TELLS,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${spamItemId}/tells`,
        method: 'GET',
        limit,
      },
      commit: {
        type: t.REFRESH_TELLS_SUCCESS,
        meta: { spamItemId },
      },
      rollback: { type: t.REFRESH_TELLS_ERROR },
    },
  },
})

export const undoSpamItem = (payload) => ({
  type: t.UNDO_SPAM_ITEM,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${payload.spamItemId}/undo`,
        method: 'POST',
      },
      commit: { type: t.UNDO_SPAM_ITEM_SUCCESS, meta: payload },
      rollback: { type: t.UNDO_SPAM_ITEM_ERROR },
    },
  },
})

export const whitelistTell = (payload) => ({
  type: t.WHITELIST_TELL,
  payload,
  meta: {
    offline: {
      effect: {
        path: `admin/spam/id/${payload.id}/whitelist?tellId=${payload.tellId}`,
        method: 'POST',
      },
      commit: { type: t.WHITELIST_TELL_SUCCESS },
      rollback: { type: t.WHITELIST_TELL_ERROR },
    },
  },
})
