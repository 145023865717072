import React from 'react'
import { createStore } from 'redux'
import { Overlay } from './Overlay'

const initialState = { isVisible: false }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW':
      return { ...state, isVisible: true, ...action.payload }
    case 'HIDE':
      return initialState
    default:
      return state
  }
}

const store = createStore(reducer)

const hide = () => store.dispatch({ type: 'HIDE' })

const show = (payload) => store.dispatch({ type: 'SHOW', payload })

class RenderModal extends React.Component {
  static hide = hide
  static show = show

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => this.forceUpdate())
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  render() {
    const { isVisible, render = () => {} } = store.getState()

    return isVisible ? (
      <Overlay onPress={hide}>
        {typeof render === 'function' && render()}
      </Overlay>
    ) : null
  }
}

export { RenderModal }
