let reduxStore

export const setStore = (store) => {
  reduxStore = store
}

export const store = {
  getState: () => reduxStore?.getState() ?? {},
  dispatch: (action) => reduxStore?.dispatch(action),
}
