import { intHash } from '@tellonym/core/helpers'
import P from 'prop-types'
import React from 'react'
import { config } from '../../../config'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'
import { Image } from './Image'
import { View } from './View'

export const colors = [
  '#43A4C4',
  '#43C4A4',
  '#75BF8B',
  '#BF75A9',
  '#7584BF',
  '#7CBF75',
  '#BF9375',
  '#D16952',
  '#52D1D1',
  '#7584BF',
  '#43C4A4',
  '#7AD152',
  '#7CBF75',
  '#5281D1',
  '#8552D1',
  '#D152C2',
  '#D1527C',
  '#D17052',
  '#ABD138',
  '#3D9AD4',
  '#3DD490',
  '#3DD442',
  '#ABD138',
  '#D138AB',
  '#C9AC28',
  '#14DBD8',
  '#2199CC',
  '#D65822',
  '#FF2200',
]

const getImageSize = (s) =>
  s <= 50 ? 'thumb' : s <= 200 ? 'xs' : s <= 350 ? 'md' : s <= 500 ? 'lg' : 'xs'

const getActivityIndicatorBorderWidth = (s) =>
  s <= 50 ? 2 : s <= 75 ? 3 : s <= 100 ? 4 : 6

const Avatar = Radium(
  ({
    activityIndicatorBorderColor,
    borderColor,
    borderWidth,
    hasActivityIndicator,
    rectangle = false,
    size,
    user,
    style,
  }) => (
    <View style={{ position: 'relative', ...style }}>
      {user.avatarFileName ? (
        <Image
          alt="avatar"
          style={{
            width: size,
            height: size * (rectangle ? 1.3 : 1),
            borderRadius: rectangle ? 12 : '50%',
            borderColor,
            borderWidth,
            borderStyle: borderWidth ? 'solid' : undefined,
          }}
          source={{
            uri: user.avatarFileName.includes('/')
              ? user.avatarFileName
              : rectangle
              ? `${config.api.userimg.lg_v2}/${user.avatarFileName}`
              : `${config.api.userimg[getImageSize(size)]}/${
                  user.avatarFileName
                }`,
          }}
        />
      ) : (
        <svg height={size * (rectangle ? 1.3 : 1)} width={size}>
          {rectangle ? (
            <rect
              width={size}
              height={size * 1.3}
              rx={12}
              ry={12}
              fill={
                user.username
                  ? colors[intHash(user.username) % colors.length]
                  : theme.colors.borderLightGrey
              }
              stroke={borderColor}
              strokeWidth={borderWidth}
            />
          ) : (
            <circle
              cx={size / 2}
              cy={size / 2}
              r={size / 2}
              fill={
                user.username
                  ? colors[intHash(user.username) % colors.length]
                  : theme.colors.borderLightGrey
              }
              stroke={borderColor}
              strokeWidth={borderWidth}
            />
          )}
          <text
            x={size / 2}
            y={((size * 2) / 3) * (rectangle ? 1.3 : 1)}
            fill="white"
            textAnchor="middle"
            fontSize={size / 2}
            fontFamily="Verdana,Lucida,sans-serif">
            {user.username ? user.username[0].toUpperCase() : ''}
          </text>
        </svg>
      )}
      {hasActivityIndicator && user.isActive && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 20,
            width: size / 5,
            height: size / 5,
            borderRadius: '50%',
            borderColor: activityIndicatorBorderColor,
            borderStyle: 'solid',
            borderWidth: getActivityIndicatorBorderWidth(size),
            backgroundColor: theme.colors.shinyGreen,
          }}
        />
      )}
    </View>
  )
)

Avatar.propTypes = {
  activityIndicatorBorderColor: P.string,
  borderColor: P.string,
  borderWidth: P.number,
  hasActivityIndicator: P.bool,
  size: P.number,
  user: P.shape({
    avatarFileName: P.string,
    isActive: P.bool,
    username: P.string,
  }),
}

Avatar.defaultProps = {
  activityIndicatorBorderColor: theme.colors.background,
  borderColor: undefined,
  borderWidth: 0,
  hasActivityIndicator: false,
  size: 50,
  user: {},
}

export { Avatar }
