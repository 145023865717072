import { EllipsisOutlined } from '@ant-design/icons'
import { User } from '@tellonym/enums'
import { Button, Checkbox, Input, Modal, Tooltip } from 'antd'
import React from 'react'
import { Box, styleSheets } from '../../common'
import { useShadowBanMutation, useUndoShadowBanMutation } from '../queries'

const ModalShadowBan = ({
  onChangeTextReason,
  shouldBlockSentTells,
  textReason,
  toggleShouldBlockSentTells,
}) => (
  <Box>
    <Input.TextArea
      value={textReason}
      onChange={(e) => onChangeTextReason(e.target.value)}
      placeholder="Reason for internal usage..."
      rows={4}
    />

    <Checkbox
      onChange={toggleShouldBlockSentTells}
      checked={shouldBlockSentTells}
      style={styleSheets.margin.top[24]}>
      Block all sent tells
    </Checkbox>
  </Box>
)

export const ShadowBanButtons = ({ onActionTaken, profile, report }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [shouldBlockSentTells, setShouldBlockSentTells] = React.useState(false)
  const [textReason, setTextReason] = React.useState('')

  const isShadowBanned = [
    User.USER_TYPE.BAN_SHADOW,
    User.USER_TYPE.BAN_SHADOW_AUTOMATIC,
  ].includes(profile?.type)

  const { mutate: triggerShadowBan } = useShadowBanMutation()

  const { mutate: triggerUndoShadowBan } = useUndoShadowBanMutation()

  const shadowBan = () => {
    onActionTaken?.()
    setIsModalVisible(false)

    triggerShadowBan({
      userId: report?.user?.id ?? profile?.id,
      reportId: report?.id,
      shouldBlockSentTells,
      textReason,
    })
  }

  const undoShadowBan = () => {
    onActionTaken?.()

    triggerUndoShadowBan({
      userId: report?.user?.id ?? profile?.id,
      reportId: report?.id,
    })
  }

  const openShadowBanModal = () => {
    setIsModalVisible(true)
  }

  const closeShadowBanModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Modal
        open={isModalVisible}
        onOk={shadowBan}
        onCancel={closeShadowBanModal}
        title="Shadow Ban"
        width="75%">
        <ModalShadowBan
          onChangeTextReason={setTextReason}
          shouldBlockSentTells={shouldBlockSentTells}
          textReason={textReason}
          toggleShouldBlockSentTells={() =>
            setShouldBlockSentTells((prev) => !prev)
          }
        />
      </Modal>
      {isShadowBanned ? (
        <Tooltip title="Update user type to normal, closes report but does not inform user, recount followings">
          <Button shape="round" onClick={undoShadowBan}>
            Undo shadow ban
          </Button>
        </Tooltip>
      ) : (
        <Box flexDirection="row" marginBottom={12}>
          <Tooltip
            title="Set user type to SHADOW_BAN, closes report but does not inform user, recounts followings, deletes likes, if you want to block sent tells as well use dots menu on the right"
            placement="top">
            <Button shape="round" onClick={shadowBan} style={{ flex: 1 }}>
              Shadow ban
            </Button>
          </Tooltip>
          <Button
            shape="circle"
            onClick={openShadowBanModal}
            icon={<EllipsisOutlined />}
            style={styleSheets.margin.left[8]}
          />
        </Box>
      )}
    </>
  )
}
