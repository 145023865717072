import { CloseOutlined } from '@ant-design/icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { colors } from '@tellonym/core/common/colorSystem'
import React from 'react'
import RNText from 'react-native-web/dist/exports/Text'
import { config } from '../../../config'
import {
  Box,
  Icon,
  Image,
  Text,
  TouchableOpacity,
  history,
  theme,
} from '../../common'
import { RNImage } from '../../common/components/Image'

export const ProfilePicture = ({
  avatarFileName,
  isSmall = false,
  ...props
}) => {
  const style = React.useMemo(
    () => ({
      width: isSmall ? 78 : 142,
      height: isSmall ? 106 : 188,
      borderRadius: isSmall ? 8 : 12,
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: 1,
      objectFit: 'cover',
    }),
    []
  )

  return (
    <Box transparent marginRight={8} {...style} {...props}>
      <Image
        style={style}
        source={{
          uri: avatarFileName?.startsWith?.('http')
            ? avatarFileName
            : `${config.api.userimg.lg_v2}/${avatarFileName}`,
        }}
      />
    </Box>
  )
}

export const AccountVerificationItem = ({
  item,
  onPressRemoveVerification,
  onPressSelect,
}) => {
  const [isSelected, setIsSelected] = React.useState()
  const [isRemoved, setIsRemoved] = React.useState()

  const onSelect = React.useCallback(() => {
    onPressSelect(item.id)
    setIsSelected((prev) => !prev)
  }, [isSelected, item.id, onPressSelect])

  const styles = React.useMemo(
    () => ({
      nameColor: isSelected ? theme.colors.placeholder : theme.colors.black,
      titleColor: isSelected
        ? theme.colors.placeholder
        : theme.colors.greyishBlack,
      filter: isSelected ? 'blur(1px)' : undefined,
    }),
    [isSelected]
  )

  const openInNewTab = React.useCallback(() => {
    window.open(
      `${config.api.host.replace('api', 'modcp')}/user/${
        item.id
      }?stage=Settings`,
      '_blank'
    )
  }, [])

  const onPressRemove = React.useCallback(() => {
    onPressRemoveVerification(item.id)
    setIsRemoved(true)
  }, [item.id])

  if (isRemoved) {
    return null
  }

  return (
    <Box transparent position="relative">
      <Box transparent position="absolute" top={28} right={60} zIndex={1000}>
        <input
          type="checkbox"
          name="isSelected"
          checked={isSelected}
          onChange={onSelect}
          style={{
            width: 24,
            height: 24,
          }}
        />
      </Box>

      <Box
        width={470}
        alignSelf="center"
        backgroundColor="#F4F8FF"
        borderRadius={24}
        paddingTop={16}
        paddingRight={12}
        paddingBottom={24}
        paddingLeft={16}
        margin={8}
        filter={styles.filter}
        zIndex={90}>
        <Box
          transparent
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Box transparent flexDirection="row" alignItems="center" width={350}>
            <RNText
              ellipsizeMode="tail"
              numberOfLines={1}
              onPress={() => {
                history.push(`/user/${item.id}?stage=Settings`)
              }}
              style={{
                fontSize: 20,
                fontWeight: '700',
                marginRight: 4,
                color: styles.nameColor,
              }}>
              {item.displayName}
            </RNText>
            <RNImage
              source={'/assets/img/verified.png'}
              style={{
                width: 20,
                height: 20,
                marginRight: 4,
                tintColor: colors.blue[2],
              }}
            />
            <RNText
              ellipsizeMode="tail"
              numberOfLines={1}
              onPress={() => {
                history.push(`/user/${item.id}?stage=Settings`)
              }}
              style={{
                fontSize: 20,
                fontWeight: '700',
                marginRight: 4,
                color: styles.nameColor,
              }}>
              {`@${item.username}`}
            </RNText>
            <TouchableOpacity
              onPress={openInNewTab}
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
                openInNewTab()
              }}>
              <Icon
                icon={faExternalLinkAlt}
                color={theme.colors.primary}
                size="lg"
              />
            </TouchableOpacity>
          </Box>
          <Box onPress={onPressRemove} transparent>
            <CloseOutlined
              style={{
                fontSize: 22,
                padding: 4,
                alignSelf: 'center',
              }}
            />
          </Box>
        </Box>

        <Box transparent>
          <Box transparent marginTop={8}>
            <Text
              style={{
                color:
                  item.avatars.length > 0 ? styles.titleColor : 'transparent',
              }}>
              Profile Photos
            </Text>
            <Box transparent flexDirection="row" marginTop={4}>
              {item.avatars.length > 0 ? (
                item.avatars.map(({ avatarFileName }) => (
                  <ProfilePicture
                    key={avatarFileName}
                    avatarFileName={avatarFileName}
                  />
                ))
              ) : (
                <Box
                  transparent
                  flex={1}
                  justifyContent="center"
                  height={188}
                  alignItems="center">
                  <Text style={{ color: styles.titleColor }}>
                    User doesn't have any profile photos to display
                  </Text>
                </Box>
              )}
            </Box>
          </Box>

          <Box transparent marginTop={32}>
            <Text style={{ color: styles.titleColor }}>
              Verification Photos
            </Text>
            <Box flexDirection="row" marginTop={4} transparent>
              <Box transparent marginTop={8}>
                <Text
                  fontWeight="500"
                  style={{
                    color: colors.grey[6],
                    fontSize: 14,
                    marginBottom: 8,
                  }}>
                  Current
                </Text>
                <ProfilePicture
                  key={item.verificationPhoto}
                  avatarFileName={item.verificationPhoto}
                />
              </Box>

              <Box
                marginTop={8}
                flexDirection="row"
                overflowX="scroll"
                width={300}
                transparent>
                {item.previousVerificationPhotos?.map(
                  (avatarFileName, index) => (
                    <Box key={avatarFileName} transparent>
                      <Text
                        fontWeight="500"
                        style={{
                          color: colors.grey[6],
                          fontSize: 14,
                          marginBottom: 8,
                        }}>
                        {index === 0 ? 'Previous' : ' '}
                      </Text>
                      <ProfilePicture avatarFileName={avatarFileName} isSmall />
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
