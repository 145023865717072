import { BAN_REASON } from '@tellonym/enums/lib/User'
import dayjs from 'dayjs'

const {
  FAKE_ACCOUNT,
  HARASSMENT,
  INAPPROPRIATE_CONTENT,
  INAPPROPRIATE_USERNAME,
  NO_PARENTAL_CONFIRMATION,
  SELF_HARM,
  SEXUAL_CONTENT,
  SEXUAL_HARASSMENT,
} = BAN_REASON

const getDEMessageForBanReason = (banReason) => {
  switch (banReason) {
    case SEXUAL_HARASSMENT:
      return 'Sexuelle Belästigung - Informiere Dich über unsere Nutzerregeln!'

    case SEXUAL_CONTENT:
      return 'Sexueller Inhalt - Informiere Dich über unsere Nutzerregeln!'

    case SELF_HARM:
      return 'Selbstverletzung - Informiere Dich über unsere Nutzerregeln!'

    case INAPPROPRIATE_CONTENT:
      return 'Unangebrachter Inhalt - Informiere Dich über unsere Nutzerregeln!'

    case HARASSMENT:
      return 'Beleidigung - Informiere Dich über unsere Nutzerregeln!'

    case FAKE_ACCOUNT:
      return 'Fake Account - Informiere Dich über unsere Nutzerregeln!'

    case NO_PARENTAL_CONFIRMATION:
      return 'Keine Bestätigung der Erziehungsberechtigten - Informiere Dich über unsere Nutzerregeln!'

    case INAPPROPRIATE_USERNAME:
      return 'Unangebrachter Nutzername - Bitte kontaktiere unseren Support.'

    default:
      return 'Du hast dich nicht an unsere Nutzungsbedingungen gehalten und wurdest deshalb gesperrt.'
  }
}

const getENMessageForBanReason = (banReason) => {
  switch (banReason) {
    case SEXUAL_HARASSMENT:
      return 'Sexual Harassment - Inform yourself about our Community Guidelines!'

    case SEXUAL_CONTENT:
      return 'Sexual Content - Inform yourself about our Community Guidelines!'

    case SELF_HARM:
      return 'Self Harm - Inform yourself about our Community Guidelines!'

    case INAPPROPRIATE_CONTENT:
      return 'Disturbing/Inappropriate Content - Harassment - Inform yourself about our Community Guidelines!'

    case HARASSMENT:
      return 'Harassment - Inform yourself about our Community Guidelines!'

    case FAKE_ACCOUNT:
      return 'Fake Account - Inform yourself about our Community Guidelines!'

    case NO_PARENTAL_CONFIRMATION:
      return 'No Parental Confirmation - Inform yourself about our Community Guidelines!'

    case INAPPROPRIATE_USERNAME:
      return 'Inappropriate Username - Please contact support.'

    default:
      return 'You did not comply with our terms of use and therefore received a ban.'
  }
}

export const getMessageForBanReason = (lang, banReason) => {
  if (lang === 'de') {
    return getDEMessageForBanReason(banReason)
  }

  return getENMessageForBanReason(banReason)
}

export const getPreviewMessage = (lang, message, endDate) => {
  if (lang === 'de') {
    return `Du bist von unseren Diensten ausgeschlossen. Grund: ${message}${
      endDate
        ? ` Verbleibende Stunden: ${dayjs(endDate).diff(
            dayjs().second(0),
            'hours'
          )}`
        : ''
    }`
  }

  return `You are banned from our services. Reason: ${message}${
    endDate
      ? ` Hours left: ${dayjs(endDate).diff(dayjs().second(0), 'hours')}`
      : ''
  }`
}
