import React from 'react'
import { Radium } from '../hocs/Radium'

const defaultStyle = {
  display: 'flex',
  flexDirection: 'column',
}

const mobileOnly = {
  '@media (min-width: 481px)': {
    display: 'none',
  },
}

const desktopOnly = {
  '@media (max-width: 480px)': {
    display: 'none',
  },
}

export const View = Radium(
  ({
    desktop = false,
    desktopStyle,
    forwardRef,
    mobile = false,
    onPress,
    style,
    ...props
  }) => (
    <div
      {...props}
      ref={forwardRef}
      onClick={onPress}
      style={[
        defaultStyle,
        style,
        desktopStyle && {
          '@media (min-width: 481px)': {
            ...desktopStyle,
          },
        },
        mobile && mobileOnly,
        desktop && desktopOnly,
      ]}
    />
  )
)
