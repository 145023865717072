import { colors } from '@tellonym/core/common/colorSystem'
import React from 'react'
import { fontSizes } from '../styles/fontSizes'
import { AvatarGroup } from './AvatarGroup'
import { Box } from './Box'
import { IconVerified } from './IconVerified'
import { Text } from './Text'

export const ProfileCardUser = ({
  additionalText,
  containerStyle,
  isTwoLine,
  user = {},
}) => {
  const { height: textHeight } = React.useMemo(
    () => ({
      height: isTwoLine ? 48 : 20,
    }),
    [isTwoLine]
  )

  return (
    <Box style={containerStyle}>
      <Box
        borderWidth={1}
        borderColor={colors.grey[1]}
        borderRadius={8}
        overflow="hidden">
        <AvatarGroup user={user} />
      </Box>
      <Box width="100%" marginTop={8} height={textHeight}>
        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
          marginHorizontal={user.isVerified ? 5 : 0}>
          <Text
            color={colors.black[1]}
            type="small"
            style={{
              fontWeight: '700',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {user.displayName}
          </Text>
          {user.isVerified && (
            <Box marginLeft={2}>
              <IconVerified size={12} />
            </Box>
          )}
        </Box>
        {isTwoLine && typeof additionalText !== 'undefined' && (
          <Text
            color={colors.grey[6]}
            fontSize={fontSizes.note}
            center
            style={{
              marginTop: 2,
              fontWeight: '400',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {additionalText}
          </Text>
        )}
      </Box>
    </Box>
  )
}
