import { getAccount } from '@tellonym/core/app/selectors'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

const getState = prop('__app__')

export const getIsSidemenuShown = createSelector(
  getState,
  prop('isSidemenuShown')
)

export const getPermissions = createSelector(
  getAccount,
  propOr([], 'permissions')
)

export const getCustomApiHost = createSelector(
  getState,
  propOr([], 'customApiHost')
)
