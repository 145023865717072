// @flow

import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {
  Avatar,
  Box,
  Icon,
  Link,
  moment,
  Text,
  theme,
  View,
} from '../../common'
import { IconVerified } from '../../common/components/IconVerified'
import { UserTypeBadge } from './UserTypeBadge'

const LastActive = ({ timestamp }) => (
  <View style={{ marginRight: 24 }}>
    <Text center type="small">
      last active:
    </Text>
    <Text type="small" color={theme.colors.placeholder}>
      {moment(timestamp).fromNow()}
    </Text>
  </View>
)

export const SearchItem = ({ profile }) => (
  <View
    style={{
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      borderRadius: 12,
      paddingVertical: 12,
      paddingHorizontal: 24,
      marginBottom: 12,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
      width: '70%',
    }}>
    <Link to={`/user/${profile.id}`} style={{ alignItems: 'center', flex: 1 }}>
      <Avatar user={profile} />
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginLeft: 12,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <View style={{ justifyContent: 'center' }}>
          <View style={{ flexDirection: 'row' }}>
            <Text bold>{profile.displayName}</Text>
            {profile.isVerified && (
              <Box marginLeft={4}>
                <IconVerified />
              </Box>
            )}
          </View>
          <Text color={theme.colors.placeholder}>{`@${profile.username}`}</Text>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LastActive timestamp={profile.lastActiveAllAt} />
          <UserTypeBadge type={profile.type} />
        </View>
      </View>
      <Icon icon={faChevronRight} style={{ marginLeft: 24, marginRight: 12 }} />
    </Link>
  </View>
)
