import { config } from '../config'

const networkLibraryErrorRegex = /^(Network request failed|Failed to fetch)\.?$/

export const discard = (error, action, retries = 0) => {
  if (networkLibraryErrorRegex.test(error.message)) {
    const retryCount = action.meta?.offline?.retryCount ?? 1

    return retries >= retryCount
  }

  const retryCount =
    action.meta?.offline?.retryCount ?? config.api?.retryCount ?? 2

  if (retries >= retryCount) {
    return true
  }

  if (!('status' in error)) {
    return true
  }

  return error.status >= 400 && error.status < 500
}
