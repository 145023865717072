export const FETCH_ANSWER = 'answer/FETCH_ANSWER'
export const FETCH_ANSWER_ERROR = 'answer/FETCH_ANSWER_ERROR'
export const FETCH_ANSWER_SUCCESS = 'answer/FETCH_ANSWER_SUCCESS'

export const HIDE = 'answer/HIDE'

export const LIKE_ANSWER = 'answer/LIKE_ANSWER'
export const LIKE_ANSWER_ERROR = 'answer/LIKE_ANSWER_ERROR'
export const LIKE_ANSWER_SUCCESS = 'answer/LIKE_ANSWER_SUCCESS'

export const REFRESH_ANSWER = 'answer/REFRESH_ANSWER'
export const REFRESH_ANSWER_ERROR = 'answer/REFRESH_ANSWER_ERROR'
export const REFRESH_ANSWER_SUCCESS = 'answer/REFRESH_ANSWER_SUCCESS'

export const REMOVE_ANSWER = 'answer/REMOVE_ANSWER'
export const REMOVE_ANSWER_ERROR = 'answer/REMOVE_ANSWER_ERROR'
export const REMOVE_ANSWER_SUCCESS = 'answer/REMOVE_ANSWER_SUCCESS'

export const REMOVE_SENDER_STATUS = 'answer/REMOVE_SENDER_STATUS'
export const REMOVE_SENDER_STATUS_ERROR = 'answer/REMOVE_SENDER_STATUS_ERROR'
export const REMOVE_SENDER_STATUS_SUCCESS = 'answer/REMOVE_SENDER_STATUS_SUCCESS' // prettier-ignore

export const UNLIKE_ANSWER = 'answer/UNLIKE_ANSWER'
export const UNLIKE_ANSWER_ERROR = 'answer/UNLIKE_ANSWER_ERROR'
export const UNLIKE_ANSWER_SUCCESS = 'answer/UNLIKE_ANSWER_SUCCESS'
