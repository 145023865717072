import { DETECTABLE_LANGUAGE } from '@tellonym/enums/lib/Language'
import { DETECTION_TYPE } from '@tellonym/enums/lib/Tell'
import { Select } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList, moment, Text, View } from '../../common'
import { refreshLastBlocked } from '../actions'
import { LastBlocked as selector } from '../selectors'

const detectionTypes = Object.values(DETECTION_TYPE)
  .map((item) => (typeof item === 'string' ? item : false))
  .filter(Boolean)
const languages = [
  DETECTABLE_LANGUAGE[DETECTABLE_LANGUAGE.GERMAN],
  DETECTABLE_LANGUAGE[DETECTABLE_LANGUAGE.ENGLISH],
  DETECTABLE_LANGUAGE[DETECTABLE_LANGUAGE.SPANISH],
  DETECTABLE_LANGUAGE[DETECTABLE_LANGUAGE.PORTUGUESE],
]
const limit = ['10', '30', '80', '300', '1000']
const onlyBlocked = ['all', 'onlyBlocked']

const ConfigurationBar = ({ onChangeItem }) => (
  <View
    style={{
      alignSelf: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 24,
      width: '70%',
    }}>
    <Select
      defaultValue={detectionTypes[0]}
      options={detectionTypes.map((label, value) => ({
        label,
        value,
      }))}
      onChange={(selectedItem) =>
        onChangeItem({
          type: 'detectionType',
          selectedItem,
        })
      }
      style={{
        width: 200,
      }}
    />

    <Select
      defaultValue={languages[0]}
      options={languages.map((label, value) => ({
        label,
        value,
      }))}
      onChange={(selectedItem) =>
        onChangeItem({
          type: 'languages',
          selectedItem,
        })
      }
      style={{
        minWidth: 150,
        maxWidth: 170,
      }}
    />

    <Select
      defaultValue={limit[0]}
      options={limit.map((label, value) => ({
        label,
        value,
      }))}
      onChange={(selectedItem) =>
        onChangeItem({
          type: 'limit',
          selectedItem,
        })
      }
      style={{
        minWidth: 100,
        maxWidth: 150,
      }}
    />

    <Select
      defaultValue={onlyBlocked[0]}
      options={onlyBlocked.map((label, value) => ({
        label,
        value,
      }))}
      onChange={(selectedItem) =>
        onChangeItem({
          type: 'onlyBlocked',
          selectedItem,
        })
      }
      style={{
        minWidth: 150,
        maxWidth: 170,
      }}
    />
  </View>
)

const LastBlockedItem = ({ item }) => (
  <View
    key={item.time}
    style={{
      alignSelf: 'center',
      backgroundColor: 'white',
      borderRadius: 25,
      padding: 20,
      width: '70%',
      marginBottom: 12,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
    }}>
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
      <Text
        style={{
          color: '#68a1ff',
          padding: '1px 8px',
          border: '0.5px #68a1ff solid',
          borderRadius: 5,
        }}>
        {Math.round(item.detectionValue * 1000) / 1000}
      </Text>
      <Text style={{ color: 'grey' }}>{moment(item.time).fromNow()}</Text>
    </View>
    <Text style={{ marginTop: 12 }}>{item.content}</Text>
  </View>
)

class LastBlockedComponent extends React.Component {
  state = {
    detectionTypeActiveIndex: 0,
    languagesActiveIndex: 0,
    limitActiveIndex: 0,
    onlyBlockedActiveIndex: 1,
  }
  componentDidMount() {
    const {
      detectionTypeActiveIndex,
      languagesActiveIndex,
      limitActiveIndex,
      onlyBlockedActiveIndex,
    } = this.state

    this.props.actions.refreshLastBlocked({
      detectionType: DETECTION_TYPE[detectionTypes[detectionTypeActiveIndex]],
      onlyBlocked: !!onlyBlockedActiveIndex,
      lang: DETECTABLE_LANGUAGE[languages[languagesActiveIndex]],
      limit: parseInt(limit[limitActiveIndex], 10),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      detectionTypeActiveIndex,
      languagesActiveIndex,
      limitActiveIndex,
      onlyBlockedActiveIndex,
    } = this.state

    const hasValueChanged =
      detectionTypeActiveIndex !== prevState.detectionTypeActiveIndex ||
      languagesActiveIndex !== prevState.languagesActiveIndex ||
      limitActiveIndex !== prevState.limitActiveIndex ||
      onlyBlockedActiveIndex !== prevState.onlyBlockedActiveIndex

    if (hasValueChanged) {
      this.props.actions.refreshLastBlocked({
        detectionType: DETECTION_TYPE[detectionTypes[detectionTypeActiveIndex]],
        onlyBlocked: !!onlyBlockedActiveIndex,
        lang: DETECTABLE_LANGUAGE[languages[languagesActiveIndex]],
        limit: parseInt(limit[limitActiveIndex], 10),
      })
    }
  }

  _onChangeItem = ({ type, selectedItem }) => {
    this.setState({ [`${type}ActiveIndex`]: selectedItem })
  }

  _renderHeader = () => {
    if (this.props.shouldShowConfigurationBar === false) {
      return null
    }

    return (
      <ConfigurationBar
        onChangeItem={this._onChangeItem}
        onlyBlockedActiveIndex={this.state.onlyBlockedActiveIndex}
      />
    )
  }

  render() {
    return (
      <FlatList
        component={LastBlockedItem}
        hasMore={this.props.hasMore}
        isFetching={this.props.isFetching}
        isRefreshing={this.props.isRefreshing}
        items={this.props.lastBlocked.data}
        renderHeader={this._renderHeader}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  ...selector(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      refreshLastBlocked,
    },
    dispatch
  ),
})

const PageLastBlocked = connect(
  mapStateToProps,
  mapDispatchToProps
)(LastBlockedComponent)

export { PageLastBlocked }
