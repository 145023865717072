import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList } from '../../common'
import { fetchUserLog as fetch, refreshUserLog as refresh } from '../actions'
import { TabLogs as selector } from '../selectors'
import { LogItem } from './LogItem'

class _TabLog extends React.PureComponent {
  componentDidMount() {
    const { actions, profile } = this.props

    actions.refresh({ id: profile.id })
  }

  render() {
    const { actions, hasMore, isFetching, isRefreshing, data, profile } =
      this.props

    return (
      <FlatList
        actions={{
          fetch: (payload) =>
            actions.fetch({
              id: profile.id,
              ...payload,
            }),
          refresh: (payload) =>
            actions.refresh({
              id: profile.id,
              ...payload,
            }),
        }}
        component={LogItem}
        hasMore={hasMore}
        isFetching={isFetching}
        isRefreshing={isRefreshing}
        items={data[profile.id] || { ids: [], data: {} }}
        style={{ height: window.innerHeight - 171, minWidth: 700 }}
        extraProps={{
          profile,
        }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetch, refresh }, dispatch),
})

const mapStateToProps = (state) => ({
  ...selector(state),
})

const TabLog = connect(mapStateToProps, mapDispatchToProps)(_TabLog)

export { TabLog }
