import React from 'react'
import { theme } from '../styles/theme'
import { Text } from './Text'
import { View } from './View'

const screenWidth = window.tnym.getWidth()
const itemWidth = screenWidth < 400 ? 240 : 320

export const ItemParentTag = ({ item, style }) => (
  <View
    style={[
      {
        paddingVertical: 6,
        paddingRight: 12,
        alignSelf: 'flex-start',
      },
      style,
    ]}>
    <View
      style={{
        backgroundColor: theme.colors.borderLightGrey,
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 2,
      }}>
      <Text ellipsisWidth={itemWidth} type="note">
        {item.parent.content}
      </Text>
    </View>
  </View>
)
