import React from 'react'
import { Text } from './Text'

export const Title = ({ style = {}, ...props }) => (
  <Text
    type="h1"
    style={{ marginBottom: 24, lineHeight: 1.5, textAlign: 'center', ...style }}
    desktopStyle={{ textAlign: 'start' }}
    {...props}
  />
)
