import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { mergeDeepRight } from 'ramda'
import {
  getArtificialTellsDataModeFromUrl,
  getArtificialTellsHeaderModeFromUrl,
  getArtificialTellsLanguageFromUrl,
} from '../common/helpers'
import * as t from './typesV2'

export { nameV2 as name } from './constants'

export const initialState = {
  dataMode: getArtificialTellsDataModeFromUrl(),
  headerMode: getArtificialTellsHeaderModeFromUrl(),
  groupDetails: { isRefreshing: false, shouldShowDeletedVariances: false },
  isRefreshingTodos: false,
  language: getArtificialTellsLanguageFromUrl(),
  topicDetails: { isRefreshing: false },
  topics: { isRefreshing: false },
  varianceAnalytics: {},
  todos: [],
  underperformingGroups: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.REFRESH_GROUP: {
      if (action.meta.shouldDiscardVariances) {
        return mergeDeepRight(state, {
          groupDetails: {
            isRefreshing: true,
            [action.payload.groupId]: {
              variances: [],
            },
          },
        })
      }

      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          isRefreshing: true,
        },
      }
    }

    case t.REFRESH_GROUP_ERROR: {
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          isRefreshing: false,
        },
      }
    }

    case t.REFRESH_GROUP_SUCCESS: {
      return {
        ...state,
        groupDetails: {
          ...state.groupDetails,
          isRefreshing: false,
          [action.payload.id]: action.payload,
        },
      }
    }

    case t.CREATE_VARIANCE_SUCCESS:
      return mergeDeepRight(state, {
        groupDetails: {
          [action.payload.groupId]: {
            variances: [
              ...state.groupDetails[action.payload.groupId].variances,
              action.payload,
            ],
          },
        },
      })

    case t.EDIT_VARIANCE_SUCCESS: {
      const update = state.groupDetails[
        action.payload.groupId
      ].variances.reduce(
        (acc, currentVariance) => {
          /**
           * We use the updated variance from the server if it matches the id
           * of the variance we edited.
           */
          const variance =
            currentVariance.id === action.payload.id
              ? action.payload
              : currentVariance

          acc.variances.push(variance)

          if (variance.status === ARTIFICIAL_TELL_STATUS.ACTIVE) {
            /**
             * We set the group status to active if any of the variances are.
             */
            if (acc.status !== ARTIFICIAL_TELL_STATUS.ACTIVE) {
              acc.status = ARTIFICIAL_TELL_STATUS.ACTIVE
            }
          }

          return acc
        },
        {
          status: ARTIFICIAL_TELL_STATUS.INACTIVE,
          variances: [],
        }
      )

      return mergeDeepRight(state, {
        groupDetails: {
          [action.payload.groupId]: update,
        },
      })
    }

    case t.REFRESH_TOPICS: {
      return mergeDeepRight(state, {
        topics: {
          isRefreshing: true,
        },
      })
    }

    case t.REFRESH_TOPICS_ERROR: {
      return mergeDeepRight(state, {
        topics: {
          isRefreshing: false,
        },
      })
    }

    case t.REFRESH_TOPICS_SUCCESS: {
      return mergeDeepRight(state, {
        topics: {
          ...action.payload,
          isRefreshing: false,
        },
      })
    }

    case t.REFRESH_TOPIC: {
      return mergeDeepRight(state, {
        topicDetails: {
          isRefreshing: true,
        },
      })
    }

    case t.REFRESH_TOPIC_ERROR: {
      return mergeDeepRight(state, {
        topicDetails: {
          isRefreshing: false,
        },
      })
    }

    case t.REFRESH_TOPIC_SUCCESS: {
      return {
        ...state,
        topicDetails: {
          ...state.topicDetails,
          isRefreshing: false,
          [action.payload.topic.id]: action.payload,
        },
      }
    }

    case t.REFRESH_VARIANCE_ANALYTICS: {
      return {
        ...state,
        varianceAnalytics: {
          ...state.varianceAnalytics,
          isRefreshing: true,
        },
      }
    }

    case t.REFRESH_VARIANCE_ANALYTICS_ERROR: {
      return {
        ...state,
        varianceAnalytics: {
          ...state.varianceAnalytics,
          isRefreshing: false,
        },
      }
    }

    case t.REFRESH_VARIANCE_ANALYTICS_SUCCESS: {
      return {
        ...state,
        varianceAnalytics: {
          ...state.varianceAnalytics,
          isRefreshing: false,
          ...action.payload.analytics.reduce((acc, item) => {
            acc[item.varianceId] = item
            return acc
          }, {}),
        },
      }
    }

    case t.FETCH_VARIANCE_ANSWERS_SUCCESS:
      return mergeDeepRight(state, {
        varianceAnswers: {
          [action.payload.variance.id]: {
            answers: state.varianceAnswers[
              action.payload.variance.id
            ].answers.concat(action.payload.answers),
            hasMore: action.payload.hasMore,
          },
        },
      })

    case t.REFRESH_UNDERPERFORMING_GROUPS_SUCCESS: {
      return {
        ...state,
        underperformingGroups: action.payload,
      }
    }

    case t.SET_DATA_MODE:
      return mergeDeepRight(state, {
        dataMode: action.payload,
      })

    case t.SET_HEADER_MODE:
      return mergeDeepRight(state, {
        headerMode: action.payload,
      })

    case t.SET_LANGUAGE:
      return mergeDeepRight(state, {
        language: action.payload,
      })

    case t.SET_SHOULD_SHOW_DELETED_VARIANCES:
      return mergeDeepRight(state, {
        groupDetails: {
          shouldShowDeletedVariances: action.payload,
        },
      })

    case t.REFRESH_TODOS: {
      return { ...state, isRefreshingTodos: true }
    }

    case t.REFRESH_TODOS_ERROR: {
      return { ...state, isRefreshingTodos: false }
    }

    case t.REFRESH_TODOS_SUCCESS: {
      return { ...state, isRefreshingTodos: false, todos: action.payload }
    }

    case t.COMPLETE_TODO_SUCCESS: {
      const openTodos = state.todos.filter(
        (todo) => todo.id !== action.payload.id
      )

      return { ...state, todos: openTodos }
    }

    default:
      return state
  }
}
