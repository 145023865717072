import { prop } from 'ramda'
import { createSelector } from 'reselect'

const getState = prop('tools')

const getBadwordscore = createSelector(getState, prop('badwordscore'))

export const getBadwordscoreData = createSelector(getBadwordscore, prop('data'))

const getExperiments = createSelector(getState, prop('experiments'))

export const getIsLoadingRerollExperiments = createSelector(
  getExperiments,
  prop('isLoadingReroll')
)
