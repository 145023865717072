import { UploadOutlined } from '@ant-design/icons'
import { buildFetch, HOST_TYPE, resolveOrReject } from '@tellonym/core/api'
import { Button, Radio, Typography, Upload } from 'antd'
import React from 'react'
import {
  Alert,
  Box,
  colors,
  Image,
  ScrollView,
  styleSheets,
  Text,
  View,
} from '../../common'
import * as hooks from '../../common/hooks'
import { theme } from '../../common/styles/theme'
import { useTiktokVideoBackgroundQuery } from '../queries'

const imageSacling = window.tnym.isDesktop() ? 2 : 3

const videoTypeOptions = ['communityAnswers', 'mixedAnswers'].map?.((v) => ({
  label: v,
  value: v,
}))

const styles = {
  thumbnailOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.colors.translucentDark,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  thumbnailImage: {
    borderRadius: 8,
    width: 360 / imageSacling,
    height: 640 / imageSacling,
  },
}

const Thumbnail = ({ image, name, onPress, isSelected }) => (
  <Box onPress={() => onPress(name)} marginBottom={8}>
    <Box marginRight={12} position="relative">
      {isSelected && (
        <Box style={styles.thumbnailOverlay}>
          <Text center color={theme.colors.transparentWhite}>
            Selected
          </Text>
        </Box>
      )}
      <Image source={image} style={styles.thumbnailImage} />
    </Box>
    <Text type="small" center color={theme.colors.appleLines}>
      {name}
    </Text>
  </Box>
)

const StickerPreview = ({ uri, onPress, name, selectedPosition }) => (
  <Box onPress={() => onPress(name)} marginBottom={8}>
    <Box marginRight={12} position="relative">
      {selectedPosition > 0 && (
        <Box style={styles.thumbnailOverlay}>
          <Text type="h2" center color={theme.colors.transparentWhite}>
            {selectedPosition}
          </Text>
        </Box>
      )}
      <Image source={uri} width="200" style={styleSheets.margin.right[8]} />
    </Box>
  </Box>
)

export const PageTiktokVideoGenerator = () => {
  const [downloadUrl, setDownloadUrl] = React.useState()
  const [previewImages, setPreviewImages] = React.useState([])
  const [previewImageOrder, setPreviewImageOrder] = React.useState([])
  const [fileList, setFileList] = React.useState([])
  const [isUploading, setIsUploading] = React.useState(false)
  const containerStyle = hooks.usePageContainerStyle()
  const [fileName, setFileName] = React.useState(undefined)
  const [videoType, setVideoType] = React.useState(undefined)

  const { data } = useTiktokVideoBackgroundQuery()

  const areRequiredPropsSet = React.useMemo(
    () =>
      typeof fileName !== 'undefined' &&
      typeof videoType !== 'undefined' &&
      fileList.length > 0,
    [fileList.length, fileName, videoType]
  )

  const uploadProps = React.useMemo(
    () => ({
      multiple: true,
      accept: 'image/*',
      onRemove: (file) => {
        const index = fileList.indexOf(file)
        const newFileList = fileList.slice()

        newFileList.splice(index, 1)

        setFileList(newFileList)
      },
      beforeUpload: (_, fileArray) => {
        setFileList([...fileList, ...fileArray])
        setPreviewImageOrder([
          ...new Set([...previewImageOrder, ...fileArray.map((v) => v.name)]),
        ])

        return false
      },
      fileList,
    }),
    [fileList, previewImageOrder]
  )

  const openUrl = () => {
    window.open(downloadUrl, '_blank')
  }

  const toggleThumbnail = React.useCallback(
    (name) => {
      if (name === fileName) {
        setFileName(undefined)
      } else {
        setFileName(name)
      }
    },
    [fileName]
  )

  const onChangeVideoType = React.useCallback(({ target: { value } }) => {
    setVideoType(value)
  }, [])

  const onPressPreview = (name) => {
    if (previewImageOrder.includes(name)) {
      setPreviewImageOrder(previewImageOrder.filter((v) => v !== name))
    } else {
      setPreviewImageOrder([...previewImageOrder, name])
    }
  }

  const handleUpload = React.useCallback(async () => {
    setDownloadUrl(undefined)

    const formData = new FormData()

    previewImageOrder.forEach((name, index) => {
      const file = fileList.find((v) => v.name === name)

      formData.append(`sticker${index}`, file)
    })

    formData.append('background', fileName)
    formData.append('type', videoType)

    setIsUploading(true)

    const [url, fetchConfig] = buildFetch(
      {
        path: '',
        method: 'POST',
        payload: {
          formData,
        },
      },
      {
        hostType: HOST_TYPE.VIDEOGEN,
      }
    )

    const { headers: _, ...fetchConfigWithoutHeaders } = fetchConfig
    try {
      const response = await fetch(url, fetchConfigWithoutHeaders)

      const res = await resolveOrReject(response, null, false)

      setDownloadUrl(res?.url)

      setFileList([])

      Alert.success('Upload successfully.')
    } catch (e) {
      Alert.error('Upload failed.')
      console.error('Upload failed', e)
    }

    setIsUploading(false)
  }, [fileList, fileName, previewImageOrder, videoType])

  React.useEffect(() => {
    const images = []
    const fileReaders = []
    let isCancel = false

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        const fileReader = new FileReader()

        fileReaders.push(fileReader)

        fileReader.onload = (e) => {
          const { result } = e.target

          if (result) {
            images.push({ uri: result, name: file.name })
          }

          if (images.length === fileList.length && !isCancel) {
            setPreviewImages(images)
          }
        }

        fileReader.readAsDataURL(file)
      })
    }

    return () => {
      isCancel = true
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [fileList])

  return (
    <View style={containerStyle}>
      <Box padding={16} backgroundColor={colors.background}>
        <Typography.Title>Tiktok Video Generator</Typography.Title>
        <Box marginTop={24} alignItems="center">
          <ScrollView horizontal style={{ width: '90%' }}>
            {data?.backgrounds?.map(({ name, thumbnail }) => (
              <Thumbnail
                key={name}
                image={thumbnail}
                name={name}
                onPress={toggleThumbnail}
                isSelected={fileName === name}
              />
            ))}
          </ScrollView>

          <Radio.Group
            optionType="button"
            placeholder="Select video type"
            options={videoTypeOptions}
            onChange={onChangeVideoType}
            value={videoType}
            style={{ marginTop: 24 }}
          />
          <Upload {...uploadProps}>
            <Button
              icon={<UploadOutlined />}
              style={{ marginTop: 24, minWidth: 280 }}>
              Select stickers
            </Button>
          </Upload>

          <ScrollView horizontal style={{ width: '90%', marginTop: 12 }}>
            {previewImages?.map(({ name, uri }) => (
              <StickerPreview
                key={name}
                uri={uri}
                name={name}
                selectedPosition={
                  previewImageOrder.findIndex((v) => v === name) + 1
                }
                onPress={onPressPreview}
              />
            ))}
          </ScrollView>

          <Button
            type="primary"
            onClick={handleUpload}
            disabled={!areRequiredPropsSet}
            loading={isUploading}
            style={{ marginTop: 36, width: 280 }}>
            {isUploading ? 'Uploading' : 'Start Upload'}
          </Button>
          <Button
            type="primary"
            onClick={openUrl}
            disabled={typeof downloadUrl === 'undefined'}
            style={{ marginTop: 36, marginBottom: 36, width: 280 }}>
            Download video
          </Button>
        </Box>
      </Box>
    </View>
  )
}
