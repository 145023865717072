import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList } from '../../common'
import { fetchTells, refreshTells } from '../actions'
import { TabTells as selector } from '../selectors'
import { HeaderSelectionActions } from './HeaderSelectionActions'
import { TellItem } from './TellItem'

class TabTellsComponent extends React.Component {
  state = { searchPhrase: '', shouldTranslate: false }

  componentDidMount() {
    const { actions, profile } = this.props

    actions.refresh({ userId: profile.id })
  }

  _onChangeSearchInput = (searchPhrase) => {
    this.setState({ searchPhrase })
  }

  _onPressSearch = () => {
    const { searchPhrase } = this.state
    const { actions, profile } = this.props

    actions.refresh({ searchPhrase, userId: profile.id })
  }

  _toggleTranslate = () => {
    this.setState((state) => ({ shouldTranslate: !state.shouldTranslate }))
  }

  _renderHeader = () => {
    const { data, profile } = this.props
    const { searchPhrase } = this.state

    const hasItems =
      !!data[profile.id] &&
      !!data[profile.id].ids &&
      data[profile.id].ids.length

    return (
      <HeaderSelectionActions
        hasItems={hasItems}
        onChangeSearchInput={this._onChangeSearchInput}
        onPressSearch={this._onPressSearch}
        profile={profile}
        searchPhrase={searchPhrase}
        shouldTranslate={this.state.shouldTranslate}
        toggleTranslate={this._toggleTranslate}
        type="tells"
      />
    )
  }

  render() {
    return (
      <FlatList
        _rerenderItem={this.props._rerenderItem}
        actions={{
          fetch: (payload) =>
            this.props.actions.fetch({
              userId: this.props.profile.id,
              ...payload,
            }),
          refresh: (payload) =>
            this.props.actions.refresh({
              userId: this.props.profile.id,
              ...payload,
            }),
        }}
        component={TellItem}
        extraData={{
          hasMore: this.props.hasMore,
          isFetching: this.props.isFetching,
          isRefreshing: this.props.isRefreshing,
        }}
        extraProps={{
          type: 'tells',
          shouldTranslate: this.state.shouldTranslate,
        }}
        hasMore={this.props.hasMore}
        isFetching={this.props.isFetching}
        isRefreshing={this.props.isRefreshing}
        items={this.props.data[this.props.profile.id] || { ids: [], data: {} }}
        renderHeader={this._renderHeader}
        style={{ height: window.innerHeight - 171, minWidth: 700 }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetch: fetchTells, refresh: refreshTells },
    dispatch
  ),
})

export const TabTells = connect(selector, mapDispatchToProps)(TabTellsComponent)
