import React from 'react'
import { Avatar, Link, Modal, theme, View } from '../../common'

export const FollowItem = ({ item: user, modalId }) => (
  <View
    style={{
      backgroundColor: theme.colors.background,
      paddingVertical: 5,
    }}>
    <Link
      to={`/user/${user.id}`}
      onClick={() => (modalId ? Modal.hide({ id: modalId }) : null)}
      style={{ alignItems: 'center', flex: 1 }}>
      <Avatar user={{ ...user, avatarFileName: user.avatar }} />
      <p style={{ paddingLeft: 10 }}>
        <b>{user.username}</b>
        <br />
        Followed at: {user.time}
        <br />
        Notifications enabled: {String(!!user.notification)}
        <br />
        Anonymous: {String(!!user.anonymous)}
      </p>
    </Link>
  </View>
)
