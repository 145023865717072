import { faEye } from '@fortawesome/free-regular-svg-icons'
import React from 'react'
import { Icon, moment, Text, theme, TouchableOpacity, View } from '../../common'
import {
  useHideBanCandidatesMutation,
  useUnhideBanCandidatesMutation,
} from '../queries'
import { SearchItem } from './SearchItem'

export const BanItem = ({ isHiddenCandidates, item }) => {
  const userId = item.id

  const { mutate: hide } = useHideBanCandidatesMutation()
  const { mutate: unhide } = useUnhideBanCandidatesMutation()

  const onPressHide = (daysToHide) => {
    hide({
      userId,
      untilDate: moment().add(daysToHide, 'day').toDate(),
    })
  }

  const onPressUnhide = () => {
    unhide({ userId })
  }

  return (
    <View style={{ alignItems: 'center', flexDirection: 'row' }}>
      <View style={{ flex: 1, alignItems: 'center' }}>
        {isHiddenCandidates ? (
          <TouchableOpacity onPress={onPressUnhide}>
            <Icon icon={faEye} />
          </TouchableOpacity>
        ) : (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-around',
            }}>
            <Text
              center
              onPress={() => onPressHide(7)}
              style={{ onHover: { color: theme.colors.primary } }}>
              7d
            </Text>
            <Text
              center
              onPress={() => onPressHide(30)}
              style={{ onHover: { color: theme.colors.primary } }}>
              30d
            </Text>
          </View>
        )}
      </View>
      <Text center bold style={{ flex: 1 }}>
        {item.actionsCount}
      </Text>
      <View style={{ flex: 6 }}>
        <SearchItem profile={item} />
      </View>
    </View>
  )
}
