import { ARTIFICIAL_TELL_GROUP_TYPES } from '@tellonym/enums/lib/Tell'
import React from 'react'
import { colors } from '../../common'
import { TagBox } from '../../common/components/TagBox'

export const ArtificialTellGroupTypeTag = ({ groupType }) => {
  return (
    <TagBox
      backgroundColor={
        {
          [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]:
            colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE],
          [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]:
            colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC],
          [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]:
            colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.OPEN],
        }[groupType]
      }
      text={
        {
          [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: 'Simple',
          [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: 'Organic',
          [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: 'Open',
        }[groupType]
      }
    />
  )
}
