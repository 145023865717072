import React from 'react'
import { Image as RNI } from 'react-native-web'
import { Radium } from '../hocs/Radium'

const removeEndingSlash = (string) =>
  string.slice(-1) === '/' ? string.slice(-1) : string

const addCdnUrlIfNoUrlProvided = (source) =>
  source.slice(0, 4) === 'http'
    ? source
    : `${removeEndingSlash(process.env.PUBLIC_URL)}${source}`

const defaultStyle = {
  display: 'flex',
  flexDirection: 'column',
}

const mobileOnly = {
  '@media (min-width: 481px)': {
    display: 'none',
  },
}

const desktopOnly = {
  '@media (max-width: 480px)': {
    display: 'none',
  },
}

export const Image = Radium(
  ({
    alt,
    desktop = false,
    desktopStyle,
    mobile = false,
    source,
    style,
    ...props
  }) => (
    <img
      {...props}
      alt={alt}
      src={
        typeof source === 'string'
          ? source
          : typeof source === 'object'
          ? source.uri
          : source
      }
      style={[
        defaultStyle,
        style,
        desktopStyle && {
          '@media (min-width: 481px)': {
            ...desktopStyle,
          },
        },
        mobile && mobileOnly,
        desktop && desktopOnly,
      ]}
    />
  )
)

export const RNImage = Radium(({ alt = '', source, ...props }) => (
  <RNI
    alt={alt}
    source={
      typeof source === 'string'
        ? addCdnUrlIfNoUrlProvided(source)
        : typeof source === 'object'
        ? addCdnUrlIfNoUrlProvided(source.uri)
        : source
    }
    {...props}
  />
))
