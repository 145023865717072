import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { copyToClipboard } from '@tellonym/core/share/actions'
import P from 'prop-types'
import { compose } from 'ramda'
import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { getIsSidemenuShown } from '../../app/selectors'
import {
  Avatar,
  Button,
  Dropdown,
  FlatList,
  Icon,
  Input,
  Link,
  moment,
  Text,
  theme,
  View,
} from '../../common'
import { styles as defaultStyles } from '../../common/styles/styles'
import {
  deleteDeviceBan,
  fetchBannedDevices,
  refreshBannedDevices,
} from '../actions'
import { getBannedDevicesData } from '../selectors'
import { UserTypeBadge } from './UserTypeBadge'

const styles = {
  bannedDeviceItemContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  bannedDeviceItemColumn: {
    flex: 1,
    alignItems: 'center',
  },
  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 24,
    flex: 1,
  },
  navIcon: {
    alignSelf: 'center',
    marginLeft: 12,
    marginRight: 12,
  },
  profileContainer: {
    borderRadius: 2,
    flex: 2,
    onHover: {
      backgroundColor: theme.colors.backgroundLight,
      boxShadow: defaultStyles.shadow,
    },
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  searchBarIcon: {
    marginLeft: 24,
    marginRight: 12,
  },
  searchBarInput: {
    borderBottom: 'none',
    marginBottom: 0,
    width: '100%',
  },
  tableHeadContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.borderLightGrey,
    boxShadow: defaultStyles.shadow,
    flexDirection: 'row',
    marginBottom: 12,
    paddingVertical: 8,
  },
  usernameContainer: { justifyContent: 'center', marginLeft: 24, flex: 1 },
  uuidLabel: { cursor: 'pointer', marginLeft: 32 },
}

const searchCategories = {
  UUID: 'uuid',
  USERNAME: 'username',
}
const searchCategoriesOrdered = [
  searchCategories.UUID,
  searchCategories.USERNAME,
]

const SearchBar = ({ actions }) => {
  const [value, onChangeText] = useState('')
  const [searchType, setSearchType] = useState({
    item: searchCategories.UUID,
    index: 0,
  })

  const onSubmit = (e) => {
    e.preventDefault()

    actions.refresh({
      limit: 50,
      [searchType.item]: value === '' ? undefined : value,
    })
  }

  return (
    <View style={styles.searchBarContainer}>
      <Dropdown
        currentIndex={searchType.index}
        options={searchCategoriesOrdered}
        onChange={setSearchType}
      />
      <Icon icon={faSearch} style={styles.searchBarIcon} />
      <form onSubmit={onSubmit}>
        <Input
          onChangeText={onChangeText}
          placeholder="Search"
          style={styles.searchBarInput}
          value={value}
        />
      </form>
      <Button onPress={onSubmit}>Search</Button>
    </View>
  )
}

const BannedDeviceItem = ({ actions, item, ...props }) => (
  <View style={styles.bannedDeviceItemContainer} {...props}>
    <View style={{ flex: 2 }}>
      <Text
        onPress={() => actions.copyToClipboard(item.uuid)}
        style={styles.uuidLabel}>
        {item.uuid}
      </Text>
    </View>
    <View style={styles.profileContainer}>
      <Link to={`/user/${item.bannedUser.id}`}>
        <Avatar user={item.bannedUser} />
        <View style={styles.usernameContainer}>
          <Text color={theme.colors.Text} style={{ marginBottom: 4 }}>
            {item.bannedUser.displayName}
          </Text>
          <Text
            color={
              theme.colors.placeholder
            }>{`@${item.bannedUser.username}`}</Text>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <UserTypeBadge type={item.bannedUser.type} />
        </View>
        <Icon icon={faChevronRight} style={styles.navIcon} />
      </Link>
    </View>
    <View style={styles.bannedDeviceItemColumn}>
      <Text color={theme.colors.placeholder}>
        {moment(item.time).fromNow()}
      </Text>
    </View>
    <View style={styles.bannedDeviceItemColumn}>
      <Button
        onPress={() =>
          actions.deleteDeviceBan({
            bannedUserId: item.bannedUser.id,
            uuid: item.uuid,
          })
        }>
        Unban
      </Button>
    </View>
  </View>
)

const TableHead = (props) => (
  <View style={{ flex: 1 }}>
    <SearchBar {...props} />
    <View style={styles.tableHeadContainer}>
      <View style={{ flex: 2 }}>
        <Text color={theme.colors.placeholder} style={{ marginLeft: 32 }}>
          uuid
        </Text>
      </View>
      <View style={{ flex: 2 }}>
        <Text color={theme.colors.placeholder} style={{ marginLeft: 8 }}>
          User
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text center color={theme.colors.placeholder}>
          Ban Time
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text center color={theme.colors.placeholder}>
          Unban
        </Text>
      </View>
    </View>
  </View>
)

const _PageBannedDevices = ({ actions, ...props }) => {
  useEffect(() => {
    actions.refresh({ limit: 50 })
  }, [])

  return (
    <FlatList
      actions={{
        refresh: (payload) => actions.refresh({ limit: 50, ...payload }),
      }}
      component={BannedDeviceItem}
      extraData={{
        hasMore: props.hasMore,
        isFetching: props.isFetching,
        isRefreshing: props.isRefreshing,
      }}
      extraProps={{ actions }}
      hasMore={props.hasMore}
      isRefreshing={props.isRefreshing}
      items={props.data}
      renderHeader={TableHead}
    />
  )
}

_PageBannedDevices.propTypes = {
  _rerenderItem: P.shape({ ids: P.number, count: P.number }),
  actions: P.shape({ refresh: P.func.isRequired, fetch: P.func.isRequired })
    .isRequired,
  data: P.shape({
    ids: P.arrayOf(P.oneOfType([P.number, P.string])),
    data: P.any,
  }).isRequired,
  isRefreshing: P.bool,
  isSidemenuShown: P.bool.isRequired,
  hasMore: P.bool,
}

_PageBannedDevices.defaultProps = {
  _rerenderItem: undefined,
  isRefreshing: false,
  hasMore: false,
}

const mapStateToProps = createStructuredSelector({
  data: getBannedDevicesData,
  isSidemenuShown: getIsSidemenuShown,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      copyToClipboard,
      deleteDeviceBan,
      fetch: fetchBannedDevices,
      refresh: refreshBannedDevices,
    },
    dispatch
  ),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const enhancer = compose(memo, withConnect)

export const PageBannedDevices = enhancer(_PageBannedDevices)
