import { safeEffects } from '@tellonym/core/sagas'
import * as t from '@tellonym/core/share/types'
import { message } from 'antd'

const copy = function* ({ payload }) {
  const el = document.createElement('textarea')
  el.value = payload
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  message.success('Copied to clipboard')
}

export const actionHandler = {
  [t.COPY_TO_CLIPBOARD]: [safeEffects.takeLatest, copy],
}
