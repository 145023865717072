import { API_CODE } from '@tellonym/enums/lib/ApiCode'
import { mergeDeepRight } from 'ramda'
import { parseJson } from '../../api'
import { getIsLoggedIn } from '../../app/selectors'
import { isError, isResponse } from '../../validations'

export const errorHandlerMiddleware =
  ({ badConnectionHandler = () => {}, errorHandler = () => {} }) =>
  (store) =>
  (next) =>
  // eslint-disable-next-line consistent-return
  (action) => {
    if (action.type.includes('ERROR') === false) {
      return next(action)
    }

    const handle = (code, message, errors = []) =>
      errorHandler({
        code,
        dispatch: store.dispatch,
        errors,
        getState: store.getState,
        isLoggedIn: getIsLoggedIn(store.getState()),
        message,
        meta: action.meta,
        payload: action.payload,
        type: action.type,
      })

    if (isResponse(action.payload) && action.payload.status >= 500) {
      handle(API_CODE.INTERNAL_SERVER_ERROR)

      return next(
        mergeDeepRight(action, {
          meta: {
            err: {
              code: API_CODE.INTERNAL_SERVER_ERROR,
            },
          },
        })
      )
    } else if (isResponse(action.payload)) {
      parseJson(action.payload.clone()).then((payload) => {
        const meta = mergeDeepRight(
          {
            err: {
              code: 'NO_CODE',
              msg: '',
              errors: [],
            },
          },
          { err: payload.err ?? {} }
        )

        handle(meta.err.code, meta.err.msg, meta.err.errors)

        return next(
          mergeDeepRight(action, {
            meta,
          })
        )
      })
    } else if (
      isError(action.payload) &&
      /(Network request failed|Failed to fetch)/.test(action.payload.message)
    ) {
      badConnectionHandler({
        error: action.payload,
        dispatch: store.dispatch,
        getState: store.getState,
        meta: action.meta,
        type: action.type,
      })

      return next(
        mergeDeepRight(action, {
          meta: {
            err: {
              code: 'NO_INTERNET',
            },
          },
        })
      )
    } else {
      return next(action)
    }
  }
