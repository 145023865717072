import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Radium } from '../hocs/Radium'
import { theme } from '../styles/theme'
import { Icon } from './Icon'
import { PopupModal } from './PopupModal'
import { TouchableOpacity } from './TouchableOpacity'

export const PopupMenu = Radium(
  ({ actions, containerStyle = {}, onPress, style = {} }) => (
    <TouchableOpacity
      onPress={() => PopupModal.show({ actions, onPress })}
      style={containerStyle}>
      <Icon icon={faEllipsisH} color={theme.colors.icon} style={style} />
    </TouchableOpacity>
  )
)
