export const CHANGE_STATE = 'search/CHANGE_STATE'

export const CHANGE_TAG_SUGGESTIONS_TERM = 'search/CHANGE_TAG_SUGGESTIONS_TERM'

export const CHANGE_TERM = 'search/CHANGE_TERM'

export const HIDE_SEARCH_HISTORY_ITEM = 'search/HIDE_SEARCH_HISTORY_ITEM'
export const HIDE_SEARCH_HISTORY_ITEM_ERROR = 'search/HIDE_SEARCH_HISTORY_ITEM_ERROR' // prettier-ignore
export const HIDE_SEARCH_HISTORY_ITEM_SUCCESS = 'search/HIDE_SEARCH_HISTORY_ITEM_SUCCESS' // prettier-ignore

export const FETCH_ANSWERS = 'search/FETCH_ANSWERS'
export const FETCH_ANSWERS_ERROR = 'search/FETCH_ANSWERS_ERROR'
export const FETCH_ANSWERS_SUCCESS = 'search/FETCH_ANSWERS_SUCCESS'

export const FETCH_PROFILES = 'search/FETCH_PROFILES'
export const FETCH_PROFILES_ERROR = 'search/FETCH_PROFILES_ERROR'
export const FETCH_PROFILES_SUCCESS = 'search/FETCH_PROFILES_SUCCESS'

export const REFRESH_ACTIVE_RT_POST = 'posts/REFRESH_ACTIVE_RT_POST'
export const REFRESH_ACTIVE_RT_POST_ERROR = 'posts/REFRESH_ACTIVE_RT_POST_ERROR'
export const REFRESH_ACTIVE_RT_POST_SUCCESS =
  'posts/REFRESH_ACTIVE_RT_POST_SUCCESS'

export const REFRESH_PROFILE = 'search/REFRESH_PROFILE'
export const REFRESH_PROFILE_ERROR = 'search/REFRESH_PROFILE_ERROR'
export const REFRESH_PROFILE_SUCCESS = 'search/REFRESH_PROFILE_SUCCESS'

export const REFRESH_PROFILES = 'search/REFRESH_PROFILES'
export const REFRESH_PROFILES_ERROR = 'search/REFRESH_PROFILES_ERROR'
export const REFRESH_PROFILES_SUCCESS = 'search/REFRESH_PROFILES_SUCCESS'

export const REFRESH_PROFILES_SEARCH_HISTORY = 'search/REFRESH_PROFILES_SEARCH_HISTORY' // prettier-ignore
export const REFRESH_PROFILES_SEARCH_HISTORY_ERROR = 'search/REFRESH_PROFILES_SEARCH_HISTORY_ERROR' // prettier-ignore
export const REFRESH_PROFILES_SEARCH_HISTORY_SUCCESS = 'search/REFRESH_PROFILES_SEARCH_HISTORY_SUCCESS' // prettier-ignore

export const REFRESH_SEARCH_HISTORY = 'search/REFRESH_SEARCH_HISTORY'
export const REFRESH_SEARCH_HISTORY_ERROR = 'search/REFRESH_SEARCH_HISTORY_ERROR' // prettier-ignore
export const REFRESH_SEARCH_HISTORY_SUCCESS = 'search/REFRESH_SEARCH_HISTORY_SUCCESS' // prettier-ignore

export const REFRESH_TAG_SUGGESTIONS = 'search/REFRESH_TAG_SUGGESTIONS'
export const REFRESH_TAG_SUGGESTIONS_ERROR = 'search/REFRESH_TAG_SUGGESTIONS_ERROR' // prettier-ignore
export const REFRESH_TAG_SUGGESTIONS_SUCCESS = 'search/REFRESH_TAG_SUGGESTIONS_SUCCESS' // prettier-ignore

export const RESET_HAS_SEARCHED = 'search/RESET_HAS_SEARCHED'

export const SET_HAS_SEARCHED = 'search/SET_HAS_SEARCHED'

export const SET_HAS_TRACKED_WEB_OPEN_RECEIVE = 'search/SET_HAS_TRACKED_WEB_OPEN_RECEIVE' // prettier-ignore

export const SET_IS_SEARCH_HISTORY_VISIBLE = 'search/SET_IS_SEARCH_HISTORY_VISIBLE' // prettier-ignore

export const SET_IS_AVATAR_IN_PROFILE_HEADER_VISIBLE = 'search/SET_IS_AVATAR_IN_PROFILE_HEADER_VISIBLE' // prettier-ignore
