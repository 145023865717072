import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import React from 'react'
import { Redirect, Route as R } from 'react-router-dom'
import { connectWithRouter } from '../../common'

export const Route = connectWithRouter((state) => ({
  isLoggedIn: getIsLoggedIn(state),
}))(
  ({
    component: C,
    authenticated: A,
    unauthenticated: U,
    isLoggedIn,
    ...rest
  }) => (
    <R
      {...rest}
      render={(props) => {
        if (C) {
          return <C {...props} />
        }
        if (A && isLoggedIn) {
          return <A {...props} />
        }
        if (U && !isLoggedIn) {
          return <U {...props} />
        }
        if (U && isLoggedIn) {
          return <Redirect to="/" />
        }
        return <Redirect to="/" />
      }}
    />
  )
)
