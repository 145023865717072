export const dateTypes = {
  LAST_21_DAYS: 'LAST_21_DAYS',
  LAST_90_DAYS: 'LAST_90_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_14_DAYS: 'LAST_14_DAYS',
  LAST_7_DAYS: 'LAST_7_DAYS',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  CUSTOM_DATE: 'CUSTOM_DATE',
  SYNCED: 'SYNCED',
}

export const defaultValueKeys = [
  'percentageOfUsers',
  'amountPerUnique',
  'amountUniques',
  'amountActions',
  'amountPerUser',
  'amountUsers',
]

const comparisonInterval = ['oneWeek', 'oneMonth', 'threeMonths', 'sixMonths']
export const comparisonIntervalOptions = comparisonInterval.map((v) => ({
  label: v
    .replace(/^one/, '1 ')
    .replace(/^three/, '3 ')
    .replace(/^six/, '6 '),
  value: v,
}))

export const statsTypes = {
  'ALL-EXP-MAIN': 'ALL-EXP-MAIN',
  'SIGNIFICANT-EXP-MAIN': 'SIGNIFICANT-EXP-MAIN',
  EXP: 'EXP',
  'ALL-MAIN': 'ALL-MAIN',
  ALL: 'ALL',
  SIGNIFICANT: 'SIGNIFICANT',
  'SIGNIFICANT-MAIN': 'SIGNIFICANT-MAIN',
  DAILY_UPDATE: 'DAILY_UPDATE',
}
