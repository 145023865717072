import React from 'react'
import { Radium } from '../hocs/Radium'
import { Image } from './Image'
import { View } from './View'

export const PostImage = Radium(({ maxHeight = 400, imgUrl }) => (
  <View style={{ position: 'relative', display: 'block' }}>
    <Image
      style={{
        height: maxHeight,
        borderStyle: undefined,
      }}
      source={{
        uri: imgUrl,
      }}
    />
  </View>
))
