import { flattenProps } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import React from 'react'
import * as Redux from 'react-redux'
import {
  Alert,
  Button,
  FlatList,
  moment,
  Text,
  theme,
  View,
} from '../../common'
import { getDeviceDump } from '../../common/helpers'
import {
  fetchDevices as fetch,
  logoutAllDevices,
  logoutDevice,
  refreshDevices as refresh,
} from '../actions'
import { TabDevices as selectorTabDevices } from '../selectors'
import { AdditionalInfos } from './AdditionalInfos'
import { ModalSendPushNotification } from './ModalSendPushNotification'

const Cell = ({ children, ...props }) => (
  <Text center style={{ flex: 1 }} {...props}>
    {children}
  </Text>
)

const DeviceRow = ({ item, positionInList, userId }) => {
  const dispatch = Redux.useDispatch()

  if (!item) {
    return null
  }
  const { identifier, isDeviceLoggedIn, lastSeen, pushNotificationToken } =
    item || {}
  const { device, name, os, userAgent } = identifier || {}
  // eslint-disable-next-line no-unused-vars
  const { ...extraData } = item
  const hasPNT = !!pushNotificationToken

  return (
    <View
      onPress={() => AdditionalInfos.show({ item: extraData })}
      style={{
        flexDirection: 'row',
        flex: 1,
        backgroundColor:
          positionInList % 2 !== 0
            ? theme.colors.borderLightGrey
            : theme.colors.background,
        paddingHorizontal: 6,
        paddingVertical: 12,
        minWidth: 700,
      }}>
      <Cell>{name && name.substring(0, 32)}</Cell>
      <Cell>{device}</Cell>
      <Cell>{userAgent}</Cell>
      <Cell>{os}</Cell>
      <Cell>{moment(lastSeen).fromNow()}</Cell>
      <Cell>{isDeviceLoggedIn ? 'Yes' : 'No'}</Cell>
      <Cell
        color={hasPNT ? theme.colors.primary : theme.colors.Text}
        onPress={(e) => {
          e.stopPropagation()

          if (hasPNT) {
            ModalSendPushNotification.show({ pushNotificationToken })
          }
        }}
        style={{ cursor: hasPNT ? 'pointer' : 'default' }}>
        Send
      </Cell>
      {isDeviceLoggedIn ? (
        <Cell
          center
          color={theme.colors.primary}
          onPress={(e) => {
            e.stopPropagation()
            Alert.alert(
              'Sure?',
              'Do you really want to log out the user?'
            ).then(
              (res) =>
                res === Alert.CONFIRMED &&
                dispatch(logoutDevice({ id: item.id, userId }))
            )
          }}>
          Logout
        </Cell>
      ) : (
        <Cell />
      )}
    </View>
  )
}

const Header = ({ profile, devices }) => {
  const dispatch = Redux.useDispatch()

  const onPressCopyDevices = () => {
    const dump = devices.ids.reduce((acc, id) => {
      const item = devices.data[id]
      return `${acc}${getDeviceDump(
        flattenProps({ ...item, ownerUsername: profile.username })
      )}\n`
    }, '')

    dispatch(copyToClipboard(dump))
  }

  return (
    <React.Fragment>
      <Button
        onPress={onPressCopyDevices}
        style={{ marginBottom: 12, alignSelf: 'flex-end', marginRight: 12 }}>
        Copy Data
      </Button>
      <Button
        onPress={() =>
          Alert.alert(
            'Sure?',
            'Do you really want to log out the user from all devices?'
          ).then(
            (res) =>
              res === Alert.CONFIRMED &&
              dispatch(logoutAllDevices({ userId: profile.id }))
          )
        }
        style={{ marginBottom: 12, alignSelf: 'flex-end', marginRight: 12 }}>
        Logout all
      </Button>
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          backgroundColor: theme.colors.borderLightGrey,
          padding: 6,
          marginBottom: 6,
        }}>
        <Cell bold>Name</Cell>
        <Cell bold>Devices</Cell>
        <Cell bold>User Agent</Cell>
        <Cell bold>OS</Cell>
        <Cell bold>Last Seen</Cell>
        <Cell bold>Logged In</Cell>
        <Cell bold>PushNoti</Cell>
        <Cell bold>action</Cell>
      </View>
    </React.Fragment>
  )
}

export const TabDevices = ({ profile }) => {
  const dispatch = Redux.useDispatch()
  const { _rerenderItem, data, hasMore, isFetching, isRefreshing } =
    Redux.useSelector(selectorTabDevices)

  React.useEffect(() => {
    dispatch(refresh({ userId: profile.id }))
  }, [])

  return (
    <FlatList
      _rerenderItem={_rerenderItem}
      actions={{
        fetch: (payload) =>
          dispatch(
            fetch({
              userId: profile.id,
              ...payload,
            })
          ),
        refresh: (payload) =>
          dispatch(
            refresh({
              userId: profile.id,
              ...payload,
            })
          ),
      }}
      component={DeviceRow}
      contentContainerStyle={{
        maxWidth: undefined,
      }}
      renderHeader={(props) => (
        <Header
          profile={profile}
          devices={data[profile.id] ?? { ids: [], data: {} }}
          {...props}
        />
      )}
      extraData={{ hasMore, isFetching, isRefreshing }}
      extraProps={{ userId: profile.id }}
      hasMore={hasMore}
      isFetching={isFetching}
      isRefreshing={isRefreshing}
      items={data[profile.id] ?? { ids: [], data: {} }}
      style={{ height: window.innerHeight - 171, minWidth: 700 }}
    />
  )
}
