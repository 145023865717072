import { ACCOUNT_VERIFICATION_STATUS } from '@tellonym/enums/lib/AccountVerification'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, Button, FlatList, Text, View } from '../../common'
import {
  changeSettings,
  refreshAccountVerifications as refresh,
  updateAccountVerifications,
} from '../actions'
import { getAccountVerifications } from '../selectors'
import { AccountVerificationItem } from './AccountVerificationItem'

export const PageAccountVerification = () => {
  const dispatch = ReactRedux.useDispatch()
  const accountVerifications = ReactRedux.useSelector(getAccountVerifications)
  const refFlatlist = React.useRef()
  const [userIds, setUserIds] = React.useState([])

  const onPressSelect = React.useCallback(
    (userId) => {
      if (userIds.includes(userId)) {
        setUserIds(userIds.filter((id) => id !== userId))
      } else {
        setUserIds([...userIds, userId])
      }
    },
    [userIds, setUserIds]
  )

  const onPressUpdate = React.useCallback(() => {
    dispatch(updateAccountVerifications({ userIds }))
  }, [dispatch, userIds])

  const renderHeader = React.useCallback(() => {
    return (
      <Box
        transparent
        alignSelf="center"
        alignItems="center"
        padding={20}
        margin={12}>
        <Text center style={{ marginBottom: 12 }}>
          {`For those users where their verification photo matches at least one profile photo: select them and click on ‘Update’.
Where that doesn’t hold true, press the X to remove the verification status directly.`}
        </Text>
        <View
          style={{
            alignSelf: 'center',
            flexDirection: 'row',
            width: 240,
            justifyContent: 'space-around',
          }}>
          <Button
            disabled={
              accountVerifications.isRefreshing ||
              accountVerifications.data.ids.length === 0
            }
            onPress={onPressUpdate}>
            Update
          </Button>
        </View>
      </Box>
    )
  }, [accountVerifications, onPressUpdate])

  React.useEffect(() => {
    dispatch(refresh())
  }, [])

  const { _rerenderItem, isFetching, isRefreshing, data } = accountVerifications

  const onPressRemoveVerification = React.useCallback((userId) => {
    dispatch(
      changeSettings({
        id: userId,
        isVerified: false,
        verificationStatus: ACCOUNT_VERIFICATION_STATUS.REJECTED,
      })
    )
  }, [])

  return (
    <FlatList
      _rerenderItem={_rerenderItem}
      actions={{
        refresh,
      }}
      component={AccountVerificationItem}
      contentContainerStyle={{
        alignItems: 'center',
      }}
      extraData={{
        isFetching,
        isRefreshing,
      }}
      extraProps={{
        onPressSelect,
        onPressRemoveVerification,
      }}
      forwardRef={refFlatlist}
      isFetching={isFetching}
      isRefreshing={isRefreshing}
      items={data}
      numColumns={3}
      renderHeader={renderHeader}
    />
  )
}
