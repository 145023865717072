import { buildUrl } from '@tellonym/core/api'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCustomApiHost } from '../../app/actions'
import { Button } from '../../common/components/Button'
import { Dropdown } from '../../common/components/Dropdown'
import { Input } from '../../common/components/Input'
import { Modal } from '../../common/components/Modal'
import { Text } from '../../common/components/Text'
import { View } from '../../common/components/View'
import { theme } from '../../common/styles/theme'

const mapStateToProps = (_state) => ({})

const modalId = 1337
const getCurrentApiUrl = () => buildUrl({ path: '' }).split('/?')[0]
const predefinedApiHosts = [
  'CUSTOM',
  'https://api.tellonym.me',
  'https://api-staging.tellonym.me',
  'https://api.tnym.de',
  'https://api-red.tnym.de',
  'https://api-blue.tnym.de',
  'http://localhost:3005',
]

const DebugModalView = connect(mapStateToProps, (dispatch) => ({
  setCustomApiHost: bindActionCreators(setCustomApiHost, dispatch),
}))(({ setCustomApiHost }) => {
  const [apiHost, setApiHost] = useState(getCurrentApiUrl)

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body>
        <View
          style={{
            borderRadius: 25,
            backgroundColor: theme.colors.background,
            padding: 32,
            width: 500,
            minHeight: 150,
          }}>
          <Text type="h2" bold style={{ marginBottom: 12 }}>
            Debug Menu
          </Text>

          <View
            style={{
              flex: 1,
            }}>
            <Input
              grey
              label="Api Host"
              value={apiHost}
              onChange={(e) => setApiHost(e.target.value)}
              placeholder="Api host with / at the end"
              style={{ resize: 'vertical' }}
            />
            <Dropdown
              label="Predefined Api Hosts"
              options={predefinedApiHosts}
              onChange={({ item }) => {
                if (item !== 'CUSTOM') {
                  setApiHost(item)
                }
              }}
              currentIndex={
                predefinedApiHosts.indexOf(apiHost) < 0
                  ? 0
                  : predefinedApiHosts.indexOf(apiHost)
              }
              shouldSetInitialValue
              style={{ width: '100%', alignSelf: 'center' }}
            />
            <Button
              onPress={() => {
                setCustomApiHost({ customApiHost: apiHost })
                Modal.hide({ id: modalId })
              }}
              style={[{ flex: 1, alignSelf: 'center', marginTop: 24 }]}>
              Save
            </Button>
          </View>
        </View>
      </Modal.Body>
    </View>
  )
})

const show = (payload) =>
  Modal.show({
    render: (props) => <DebugModalView {...payload} {...props} />,
    hasBackdrop: true,
    shouldHideOnBackgroundPress: true,
    id: modalId,
  })

export const DebugModal = { show }
