import React from 'react'
import A from 'react-s-alert'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import 'react-s-alert/dist/s-alert-default.css'
import { createStore } from 'redux'
import { Card } from './Card'
import { ModalOption } from './ModalOption'
import { Overlay } from './Overlay'
import { Text } from './Text'

const initialState = {
  options: {
    confirmText: 'Confirm',
    dismissText: 'Cancel',
    hasLater: false,
    isCancelable: true,
    isDismissable: false,
    laterText: 'Later',
  },
  isVisible: false,
  resolve: (payload) => Promise.resolve(payload),
  text: '',
  title: '',
  queue: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        isVisible: true,
        ...action.payload,
        options: {
          ...state.options,
          ...action.payload.options,
        },
      }
    case 'HIDE':
      return { ...initialState, queue: state.queue.slice(1) }
    default:
      return state
  }
}

const store = createStore(reducer)

const show = (payload) => store.dispatch({ type: 'SHOW', payload })

const hide = (payload) => {
  store.getState().resolve(payload)
  store.dispatch({ type: 'HIDE' })
  const state = store.getState()
  if (state.queue.length) {
    show(state.queue[0])
  }
}

const CONFIRMED = 'CONFIRMED'
const DISMISSED = 'DISMISSED'
const IGNORED = 'IGNORED'
const LATER = 'LATER'

const alert = (title, text, options) =>
  new Promise((resolve) => {
    const state = store.getState()
    const isQueued = state.queue.some(
      (alert) => alert.title === title && alert.text === text
    )
    if (isQueued) {
      resolve(IGNORED)
    } else {
      state.queue.push({ title, text, options, resolve })
      if (!store.getState().isVisible && state.queue.length) {
        show(state.queue[0])
      }
    }
  })

export class Alert extends React.Component {
  static alert = alert
  static close = A.close
  static closeAll = A.closeAll
  static error = A.error
  static info = A.info
  static success = A.success
  static warning = A.warning

  static CONFIRMED = CONFIRMED
  static DISMISSED = DISMISSED
  static IGNORED = IGNORED
  static LATER = LATER

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => this.forceUpdate())
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  render() {
    const { isVisible, options, title, text } = store.getState()

    return (
      <React.Fragment>
        <A position="top" stack={false} timeout={2000} />
        {isVisible && (
          <Overlay onPress={() => options.isDismissable && hide(DISMISSED)}>
            <Card
              onPressClose={
                options.isDismissable ? () => hide(DISMISSED) : undefined
              }
              style={options.cardStyle}>
              <Text center type="h2" style={{ marginBottom: 24 }}>
                {title}
              </Text>
              <Text style={{ marginBottom: 24 }}>{text}</Text>
              <ModalOption onPress={() => hide(CONFIRMED)} hasBorderTop>
                {options.confirmText}
              </ModalOption>
              {options.hasLater && (
                <ModalOption onPress={() => hide(LATER)} hasBorderTop>
                  {options.laterText}
                </ModalOption>
              )}
              {options.isCancelable && (
                <ModalOption onPress={() => hide(DISMISSED)} hasBorderTop>
                  {options.dismissText}
                </ModalOption>
              )}
            </Card>
          </Overlay>
        )}
      </React.Fragment>
    )
  }
}
