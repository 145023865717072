import Radium from 'radium'
import React from 'react'
import { Radium as R } from '../hocs/Radium'
import { theme } from '../styles/theme'

class SpinnerComponent extends React.Component {
  static size = 25

  render() {
    const {
      border = 4,
      color = theme.colors.placeholder,
      size = SpinnerComponent.size,
      style = {},
    } = this.props

    const spin = Radium.keyframes(
      {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
      'spin'
    )

    return (
      <div
        style={{
          alignSelf: 'center',
          borderColor: color ? `${color}80` : '#4D000000',
          borderStyle: 'solid',
          borderWidth: border,
          borderTopWidth: border,
          borderTopColor: color || 'black',
          borderRadius: '50%',
          width: size,
          height: size,
          animation: 'x 0.5s linear infinite',
          animationName: spin,
          ...style,
        }}
      />
    )
  }
}

export const Spinner = R(SpinnerComponent)
