import R from 'radium'
import friendlyPseudos from 'radium-plugin-friendly-pseudos'

const resolveHorizontalAndVertical = ({ style = {}, ...props }) => {
  const {
    marginVertical,
    marginHorizontal,
    paddingHorizontal,
    paddingVertical,
    ...restStyles
  } = style

  if (style.padding && style.margin) {
    return restStyles
  }

  let transformedStyles = {}

  if (paddingHorizontal) {
    transformedStyles = {
      paddingLeft: paddingHorizontal,
      paddingRight: paddingHorizontal,
    }
  }
  if (paddingVertical) {
    transformedStyles = {
      ...transformedStyles,
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical,
    }
  }
  if (marginVertical) {
    transformedStyles = {
      ...transformedStyles,
      marginLeft: marginHorizontal,
      marginRight: marginHorizontal,
    }
  }
  if (marginHorizontal) {
    transformedStyles = {
      ...transformedStyles,
      marginTop: marginVertical,
      marginBottom: marginVertical,
    }
  }

  return { ...props, style: { ...transformedStyles, ...restStyles } }
}

export const Radium = (Component) =>
  R({
    plugins: [
      R.Plugins.mergeStyleArray,
      R.Plugins.checkProps,
      R.Plugins.resolveMediaQueries,
      friendlyPseudos,
      resolveHorizontalAndVertical,
      R.Plugins.resolveInteractionStyles,
      R.Plugins.keyframes,
      R.Plugins.prefix,
      R.Plugins.checkProps,
    ],
  })(Component)
