import P from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { theme } from '../styles/theme'
import { Spinner } from './Spinner'
import { View } from './View'

const show = Radium.keyframes(
  {
    '0%': {
      transform: 'scale(0)',
      height: 0,
      visibility: 'hidden',
    },
    '50%': {
      height: 34,
    },
    '100%': {
      transform: 'scale(1)',
      height: 34,
      visibility: 'visible',
    },
  },
  'show'
)

const hide = Radium.keyframes(
  {
    '0%': {
      transform: 'scale(1)',
      height: 34,
      visibility: 'visible',
    },
    '50%': {
      height: 34,
    },
    '100%': {
      transform: 'scale(0)',
      height: 0,
      visibility: 'hidden',
    },
  },
  'hide'
)

const styles = {
  container: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'cetner',
    backgroundColor: theme.colors.background,
    borderRadius: 50,
    boxShadow: '0px 0px 24px 0px rgba(0,0,0,0.2)',
    height: 0,
    margin: 0,
    padding: 4,
    position: 'absolute',
    top: 72,
    visibility: 'hidden',
    zIndex: 99,
  },
}

export class LoadingIndicator extends React.Component {
  state = { isVisible: this.props.isLoading || undefined }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.state.isVisible !== nextState.isVisible
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.setState({ isVisible: this.props.isLoading })
    }
  }

  render() {
    const animation = {
      ...(this.state.isVisible
        ? {
            animation: 'x 0.3s ease',
            animationFillMode: 'forwards',
            animationName: show,
          }
        : {}),
      ...(this.state.isVisible === false
        ? {
            animation: 'x 0.3s ease',
            animationFillMode: 'forwards',
            animationName: hide,
          }
        : {}),
    }

    return (
      <View style={[styles.container, animation]}>
        <Spinner />
      </View>
    )
  }
}

LoadingIndicator.propTypes = {
  isLoading: P.bool.isRequired,
}
