import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_QUESTION_DEPTH } from '@tellonym/enums/lib/Tell'
import { Breadcrumb, Button, Skeleton, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, ScrollView, history, hooks, theme } from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import { createTopic, refreshTopics } from '../actionsV2'
import { getIsRefreshingTopics, getLanguage } from '../selectorsV2'
import { ArtificialTellTopicsTable } from './ArtificialTellTopicsTable'
import { ButtonBack } from './ButtonBack'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { SelectorsLanguage } from './SelectorsLanguage'

const medianTexts = [
  'Total Sent:',
  'Answer Rate:',
  'Share Rate:',
  'Median Length:',
]

const HeaderSkeleton = () => (
  <Box
    paddingBottom={16}
    paddingHorizontal={24}
    paddingTop={24}
    backgroundColor={theme.colors.antdBackgroundElevated}>
    <Box alignItems="center" transparent flexDirection="row" paddingBottom={24}>
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        transparent>
        <ButtonBack
          onPress={() => {
            history.goBack()
          }}
          style={{ marginRight: 12, width: 30, height: 30 }}
        />

        <Breadcrumb>
          <Breadcrumb.Item key="Topics">Topics</Breadcrumb.Item>
        </Breadcrumb>
      </Box>
    </Box>

    <Box flexDirection="row" justifyContent="space-between" transparent>
      <Skeleton
        active
        title
        paragraph={{ rows: 3 }}
        size="small"
        style={{ width: 115, marginRight: 48 }}
      />
      <Skeleton
        active
        title
        paragraph={{ rows: 5 }}
        size="small"
        style={{ width: 275 }}
      />
      <Skeleton.Button active size="large" style={{ width: 300 }} />
    </Box>

    <Skeleton.Button active style={{ width: 100, marginTop: 24 }} />
  </Box>
)

const Header = ({ data }) => {
  const [isCreatingTopic, setIsCreatingTopic] = React.useState(false)

  const dispatch = ReactRedux.useDispatch()
  const language = ReactRedux.useSelector(getLanguage)
  const isRefreshing = ReactRedux.useSelector(getIsRefreshingTopics)

  const onPressNewTopic = React.useCallback(() => {
    const name = `name ${Math.round(Math.random() * 1000000)}`

    dispatch(createTopic({ name }))
    setIsCreatingTopic(true)
  }, [])

  const onChangeDataMode = () => {
    dispatch(refreshTopics())
  }

  if (typeof data.topics === 'undefined') {
    return <HeaderSkeleton />
  }

  const groupsMedianIntro =
    data.analytics?.[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]

  const groupsMedianDeep = data.analytics?.[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]

  return (
    <Box
      paddingBottom={16}
      paddingHorizontal={24}
      paddingTop={24}
      backgroundColor={theme.colors.antdBackgroundElevated}>
      <Box
        alignItems="center"
        transparent
        flexDirection="row"
        paddingBottom={24}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Topics">Topics</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Box marginRight={48} justifyContent="space-between" transparent>
          <Box flexShrink={0} transparent>
            <Typography.Title level={5}>
              {DETECTABLE_LANGUAGE[
                langDetectObjectsByType1[language]
              ].toUpperCase()}
            </Typography.Title>
            <Typography.Text>{`${data.activeCounts.topics} Topics`}</Typography.Text>
            <Typography.Text>{`${data.activeCounts.groups} Groups`}</Typography.Text>
            <Typography.Text>{`${data.activeCounts.groupsIntro} Intro,  ${data.activeCounts.groupsDeep} Deep`}</Typography.Text>
          </Box>
        </Box>

        <MedianDataForDataMode
          data={[
            {
              title: 'Median Intro',
              texts: medianTexts,
              values: [
                groupsMedianIntro.totalSent,
                groupsMedianIntro.answerRate,
                groupsMedianIntro.shareRate,
                groupsMedianIntro.p50Length,
              ],
            },
            {
              title: 'Median Deep',
              texts: medianTexts,
              values: [
                groupsMedianDeep.totalSent,
                groupsMedianDeep.answerRate,
                groupsMedianDeep.shareRate,
                groupsMedianDeep.p50Length,
              ],
            },
          ]}
          isLoading={isRefreshing}
          onChange={onChangeDataMode}
          flexShrink={0}
          backgroundColor={theme.colors.antdBackgroundElevated}
        />

        <SelectorsLanguage />
      </Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        marginTop={24}
        transparent>
        <Button
          size="middle"
          onClick={onPressNewTopic}
          loading={isCreatingTopic}>
          Create Topic
        </Button>
      </Box>
    </Box>
  )
}

export const PageArtificialTellsTopics = () => {
  const dispatch = ReactRedux.useDispatch()

  const table = hooks.useTableState()

  const language = ReactRedux.useSelector(getLanguage)

  const artificialTellsV2 = ReactRedux.useSelector(
    (state) => state.artificialTellsV2
  )

  const allData = artificialTellsV2.topics

  React.useEffect(() => {
    updateLocationSearch(table)
  }, [])

  React.useEffect(() => {
    dispatch(refreshTopics())
  }, [language])

  return (
    <Box>
      <Header language={language} data={allData} />
      <ScrollView>
        <ArtificialTellTopicsTable
          {...table}
          useFixedHeader
          dataSource={allData?.topics}
          language={language}
          locale={{
            emptyText: allData?.topics ? null : <Skeleton active={true} />,
          }}
          interestsList={allData?.allInterestsAvailablePerLang ?? []}
        />
      </ScrollView>
    </Box>
  )
}
