import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleSidemenu } from '../../app/actions'
import { getPermissions } from '../../app/selectors'
import { Avatar } from '../../common/components/Avatar'
import { Link } from '../../common/components/Link'
import { Text } from '../../common/components/Text'
import { View } from '../../common/components/View'
import { checkPermission } from '../../common/helpers'
import { theme } from '../../common/styles/theme'
import { DebugModal } from './DebugModal'

const headerHeight = 50
const headerBorder = 1
const height = headerHeight + headerBorder

const Bar = () => (
  <View
    style={{
      width: 20,
      height: 2,
      backgroundColor: theme.colors.primary,
      transition: '0.4s',
      margin: '2px 0',
    }}
  />
)

const BurgerMenu = ({ onPress }) => (
  <View
    style={{
      cursor: 'pointer',
    }}
    onPress={onPress}>
    <Bar />
    <Bar />
    <Bar />
  </View>
)

const ProfileMenuItem = ({ text, to, onPress, style, children }) => (
  <View
    style={{
      paddingVertical: 12,
      paddingHorizontal: 24,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      ...style,
    }}>
    {children || (
      <Link to={to} onClick={onPress} style={{ marginLeft: 12 }}>
        <Text>{text}</Text>
      </Link>
    )}
  </View>
)

const ProfileMenu = ({ profile }) => {
  const [isShown, setIsShown] = useState(false)
  const permissions = useSelector(getPermissions)

  const _onPress = () => setIsShown(!isShown)
  let timeout

  const _onMouseEnter = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setIsShown(true)
  }
  const _onMouseLeave = () =>
    (timeout = setTimeout(() => setIsShown(false), 500))

  // show debug menu on all other domains than modcp.tellonym.me or only if permissions exist
  const shouldShowDebugMenu =
    !window.location.host.includes('modcp.tellonym.me') ||
    checkPermission('debugmenu', permissions)

  return (
    <View
      style={{
        height: headerHeight,
      }}
      onPress={_onPress}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}>
      <Avatar
        style={{
          marginTop: 10,
        }}
        size={30}
        user={profile}
      />
      {isShown ? (
        <View
          onMouseEnter={_onMouseEnter}
          onMouseLeave={_onMouseLeave}
          style={{
            position: 'absolute',
            zIndex: 50,
            minHeight: 20,
            width: 200,
            right: 0,
            marginTop: height - 1,
            backgroundColor: theme.colors.background,
            boxShadow: theme.styles.shadow,
          }}>
          {shouldShowDebugMenu ? (
            <ProfileMenuItem
              text="Debug Menu"
              onPress={(e) => {
                e.preventDefault()
                DebugModal.show()
              }}
            />
          ) : (
            <Text>Nothing here</Text>
          )}
        </View>
      ) : null}
    </View>
  )
}

const HeaderComponent = ({ actions, profile }) => (
  <View
    style={{
      zIndex: 99,
      position: 'fixed',
      left: 0,
      right: 0,
      height: headerHeight,
      boxShadow: theme.styles.shadow,
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.colors.background,
      flexDirection: 'row',
      paddingHorizontal: 12,
    }}>
    <BurgerMenu onPress={actions.toggleSidemenu} />

    <Link
      to="/"
      style={{
        justifyContent: 'center',
      }}>
      <img
        src="/logo.png"
        alt="logo"
        style={{
          height: 25,
          margin: 0,
          width: 126,
        }}
      />
    </Link>
    <ProfileMenu profile={profile} />
  </View>
)

HeaderComponent.height = height

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ toggleSidemenu }, dispatch),
})

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

export { Header }
