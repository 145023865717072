export const name = 'user'

export const SERVICE = {
  APPLE: 'APPLE',
  EMAIL: 'EMAIL',
  GOOGLE: 'GOOGLE',
}

export const TYPE = {
  CONNECT: 'CONNECT',
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
}
