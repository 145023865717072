import React from 'react'
import { createStore } from 'redux'
import { disableScrolling, enableScrolling } from '../helpers'
import { theme } from '../styles/theme'
import { Animate } from './Animate'
import { View } from './View'

const Actions = { CLOSED_BY_USER: 'CLOSED_BY_USER' }

const initialState = {
  activeZIndex: 99,
  queue: { ids: [], data: {} },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW': {
      const { data, ids } = state.queue
      const prevModal = data[ids[ids.length - 1]]
      const { id } = action.payload
      const nextZIndex = state.activeZIndex + 1

      return !state.queue.ids.includes(id)
        ? {
            ...state,
            activeZIndex: nextZIndex,
            queue: {
              ids: [...new Set([...ids, id])],
              data: {
                ...data,
                ...(prevModal
                  ? {
                      [prevModal.id]: {
                        ...prevModal,
                        isFocused: false,
                      },
                    }
                  : {}),
                [id]: {
                  ...action.payload,
                  isFocused: true,
                  isRequestingClose: false,
                  zIndex: nextZIndex,
                },
              },
            },
          }
        : state
    }

    case 'HIDE': {
      const { data, ids } = state.queue
      const prevModal = data[ids[ids.length - 2]]
      const { extraData, id, resolveHide } = action.payload
      const itemId = id || state.activeZIndex

      return state.queue.ids.includes(itemId)
        ? {
            ...state,
            queue: {
              ...state.queue,
              data: {
                ...data,
                ...(prevModal
                  ? {
                      [prevModal.id]: {
                        ...prevModal,
                        isFocused: true,
                      },
                    }
                  : {}),
                [itemId]: {
                  ...data[itemId],
                  extraData,
                  isRequestingClose: true,
                  isFocused: false,
                  resolveHide,
                },
              },
            },
          }
        : state
    }

    case 'RESET': {
      const { id } = action.payload
      const { data, ids } = state.queue

      return {
        ...state,
        activeZIndex: state.activeZIndex - 1,
        queue: {
          ids: ids.filter((item) => item !== id),
          data: ids.reduce(
            (acc, curr) => (curr !== id ? { ...acc, [curr]: data[curr] } : acc),
            {}
          ),
        },
      }
    }

    default:
      return state
  }
}

const store = createStore(reducer)

const hide = ({ id, type, extraData }) =>
  new Promise((resolve) => {
    const { queue } = store.getState()

    if (queue.ids.length === 1) {
      enableScrolling()
    }

    store.dispatch({
      type: 'HIDE',
      payload: {
        extraData: type ? { type } : extraData,
        id,
        resolveHide: resolve,
      },
    })
  })

const isVisible = (payload = { id: undefined }) => {
  const { queue } = store.getState()
  const { id } = payload
  return id ? queue.data[id] && queue.data[id].isVisible : queue.ids.length > 0
}

const reset = ({ id }) => store.dispatch({ type: 'RESET', payload: { id } })
// eslint-disable-next-line
const replace = () => {} // TODO implement replacement of modal if isOpen
// eslint-disable-next-line
const pop = () => {} // TODO show modal no matter if id is present

const show = ({
  animationType = undefined,
  hasBackdrop = false,
  id = undefined,
  render = () => {},
  shouldHideOnBackgroundPress = false,
}) =>
  new Promise((resolve) => {
    const { queue } = store.getState()

    if (!queue.ids.length) {
      disableScrolling()
    }

    store.dispatch({
      type: 'SHOW',
      payload: {
        animationType,
        hasBackdrop,
        id: id || store.getState().activeZIndex + 1, // TODO could lead to error when custom id is same as activeZIndex + 1
        render,
        resolveShow: resolve,
        resolveHide: () => {},
        shouldHideOnBackgroundPress,
      },
    })
  })

const Wrapper = ({ children, id, shouldHideOnBackgroundPress, zIndex }) =>
  shouldHideOnBackgroundPress ? (
    <View
      onPress={(e) => {
        e.stopPropagation()
        hide({ id, type: Actions.CLOSED_BY_USER })
      }}
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex,
        overflow: 'hidden',
      }}>
      {children}
    </View>
  ) : (
    <View
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex,
        overflow: 'hidden',
      }}>
      {children}
    </View>
  )

const ModalBody = ({ onPress = undefined, style, ...props }) => (
  <View
    onPress={(e) => {
      e.stopPropagation()
      if (typeof onPress === 'function') {
        onPress()
      }
    }}
    style={[
      style,
      {
        maxHeight: window.tnym.getHeight(),
        overflow: 'scroll',
      },
    ]}
    {...props}
  />
)

class Modal extends React.Component {
  static Actions = Actions
  static Animations = Animate.Animations
  static hide = hide
  static show = show
  static isVisible = isVisible
  static Body = ModalBody

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => this.forceUpdate())
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  render() {
    const { queue } = store.getState()

    return queue.ids[0]
      ? queue.ids.map((id) => {
          const {
            animationType,
            extraData,
            isFocused,
            isRequestingClose,
            hasBackdrop,
            shouldHideOnBackgroundPress,
            render,
            resolveHide,
            resolveShow,
            zIndex,
          } = queue.data[id]

          return (
            <Wrapper
              key={id}
              id={id}
              shouldHideOnBackgroundPress={shouldHideOnBackgroundPress}
              zIndex={zIndex}>
              <Animate
                type={animationType}
                isRequestingClose={isRequestingClose}
                onClose={() => {
                  reset({ id })
                  resolveHide(extraData)
                  resolveShow(extraData)
                }}
                style={{
                  flex: 1,
                  backgroundColor: hasBackdrop
                    ? theme.colors.modalBackground
                    : theme.colors.transparent,
                }}>
                {render({
                  modalId: id,
                  isFocused,
                })}
              </Animate>
            </Wrapper>
          )
        })
      : null
  }
}

export { Modal }
