export const ABORT_REGISTRATION = 'user/ABORT_REGISTRATION'
export const ABORT_REGISTRATION_SUCCESS = 'user/ABORT_REGISTRATION_SUCCESS'
export const ABORT_REGISTRATION_ERROR = 'user/ABORT_REGISTRATION_ERROR'

export const AUTHENTICATE = 'user/AUTHENTICATE'

export const AUTHENTICATION_COMPLETED = 'user/AUTHENTICATION_COMPLETED'

export const CHANGE_EMAIL = 'user/CHANGE_EMAIL'

export const CHANGE_PASSWORD = 'user/CHANGE_PASSWORD'

export const CHANGE_STATE = 'user/CHANGE_STATE'

export const CHANGE_USERNAME = 'user/CHANGE_USERNAME'

export const CHECK_EMAIL_AVAILABILITY = 'user/CHECK_EMAIL_AVAILABILITY'
export const CHECK_EMAIL_AVAILABILITY_ERROR = 'user/CHECK_EMAIL_AVAILABILITY_ERROR' // prettier-ignore
export const CHECK_EMAIL_AVAILABILITY_SUCCESS = 'user/CHECK_EMAIL_AVAILABILITY_SUCCESS' // prettier-ignore

export const CHECK_USERNAME_AVAILABILITY = 'user/CHECK_USERNAME_AVAILABILITY'
export const CHECK_USERNAME_AVAILABILITY_ERROR = 'user/CHECK_USERNAME_AVAILABILITY_ERROR' // prettier-ignore
export const CHECK_USERNAME_AVAILABILITY_SUCCESS = 'user/CHECK_USERNAME_AVAILABILITY_SUCCESS' // prettier-ignore

export const CONTINUE_ONBOARDING = 'user/CONTINUE_ONBOARDING'

export const GIVE_PARENTAL_CONFIRMATION = 'user/GIVE_PARENTAL_CONFIRMATION'
export const GIVE_PARENTAL_CONFIRMATION_ERROR = 'user/GIVE_PARENTAL_CONFIRMATION_ERROR' // prettier-ignore
export const GIVE_PARENTAL_CONFIRMATION_SUCCESS = 'user/GIVE_PARENTAL_CONFIRMATION_SUCCESS' // prettier-ignore

export const LOGIN = 'user/LOGIN'
export const LOGIN_ERROR = 'user/LOGIN_ERROR'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'

export const LOGIN_LEGACY = 'user/LOGIN_LEGACY'
export const LOGIN_LEGACY_ERROR = 'user/LOGIN_LEGACY_ERROR'
export const LOGIN_LEGACY_SUCCESS = 'user/LOGIN_LEGACY_SUCCESS'

export const LOGIN_WITH_APPLE = 'user/LOGIN_WITH_APPLE'
export const LOGIN_WITH_APPLE_ERROR = 'user/LOGIN_WITH_APPLE_ERROR'
export const LOGIN_WITH_APPLE_SUCCESS = 'user/LOGIN_WITH_APPLE_SUCCESS'

export const LOGIN_WITH_GOOGLE = 'user/LOGIN_WITH_GOOGLE'
export const LOGIN_WITH_GOOGLE_ERROR = 'user/LOGIN_WITH_GOOGLE_ERROR'
export const LOGIN_WITH_GOOGLE_SUCCESS = 'user/LOGIN_WITH_GOOGLE_SUCCESS'

export const REFRESH_BANNED_STATUS = 'user/REFRESH_BANNED_STATUS'
export const REFRESH_BANNED_STATUS_ERROR = 'user/REFRESH_BANNED_STATUS_ERROR'
export const REFRESH_BANNED_STATUS_SUCCESS = 'user/REFRESH_BANNED_STATUS_SUCCESS' // prettier-ignore

export const REGISTER = 'user/REGISTER'
export const REGISTER_ERROR = 'user/REGISTER_ERROR'
export const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS'

export const REGISTER_WITH_APPLE = 'user/REGISTER_WITH_APPLE'
export const REGISTER_WITH_APPLE_ERROR = 'user/REGISTER_WITH_APPLE_ERROR'
export const REGISTER_WITH_APPLE_SUCCESS = 'user/REGISTER_WITH_APPLE_SUCCESS'

export const REGISTER_WITH_GOOGLE = 'user/REGISTER_WITH_GOOGLE'
export const REGISTER_WITH_GOOGLE_ERROR = 'user/REGISTER_WITH_GOOGLE_ERROR'
export const REGISTER_WITH_GOOGLE_SUCCESS = 'user/REGISTER_WITH_GOOGLE_SUCCESS'

export const REQUEST_ATTRIBUTED_USER = 'user/REQUEST_ATTRIBUTED_USER'
export const REQUEST_ATTRIBUTED_USER_ERROR = 'user/REQUEST_ATTRIBUTED_USER_ERROR' // prettier-ignore
export const REQUEST_ATTRIBUTED_USER_SUCCESS = 'user/REQUEST_ATTRIBUTED_USER_SUCCESS' // prettier-ignore

export const REQUEST_PASSWORD = 'user/REQUEST_PASSWORD'
export const REQUEST_PASSWORD_ERROR = 'user/REQUEST_PASSWORD_ERROR'
export const REQUEST_PASSWORD_SUCCESS = 'user/REQUEST_PASSWORD_SUCCESS'

export const RESET_PASSWORD = 'user/RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'user/RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_SUCCESS = 'user/RESET_PASSWORD_SUCCESS'

export const RESET_AUTHENTICATION = 'user/RESET_AUTHENTICATION'

export const SET_HAS_ACCEPTED_TERMS = 'user/SET_HAS_ACCEPTED_TERMS'

export const SHOW_APPLE_LOGIN = 'user/SHOW_APPLE_LOGIN'

export const SHOW_GOOGLE_LOGIN = 'user/SHOW_GOOGLE_LOGIN'

export const SET_IS_BANNED = 'user/SET_IS_BANNED'

export const SET_SHOULD_SHOW_GOOGLE_IDENTITY_SERVICE_BUTTON = 'user/SET_SHOULD_SHOW_GOOGLE_IDENTITY_SERVICE_BUTTON' // prettier-ignore
