import dayjs from 'dayjs'
import { dateTypes } from './constants'

export const dateTypeToDates = ({ dateType, startDate, endDate }) => {
  switch (dateType) {
    case dateTypes.CUSTOM_DATE:
      return {
        startDate: dayjs(startDate).format(),
        endDate: dayjs(endDate).format(),
      }

    case dateTypes.LAST_30_MINUTES:
      // eslint-disable-next-line no-unused-vars, no-case-declarations
      const [_, timespan] = dateType.match(/_(\d*)_/)

      return {
        startDate: dayjs().subtract(Number(timespan), 'minute').format(),
        endDate: dayjs().format(),
      }

    case dateTypes.LAST_2_HOURS:
    case dateTypes.LAST_6_HOURS:
    case dateTypes.LAST_24_HOURS: {
      // eslint-disable-next-line no-unused-vars
      const [_, timespan] = dateType.match(/_(\d*)_/)
      return {
        endDate: dayjs().format(),
        startDate: dayjs().subtract(Number(timespan), 'hours').format(),
      }
    }

    default:
      return undefined
  }
}
