import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { mergeDeepRight } from 'ramda'
import { getDataMode, getLanguage } from './selectorsV2'
import * as t from './typesV2'

const createAction =
  (actionCreator) => (payload, meta) => (dispatch, getState) => {
    const state = getState()
    const dataMode = getDataMode(state)
    const languageString = getLanguage(state)
    const language = langDetectObjectsByType1[languageString]

    const action = actionCreator(
      mergeDeepRight(payload, {
        language,
        dataMode,
        analytics: { dataMode },
      }),
      meta
    )

    action.shouldAdjustData = false

    dispatch(action)
  }

export const createGroup = createAction((payload) => ({
  type: t.CREATE_GROUP,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/item' },
      commit: { type: t.CREATE_GROUP_SUCCESS },
      rollback: { type: t.CREATE_GROUP_ERROR },
    },
  },
}))

export const createTodo = (payload) => ({
  type: t.CREATE_TODO,
  shouldAdjustData: false,
  payload: {
    ...payload,
    language: langDetectObjectsByType1[payload.language],
  },
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: 'admin/artificialtells/v2/todo',
      },
      commit: { type: t.CREATE_TODO_SUCCESS },
      rollback: { type: t.CREATE_TODO_ERROR },
    },
  },
})

export const refreshTodos = (payload) => ({
  type: t.REFRESH_TODOS,
  shouldAdjustData: false,
  payload,
  meta: {
    offline: {
      effect: {
        limit: null,
        method: 'GET',
        path: 'admin/artificialtells/v2/todo',
        ...payload,
      },
      commit: { type: t.REFRESH_TODOS_SUCCESS },
      rollback: { type: t.REFRESH_TODOS_ERROR },
    },
  },
})

export const completeTodo = (payload) => ({
  type: t.COMPLETE_TODO,
  shouldAdjustData: false,
  payload,
  meta: {
    offline: {
      effect: {
        method: 'POST',
        path: `admin/artificialtells/v2/todo/${payload.todoId}/complete`,
      },
      commit: { type: t.COMPLETE_TODO_SUCCESS },
      rollback: { type: t.COMPLETE_TODO_ERROR },
    },
  },
})

export const createVariance = createAction((payload) => ({
  type: t.CREATE_VARIANCE,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/variance' },
      commit: { type: t.CREATE_VARIANCE_SUCCESS },
      rollback: { type: t.CREATE_VARIANCE_ERROR },
    },
  },
}))

export const editGroup = createAction((payload) => ({
  type: t.EDIT_GROUP,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/item' },
      commit: { type: t.EDIT_GROUP_SUCCESS, meta: payload },
      rollback: { type: t.EDIT_GROUP_ERROR },
    },
  },
}))

export const createTopic = createAction((payload) => ({
  type: t.CREATE_TOPIC,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/topic' },
      commit: { type: t.CREATE_TOPIC_SUCCESS },
      rollback: { type: t.CREATE_TOPIC_ERROR },
    },
  },
}))

export const editTopic = createAction((payload) => ({
  type: t.EDIT_TOPIC,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/topic' },
      commit: { type: t.EDIT_TOPIC_SUCCESS },
      rollback: { type: t.EDIT_TOPIC_ERROR },
    },
  },
}))

export const editVariance = createAction((payload) => ({
  type: t.EDIT_VARIANCE,
  payload,
  meta: {
    offline: {
      effect: { method: 'POST', path: 'admin/artificialtells/v2/variance' },
      commit: { type: t.EDIT_VARIANCE_SUCCESS },
      rollback: { type: t.EDIT_VARIANCE_ERROR },
    },
  },
}))

export const refreshGroup = createAction((payload, meta) => {
  const { language, dataMode, groupId, showDeletedVariances } = payload

  return {
    type: t.REFRESH_GROUP,
    payload,
    meta: {
      ...meta,
      offline: {
        effect: {
          path: `admin/artificialtells/v2/item/${language}/${groupId}`,
          dataMode,
          showDeletedVariances,
        },
        commit: { type: t.REFRESH_GROUP_SUCCESS },
        rollback: { type: t.REFRESH_GROUP_ERROR },
      },
    },
  }
})

export const refreshVarianceAnalytics = createAction((payload) => {
  const { dataMode, varianceIds, language } = payload

  return {
    type: t.REFRESH_VARIANCE_ANALYTICS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/item/variance/analytics',
          varianceIds,
          dataMode,
          language,
        },
        commit: { type: t.REFRESH_VARIANCE_ANALYTICS_SUCCESS, meta: { payload } }, // prettier-ignore
        rollback: { type: t.REFRESH_VARIANCE_ANALYTICS_ERROR },
      },
    },
  }
})

export const refreshTopic = createAction((payload) => {
  const { language, dataMode, topicId } = payload

  return {
    type: t.REFRESH_TOPIC,
    payload,
    meta: {
      offline: {
        effect: {
          path: `admin/artificialtells/v2/topic/${topicId}`,
          lang: language,
          dataMode,
        },
        commit: { type: t.REFRESH_TOPIC_SUCCESS },
        rollback: { type: t.REFRESH_TOPIC_ERROR },
      },
    },
  }
})

export const refreshTopics = createAction((payload) => {
  const { language, dataMode } = payload

  return {
    type: t.REFRESH_TOPICS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/topics',
          lang: language,
          dataMode,
        },
        commit: { type: t.REFRESH_TOPICS_SUCCESS },
        rollback: { type: t.REFRESH_TOPICS_ERROR },
      },
    },
  }
})

export const refreshUnderperformingGroups = createAction((payload) => {
  const { language, dataMode } = payload

  return {
    type: t.REFRESH_UNDERPERFORMING_GROUPS,
    payload,
    meta: {
      offline: {
        effect: {
          path: 'admin/artificialtells/v2/items/underperforming',
          language,
          dataMode,
        },
        commit: { type: t.REFRESH_UNDERPERFORMING_GROUPS_SUCCESS },
        rollback: { type: t.REFRESH_UNDERPERFORMING_GROUPS_ERROR },
      },
    },
  }
})

export const setDataMode = (payload) => ({
  type: t.SET_DATA_MODE,
  payload,
})

export const setHeaderMode = (payload) => ({
  type: t.SET_HEADER_MODE,
  payload,
})

export const setLanguage = (payload) => ({
  type: t.SET_LANGUAGE,
  payload,
})

export const setShouldShowDeletedVariances = (payload) => ({
  type: t.SET_SHOULD_SHOW_DELETED_VARIANCES,
  payload,
})
