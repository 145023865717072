import { safeEffects } from '@tellonym/core/sagas'
import { Alert } from '../common'
import * as t from './types'

const onUploadCsvSuccess = function* (action) {
  if (action.meta.payload.preview !== true) {
    yield Alert.success('Success')
  }
}

export const actionHandler = {
  [t.UPLOAD_CSV_SUCCESS]: [safeEffects.takeLatest, onUploadCsvSuccess],
}
