import { yellow } from '@ant-design/colors'
import { colors } from '@tellonym/core/common/colorSystem'
import { Input, Select, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Box, Text, View } from '../../common'
import { useQueryParams } from '../../common/hooks'

const styles = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  input: { width: 180 },
  text: { marginRight: 12, width: 120 },
}

const defaultValidateValue = () => true

const TextInput = ({
  defaultQueryParams,
  isDisabled,
  isOptional,
  placeholder,
  queryParamName,
  submitTransformer,
  validateValue = defaultValidateValue,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)

  const [value, setValue] = useState(queryParams[queryParamName] ?? '')
  const [isValueSupported, setIsValueSupported] = useState(true)

  const onChangeText = (e) => {
    const { value } = e.target

    setValue(value)

    const isValueSupported = value !== '' && validateValue(value)

    setIsValueSupported(isValueSupported)

    if (typeof defaultQueryParams !== 'undefined') {
      const transformedValue = submitTransformer?.(value) ?? value

      setQueryParams({ [queryParamName]: transformedValue })
    }
  }

  return (
    <Tooltip
      color={yellow[6]}
      placement="right"
      title="unsupported value!"
      visible={value !== '' && !isValueSupported}>
      <Tooltip
        placement="right"
        title="required"
        visible={!isOptional && value === ''}>
        <Input
          disabled={isDisabled}
          onChange={onChangeText}
          placeholder={placeholder || (isOptional ? 'optional' : undefined)}
          value={value}
          style={styles.input}
        />
      </Tooltip>
    </Tooltip>
  )
}

const getDefaultValue = (hasMultipleValues, value) => {
  if (typeof value === 'undefined') {
    return []
  }

  if (hasMultipleValues) {
    return value.split?.(',') ?? value
  }

  return [value]
}

const SelectInput = ({
  defaultQueryParams,
  hasMultipleValues,
  hasSelectAll,
  isDisabled,
  isOptional,
  options,
  placeholder,
  queryParamName,
  submitTransformer,
  validateValue = defaultValidateValue,
}) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)

  const [value, setValue] = useState(
    getDefaultValue(hasMultipleValues, queryParams[queryParamName])
  )

  const [isValueSupported, setIsValueSupported] = useState(true)

  const onChange = (values) => {
    const externalValue = hasMultipleValues ? values : values[values.length - 1]
    const internalValue = hasMultipleValues
      ? values
      : values.slice(values.length - 1)

    setValue(internalValue)

    const isValueSupported =
      externalValue && externalValue !== '' && validateValue(externalValue)

    setIsValueSupported(isValueSupported)

    if (typeof defaultQueryParams !== 'undefined') {
      const transformedValue =
        submitTransformer?.(externalValue) ?? externalValue

      setQueryParams({ [queryParamName]: transformedValue })
    }
  }

  const onToggleSelectAll = () => {
    if (value.length === options.length) {
      onChange([])
    } else {
      const values = options.map(({ value }) => value)

      onChange(values)
    }
  }

  return (
    <Tooltip
      color={yellow[6]}
      placement="right"
      title="unsupported value!"
      visible={value.length > 0 && !isValueSupported}>
      <Tooltip
        placement="right"
        title="required"
        visible={!isOptional && value.length === 0}>
        <Box position="relative">
          <Select
            maxTagCount="responsive"
            mode="tags"
            disabled={isDisabled}
            onChange={onChange}
            placeholder={placeholder || (isOptional ? 'optional' : undefined)}
            value={value}
            options={options}
            style={styles.input}
          />
          {hasSelectAll && (
            <Text
              onPress={onToggleSelectAll}
              type="note"
              color={colors.blue[2]}
              style={{
                marginLeft: 8,
                position: 'absolute',
                right: -55,
                top: 6,
              }}>
              {value.length === options.length ? 'Unselect' : 'Select all'}
            </Text>
          )}
        </Box>
      </Tooltip>
    </Tooltip>
  )
}

export const InputQueryParam = ({ text, ...props }) => {
  const Component = Array.isArray(props.options) ? SelectInput : TextInput

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>

      <Component {...props} />
    </View>
  )
}
