import { LOG_LEVEL } from '@tellonym/core/store/middleware/createLogger'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import storage from 'redux-persist-indexeddb-storage'

const { version } = require('../package.json')

const chooseEnv = (obj, overrideEnv) => {
  switch (overrideEnv || process.env.NODE_ENV) {
    case 'production':
      return obj.production
    case 'staging':
      return obj.staging || obj.production
    case 'development':
      return obj.development
    case 'testing':
      return obj.testing || obj.development
    default:
      return obj.development
  }
}

const reduxLogger = {
  actionType: 'analytics/ADD_EVENT',
  logLevel: LOG_LEVEL.ALL,
  module: 'analytics',
  eventValidation: false,
}

export const config = {
  api: {
    client: `modcp:${version}`,
    errorBlacklist: [],
    homepage: 'https://tellonym.me',
    host: chooseEnv(
      {
        development: 'https://api.tnym.de',
        testing: 'https://api.tnym.de',
        staging: 'https://api-staging.tellonym.me',
        production: 'https://api.tellonym.me',
      },
      process.env.REACT_APP_ENV
    ),
    isWeb: true,
    limit: 24,
    retryCount: 3,
    userimg: {
      thumb: 'https://userimg.tellonym.me/thumb',
      xs: 'https://userimg.tellonym.me/xs',
      md: 'https://userimg.tellonym.me/md',
      lg: 'https://userimg.tellonym.me/lg',
      xs_v2: 'https://userimg.tellonym.me/xs-v2',
      md_v2: 'https://userimg.tellonym.me/md-v2',
      lg_v2: 'https://userimg.tellonym.me/lg-v2',
    },
  },
  app: {
    routes: [
      'Feed',
      'Search',
      'Tells',
      'Profile',
      'PeopleSuggestions',
      'ContactsSuggestions',
      'AuthTutorialReceiveTells',
      'AuthTutorialAnswers',
      'AuthTutorialWriteTells',
      'Answer',
      'Blocks',
      'EditProfile',
      'Email',
      'Followers',
      'Followings',
      'Likes',
      'LinkInstagram',
      'LinkSnapchat',
      'Main',
      'ModerationHint',
      'ModerationSettings',
      'ModerationTutorial',
      'Moderation',
      'MoreSettings',
      'Notifications',
      'Password',
      'PushNotificationsSettings',
      'ReportCase',
      'Result',
      'SafetyLevel',
      'Settings',
      'Suggestions',
      'TellonymContact',
      'Wordfilter',
      'Write',
      'AuthAgeRestrictionLock',
      'AuthEmail',
      'AuthFindContacts',
      'AuthLogin',
      'AuthLoginMethodsPicker',
      'AuthLoginMethodsProfiles',
      'AuthLoginMethodsResetPassword',
      'AuthLoginMethodsUsername',
      'AuthOver17',
      'AuthPassword',
      'AuthProfilePic',
      'AuthRegistrationMethods',
      'AuthRoot',
      'AuthUsername',
      'OAuth',
      'App',
      'Auth',
      'Splash',
    ],
  },
  components: {
    ArtTellTags: {
      language: langDetectObjectsByType1.de,
    },
    ReadMore: {
      lineBreakWidth: 30,
      maxLength: 280,
    },
  },
  CountryPhoneNumber: {
    defaultCountryCode: 'US',
  },
  DeviceInfo: {
    deviceName: `${navigator.userAgent}`.substring(0, 100),
    deviceType: 'other',
  },
  hCaptcha: {
    siteKeys: {
      login: '8623e8b4-d93c-40b4-9fb8-6ed629540b02',
      register: '3e48b1d1-44bf-4bc4-a597-e76af6f3a260',
      sendTell: '494aec4c-f02d-462c-9a37-606425aa6769',
    },
  },
  reduxLogger,
  Sentry: {
    config: {
      deactivateStacktraceMerging: true,
      debug: false,
      environment: process.env.NODE_ENV,
      logLevel: 1,
      version,
      autoBreadcrumbs: {
        xhr: false,
        console: false,
        dom: false,
        location: false,
        sentry: false,
      },
    },
    url: 'https://66f284fff9f146fcb7e4de6ac8ba91a7@o74781.ingest.sentry.io/1286218',
  },
  store: {
    offline: {
      persistOptions: {
        key: 'reduxPersist',
        storage: storage('TNYMModCP'),
      },
    },
    version: '0.15.0',
  },
  version,
  webUrl: chooseEnv(
    {
      development: 'http://testonym.de',
      production: 'https://tellonym.me',
      staging: 'https://staging.tellonym.me',
      testing: 'https://tnym.de',
    },
    process.env.REACT_APP_ENV
  ),
}
