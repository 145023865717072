import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FlatList } from '../../common'
import { TellItem } from '../../moderator/components/TellItem'
import { fetchPosts as fetch, refreshPosts as refresh } from '../actions'
import {
  getHasMorePosts,
  getIsFetchingPosts,
  getIsRefreshingPosts,
  getPosts,
  getRerenderPostItem,
} from '../selectors'

class _PostsList extends React.PureComponent {
  render() {
    const {
      actions,
      _rerenderItem,
      hasMore,
      isFetching,
      isRefreshing,
      spamItemId,
      posts,
    } = this.props
    if (!posts || !posts.ids || !posts.ids.length) {
      return null
    }

    return (
      <FlatList
        _rerenderItem={_rerenderItem}
        actions={{
          fetch: (payload) => actions.fetch({ ...payload, spamItemId }),
          refresh: (payload) => actions.refresh({ ...payload, spamItemId }),
        }}
        component={TellItem}
        extraProps={{ spamItemId }}
        hasMore={hasMore}
        isFetching={isFetching}
        isRefreshing={isRefreshing}
        items={posts}
        style={{ maxHeight: 720 }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetch, refresh }, dispatch),
})

const mapStateToProps = (state, props) => ({
  _rerenderItem: getRerenderPostItem(state),
  hasMore: getHasMorePosts(state),
  isFetching: getIsFetchingPosts(state),
  isRefreshing: getIsRefreshingPosts(state),
  posts: getPosts(state, props.spamItemId),
})

const PostsList = connect(mapStateToProps, mapDispatchToProps)(_PostsList)

export { PostsList }
