export const CREATE_GROUP = 'artificialTellsV2/CREATE_GROUP'
export const CREATE_GROUP_ERROR = 'artificialTellsV2/CREATE_GROUP_ERROR'
export const CREATE_GROUP_SUCCESS = 'artificialTellsV2/CREATE_GROUP_SUCCESS' // prettier-ignore

export const CREATE_TODO = 'artificialTellsV2/CREATE_TODO'
export const CREATE_TODO_ERROR = 'artificialTellsV2/CREATE_TODO_ERROR'
export const CREATE_TODO_SUCCESS = 'artificialTellsV2/CREATE_TODO_SUCCESS'

export const REFRESH_TODOS = 'artificialTellsV2/REFRESH_TODOS'
export const REFRESH_TODOS_ERROR = 'artificialTellsV2/REFRESH_TODOS_ERROR'
export const REFRESH_TODOS_SUCCESS = 'artificialTellsV2/REFRESH_TODOS_SUCCESS'

export const COMPLETE_TODO = 'artificialTellsV2/COMPLETE_TODO'
export const COMPLETE_TODO_ERROR = 'artificialTellsV2/COMPLETE_TODO_ERROR'
export const COMPLETE_TODO_SUCCESS = 'artificialTellsV2/COMPLETE_TODO_SUCCESS'

export const CREATE_TOPIC = 'artificialTellsV2/CREATE_TOPIC'
export const CREATE_TOPIC_ERROR = 'artificialTellsV2/CREATE_TOPIC_ERROR'
export const CREATE_TOPIC_SUCCESS = 'artificialTellsV2/CREATE_TOPIC_SUCCESS'

export const CREATE_VARIANCE = 'artificialTellsV2/CREATE_VARIANCE'
export const CREATE_VARIANCE_ERROR = 'artificialTellsV2/CREATE_VARIANCE_ERROR'
export const CREATE_VARIANCE_SUCCESS = 'artificialTellsV2/CREATE_VARIANCE_SUCCESS' // prettier-ignore

export const EDIT_GROUP = 'artificialTellsV2/EDIT_GROUP'
export const EDIT_GROUP_ERROR = 'artificialTellsV2/EDIT_GROUP_ERROR'
export const EDIT_GROUP_SUCCESS = 'artificialTellsV2/EDIT_GROUP_SUCCESS'

export const EDIT_TOPIC = 'artificialTellsV2/EDIT_TOPIC'
export const EDIT_TOPIC_ERROR = 'artificialTellsV2/EDIT_TOPIC_ERROR'
export const EDIT_TOPIC_SUCCESS = 'artificialTellsV2/EDIT_TOPIC_SUCCESS'

export const EDIT_VARIANCE = 'artificialTellsV2/EDIT_VARIANCE'
export const EDIT_VARIANCE_ERROR = 'artificialTellsV2/EDIT_VARIANCE_ERROR'
export const EDIT_VARIANCE_SUCCESS = 'artificialTellsV2/EDIT_VARIANCE_SUCCESS'

export const REFRESH_GROUP = 'artificialTellsV2/REFRESH_GROUP'
export const REFRESH_GROUP_ERROR = 'artificialTellsV2/REFRESH_GROUP_ERROR'
export const REFRESH_GROUP_SUCCESS = 'artificialTellsV2/REFRESH_GROUP_SUCCESS'

export const FETCH_GROUP_ANSWERS = 'artificialTellsV2/FETCH_GROUP_ANSWERS' // prettier-ignore
export const FETCH_GROUP_ANSWERS_ERROR = 'artificialTellsV2/FETCH_GROUP_ANSWERS_ERROR' // prettier-ignore
export const FETCH_GROUP_ANSWERS_SUCCESS = 'artificialTellsV2/FETCH_GROUP_ANSWERS_SUCCESS' // prettier-ignore

export const REFRESH_TOPIC = 'artificialTellsV2/REFRESH_TOPIC'
export const REFRESH_TOPIC_ERROR = 'artificialTellsV2/REFRESH_TOPIC_ERROR'
export const REFRESH_TOPIC_SUCCESS = 'artificialTellsV2/REFRESH_TOPIC_SUCCESS'

export const REFRESH_TOPICS = 'artificialTellsV2/REFRESH_TOPICS'
export const REFRESH_TOPICS_ERROR = 'artificialTellsV2/REFRESH_TOPICS_ERROR'
export const REFRESH_TOPICS_SUCCESS = 'artificialTellsV2/REFRESH_TOPICS_SUCCESS'

export const REFRESH_UNDERPERFORMING_GROUPS = 'artificialTellsV2/REFRESH_UNDERPERFORMING_GROUPS' // prettier-ignore
export const REFRESH_UNDERPERFORMING_GROUPS_ERROR = 'artificialTellsV2/REFRESH_UNDERPERFORMING_GROUPS_ERROR' // prettier-ignore
export const REFRESH_UNDERPERFORMING_GROUPS_SUCCESS = 'artificialTellsV2/REFRESH_UNDERPERFORMING_GROUPS_SUCCESS' // prettier-ignore

export const REFRESH_VARIANCE_ANALYTICS = 'artificialTellsV2/REFRESH_VARIANCE_ANALYTICS' // prettier-ignore
export const REFRESH_VARIANCE_ANALYTICS_ERROR = 'artificialTellsV2/REFRESH_VARIANCE_ANALYTICS_ERROR' // prettier-ignore
export const REFRESH_VARIANCE_ANALYTICS_SUCCESS = 'artificialTellsV2/REFRESH_VARIANCE_ANALYTICS_SUCCESS' // prettier-ignore

export const FETCH_VARIANCE_ANSWERS = 'artificialTellsV2/FETCH_VARIANCE_ANSWERS' // prettier-ignore
export const FETCH_VARIANCE_ANSWERS_ERROR = 'artificialTellsV2/FETCH_VARIANCE_ANSWERS_ERROR' // prettier-ignore
export const FETCH_VARIANCE_ANSWERS_SUCCESS = 'artificialTellsV2/FETCH_VARIANCE_ANSWERS_SUCCESS' // prettier-ignore

export const SET_DATA_MODE = 'artificialTellsV2/SET_DATA_MODE'

export const SET_HEADER_MODE = 'artificialTellsV2/SET_HEADER_MODE'

export const SET_LANGUAGE = 'artificialTellsV2/SET_LANGUAGE'

export const SET_SHOULD_SHOW_DELETED_VARIANCES = 'artificialTellsV2/SET_SHOULD_SHOW_DELETED_VARIANCES' // prettier-ignore
