import { isNil } from 'ramda'
import React, { useEffect } from 'react'
import * as Redux from 'react-redux'
import { FlatList, history, Text, theme, View } from '../../common'
import { useBanCandidatesQuery } from '../queries'
import { get_rerenderItem } from '../selectors'
import { BanItem } from './BanItem'

const Tabs = {
  ALL: 'all',
  ONLY_HIDDEN: 'onlyhidden',
}

const styles = {
  tableHeadContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.borderLightGrey,
    paddingVertical: 8,
  },
}

const TabChooser = ({ onPressTab, tab }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      paddingVertical: 12,
      maxWidth: 600,
      alignSelf: 'center',
      width: '100%',
    }}>
    <Text
      center
      color={tab !== Tabs.ALL ? theme.colors.placeholder : undefined}
      onPress={() => onPressTab(Tabs.ALL)}
      style={{ flex: 1, onHover: { color: theme.colors.primary } }}>
      Candidates
    </Text>
    <Text
      center
      color={tab !== Tabs.ONLY_HIDDEN ? theme.colors.placeholder : undefined}
      onPress={() => onPressTab(Tabs.ONLY_HIDDEN)}
      style={{ flex: 1, onHover: { color: theme.colors.primary } }}>
      Hidden Candidates
    </Text>
  </View>
)

const TableHead = ({ tab }) => (
  <View style={styles.tableHeadContainer}>
    <Text center color={theme.colors.placeholder} style={{ flex: 1 }}>
      {tab === Tabs.ONLY_HIDDEN ? 'Unhide' : 'Hide'}
    </Text>
    <Text center color={theme.colors.placeholder} style={{ flex: 1 }}>
      Count
    </Text>
    <Text color={theme.colors.placeholder} style={{ flex: 6, marginLeft: 16 }}>
      User
    </Text>
  </View>
)

const BanCandidatesHeader = ({ onPressTab, tab }) => (
  <React.Fragment>
    <TabChooser tab={tab} onPressTab={onPressTab} />
    <TableHead tab={tab} />
  </React.Fragment>
)

export const PageBanCandidates = ({ match }) => {
  const { tab } = match.params

  const _rerenderItem = Redux.useSelector(get_rerenderItem)

  const { data, isFetching = false } = useBanCandidatesQuery(
    tab === Tabs.ONLY_HIDDEN
  )

  useEffect(() => {
    if (isNil(tab)) {
      history.push('/ban/candidates/all')
    }
  }, [tab])

  return (
    <FlatList
      _rerenderItem={_rerenderItem}
      component={BanItem}
      extraData={{
        hasMore: false,
        isFetching,
        isRefreshing: isFetching,
      }}
      extraProps={{
        isHiddenCandidates: tab === Tabs.ONLY_HIDDEN,
      }}
      hasMore={false}
      isRefreshing={isFetching}
      items={data}
      renderHeader={() => (
        <BanCandidatesHeader
          onPressTab={(tab) => history.push(`/ban/candidates/${tab}`)}
          tab={tab}
        />
      )}
    />
  )
}
