import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { PUSH_NOTIFICATION_TYPE } from '@tellonym/enums/lib/Notification'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Alert,
  Button,
  Dropdown,
  helpers,
  Icon,
  Input,
  Modal,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { sendPushNotification } from '../actions'

const modalId = 'SendPushNotification'

const hide = (payload) => Modal.hide({ id: modalId, extraData: payload })

const isVisible = () => Modal.isVisible({ id: modalId })

const show = (payload) =>
  Modal.show({
    hasBackdrop: true,
    id: modalId,
    render: (props) => <SendPushNotification {...payload} {...props} />,
    shouldHideOnBackgroundPress: true,
  })

const ShowCustomParamsInput = ({ onPress }) => (
  <View style={{ alignItems: 'center', marginBottom: 24 }}>
    <Text onPress={onPress} color={theme.colors.primary}>
      Show custom param input
    </Text>
  </View>
)

const CustomParamsInput = ({ onChange, value }) => (
  <Input
    grey
    label="Custom Params"
    multiline
    name="customParams"
    onChangeText={onChange}
    value={value}
  />
)

const ParamsInputs = ({ config, onChangeParam, params }) => {
  if (!config) {
    return null
  }

  return (
    <View>
      {config.map((param, index) => (
        <Input
          grey
          // eslint-disable-next-line react/no-array-index-key
          key={param.length * (index + 1)}
          label={param}
          name={param}
          onChangeText={(str) => onChangeParam(param, str)}
          value={params[param] || ''}
        />
      ))}
    </View>
  )
}

const _SendPushNotification = ({ actions, pushNotificationToken = '' }) => {
  const { config, types } =
    helpers.getAvailablePushNotificationStringsWithConfig()

  const [type, setType] = useState(PUSH_NOTIFICATION_TYPE.GOT_TELL)
  const [params, setParams] = useState({ pushNotificationToken })
  const [customParams, setCustomParams] = useState('{\n}')
  const [isVisibleCustomParamsInput, setIsVisibleCustomParamsInput] =
    useState(false)

  const validateParams = (params) =>
    Object.keys(params).reduce((isValid, paramKey) => {
      const param = params[paramKey]

      if (param === '') {
        return false
      }

      return isValid
    }, true)

  const submit = () => {
    try {
      const areParamsValid = validateParams(params)

      const parsedCustomParams = JSON.parse(customParams)
      const areCustomParamsValid = validateParams(parsedCustomParams)

      if (areParamsValid && areCustomParamsValid) {
        actions.sendPushNotification({
          pushNotificationType: type,
          ...params,
          ...parsedCustomParams,
        })
        hide()
        Alert.success('Push Notification has been sent')
      } else {
        Alert.error('Params missing to send Push Notification')
      }
    } catch (err) {
      Alert.error('Custom Params are not valid')
    }
  }

  const onChangeType = ({ item }) => {
    const type = PUSH_NOTIFICATION_TYPE[item]
    const _config = config[type]
    const tokenParam = { pushNotificationToken: params.pushNotificationToken }

    const newParams = !!_config
      ? _config.reduce((params, param) => {
          return {
            ...params,
            [param]: '',
          }
        }, tokenParam)
      : tokenParam

    setType(type)
    setParams(newParams)
  }

  const onChangeParam = (param, value) =>
    setParams({
      ...params,
      [param]: value,
    })

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Modal.Body
        style={{
          backgroundColor: theme.colors.background,
          borderRadius: 25,
          padding: 24,
          minWidth: 600,
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 24,
          }}>
          <Text bold center type="h1">
            Send Push Notification
          </Text>
          <TouchableOpacity
            onPress={hide}
            style={{
              justifyContent: 'center',
              borderRadius: 12,
              paddingHorizontal: 12,
            }}>
            <Icon icon={faTimes} color={theme.colors.placeholder} />
          </TouchableOpacity>
        </View>
        <Dropdown
          label="Push Notification Type"
          name="type"
          options={types}
          onChange={onChangeType}
          initialActiveIndex={0}
          shouldSetInitialValue
          containerStyle={{ marginBottom: 16 }}
        />
        <Input
          grey
          label="Push Notification Token"
          name="pushNotificationToken"
          onChangeText={(str) => onChangeParam('pushNotificationToken', str)}
          value={params.pushNotificationToken || ''}
        />
        <ParamsInputs
          config={config[type]}
          onChangeParam={onChangeParam}
          params={params}
        />
        {isVisibleCustomParamsInput ? (
          <CustomParamsInput onChange={setCustomParams} value={customParams} />
        ) : (
          <ShowCustomParamsInput onPress={setIsVisibleCustomParamsInput} />
        )}
        <Button onPress={submit}>
          <Text color={theme.colors.white} type="h4">
            Send
          </Text>
        </Button>
      </Modal.Body>
    </View>
  )
}

const SendPushNotification = connect(null, (dispatch) => ({
  actions: bindActionCreators({ sendPushNotification }, dispatch),
}))(_SendPushNotification)

export const ModalSendPushNotification = { show, hide, isVisible }
