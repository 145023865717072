import { shortenNumber } from '@tellonym/core/helpers'
import { ARTIFICIAL_TELL_USER_GROUP_TYPE } from '@tellonym/enums/lib/Tell'
import { Segmented, Spin, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box } from '../../common'
import { valueToPercentage } from '../../statistics/services'
import { setDataMode } from '../actionsV2'
import { getDataMode } from '../selectorsV2'

const MedianDataOverview = ({ title, texts, values, ...props }) => {
  return (
    <Box {...props}>
      {typeof title === 'string' && (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
      )}
      <table>
        <tbody>
          {texts.map((text, index) => {
            const sign = values[index]?.change > 0 ? '+' : ''

            const amountValue =
              values[index]?.amount ??
              values[index]?.percentage ??
              values[index]

            const isPercentage = text.includes('Rate')

            const amount = isPercentage
              ? valueToPercentage(amountValue, 1).replace('.0', '')
              : shortenNumber(amountValue)

            const changeValue = values[index]?.change

            const change =
              typeof changeValue !== 'undefined'
                ? `(${sign}${
                    isPercentage
                      ? valueToPercentage(changeValue, 1).replace('.0', '')
                      : shortenNumber(changeValue)
                  })`
                : ''

            return (
              <tr key={text}>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingRight: 6,
                  }}>
                  <Typography.Text>{text}</Typography.Text>
                </td>
                <td>
                  <Typography.Text>{`${amount} ${change} `}</Typography.Text>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Box>
  )
}

export const MedianDataForDataMode = ({
  backgroundColor,
  hasTitle = true,
  isLoading = false,
  onChange,
  data,
  ...props
}) => {
  const dispatch = ReactRedux.useDispatch()

  const dataModeNumber = ReactRedux.useSelector(getDataMode)

  const onChangeDataMode = React.useCallback(
    (value) => {
      dispatch(setDataMode(ARTIFICIAL_TELL_USER_GROUP_TYPE[value]))
      onChange(value)
    },
    [dispatch, onChange]
  )

  return (
    <Box alignItems="center" backgroundColor={backgroundColor} {...props}>
      <Box
        alignItems="center"
        backgroundColor={backgroundColor}
        flexDirection="row"
        marginBottom={12}>
        {hasTitle && (
          <Typography.Title
            level={5}
            style={{ marginBottom: 0, marginRight: 24 }}>
            Data MODE:
          </Typography.Title>
        )}

        <Segmented
          value={ARTIFICIAL_TELL_USER_GROUP_TYPE[dataModeNumber]}
          options={[
            ARTIFICIAL_TELL_USER_GROUP_TYPE[2],
            ARTIFICIAL_TELL_USER_GROUP_TYPE[0],
          ]}
          onChange={onChangeDataMode}
          size="small"
        />
      </Box>

      <Spin spinning={isLoading}>
        <Box backgroundColor={backgroundColor} flexDirection="row">
          {data.map((item, index) => (
            <MedianDataOverview
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              backgroundColor={backgroundColor}
              marginRight={index < data.length - 1 ? 24 : 0}
              title={item.title}
              texts={item.texts}
              values={item.values}
            />
          ))}
        </Box>
      </Spin>
    </Box>
  )
}
