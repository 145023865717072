import { assocPath, compose, mergeDeepRight, __ } from 'ramda'

const setNewAccountState = (newAccountState, state) =>
  compose(
    assocPath(['__app__', 'accounts'], __, state),
    mergeDeepRight(state.__app__.accounts)
  )(newAccountState)

export const setAccountForExistingUsers = (newKeyValues) => (state) => {
  const accountIds = Object.keys(state.__app__.accounts).filter((key) =>
    Number.isInteger(parseInt(key, 10))
  )

  const accountObject = accountIds.reduce(
    (acc, id) => ({ ...acc, [id]: newKeyValues }),
    {}
  )

  return setNewAccountState(accountObject, state)
}

export const migrations = {
  '0.15.0': (state) => {
    /**
     * permissions
     */
    return setNewAccountState({ permissions: [] }, state)
  },
}
