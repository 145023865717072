export const name = 'moderator'

export const ADD_BLACKLIST_ENTRY = `${name}/ADD_BLACKLIST_ENTRY`
export const ADD_BLACKLIST_ENTRY_ERROR = `${name}/ADD_BLACKLIST_ENTRY_ERROR`
export const ADD_BLACKLIST_ENTRY_SUCCESS = `${name}/ADD_BLACKLIST_ENTRY_SUCCESS`

export const CHANGE_SETTINGS = `${name}/CHANGE_SETTINGS`
export const CHANGE_SETTINGS_ERROR = `${name}/CHANGE_SETTINGS_ERROR`
export const CHANGE_SETTINGS_SUCCESS = `${name}/CHANGE_SETTINGS_SUCCESS`

export const CLEAR_SELECTION = `${name}/CLEAR_SELECTION`

export const COPY_DEVICES = `${name}/COPY_DEVICES`

export const COPY_PROFILE = `${name}/COPY_PROFILE`

export const COPY_ITEMS = `${name}/COPY_ITEMS`

export const CREATE_BAN = `${name}/CREATE_BAN`
export const CREATE_BAN_ERROR = `${name}/CREATE_BAN_ERROR`
export const CREATE_BAN_SUCCESS = `${name}/CREATE_BAN_SUCCESS`

export const CREATE_WARNING = `${name}/CREATE_WARNING`
export const CREATE_WARNING_ERROR = `${name}/CREATE_WARNING_ERROR`
export const CREATE_WARNING_SUCCESS = `${name}/CREATE_WARNING_SUCCESS`

export const BLOCK_ALL_SENT_TELLS = `${name}/BLOCK_ALL_SENT_TELLS`
export const BLOCK_ALL_SENT_TELLS_ERROR = `${name}/BLOCK_ALL_SENT_TELLS_ERROR`
export const BLOCK_ALL_SENT_TELLS_SUCCESS = `${name}/BLOCK_ALL_SENT_TELLS_SUCCESS`

export const BLOCK_ANSWER = `${name}/BLOCK_ANSWER`
export const BLOCK_ANSWER_ERROR = `${name}/BLOCK_ANSWER_ERROR`
export const BLOCK_ANSWER_SUCCESS = `${name}/BLOCK_ANSWER_SUCCESS`

export const BLOCK_POST = `${name}/BLOCK_POST`
export const BLOCK_POST_ERROR = `${name}/BLOCK_POST_ERROR`
export const BLOCK_POST_SUCCESS = `${name}/BLOCK_POST_SUCCESS`

export const BLOCK_TELL = `${name}/BLOCK_TELL`
export const BLOCK_TELL_ERROR = `${name}/BLOCK_TELL_ERROR`
export const BLOCK_TELL_SUCCESS = `${name}/BLOCK_TELL_SUCCESS`

export const DELETE_PROFILE_PIC = `${name}/DELETE_PROFILE_PIC`
export const DELETE_PROFILE_PIC_ERROR = `${name}/DELETE_PROFILE_PIC_ERROR`
export const DELETE_PROFILE_PIC_SUCCESS = `${name}/DELETE_PROFILE_PIC_SUCCESS`

export const DELETE_PUBLIC_LINK = `${name}/DELETE_PUBLIC_LINK`
export const DELETE_PUBLIC_LINK_ERROR = `${name}/DELETE_PUBLIC_LINK_ERROR`
export const DELETE_PUBLIC_LINK_SUCCESS = `${name}/DELETE_PUBLIC_LINK_SUCCESS`

export const DELETE_USER = `${name}/DELETE_USER`
export const DELETE_USER_ERROR = `${name}/DELETE_USER_ERROR`
export const DELETE_USER_SUCCESS = `${name}/DELETE_USER_SUCCESS`

export const REFRESH_ACCOUNT_VERIFICATIONS = `${name}/REFRESH_ACCOUNT_VERIFICATIONS`
export const REFRESH_ACCOUNT_VERIFICATIONS_ERROR = `${name}/REFRESH_ACCOUNT_VERIFICATIONS_ERROR`
export const REFRESH_ACCOUNT_VERIFICATIONS_SUCCESS = `${name}/REFRESH_ACCOUNT_VERIFICATIONS_SUCCESS`

export const UPDATE_ACCOUNT_VERIFICATIONS = `${name}/UPDATE_ACCOUNT_VERIFICATIONS`
export const UPDATE_ACCOUNT_VERIFICATIONS_ERROR = `${name}/UPDATE_ACCOUNT_VERIFICATIONS_ERROR`
export const UPDATE_ACCOUNT_VERIFICATIONS_SUCCESS = `${name}/UPDATE_ACCOUNT_VERIFICATIONS_SUCCESS`

export const REFRESH_CHAT_MESSAGES = `${name}/REFRESH_CHAT_MESSAGES`
export const REFRESH_CHAT_MESSAGES_ERROR = `${name}/REFRESH_CHAT_MESSAGES_ERROR`
export const REFRESH_CHAT_MESSAGES_SUCCESS = `${name}/REFRESH_CHAT_MESSAGES_SUCCESS`

export const HIDE_BAN_CANDIDATE = `${name}/HIDE_BAN_CANDIDATE`
export const HIDE_BAN_CANDIDATE_ERROR = `${name}/HIDE_BAN_CANDIDATE_ERROR`
export const HIDE_BAN_CANDIDATE_SUCCESS = `${name}/HIDE_BAN_CANDIDATE_SUCCESS`

export const LOGOUT_ALL_DEVICES = `${name}/LOGOUT_ALL_DEVICES`
export const LOGOUT_ALL_DEVICES_ERROR = `${name}/LOGOUT_ALL_DEVICES_ERROR`
export const LOGOUT_ALL_DEVICES_SUCCESS = `${name}/LOGOUT_ALL_DEVICES_SUCCESS`

export const LOGOUT_DEVICE = `${name}/LOGOUT_DEVICE`
export const LOGOUT_DEVICE_ERROR = `${name}/LOGOUT_DEVICE_ERROR`
export const LOGOUT_DEVICE_SUCCESS = `${name}/LOGOUT_DEVICE_SUCCESS`

export const FETCH_PROFILE_DATA_IN_MODERATION = `${name}/FETCH_PROFILE_DATA_IN_MODERATION`
export const FETCH_PROFILE_DATA_IN_MODERATION_ERROR = `${name}/FETCH_PROFILE_DATA_IN_MODERATION_ERROR`
export const FETCH_PROFILE_DATA_IN_MODERATION_SUCCESS = `${name}/FETCH_PROFILE_DATA_IN_MODERATION_SUCCESS`

export const REFRESH_ANSWERS = `${name}/REFRESH_ANSWERS`
export const REFRESH_ANSWERS_ERROR = `${name}/REFRESH_ANSWERS_ERROR`
export const REFRESH_ANSWERS_SUCCESS = `${name}/REFRESH_ANSWERS_SUCCESS`
export const FETCH_ANSWERS = `${name}/FETCH_ANSWERS`
export const FETCH_ANSWERS_ERROR = `${name}/FETCH_ANSWERS_ERROR`
export const FETCH_ANSWERS_SUCCESS = `${name}/FETCH_ANSWERS_SUCCESS`

export const REFRESH_BAN_CANDIDATES = `${name}/REFRESH_BAN_CANDIDATES`
export const REFRESH_BAN_CANDIDATES_ERROR = `${name}/REFRESH_BAN_CANDIDATES_ERROR`
export const REFRESH_BAN_CANDIDATES_SUCCESS = `${name}/REFRESH_BAN_CANDIDATES_SUCCESS`
export const FETCH_BAN_CANDIDATES = `${name}/FETCH_BAN_CANDIDATES`
export const FETCH_BAN_CANDIDATES_ERROR = `${name}/FETCH_BAN_CANDIDATES_ERROR`
export const FETCH_BAN_CANDIDATES_SUCCESS = `${name}/FETCH_BAN_CANDIDATES_SUCCESS`

export const REFRESH_BANNED_DEVICES = `${name}/REFRESH_BANNED_DEVICES`
export const REFRESH_BANNED_DEVICES_ERROR = `${name}/REFRESH_BANNED_DEVICES_ERROR`
export const REFRESH_BANNED_DEVICES_SUCCESS = `${name}/REFRESH_BANNED_DEVICES_SUCCESS`
export const FETCH_BANNED_DEVICES = `${name}/FETCH_BANNED_DEVICES`
export const FETCH_BANNED_DEVICES_ERROR = `${name}/FETCH_BANNED_DEVICES_ERROR`
export const FETCH_BANNED_DEVICES_SUCCESS = `${name}/FETCH_BANNED_DEVICES_SUCCESS`

export const DELETE_DEVICE_BAN = `${name}/DELETE_DEVICE_BAN`
export const DELETE_DEVICE_BAN_ERROR = `${name}/DELETE_DEVICE_BAN_ERROR`
export const DELETE_DEVICE_BAN_SUCCESS = `${name}/DELETE_DEVICE_BAN_SUCCESS`

export const REFRESH_DEVICES = `${name}/REFRESH_DEVICES`
export const REFRESH_DEVICES_ERROR = `${name}/REFRESH_DEVICES_ERROR`
export const REFRESH_DEVICES_SUCCESS = `${name}/REFRESH_DEVICES_SUCCESS`
export const FETCH_DEVICES = `${name}/FETCH_DEVICES`
export const FETCH_DEVICES_ERROR = `${name}/FETCH_DEVICES_ERROR`
export const FETCH_DEVICES_SUCCESS = `${name}/FETCH_DEVICES_SUCCESS`

export const REFRESH_NSFW_PICTURES = `${name}/REFRESH_NSFW_PICTURES`
export const REFRESH_NSFW_PICTURES_ERROR = `${name}/REFRESH_NSFW_PICTURES_ERROR`
export const REFRESH_NSFW_PICTURES_SUCCESS = `${name}/REFRESH_NSFW_PICTURES_SUCCESS`
export const FETCH_NSFW_PICTURES = `${name}/FETCH_NSFW_PICTURES`
export const FETCH_NSFW_PICTURES_ERROR = `${name}/FETCH_PICTURES_ERROR`
export const FETCH_NSFW_PICTURES_SUCCESS = `${name}/FETCH_PICTURES_SUCCESS`

export const REFRESH_PROFILE = `${name}/REFRESH_PROFILE`
export const REFRESH_PROFILE_ERROR = `${name}/REFRESH_PROFILE_ERROR`
export const REFRESH_PROFILE_SUCCESS = `${name}/REFRESH_PROFILE_SUCCESS`
export const FETCH_PROFILE = `${name}/FETCH_PROFILE`
export const FETCH_PROFILE_ERROR = `${name}/FETCH_PROFILE_ERROR`
export const FETCH_PROFILE_SUCCESS = `${name}/FETCH_PROFILE_SUCCESS`

export const REFRESH_TELLS = `${name}/REFRESH_TELLS`
export const REFRESH_TELLS_ERROR = `${name}/REFRESH_TELLS_ERROR`
export const REFRESH_TELLS_SUCCESS = `${name}/REFRESH_TELLS_SUCCESS`
export const FETCH_TELLS = `${name}/FETCH_TELLS`
export const FETCH_TELLS_ERROR = `${name}/FETCH_TELLS_ERROR`
export const FETCH_TELLS_SUCCESS = `${name}/FETCH_TELLS_SUCCESS`

export const REFRESH_SENT_TELLS = `${name}/REFRESH_SENT_TELLS`
export const REFRESH_SENT_TELLS_ERROR = `${name}/REFRESH_SENT_TELLS_ERROR`
export const REFRESH_SENT_TELLS_SUCCESS = `${name}/REFRESH_SENT_TELLS_SUCCESS`
export const FETCH_SENT_TELLS = `${name}/FETCH_SENT_TELLS`
export const FETCH_SENT_TELLS_ERROR = `${name}/FETCH_SENT_TELLS_ERROR`
export const FETCH_SENT_TELLS_SUCCESS = `${name}/FETCH_SENT_TELLS_SUCCESS`

export const REFRESH_USER_LOG = `${name}/REFRESH_USER_LOG`
export const REFRESH_USER_LOG_ERROR = `${name}/REFRESH_USER_LOG_ERROR`
export const REFRESH_USER_LOG_SUCCESS = `${name}/REFRESH_USER_LOG_SUCCESS`
export const FETCH_USER_LOG = `${name}/FETCH_USER_LOG`
export const FETCH_USER_LOG_ERROR = `${name}/FETCH_USER_LOG_ERROR`
export const FETCH_USER_LOG_SUCCESS = `${name}/FETCH_USER_LOG_SUCCESS`

export const REFRESH_IAP = `${name}/REFRESH_IAP`
export const REFRESH_IAP_ERROR = `${name}/REFRESH_IAP_ERROR`
export const REFRESH_IAP_SUCCESS = `${name}/REFRESH_IAP_SUCCESS`
export const FETCH_IAP = `${name}/FETCH_IAP`
export const FETCH_IAP_ERROR = `${name}/FETCH_IAP_ERROR`
export const FETCH_IAP_SUCCESS = `${name}/FETCH_IAP_SUCCESS`

export const RECOUNT = `${name}/RECOUNT`
export const RECOUNT_ERROR = `${name}/RECOUNT_ERROR`
export const RECOUNT_SUCCESS = `${name}/RECOUNT_SUCCESS`

export const RESTORE_ANSWER = `${name}/RESTORE_ANSWER`
export const RESTORE_ANSWER_ERROR = `${name}/RESTORE_ANSWER_ERROR`
export const RESTORE_ANSWER_SUCCESS = `${name}/RESTORE_ANSWER_SUCCESS`

export const RESTORE_IAP = `${name}/RESTORE_IAP`
export const RESTORE_IAP_ERROR = `${name}/RESTORE_IAP_ERROR`
export const RESTORE_IAP_SUCCESS = `${name}/RESTORE_IAP_SUCCESS`

export const RESTORE_TELL = `${name}/RESTORE_TELL`
export const RESTORE_TELL_ERROR = `${name}/RESTORE_TELL_ERROR`
export const RESTORE_TELL_SUCCESS = `${name}/RESTORE_TELL_SUCCESS`

export const SEARCH = `${name}/SEARCH`
export const SEARCH_ERROR = `${name}/SEARCH_ERROR`
export const SEARCH_SUCCESS = `${name}/SEARCH_SUCCESS`

export const SELECT_ALL = `${name}/SELECT_ALL`

export const SEND_MODERATION_INVITATION = `${name}/SEND_MODERATION_INVITATION`
export const SEND_MODERATION_INVITATION_ERROR = `${name}/SEND_MODERATION_INVITATION_ERROR`
export const SEND_MODERATION_INVITATION_SUCCESS = `${name}/SEND_MODERATION_INVITATION_SUCCESS`

export const SEND_PUSH_NOTIFICATITON = `${name}/SEND_PUSH_NOTIFICATITON`

export const SEND_RECOVERY_MAIL = `${name}/SEND_RECOVERY_MAIL`
export const SEND_RECOVERY_MAIL_ERROR = `${name}/SEND_RECOVERY_MAIL_ERROR`
export const SEND_RECOVERY_MAIL_SUCCESS = `${name}/SEND_RECOVERY_MAIL_SUCCESS`

export const SEND_TELL = `${name}/SEND_TELL`
export const SEND_TELL_ERROR = `${name}/SEND_TELL_ERROR`
export const SEND_TELL_SUCCESS = `${name}/SEND_TELL_SUCCESS`

export const SET_NSFW_PICTURE_STATUS = `${name}/SET_NSFW_PICTURE_STATUS`

export const SET_SELECTION = `${name}/SET_SELECTION`

export const UNHIDE_BAN_CANDIDATE = `${name}/UNHIDE_BAN_CANDIDATE`
export const UNHIDE_BAN_CANDIDATE_ERROR = `${name}/UNHIDE_BAN_CANDIDATE_ERROR`
export const UNHIDE_BAN_CANDIDATE_SUCCESS = `${name}/UNHIDE_BAN_CANDIDATE_SUCCESS`

export const UPDATE_NSFW_PICTURES = `${name}/UPDATE_NSFW_PICTURES`
export const UPDATE_NSFW_PICTURES_ERROR = `${name}/UPDATE_NSFW_PICTURES_ERROR`
export const UPDATE_NSFW_PICTURES_SUCCESS = `${name}/UPDATE_NSFW_PICTURES_SUCCESS`

export const REFRESH_BLACKLISTS = `${name}/REFRESH_BLACKLISTS`
export const REFRESH_BLACKLISTS_ERROR = `${name}/REFRESH_BLACKLISTS_ERROR`
export const REFRESH_BLACKLISTS_SUCCESS = `${name}/REFRESH_BLACKLISTS_SUCCESS`

export const REMOVE_BLACKLIST = `${name}/REMOVE_BLACKLIST`
export const REMOVE_BLACKLIST_ERROR = `${name}/REMOVE_BLACKLIST_ERROR`
export const REMOVE_BLACKLIST_SUCCESS = `${name}/REMOVE_BLACKLIST_SUCCESS`

export const REFRESH_FOLLOWERS = `${name}/REFRESH_FOLLOWERS`
export const REFRESH_FOLLOWERS_ERROR = `${name}/REFRESH_FOLLOWERS_ERROR`
export const REFRESH_FOLLOWERS_SUCCESS = `${name}/REFRESH_FOLLOWERS_SUCCESS`
export const FETCH_FOLLOWERS = `${name}/FETCH_FOLLOWERS`
export const FETCH_FOLLOWERS_ERROR = `${name}/FETCH_FOLLOWERS_ERROR`
export const FETCH_FOLLOWERS_SUCCESS = `${name}/FETCH_FOLLOWERS_SUCCESS`

export const REFRESH_FOLLOWINGS = `${name}/REFRESH_FOLLOWINGS`
export const REFRESH_FOLLOWINGS_ERROR = `${name}/REFRESH_FOLLOWINGS_ERROR`
export const REFRESH_FOLLOWINGS_SUCCESS = `${name}/REFRESH_FOLLOWINGS_SUCCESS`
export const FETCH_FOLLOWINGS = `${name}/FETCH_FOLLOWINGS`
export const FETCH_FOLLOWINGS_ERROR = `${name}/FETCH_FOLLOWINGS_ERROR`
export const FETCH_FOLLOWINGS_SUCCESS = `${name}/FETCH_FOLLOWINGS_SUCCESS`

export const REMOVE_POST_COMMENT = `${name}/REMOVE_POST_COMMENT`
export const REMOVE_POST_COMMENT_ERROR = `${name}/REMOVE_POST_COMMENT_ERROR`
export const REMOVE_POST_COMMENT_SUCCESS = `${name}/REMOVE_POST_COMMENT_SUCCESS`
