import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import {
  hooks,
  Icon,
  Input,
  Text,
  theme,
  TouchableOpacity,
  View,
} from '../../common'

export const AddNewBlacklistEntry = ({ onSubmit, type }) => {
  const [value, setValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef(null)

  const onPressSave = () => {
    if (typeof onSubmit === 'function') {
      onSubmit({ type, value })
    }
    setValue('')
  }

  const onBlur = () => {
    setIsEditing(false)
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit: onPressSave,
  })

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      setIsEditing(false)
      setValue('')
    },
  })

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing])

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        borderBottomStyle: 'solid',
        borderBottomWidth: 0.5,
        borderBottomColor: theme.colors.border,
        paddingBottom: 6,
      }}>
      {isEditing ? (
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingTop: 12,
          }}>
          <View style={{ flex: 1 }} />
          <View style={{ flex: 5 }}>
            <Input
              forwardRef={inputRef}
              onBlur={onBlur}
              onChange={(e) => setValue(e.target.value)}
              placeholder="New Entry..."
              value={value}
              style={{ marginBottom: 0 }}
            />
          </View>
          <View style={{ flex: 2 }}>
            <Text onMouseDown={onPressSave} center color={theme.colors.primary}>
              Save
            </Text>
          </View>
        </View>
      ) : (
        <TouchableOpacity
          onPress={() => setIsEditing(true)}
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingTop: 14,
          }}>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Icon icon={faPlusCircle} color={theme.colors.primary} size="md" />
          </View>
          <View style={{ flex: 7 }}>
            <Text>{`Add New ${type} Entry`}</Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  )
}
