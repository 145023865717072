export const CHANGE_NOTIFICATION_STATUS = 'followers/CHANGE_NOTIFICATION_STATUS'
export const CHANGE_NOTIFICATION_STATUS_ERROR = 'followers/CHANGE_NOTIFICATION_STATUS_ERROR' // prettier-ignore
export const CHANGE_NOTIFICATION_STATUS_SUCCESS = 'followers/CHANGE_NOTIFICATION_STATUS_SUCCESS' // prettier-ignore

export const FETCH_FOLLOWERS = 'followers/FETCH_FOLLOWERS'
export const FETCH_FOLLOWERS_ERROR = 'followers/FETCH_FOLLOWERS_ERROR'
export const FETCH_FOLLOWERS_SUCCESS = 'followers/FETCH_FOLLOWERS_SUCCESS'

export const FETCH_FOLLOWINGS = 'followers/FETCH_FOLLOWINGS'
export const FETCH_FOLLOWINGS_ERROR = 'followers/FETCH_FOLLOWINGS_ERROR'
export const FETCH_FOLLOWINGS_SUCCESS = 'followers/FETCH_FOLLOWINGS_SUCCESS'

export const FOLLOW = 'followers/FOLLOW'
export const FOLLOW_ERROR = 'followers/FOLLOW_ERROR'
export const FOLLOW_SUCCESS = 'followers/FOLLOW_SUCCESS'

export const FOLLOW_MULTIPLE = 'followers/FOLLOW_MULTIPLE'
export const FOLLOW_MULTIPLE_ERROR = 'followers/FOLLOW_MULTIPLE_ERROR'
export const FOLLOW_MULTIPLE_SUCCESS = 'followers/FOLLOW_MULTIPLE_SUCCESS'

export const REFRESH_FOLLOWERS = 'followers/REFRESH_FOLLOWERS'
export const REFRESH_FOLLOWERS_ERROR = 'followers/REFRESH_FOLLOWERS_ERROR'
export const REFRESH_FOLLOWERS_SUCCESS = 'followers/REFRESH_FOLLOWERS_SUCCESS'

export const REFRESH_FOLLOWINGS = 'followers/REFRESH_FOLLOWINGS'
export const REFRESH_FOLLOWINGS_ERROR = 'followers/REFRESH_FOLLOWINGS_ERROR'
export const REFRESH_FOLLOWINGS_SUCCESS = 'followers/REFRESH_FOLLOWINGS_SUCCESS'

export const UNFOLLOW = 'followers/UNFOLLOW'
export const UNFOLLOW_ERROR = 'followers/UNFOLLOW_ERROR'
export const UNFOLLOW_SUCCESS = 'followers/UNFOLLOW_SUCCESS'
