import { INTERVAL_TYPE } from '@tellonym/enums/lib/Stats'
import { DatePicker, Radio, Select } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { View, styleSheets } from '../../common'
import { useQueryParams } from '../../common/hooks'
import { dateTypes } from '../constants'
import { snakeToCamelCase } from '../services'

const dateTypeOptionDefaults = Object.keys(dateTypes).map((dateType) => ({
  value: dateType,
  label: snakeToCamelCase(dateType),
}))

const intervalOptions = [
  { value: INTERVAL_TYPE.DAILY, label: 'daily' },
  { value: INTERVAL_TYPE.WEEKLY, label: 'weekly' },
]

const getDisabledDate = (current) =>
  current && current > moment().subtract(1, 'day').endOf('day')

/**
 * defaults: dateType, selectedInterval, startDate, endDate
 */
export const TimeFramePicker = ({
  dateTypeOptions = dateTypeOptionDefaults,
  defaults = {},
  isLoading,
  isIntervalTypeVisible = true,
  onChange,
  onChangeDateType: onChangeDateTypeCallback,
  onChangeDates: onChangeDatesCallback,
  onChangeInterval: onChangeIntervalCallback,
  style,
}) => {
  const [selectedInterval, setSelectedInterval] = useState(
    defaults.selectedInterval
  )
  const [selectedDateType, setSelectedDateType] = useState(defaults.dateType)

  const defaultDates =
    defaults.startDate || defaults.endDate
      ? [moment(defaults.startDate), moment(defaults.endDate)]
      : undefined

  const [selectedDates, setSelectedDates] = useState(defaultDates)

  const onChangeDateType = (dateType) => {
    if (dateType !== dateTypes.CUSTOM_DATE) {
      setSelectedDates([null, null])
    }

    setSelectedDateType(dateType)
    onChangeDateTypeCallback?.(dateType)
    onChange?.({
      dateType,
      dates: selectedDates,
      intervalType: selectedInterval,
    })
  }

  const onChangeDates = (dates) => {
    let dateType = selectedDateType

    if (dates?.every((d) => d.isValid())) {
      if (selectedDateType !== dateTypes.CUSTOM_DATE) {
        setSelectedDateType(dateTypes.CUSTOM_DATE)

        dateType = dateTypes.CUSTOM_DATE
      }
    }

    setSelectedDates(dates)
    onChangeDatesCallback?.(dates, dateType)
    onChange?.({ dateType, dates, intervalType: selectedInterval })
  }

  const onChangeInterval = (e) => {
    setSelectedInterval(e.target.value)
    onChangeIntervalCallback?.(e.target.value)
    onChange?.({
      dateType: selectedDateType,
      dates: selectedDates,
      intervalType: e.target.value,
    })
  }

  return (
    <>
      {isIntervalTypeVisible && (
        <Radio.Group
          disabled={isLoading}
          buttonStyle="solid"
          onChange={onChangeInterval}
          options={intervalOptions}
          optionType="button"
          value={selectedInterval}
          style={styleSheets.margin.bottom[16]}
        />
      )}

      <View
        style={[
          styleSheets.flex.direction.row,
          styleSheets.margin.bottom[16],
          style,
        ]}>
        <Select
          disabled={isLoading}
          options={dateTypeOptions}
          onChange={onChangeDateType}
          value={selectedDateType}
          style={{ width: 160 }}
        />
        <DatePicker.RangePicker
          disabled={isLoading || selectedDateType === dateTypes.SYNCED}
          defaultPickerValue={[
            moment().subtract(31, 'days'),
            moment().subtract(1, 'day'),
          ]}
          disabledDate={getDisabledDate}
          onChange={onChangeDates}
          value={selectedDates}
          style={styleSheets.margin.left[12]}
        />
      </View>
    </>
  )
}

/**
 * Uses TimeFramePicker component and adds query param logic to it. This means all values are
 * part of the url, defaults are read from it and values get updated.
 *
 * @param {{defaultQueryParams}} param0
 * @returns
 */
export const TimeFramePickerQueryParam = ({ defaultQueryParams, ...props }) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultQueryParams)

  const defaults = React.useMemo(
    () => ({
      dateType: queryParams.dateType,
      selectedInterval: Number(queryParams.intervalType),
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
    }),
    [
      queryParams.dateType,
      queryParams.endDate,
      queryParams.intervalType,
      queryParams.startDate,
    ]
  )

  const onChangeDateType = React.useCallback(
    (dateType) => {
      if (dateType !== dateTypes.CUSTOM_DATE) {
        setQueryParams({ dateType, startDate: '', endDate: '' })
      } else {
        setQueryParams({ dateType })
      }
    },
    [setQueryParams]
  )

  const onChangeDates = React.useCallback(
    (dates) => {
      if (dates?.every((d) => d.isValid())) {
        if (queryParams.dateType !== dateTypes.CUSTOM_DATE) {
          setQueryParams({
            startDate: dates[0].format('YYYY-MM-DD'),
            endDate: dates[1].format('YYYY-MM-DD'),
            dateType: dateTypes.CUSTOM_DATE,
          })
        } else {
          setQueryParams({
            startDate: dates[0].format('YYYY-MM-DD'),
            endDate: dates[1].format('YYYY-MM-DD'),
          })
        }
      } else if (dates === null) {
        setQueryParams({ startDate: '', endDate: '' })
      }
    },
    [setQueryParams]
  )

  const onChangeInterval = React.useCallback(
    (intervalType) => {
      setQueryParams({ intervalType })
    },
    [setQueryParams]
  )

  return (
    <TimeFramePicker
      {...props}
      defaults={defaults}
      onChangeDateType={onChangeDateType}
      onChangeInterval={onChangeInterval}
      onChangeDates={onChangeDates}
    />
  )
}
