import { uuidv4 } from '@tellonym/core/helpers'
import P from 'prop-types'
import React from 'react'
import { Radium } from '../hocs/Radium'
import { fontSizes } from '../styles/fontSizes'
import { theme } from '../styles/theme'
import { Text } from './Text'

const defaultStyle = {
  marginBottom: 24,
  fontSize: fontSizes.default,
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  borderTop: 0,
  outline: 'none',
}

const greyStyles = {
  backgroundColor: theme.colors.inputBackground,
  borderRadius: 25,
  paddingHorizontal: 16,
  paddingBottom: 12,
  paddingTop: 12,
  textAlign: 'center',
}

const HtmlInput = Radium(({ forwardRef, ...props }) => (
  <input ref={forwardRef} {...props} />
))

const HtmlTextArea = Radium(({ forwardRef, ...props }) => (
  <textarea ref={forwardRef} {...props} />
))

class Input extends React.Component {
  state = {
    id: this.props.name ?? uuidv4(),
    rows: this.props.rows ?? 1,
  }

  _setRows = (e) => {
    try {
      const { maxRows, multiline } = this.props

      if (multiline && maxRows) {
        const el =
          e?.target ?? document.querySelector(`[id='${this.state.id}']`)

        const style = window.getComputedStyle(el)

        const lineHeight = parseInt(style.lineHeight, 10)

        const previousRows = el.rows

        el.rows = this.props.rows ?? 1

        const currentRows = Math.floor(el.scrollHeight / lineHeight)

        if (currentRows === previousRows) {
          el.rows = currentRows
        }

        if (currentRows >= maxRows) {
          el.rows = maxRows
          el.scrollTop = el.scrollHeight
        }

        this.setState({
          rows: currentRows < maxRows ? currentRows : maxRows,
        })
      }
    } catch (e) {
      // noop
    }
  }

  componentDidMount() {
    this._setRows()
  }

  _onChangeHandler = (e) => {
    const { onChange, onChangeText } = this.props

    if (typeof onChangeText === 'function') {
      onChangeText(e.target.value)
    } else if (typeof onChange === 'function') {
      onChange(e)
    }

    this._setRows(e)
  }

  render() {
    const {
      grey,
      label,
      labelStyle,
      maxRows, // eslint-disable-line no-unused-vars
      multiline,
      name,
      style = {},
      onChangeText, // eslint-disable-line no-unused-vars
      ...props
    } = this.props
    const Component = multiline ? HtmlTextArea : HtmlInput

    return (
      <React.Fragment>
        {label && (
          <Text
            type="small"
            bold
            style={{ marginLeft: 16, marginBottom: 4, ...labelStyle }}>
            {label}
          </Text>
        )}

        <Component
          id={this.state.id}
          className="italicPlaceholder"
          name={name}
          {...props}
          rows={multiline ? this.state.rows : undefined}
          onChange={this._onChangeHandler}
          style={[defaultStyle, grey && greyStyles, style]}
        />
      </React.Fragment>
    )
  }
}

export { Input }

Input.propTypes = {
  forwardRef: P.oneOfType([P.func, P.object]),
  grey: P.bool,
  label: P.string,
  labelStyle: P.object, // eslint-disable-line react/forbid-prop-types
  multiline: P.bool,
  name: P.string,
  onChange: P.func,
  onChangeText: P.func,
  placeholder: P.string,
  value: P.string.isRequired,
  style: P.object, // eslint-disable-line react/forbid-prop-types
  type: P.string,
}

Input.defaultProps = {
  forwardRef: undefined,
  grey: false,
  label: undefined,
  labelStyle: undefined,
  multiline: false,
  name: undefined,
  onChange: undefined,
  onChangeText: undefined,
  placeholder: undefined,
  style: {},
  type: 'text',
}
