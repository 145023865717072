import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { POST_STATUS, POST_TYPE } from '@tellonym/enums/lib/post'
import {
  SENDER_STATUS,
  TELL_STATUS,
  TELL_TYPE_NEW,
} from '@tellonym/enums/lib/Tell'
import { Dropdown, Menu, message, Modal, Tag } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert,
  ItemParentTag,
  Link,
  moment,
  Text,
  TextTranslatable,
  theme,
  TouchableOpacity,
  View,
} from '../../common'
import { whitelistTell } from '../../filter/actions'
import {
  blockAnswer,
  blockPost,
  blockTell,
  copyItems,
  restoreAnswer,
  restoreTell,
  setSelection,
} from '../actions'
import { AdditionalInfos } from './AdditionalInfos'
import { AnswerPicture } from './AnswerPicture'

const { ANONYMOUS, HIDDEN, PUBLIC } = SENDER_STATUS
const senderStatuses = {
  [ANONYMOUS]: 'anonymous',
  [HIDDEN]: 'hidden',
  [PUBLIC]: 'public',
}

const { ANSWER, REQUEST_TELL, COMMUNITY_TELL_ANSWER } = POST_TYPE
const postTypes = {
  [ANSWER]: 'answer',
  [REQUEST_TELL]: 'request tell',
  [COMMUNITY_TELL_ANSWER]: 'community tell answer',
}

const DROPDOWN_ITEMS = {
  BLOCK: 'BLOCK',
  COPY_DUMP: 'COPY_DUMP',
  MORE_INFO: 'MORE_INFO',
  RESTORE: 'RESTORE',
}

const DropdownMenu = ({ item, type, spamItemId }) => {
  const dispatch = useDispatch()
  const isAnswer = item.postType === ANSWER
  const isPost = item.postType === REQUEST_TELL
  const _userId = type === 'sentTells' ? item.senderId : item.receiverId
  const hasRestore =
    [TELL_STATUS.BLOCKED, TELL_STATUS.DELETED].includes(item.tellStatus) ||
    [
      POST_STATUS.DELETED,
      POST_STATUS.WAITING_FOR_REVIEW,
      POST_STATUS.BLOCKED,
    ].includes(item.postStatus)

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case DROPDOWN_ITEMS.BLOCK: {
        Modal.confirm({
          title: 'Do you really want to delete this?',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            const payload = {
              id: item.id,
              _type: type,
              _userId,
              _spamItemId: spamItemId,
            }

            if (isAnswer) {
              dispatch(blockAnswer(payload))
            } else if (isPost) {
              dispatch(blockPost(payload))
            } else {
              dispatch(blockTell(payload))
            }
          },
        })
        break
      }

      case DROPDOWN_ITEMS.COPY_DUMP: {
        dispatch(
          copyItems({ items: [item], type: isAnswer ? 'answers' : 'tells' })
        )
        break
      }

      case DROPDOWN_ITEMS.MORE_INFO: {
        AdditionalInfos.show({ item })
        break
      }

      case DROPDOWN_ITEMS.RESTORE: {
        Modal.confirm({
          title: 'Do you really want to restore this?',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            const payload = {
              id: item.id,
              _type: type,
              _userId,
              _spamItemId: spamItemId,
            }

            if (isAnswer) {
              dispatch(restoreAnswer(payload))
            } else {
              dispatch(restoreTell(payload))
            }
          },
        })
        break
      }

      default:
        message.error(
          'No action was configured. Contact the frontend team to reasolve this.'
        )
        break
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={DROPDOWN_ITEMS.MORE_INFO}>More Info</Menu.Item>

      {item.postType !== REQUEST_TELL && (
        <Menu.Item key={DROPDOWN_ITEMS.COPY_DUMP}>Copy Dump</Menu.Item>
      )}

      {hasRestore ? (
        <Menu.Item key={DROPDOWN_ITEMS.RESTORE}>Restore</Menu.Item>
      ) : (
        <Menu.Item key={DROPDOWN_ITEMS.BLOCK}>Block</Menu.Item>
      )}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <EllipsisOutlined
        style={{
          fontSize: theme.fontSizes.default,
          paddingRight: 16,
          paddingLeft: 16,
        }}
      />
    </Dropdown>
  )
}

const TellItemInfo = ({ name, onPress = undefined, to = undefined, value }) =>
  to ? (
    <Link to={to}>
      <View>
        <Text type="small" center color={theme.colors.placeholder}>
          {name}
        </Text>
        <Text
          type="small"
          center
          color={value ? theme.colors.primary : undefined}>
          {value || '-'}
        </Text>
      </View>
    </Link>
  ) : (
    <TouchableOpacity onPress={onPress}>
      <Text type="small" center color={theme.colors.placeholder}>
        {name}
      </Text>
      <Text
        type="small"
        center
        color={value ? theme.colors.primary : undefined}>
        {value || '-'}
      </Text>
    </TouchableOpacity>
  )

const AnswerHead = ({
  isTellTranslatable,
  hasTell = true,
  item,
  shouldTranslate,
}) => {
  const TextComponent = isTellTranslatable ? TextTranslatable : Text

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1 }}>
        {hasTell && (
          <TextComponent
            isAlwaysTranslatable={isTellTranslatable}
            shouldTranslate={shouldTranslate}
            style={{
              marginLeft: 16,
              marginBottom: 12,
              paddingLeft: 8,
              paddingVertical: 4,
              borderLeft: `3px solid ${theme.colors.border}`,
            }}>
            {item.tellContent}
          </TextComponent>
        )}
        <TextComponent
          isAlwaysTranslatable={isTellTranslatable}
          shouldTranslate={shouldTranslate}>
          {item.postContent}
        </TextComponent>
        <AnswerPicture media={item.media} />
      </View>
    </View>
  )
}

const getBadgeColor = ({ postType, tellStatus, tellType }) => {
  switch (true) {
    case tellType === TELL_TYPE_NEW.TELL && tellStatus === TELL_STATUS.BLOCKED:
    case postType === POST_STATUS.BLOCKED:
      return 'red'

    case tellType === TELL_TYPE_NEW.TELL && tellStatus === TELL_STATUS.DELETED:
    case postType === POST_STATUS.DELETED:
      return 'purple'

    default:
      return undefined
  }
}

const TypeBadge = ({ postStatus, tellStatus, tellType }) => {
  const isPostWithBadge = [
    POST_STATUS.DELETED,
    POST_STATUS.WAITING_FOR_REVIEW,
    POST_STATUS.BLOCKED,
  ].includes(postStatus)

  const isTellWithBadge = [TELL_STATUS.BLOCKED, TELL_STATUS.DELETED].includes(
    tellStatus
  )

  if ((isPostWithBadge || isTellWithBadge) === false) {
    return null
  }

  const statusString = isPostWithBadge
    ? POST_STATUS[postStatus]
    : TELL_STATUS[tellStatus].toLocaleLowerCase()
  const color = getBadgeColor({ postStatus, tellStatus, tellType })

  return <Tag color={color}>{statusString}</Tag>
}

const WhitelistButton = ({ onPress, spamItemId, tellId }) =>
  spamItemId && tellId ? (
    <TouchableOpacity
      onPress={onPress}
      style={{
        alignSelf: 'flex-start',
        alignItems: 'center',
        borderRadius: 14,
        backgroundColor: theme.colors.secondary,
        paddingVertical: 6,
        paddingHorizontal: 10,
      }}>
      <Text bold color="white" type="note">
        Whitelist
      </Text>
    </TouchableOpacity>
  ) : null

export const TellItem = ({
  isTellTranslatable = true,
  item,
  shouldTranslate,
  spamItemId,
  type,
  style = {},
}) => {
  const dispatch = useDispatch()
  const TextComponent = isTellTranslatable ? TextTranslatable : Text

  const isTellSelfSent = item.senderId === item.receiverId
  const isCommunityTellAnswer = item.postType === COMMUNITY_TELL_ANSWER
  const isAnswer = item.postType === ANSWER || isCommunityTellAnswer
  const isRequestTell = item.postType === REQUEST_TELL
  const createdAt =
    isAnswer || isRequestTell
      ? item.postCreatedAt
      : item.tellCreatedAt || item.createdAt
  const hasSenderInfo = !isRequestTell

  const userId = type === 'sentTells' ? item.senderId : item.receiverId

  const onChangeSelection = (e) => {
    dispatch(
      setSelection({
        type,
        tellId: item.id,
        userId,
        isSelected: e.target.checked,
      })
    )
  }

  const _whitelistTell = () => {
    dispatch(whitelistTell({ id: spamItemId, tellId: item.id }))
  }

  return (
    <View
      style={[
        {
          backgroundColor: theme.colors.background,
          borderRadius: 25,
          padding: 20,
          marginLeft: 6,
          marginRight: 6,
          marginTop: 6,
          marginBottom: 12,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
        },
        style,
      ]}>
      <View
        style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
        <View
          style={{
            flexDirection: 'row',
            paddingHorizontal: 8,
            paddingVertical: 2,
          }}>
          <TypeBadge
            postStatus={item.postStatus}
            tellStatus={item.tellStatus}
            tellType={item.tellType}
          />
          <WhitelistButton
            onPress={_whitelistTell}
            spamItemId={spamItemId}
            tellId={item.id}
          />
        </View>
        <DropdownMenu item={item} type={type} spamItemId={spamItemId} />
        <input
          type="checkbox"
          name="isSelected"
          checked={
            typeof item._isSelected === 'undefined' ? false : item._isSelected
          }
          onChange={onChangeSelection}
        />
      </View>
      {item.parent && <ItemParentTag item={item} style={{ marginLeft: 4 }} />}
      {isAnswer || isRequestTell ? (
        <AnswerHead
          isTellTranslatable={isTellTranslatable}
          hasTell={hasSenderInfo}
          item={item}
          shouldTranslate={shouldTranslate}
        />
      ) : (
        <TextComponent
          style={{ flex: 1 }}
          isAlwaysTranslatable={isTellTranslatable}
          shouldTranslate={shouldTranslate}>
          {item.content || item.tellContent}
        </TextComponent>
      )}
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          marginTop: 20,
        }}>
        {type === 'sentTells' ? (
          <TellItemInfo
            name="receiver"
            value={isTellSelfSent ? 'self sent' : item.receiverUsername}
            to={
              !!item.receiverId && !isTellSelfSent
                ? `/user/${item.receiverId}`
                : undefined
            }
          />
        ) : hasSenderInfo ? (
          <TellItemInfo
            name="sender"
            value={
              isCommunityTellAnswer
                ? 'community tell'
                : isTellSelfSent
                ? 'self sent'
                : item.senderUsername || item.senderIp
            }
            to={
              !!item.senderId && !isTellSelfSent
                ? `/user/${item.senderId}`
                : undefined
            }
          />
        ) : null}

        {[ANSWER, REQUEST_TELL, COMMUNITY_TELL_ANSWER].includes(
          item.postType
        ) && <TellItemInfo name="post type" value={postTypes[item.postType]} />}

        {hasSenderInfo && !isCommunityTellAnswer ? (
          <TellItemInfo
            name="sender status"
            value={senderStatuses[item.senderStatus]}
          />
        ) : null}

        {isCommunityTellAnswer ? (
          <React.Fragment>
            <TellItemInfo
              name="community"
              value={`${item.community?.communityName} (ID: ${item.community?.communityId})`}
            />
            <TellItemInfo
              name="community tell id"
              value={item.community?.communityTellId}
            />
          </React.Fragment>
        ) : null}

        <TellItemInfo
          name="created at"
          value={moment(createdAt).format('DD.MM.YYYY HH:mm')}
          onPress={() =>
            Alert.alert(
              'created at',
              moment(createdAt).format('DD.MM.YYYY, HH:mm:ss [(UTC]Z[)]'),
              {
                isCancelable: false,
              }
            )
          }
        />
        {isAnswer ? (
          <TellItemInfo name="likes count" value={item.likesCount} />
        ) : (
          <React.Fragment>
            <TellItemInfo
              name="deleted at"
              value={
                item.tellDeletedAt || item.postDeletedAt || item.deletedAt
                  ? moment(
                      item.tellDeletedAt || item.postDeletedAt || item.deletedAt
                    ).format('DD.MM.YYYY HH:mm')
                  : undefined
              }
              onPress={() =>
                Alert.alert(
                  'deleted at',
                  moment(
                    item.tellDeletedAt || item.postDeletedAt || item.deletedAt
                  ).format('DD.MM.YYYY, HH:mm:ss [(UTC]Z[)]'),
                  { isCancelable: false }
                )
              }
            />

            <TellItemInfo
              name="posted at"
              value={
                item.postCreatedAt
                  ? moment(item.postCreatedAt).format('DD.MM.YYYY HH:mm')
                  : undefined
              }
              onPress={() =>
                Alert.alert(
                  'posted at',
                  moment(item.postCreatedAt).format(
                    'DD.MM.YYYY, HH:mm:ss [(UTC]Z[)]'
                  ),
                  { isCancelable: false }
                )
              }
            />
          </React.Fragment>
        )}
      </View>
    </View>
  )
}
