export const ModalAppAd = 'ModalAppAd'
export const ModalFeatureAnnouncementDarkerSharingBackgrounds = 'ModalFeatureAnnouncementDarkerSharingBackgrounds' // prettier-ignore
export const ModalFeatureAnnouncementEitherOrGame = 'ModalFeatureAnnouncementEitherOrGame' // prettier-ignore
export const ModalFeatureAnnouncementTelloTokensMeetVisibilityBoosts = 'ModalFeatureAnnouncementTelloTokensMeetVisibilityBoosts' // prettier-ignore
export const ModalFeatureAnnouncementTellSharing = 'ModalFeatureAnnouncementTellSharing' // prettier-ignore
export const ModalMainLanguageSelection = 'ModalMainLanguageSelection'
export const ModalMultishare = 'ModalMultishare'
export const ModalMultisharePrompt = 'ModalMultisharePrompt'
export const ModalSentTell = 'ModalSentTell'
export const ModalSharePost = 'ModalSharePost'
export const ModalShareProfilePopup = 'ModalShareProfilePopup'
export const ModalStatusEmoji = 'ModalStatusEmoji'
export const ModalUnlockHint = 'ModalUnlockHint'
export const ModalVisibilityBoost = 'ModalVisibilityBoost'
export const ModalVisibilityBoostLocationPermission = 'ModalVisibilityBoostLocationPermission' // prettier-ignore
export const ModalVisibilityBoostPacks = 'ModalVisibilityBoostPacks'
export const ModalVisibilityBoostResults = 'ModalVisibilityBoostResults'
export const ModalWalletBalance = 'ModalWalletBalance'
