import { prop, pick } from 'ramda'
import { createSelector } from 'reselect'
import { name } from './types'

const getState = (state) => state[name]

export const getIsLoading = createSelector(getState, prop('isLoading'))

const getAvailableShortNames = createSelector(
  getState,
  prop('availableShortNames')
)

export const getAvailableShortNamesData = createSelector(
  getAvailableShortNames,
  prop('data')
)

export const getChShortnamesState = createSelector(
  getState,
  prop('chShortnames')
)

export const getChDiffState = createSelector(getState, prop('chDiff'))

const getChDiffData = createSelector(getChDiffState, prop('data'))

export const getChGraphData = (shortName) => (state) => {
  const diffData = getChDiffData(state)

  return (
    diffData.data[shortName].graphData ??
    pick(['ids', 'data'], diffData.data[shortName])
  )
}

const getDiff = createSelector(getState, prop('diff'))

export const getIsRefreshingDiff = (state) => state[name].diff.isRefreshing

export const getDiffData = createSelector(getDiff, prop('data'))

const getExperiments = createSelector(getState, prop('experiments'))

export const getIsRefreshingExperiments = (state) =>
  state[name].experiments.isRefreshing

export const getExperimentsData = createSelector(getExperiments, prop('data'))

export const getCsvPreview = (state) => state[name].csvPreview
export const getIsUploadingCsv = (state) => state[name].isUploadingCsv

export const getCompareKey = createSelector(
  getState,
  (state) => state.compareKey ?? 'oneWeek'
)
