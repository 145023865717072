import { getAccessToken } from '@tellonym/core/app/selectors'
import { getProfileId } from '@tellonym/core/profile/selectors'
import { history, _ } from '../../common'

export const stateInjection = (store) => (next) => (action) => {
  const isEvent = action.type.startsWith('events/')
  const isReduxOfflineAction = !isEvent && action.type.includes('Offline/')

  _.crumb({
    category: isEvent ? 'event' : 'action',
    message: action.type,
    data: {
      route: { routeName: history.location.pathname },
    },
  })

  if (isReduxOfflineAction) {
    return next(action)
  }

  const state = store.getState()

  const enhancedAction = {
    ...action,
    meta: {
      ...action.meta,
      createdAt: Date.now(),
      currentRoute: { routeName: history.location.pathname },
      setup: {
        accessToken: action.meta?.offline ? getAccessToken(state) : undefined,
        expId: undefined,
        userId: getProfileId(state),
      },
    },
  }

  return next(enhancedAction)
}
