export const alignContent = 'alignContent'
export const alignItems = 'alignItems'
export const alignSelf = 'alignSelf'
export const aspectRatio = 'aspectRatio'
export const backfaceVisibility = 'backfaceVisibility'
export const backgroundColor = 'backgroundColor'
export const borderBottomColor = 'borderBottomColor'
export const borderBottomEndRadius = 'borderBottomEndRadius'
export const borderBottomLeftRadius = 'borderBottomLeftRadius'
export const borderBottomRightRadius = 'borderBottomRightRadius'
export const borderBottomStartRadius = 'borderBottomStartRadius'
export const borderBottomWidth = 'borderBottomWidth'
export const borderColor = 'borderColor'
export const borderEndColor = 'borderEndColor'
export const borderEndWidth = 'borderEndWidth'
export const borderLeftColor = 'borderLeftColor'
export const borderLeftWidth = 'borderLeftWidth'
export const borderRadius = 'borderRadius'
export const borderRightColor = 'borderRightColor'
export const borderRightWidth = 'borderRightWidth'
export const borderStartColor = 'borderStartColor'
export const borderStartWidth = 'borderStartWidth'
export const borderStyle = 'borderStyle'
export const borderTopColor = 'borderTopColor'
export const borderTopEndRadius = 'borderTopEndRadius'
export const borderTopLeftRadius = 'borderTopLeftRadius'
export const borderTopRightRadius = 'borderTopRightRadius'
export const borderTopStartRadius = 'borderTopStartRadius'
export const borderTopWidth = 'borderTopWidth'
export const borderWidth = 'borderWidth'
export const bottom = 'bottom'
export const boxShadow = 'boxShadow'
export const color = 'color'
export const cursor = 'cursor'
export const decomposedMatrix = 'decomposedMatrix'
export const direction = 'direction'
export const display = 'display'
export const elevation = 'elevation'
export const end = 'end'
export const flex = 'flex'
export const flexBasis = 'flexBasis'
export const flexDirection = 'flexDirection'
export const flexGrow = 'flexGrow'
export const flexShrink = 'flexShrink'
export const flexWrap = 'flexWrap'
export const fontFamily = 'fontFamily'
export const fontSize = 'fontSize'
export const fontStyle = 'fontStyle'
export const fontVariant = 'fontVariant'
export const fontWeight = 'fontWeight'
export const height = 'height'
export const includeFontPadding = 'includeFontPadding'
export const justifyContent = 'justifyContent'
export const left = 'left'
export const letterSpacing = 'letterSpacing'
export const lineHeight = 'lineHeight'
export const margin = 'margin'
export const marginBottom = 'marginBottom'
export const marginEnd = 'marginEnd'
export const marginHorizontal = 'marginHorizontal'
export const marginLeft = 'marginLeft'
export const marginRight = 'marginRight'
export const marginStart = 'marginStart'
export const marginTop = 'marginTop'
export const marginVertical = 'marginVertical'
export const maxHeight = 'maxHeight'
export const maxWidth = 'maxWidth'
export const minHeight = 'minHeight'
export const minWidth = 'minWidth'
export const opacity = 'opacity'
export const overflow = 'overflow'
export const overlayColor = 'overlayColor'
export const padding = 'padding'
export const paddingBottom = 'paddingBottom'
export const paddingEnd = 'paddingEnd'
export const paddingHorizontal = 'paddingHorizontal'
export const paddingLeft = 'paddingLeft'
export const paddingRight = 'paddingRight'
export const paddingStart = 'paddingStart'
export const paddingTop = 'paddingTop'
export const paddingVertical = 'paddingVertical'
export const position = 'position'
export const resizeMode = 'resizeMode'
export const right = 'right'
export const rotation = 'rotation'
export const scaleX = 'scaleX'
export const scaleY = 'scaleY'
export const shadowColor = 'shadowColor'
export const shadowOffset = 'shadowOffset'
export const shadowOpacity = 'shadowOpacity'
export const shadowRadius = 'shadowRadius'
export const start = 'start'
export const textAlign = 'textAlign'
export const textAlignVertical = 'textAlignVertical'
export const textDecorationColor = 'textDecorationColor'
export const textDecorationLine = 'textDecorationLine'
export const textDecorationStyle = 'textDecorationStyle'
export const textShadowColor = 'textShadowColor'
export const textShadowOffset = 'textShadowOffset'
export const textShadowRadius = 'textShadowRadius'
export const textTransform = 'textTransform'
export const tintColor = 'tintColor'
export const top = 'top'
export const transform = 'transform'
export const transformMatrix = 'transformMatrix'
export const translateX = 'translateX'
export const translateY = 'translateY'
export const width = 'width'
export const writingDirection = 'writingDirection'
export const zIndex = 'zIndex'
