import { POST_STATUS } from '@tellonym/enums/lib/post'
import { TELL_STATUS, TELL_TYPE_NEW } from '@tellonym/enums/lib/Tell'
import { normalize, uuidv4 } from '@tellonym/core/helpers'
import { mergeDeepRight, mergeRight, omit } from 'ramda'
import {
  BLOCK_ANSWER_SUCCESS,
  BLOCK_TELL_SUCCESS,
  RESTORE_ANSWER_SUCCESS,
  RESTORE_TELL_SUCCESS,
} from '../moderator/types'
import * as t from './types'

export const { name } = t

export const initialState = {
  users: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  error: null,
  lastBlocked: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {
      ids: [],
      data: {},
    },
  },
  lastDetectedSpam: {
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    isRefreshingItem: false,
    data: {
      ids: [],
      data: {},
    },
  },
  posts: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
  tells: {
    _rerenderItem: { ids: [], count: 0 },
    hasMore: false,
    isFetching: false,
    isRefreshing: false,
    data: {},
  },
}

export const reducer = (state = initialState, { type, payload, meta = {} }) => {
  switch (type) {
    case t.FETCH_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: true,
        },
      }

    case t.FETCH_USERS_ERROR:
      return {
        ...state,
        error: payload.error,
        users: {
          ...state.users,
          isFetching: false,
        },
      }

    case t.FETCH_USERS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.users
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.users.data[spamItemId].ids, ...normalized.ids]),
      ]

      return {
        ...state,
        users: {
          ...state.users,
          hasMore:
            hasMore && ids.length > state.users.data[spamItemId].ids.length,
          isFetching: false,
          data: {
            ...state.users.data,
            [spamItemId]: {
              ids,
              data: {
                ...state.users.data[spamItemId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FETCH_LAST_DETECTED_SPAM:
      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          isFetching: true,
        },
      }

    case t.FETCH_LAST_DETECTED_SPAM_ERROR:
      return {
        ...state,
        error: payload.error,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          isFetching: false,
        },
      }

    case t.FETCH_LAST_DETECTED_SPAM_SUCCESS: {
      const { data, hasMore } = payload
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.lastDetectedSpam.data.ids, ...normalized.ids]),
      ]

      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          hasMore:
            hasMore && ids.length > state.lastDetectedSpam.data.ids.length,
          isFetching: false,
          data: {
            ids,
            data: {
              ...state.lastDetectedSpam.data.data,
              ...normalized.data,
            },
          },
        },
      }
    }

    case t.FETCH_POSTS:
      return {
        ...state,
        posts: {
          ...state.posts,
          isFetching: true,
        },
      }

    case t.FETCH_POSTS_ERROR:
      return {
        ...state,
        error: payload.error,
        posts: {
          ...state.posts,
          isFetching: false,
        },
      }

    case t.FETCH_POSTS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.posts
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.posts.data[spamItemId].ids, ...normalized.ids]),
      ]

      return {
        ...state,
        posts: {
          ...state.posts,
          hasMore:
            hasMore && ids.length > state.posts.data[spamItemId].ids.length,
          isFetching: false,
          data: {
            ...state.posts.data,
            [spamItemId]: {
              ids,
              data: {
                ...state.posts.data[spamItemId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FETCH_TELLS:
      return {
        ...state,
        tells: {
          ...state.tells,
          isFetching: true,
        },
      }

    case t.FETCH_TELLS_ERROR:
      return {
        ...state,
        error: payload.error,
        tells: {
          ...state.tells,
          isFetching: false,
        },
      }

    case t.FETCH_TELLS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)
      const ids = [
        ...new Set([...state.tells.data[spamItemId].ids, ...normalized.ids]),
      ]

      return {
        ...state,
        tells: {
          ...state.tells,
          hasMore:
            hasMore && ids.length > state.tells.data[spamItemId].ids.length,
          isFetching: false,
          data: {
            ...state.tells.data,
            [spamItemId]: {
              ids,
              data: {
                ...state.tells.data[spamItemId].data,
                ...normalized.data,
              },
            },
          },
        },
      }
    }

    case t.FILTER_LAST_DETECTED_SPAM: {
      const { spamItemType } = payload
      const { ids, data } = state.lastDetectedSpam.data
      const filteredData = ids.reduce((acc, id) => {
        const item = data[id]

        if (spamItemType === undefined || item.type === spamItemType) {
          return {
            ...acc,
            [id]: { ...item, _isHidden: false },
          }
        }
        return {
          ...acc,
          [id]: { ...item, _isHidden: true },
        }
      }, {})

      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          data: {
            ...state.lastDetectedSpam.data,
            data: filteredData,
          },
        },
      }
    }

    case t.REFRESH_LAST_DETECTED_SPAM_ITEM:
      return mergeDeepRight(state, {
        lastDetectedSpam: { isRefreshingItem: true },
      })

    case t.REFRESH_LAST_DETECTED_SPAM_ITEM_ERROR:
      return mergeDeepRight(state, {
        lastDetectedSpam: { isRefreshingItem: false },
      })

    case t.REFRESH_LAST_DETECTED_SPAM_ITEM_SUCCESS: {
      const item = payload
      const hasItem = state.lastDetectedSpam.data.ids.includes(item.id)
      const data = hasItem
        ? state.lastDetectedSpam.data
        : {
            ids: [...new Set([...state.lastDetectedSpam.data.ids, item.id])],
            data: mergeRight(state.lastDetectedSpam.data, { [item.id]: item }),
          }

      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          isRefreshingItem: false,
          data,
        },
      }
    }

    case t.REFRESH_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          isRefreshing: true,
        },
      }

    case t.REFRESH_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          isRefreshing: false,
        },
        error: payload,
      }

    case t.REFRESH_USERS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.users
      const normalized = normalize(data)

      return {
        ...state,
        users: {
          ...state.users,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.users.data,
            [spamItemId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_LAST_BLOCKED_DATA:
      return {
        ...state,
        lastBlocked: {
          ...state.lastBlocked,
          isRefreshing: true,
        },
      }

    case t.REFRESH_LAST_BLOCKED_DATA_ERROR:
      return {
        ...state,
        error: payload,
        lastBlocked: {
          ...state.lastBlocked,
          isRefreshing: false,
        },
      }

    case t.REFRESH_LAST_BLOCKED_DATA_SUCCESS: {
      const { detectedTells } = payload
      const { data, hasMore } = detectedTells
      const normalized = normalize(
        data.map((item) => {
          item.id = uuidv4()
          return item
        })
      )

      return {
        ...state,
        lastBlocked: {
          ...state.lastBlocked,
          data: normalized,
          hasMore,
          isRefreshing: false,
        },
      }
    }

    case t.REFRESH_LAST_DETECTED_SPAM:
      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          isRefreshing: true,
        },
      }

    case t.REFRESH_LAST_DETECTED_SPAM_ERROR:
      return {
        ...state,
        error: payload,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          isRefreshing: false,
        },
      }

    case t.REFRESH_LAST_DETECTED_SPAM_SUCCESS: {
      const { data, hasMore } = payload
      const normalized = normalize(data)

      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          data: normalized,
          hasMore,
          isRefreshing: false,
        },
      }
    }

    case t.REFRESH_POSTS:
      return {
        ...state,
        posts: {
          ...state.posts,
          isRefreshing: true,
        },
      }

    case t.REFRESH_POSTS_ERROR:
      return {
        ...state,
        error: payload,
        posts: {
          ...state.posts,
          isRefreshing: false,
        },
      }

    case t.REFRESH_POSTS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.posts
      const normalized = normalize(data)

      return {
        ...state,
        posts: {
          ...state.posts,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.posts.data,
            [spamItemId]: normalized,
          },
        },
      }
    }

    case t.REFRESH_TELLS:
      return {
        ...state,
        tells: {
          ...state.tells,
          isRefreshing: true,
        },
      }

    case t.REFRESH_TELLS_ERROR:
      return {
        ...state,
        error: payload,
        tells: {
          ...state.tells,
          isRefreshing: false,
        },
      }

    case t.REFRESH_TELLS_SUCCESS: {
      const { spamItemId } = meta
      const { data, hasMore } = payload.tells
      const normalized = normalize(data)

      return {
        ...state,
        tells: {
          ...state.tells,
          hasMore,
          isRefreshing: false,
          data: {
            ...state.tells.data,
            [spamItemId]: normalized,
          },
        },
      }
    }

    case RESTORE_ANSWER_SUCCESS:
      return mergeDeepRight(state, {
        posts: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.posts._rerenderItem.count + 1,
          },
          data: {
            [meta._spamItemId]: {
              data: {
                [meta.id]: {
                  postStatus: POST_STATUS.ACTIVE,
                },
              },
            },
          },
        },
      })

    case BLOCK_ANSWER_SUCCESS:
      return mergeDeepRight(state, {
        posts: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.posts._rerenderItem.count + 1,
          },
          data: {
            [meta._spamItemId]: {
              data: {
                [meta.id]: {
                  postStatus: POST_STATUS.BLOCKED,
                },
              },
            },
          },
        },
      })

    case RESTORE_TELL_SUCCESS:
      return mergeDeepRight(state, {
        tells: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.tells._rerenderItem.count + 1,
          },
          data: {
            [meta._spamItemId]: {
              data: {
                [meta.id]: {
                  tellType: TELL_TYPE_NEW.TELL,
                },
              },
            },
          },
        },
      })

    case BLOCK_TELL_SUCCESS:
      return mergeDeepRight(state, {
        tells: {
          _rerenderItem: {
            ids: [meta.id],
            count: state.tells._rerenderItem.count + 1,
          },
          data: {
            [meta._spamItemId]: {
              data: {
                [meta.id]: {
                  tellStatus: TELL_STATUS.BLOCKED,
                },
              },
            },
          },
        },
      })

    case t.UNDO_SPAM_ITEM: {
      const { spamItemId } = payload
      const newIds = state.lastDetectedSpam.data.ids.filter(
        (id) => id !== spamItemId
      )

      return {
        ...state,
        lastDetectedSpam: {
          ...state.lastDetectedSpam,
          data: {
            ids: newIds,
            data: omit([spamItemId], state.lastDetectedSpam.data.data),
          },
        },
      }
    }

    case t.UNDO_SPAM_ITEM_ERROR:
      return {
        ...state,
        error: payload.error,
      }

    case t.WHITELIST_TELL_ERROR:
      return {
        ...state,
        error: payload.error,
      }

    case t.WHITELIST_TELL:
    case t.WHITELIST_TELL_SUCCESS:
    default:
      return state
  }
}
